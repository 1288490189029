import { useCallback, useEffect, useState } from "react"

import { Category, GroupedCategories } from "@doktor-se/bones-ui/dist/web-shared/types"

import { groupCategories } from "lib/format"
import { useAppSelector, useSubcategories } from "lib/hooks"
import { selectCategoriesPerRole } from "reducers/categories"

import SearchCategories from "./SearchCategories"

interface SearchCategoriesWrapperProps {
  onSelectCategory: (category: Category) => void
}

const SearchCategoriesWrapper = ({ onSelectCategory }: SearchCategoriesWrapperProps) => {
  const categories = useAppSelector(selectCategoriesPerRole)
  const [filteredCategories, setFilteredCategories] = useState<GroupedCategories[]>([])
  const subcategories = useSubcategories(categories)
  const [selectedParentCategory, setSelectedParentCategory] = useState<Category>()
  const filterCategories = useCallback(() => {
    setFilteredCategories(
      groupCategories(categories.filter(c => !c.parentId && !Object.keys(subcategories).includes(c.id.toString())))
    )
  }, [categories, subcategories])

  useEffect(() => {
    filterCategories()
  }, [filterCategories])

  const categoryOnClick = (category: Category) => {
    if (subcategories[category.id]) {
      setFilteredCategories(subcategories[category.id])
      setSelectedParentCategory(category)
    } else {
      onSelectCategory(category)
    }
  }

  const closeSubcategory = () => {
    filterCategories()
    setSelectedParentCategory(undefined)
  }

  return (
    <SearchCategories
      categories={filteredCategories}
      subcategories={Object.keys(subcategories).map(id => categories.find(c => c.id.toString() === id)!)}
      changeCategory={category => categoryOnClick(category)}
      closeSubcategory={closeSubcategory}
      selectedParentCategory={selectedParentCategory}
    />
  )
}

export default SearchCategoriesWrapper
