import { ApiErrorV2 } from "@doktor-se/bones-ui/dist/web-shared/classes"

import { handleErrorsV2 } from "api/error/handler"
import { adaptationApiFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"
import { loadPaymentResponse } from "reducers/payment/payment.reducer"

export interface PayconiqResponse {
  amount: number
  currency: "EUR"
  deeplink: string
}

export const createPayconiqPayment =
  (amount: number, token: string, description: string, conversationId: string): Thunk =>
  async dispatch => {
    const data = {
      amount,
      payconiq_token: token,
      description,
      conversation_id: conversationId
    }

    try {
      const responseObject = await dispatch(
        adaptationApiFetch({
          url: `/payment/payconiq`,
          incomingOptions: {
            method: "POST",
            body: JSON.stringify(data)
          },
          config: {
            errorHandling: "v2"
          }
        })
      )

      await dispatch(loadPaymentResponse({ conversationId, response: responseObject }))
    } catch (error: any) {
      await dispatch(handlePayconiqErrors(error))
    }
  }

const handlePayconiqErrors =
  (error: ApiErrorV2): Thunk =>
  dispatch => {
    let customMessage = "payconiq.payment.error"
    if (error.status === 400 && error.errors?.length) {
      const firstError = error.errors[0]
      if (firstError.code === "error.proximus.payconiq.payment.token.invalid") {
        customMessage = "payment.token.invalid"
      }
    }

    dispatch(handleErrorsV2({ error, customMessageLokaliseKey: customMessage }))
  }
