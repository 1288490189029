import React from "react"

interface IconRightArrowProps extends React.SVGProps<SVGSVGElement> {
  isActive: boolean
}

export const IconRightArrow: React.FC<IconRightArrowProps> = ({ isActive, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={15} height={15} viewBox="0 0 15 15" {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(.5 .305)">
        <circle cx="7.056" cy="7.056" r="7.056" fill={isActive ? "#A7C2C8" : "#B4B4B4"} />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.084"
          d="M6.202 4.615L8.633 7 6.271 9.497"
        />
      </g>
    </svg>
  )
}

export default IconRightArrow
