import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import classnames from "classnames"

import { ICONS, SearchInput, StaffHeading4, StaffHeading5 } from "@doktor-se/bones-ui"
import { SymptomBubble } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Category, GroupedCategories } from "@doktor-se/bones-ui/dist/web-shared/types"

import { appStatic } from "config"

import styles from "./SearchCategories.module.scss"

interface SearchCategoryProps {
  changeCategory: (value: Category) => void
  categories: GroupedCategories[]
  subcategories: Category[]
  closeSubcategory: () => void
  selectedParentCategory?: Category
}

const SearchCategories = ({
  changeCategory,
  categories = [],
  subcategories,
  closeSubcategory,
  selectedParentCategory
}: SearchCategoryProps) => {
  const intl = useIntl()
  const [filter, setFilter] = useState("")
  const [filteredCategories, setFilteredCategories] = useState<GroupedCategories[]>()
  const [filteredSubcategories, setFilteredSubcategories] = useState<Category[]>()

  useEffect(() => {
    setFilteredCategories(
      categories.map(c => ({
        ...c,
        categories: c.categories.filter(category => category.name.toLowerCase().includes(filter.toLowerCase()))
      }))
    )
  }, [categories, filter])

  useEffect(() => {
    setFilteredSubcategories(
      subcategories.filter(category => category.name.toLowerCase().includes(filter.toLowerCase()))
    )
  }, [subcategories, filter])

  const categorySymptomBubbleStyle = (category: Category) => {
    if (!!category.metadata.conversationMetaInit?.premiumHealth) {
      return styles.premiumHealth
    }
    if (!!category.metadata.conversationMetaInit?.revisit) {
      return styles.revisit
    }
    return undefined
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <SearchInput
          variant="onSurface"
          aria-label={intl.formatMessage({ id: "input.search.category.aria.label" })}
          value={filter}
          onKeyDown={() => {}}
          onChange={value => setFilter(value)}
          fullWidth
        />
      </div>
      {selectedParentCategory && (
        <button className={classnames(styles.categoryHeader, styles.subCategoryHeader)} onClick={closeSubcategory}>
          <ICONS.ChevronLeft height={15} width={15} />
          <StaffHeading5 as="span">{selectedParentCategory.name}</StaffHeading5>
        </button>
      )}

      {!selectedParentCategory && filteredSubcategories && filteredSubcategories.length > 0 && (
        <div className={styles.section}>
          {filteredSubcategories.map(category => (
            <button
              className={styles.categoryRow}
              key={category.id}
              onClick={() => {
                changeCategory(category)
                setFilter("")
              }}>
              <div className={styles.categoryInfo}>
                <SymptomBubble
                  className={categorySymptomBubbleStyle(category)}
                  symptom={category}
                  appStatic={appStatic}
                  cssFilter={categorySymptomBubbleStyle(category) && "brightness(0);"}
                />
                <StaffHeading5 as="p" className={styles.label}>
                  {category.name}
                </StaffHeading5>
              </div>
              <ICONS.ChevronRight height={15} width={15} />
            </button>
          ))}
        </div>
      )}

      {filteredCategories &&
        filteredCategories.map((c: GroupedCategories, i: number) => (
          <div key={i}>
            {c.header && !!c.categories.length && (
              <StaffHeading4 className={styles.subCategoryHeader}>{c.header}</StaffHeading4>
            )}
            {c.categories.map(category => (
              <button
                className={styles.categoryRow}
                key={category.id}
                onClick={() => {
                  changeCategory(category)
                  setFilter("")
                }}>
                <SymptomBubble
                  className={categorySymptomBubbleStyle(category)}
                  symptom={category}
                  appStatic={appStatic}
                  cssFilter={categorySymptomBubbleStyle(category) && "brightness(0);"}
                />
                <StaffHeading5 as="span" className={styles.label}>
                  {category.name}
                </StaffHeading5>
              </button>
            ))}
          </div>
        ))}
    </div>
  )
}

export default SearchCategories
