import { useCallback, useEffect, useState } from "react"
import { useIntl } from "react-intl"

import dayjs, { Dayjs } from "dayjs"

import { StaffBodyTextS } from "@doktor-se/bones-ui"

import BannerContainer from "components/banners/BannerContainer"

interface TokenWarningProps {
  exp: Dayjs
  logout: () => void
}

const TokenWarning = ({ logout, exp }: TokenWarningProps) => {
  const calcTime = useCallback(() => dayjs(exp).diff(dayjs(), "minutes"), [exp])
  const [time, setTime] = useState(calcTime())
  const intl = useIntl()

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(calcTime())
    }, 10000)
    return () => clearInterval(interval)
  }, [calcTime])

  return (
    <BannerContainer>
      <StaffBodyTextS>{intl.formatMessage({ id: "warning.auth.time" }, { time })}</StaffBodyTextS>
      <button className="banner-action" onClick={() => logout()}>
        <StaffBodyTextS>{intl.formatMessage({ id: "warning.auth.link" })}</StaffBodyTextS>
      </button>
      <StaffBodyTextS>{intl.formatMessage({ id: "warning.auth.login" })}</StaffBodyTextS>
    </BannerContainer>
  )
}

export default TokenWarning
