import dayjs from "dayjs"

import { AnyMessage, AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

export const conversationByPostponeUntil = (a: AssignedConversation, b: AssignedConversation) => {
  return dayjs(a.postponedUntil).diff(dayjs(b.postponedUntil))
}

export const conversationsByLastMessageId = (a: AssignedConversation, b: AssignedConversation) => {
  if (a.lastMessage?.id && b.lastMessage?.id) {
    return b.lastMessage.id - a.lastMessage.id
  }
  return 0
}

export const conversationsBySnoozedUntil = (a: AssignedConversation, b: AssignedConversation) => {
  return dayjs(a.snoozedUntil).diff(dayjs(b.snoozedUntil))
}

export const sortMessagesByCreated = (a: AnyMessage, b: AnyMessage) => dayjs(a.created).diff(dayjs(b.created))
