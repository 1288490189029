import { useIntl } from "react-intl"

import { StaffLabelTextS } from "@doktor-se/bones-ui"

import { useAppSelector } from "lib/hooks"
import { selectContactMyClinicQueue } from "reducers/queue/queue.reducer"

const ContactMyClinicCounter = () => {
  const intl = useIntl()
  const conversations = useAppSelector(selectContactMyClinicQueue)
  const conversationsAmount = conversations?.length || 0

  return (
    <div>
      <StaffLabelTextS as="p">
        {intl.formatMessage({ id: "conversations.queue.before_eod" }, { count: conversationsAmount })}
      </StaffLabelTextS>
    </div>
  )
}

export default ContactMyClinicCounter
