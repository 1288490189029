import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import dayjs, { Dayjs } from "dayjs"

import { Chip, Dropdown } from "@doktor-se/bones-ui"

import { getPostponeTimes } from "api"
import { useAppDispatch } from "lib/hooks"

import styles from "./PostponeTimes.module.scss"

const FULL_DATE_FORMAT = "DD-MM-YYYY"
const TIME_FORMAT = "HH:mm"
export interface PostponeTimesProps {
  conversationId: string
  setDate: (date: Dayjs) => any
  nowSelected: boolean
  setNowSelected: (nowSelected: boolean) => any
  selectedQueue: string
}
type GroupedPostponeTimes = {
  [date: string]: Dayjs[]
}
const PostponeTimes = ({ conversationId, setDate, nowSelected, setNowSelected, selectedQueue }: PostponeTimesProps) => {
  const intl = useIntl()
  const [groupedDates, setGroupedDates] = useState<GroupedPostponeTimes>({})
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs())
  const [selectedDay, setSelectedDay] = useState<string>()
  const dispatch = useAppDispatch()
  const setSelectedTime = (date: Dayjs) => {
    setSelectedDate(date)
    setDate(date)
  }
  const groupDates = (postponeTimes: string[]): GroupedPostponeTimes => {
    return postponeTimes?.reduce((result: GroupedPostponeTimes, dateWithTime) => {
      const date = dayjs(dateWithTime)
      const dateStr = date.format(FULL_DATE_FORMAT)
      if (result[dateStr]) {
        result[dateStr].push(date)
      } else {
        result[dateStr] = [date]
      }
      return result
    }, {})
  }

  useEffect(() => {
    const loadPostponeTimes = async () => {
      const response = await dispatch(getPostponeTimes(conversationId, selectedQueue))
      if (response) {
        const dates = groupDates(response.times)
        setGroupedDates(dates)
      }
    }
    loadPostponeTimes().then(() => {})
  }, [selectedQueue, conversationId, dispatch])
  return (
    <>
      <div className={styles.dateSelector}>
        <Dropdown
          variant={"onSurface"}
          items={Object.keys(groupedDates).map(item => ({ id: item, name: item }))}
          label={intl.formatMessage({ id: "conversation.postpone.dates.label" })}
          selectedKey={nowSelected ? "{}" : selectedDay}
          onSelectionChange={item => {
            setSelectedDay(item.toString())
            setNowSelected(false)
          }}
          placeholder={intl.formatMessage({ id: "conversation.postpone.dropdown.placeholder" })}
        />
      </div>
      {groupedDates &&
        Object.entries(groupedDates).map(
          ([date, times]) =>
            selectedDay &&
            selectedDate && (
              <div key={date} className={nowSelected ? styles.timesContainerHidden : styles.timesContainer}>
                <div className={styles.dateList}>
                  {times.map(
                    dateWithTime =>
                      dateWithTime.format(FULL_DATE_FORMAT) === selectedDay && (
                        <div className={styles.timeChip}>
                          <Chip
                            onChange={() => {
                              setSelectedTime(dateWithTime)
                              setNowSelected(false)
                            }}
                            isSelected={!nowSelected && selectedDate === dateWithTime}
                            color={"primary"}
                            id={dateWithTime.toISOString()}>
                            {dateWithTime.format(TIME_FORMAT)}
                          </Chip>
                        </div>
                      )
                  )}
                </div>
              </div>
            )
        )}
    </>
  )
}
export default PostponeTimes
