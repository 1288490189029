import { useIntl } from "react-intl"

import { DropdownSearch, StaffLabelTextL } from "@doktor-se/bones-ui"
import { Avatar } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Staff } from "@doktor-se/bones-ui/dist/web-shared/types"

import OnlineStatus from "components/OnlineStatus/OnlineStatus"

import styles from "./SelectStaff.module.scss"

export interface OptionProps {
  id: string | number
  name: string
  avatarUrl?: string
  online?: boolean
  textOnlyRenderOption?: boolean
}

const renderOption = () => (item: OptionProps) => {
  if (item.textOnlyRenderOption)
    return (
      <DropdownSearch.Item textValue={item.name}>
        <DropdownSearch.Label>
          <StaffLabelTextL margin={{ left: "var(--size-100)" }}>{item.name}</StaffLabelTextL>
        </DropdownSearch.Label>
      </DropdownSearch.Item>
    )
  return (
    <DropdownSearch.Item textValue={item.name}>
      <div className={styles.option}>
        <div className={styles.user}>
          <Avatar avatarUrl={item.avatarUrl} className={styles.avatar} />
          <DropdownSearch.Label>
            <StaffLabelTextL margin={{ left: "var(--size-100)" }}>{item.name}</StaffLabelTextL>
          </DropdownSearch.Label>
        </div>
        <OnlineStatus online={!!item.online} />
      </div>
    </DropdownSearch.Item>
  )
}

interface SelectStaffProps {
  variant: "onBackground" | "onSurface"
  selectedKey?: string
  staffList: Staff[]
  onSelectionChange: (staffId: string) => void
  clearFunction?: (evt?: Event) => void
  user?: Staff
  unselectedText: string
  searchSectionLabel?: string
  overrideSuggestionsList?: OptionProps[]
}

const SelectStaff = ({
  variant,
  selectedKey,
  staffList,
  onSelectionChange,
  clearFunction,
  user,
  unselectedText,
  searchSectionLabel,
  overrideSuggestionsList
}: SelectStaffProps) => {
  const intl = useIntl()

  const suggestionsList: OptionProps[] | undefined =
    overrideSuggestionsList ||
    (user && [
      {
        id: user.id,
        name: user.displayName,
        avatarUrl: user.avatarUrl,
        online: user.online
      }
    ])

  const searchableList: OptionProps[] = staffList
    .filter(staff => staff.id !== user?.id)
    .map(staff => ({ id: staff.id, name: staff.displayName, avatarUrl: staff.avatarUrl, online: staff.online }))

  return (
    <DropdownSearch
      fullWidth
      variant={variant}
      label={intl.formatMessage({ id: "dropdown.search.staff.aria.label" })}
      placeholder={unselectedText}
      suggestionsList={suggestionsList}
      searchableList={searchableList}
      searchSectionLabel={searchSectionLabel}
      renderOption={renderOption}
      clearFunction={clearFunction}
      onSelectionChange={(key: string | number) => onSelectionChange(key as string)}
      selectedKey={selectedKey}
    />
  )
}

export default SelectStaff
