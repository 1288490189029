import dayjs from "dayjs"

import { User } from "@doktor-se/bones-ui/dist/web-shared/types"
import { toTitleCase } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { filterPersonalQueueBasedOnRole } from "config"
import { QueueConversation, QueueOutline } from "types"

import { conversationByPostponeUntil } from "./sort"

export const transformConversation = (conversation: QueueConversation): QueueConversation => {
  return {
    ...conversation,
    account: conversation.account && {
      ...conversation.account,
      displayName: toTitleCase(conversation.account.displayName)
    }
  }
}

export const addConversations = (conversations: QueueConversation[]): QueueConversation[] =>
  conversations.map(conversation => transformConversation(conversation))

export const conversationIsPostponed = <T extends QueueConversation | QueueOutline>(conversation: T) => {
  const postponedUntil = conversation.postponedUntil
  return !!postponedUntil && dayjs(postponedUntil).diff(dayjs()) > 0
}

const separatePostponedConversations = <T extends QueueConversation | QueueOutline>(
  conversations: T[]
): { postponed: T[]; active: T[] } => ({
  active: conversations.filter(c => !c.postponedUntil || dayjs(c.postponedUntil).format() <= dayjs().format()),
  postponed: conversations.filter(conversationIsPostponed).sort(conversationByPostponeUntil)
})

export const filterPersonalQueue = (conversations: QueueConversation[], user?: User): QueueConversation[] => {
  const { active } = separatePostponedConversations(conversations)
  return filterPersonalQueueBasedOnRole(active, user)
}

export const filterSelectedQueue = <T extends QueueConversation | QueueOutline>(
  conversations: T[]
): { postponed: T[]; active: T[] } => {
  return separatePostponedConversations(conversations)
}
