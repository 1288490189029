import { useIntl } from "react-intl"

import classNames from "classnames"

import { ICONS } from "@doktor-se/bones-ui"

import { useAppSelector } from "lib/hooks"

import styles from "./StaffVideoContainer.module.scss"

const StaffVideoContainer = () => {
  const isStaffVideoOn = useAppSelector(state => state.tokbox.hasVideo.publisher)

  const intl = useIntl()

  return (
    <div className={styles.container}>
      <div
        id="publisher"
        aria-label={intl.formatMessage({ id: "calls.video.staff" })}
        className={classNames(styles.video, { [styles.show]: isStaffVideoOn })}
      />

      <div className={classNames(styles.videoOff, { [styles.show]: !isStaffVideoOn })}>
        <ICONS.PersonFilled className={styles.videoOffIcon} />
      </div>
    </div>
  )
}

export default StaffVideoContainer
