import { useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import { claimFirstAvailableConversation } from "api"
import { useAppDispatch } from "lib/hooks"

import { NextConversationComponentProps } from "./NextConversation"
import ClaimNextConversation from "./shared/ClaimNextConversation"

const NextConversationButton = ({
  autoassignTimer,
  isBlocked,
  queues,
  appLanguage,
  personalQueue,
  personalQueueKeys
}: NextConversationComponentProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const conversationCount = useMemo(() => personalQueue.length || 0, [personalQueue])

  const [isClaiming, setIsClaiming] = useState(false)

  const grabConversation = async () => {
    if (!isBlocked && !isClaiming) {
      setIsClaiming(true)
      await dispatch(claimFirstAvailableConversation(navigate))
      setIsClaiming(false)
    }
  }

  const hasAccessToContactMyClinicQueue = useMemo(
    () => personalQueueKeys?.includes("contact_my_clinic"),
    [personalQueueKeys]
  )

  const getQueueName = useCallback(
    (queueKey: string) => {
      return queues.find(queue => queue.queueKey === queueKey)?.translations[appLanguage] || ""
    },
    [appLanguage, queues]
  )

  const queueName: string = useMemo(() => {
    return (
      personalQueueKeys?.reduce((acc, queueKey, i) => {
        if (i === 0) return getQueueName(queueKey)
        return `${acc} / ${getQueueName(queueKey)}`
      }, "") || ""
    )
  }, [getQueueName, personalQueueKeys])

  return (
    <ClaimNextConversation
      queueName={queueName}
      conversationCount={conversationCount}
      queueTime={personalQueue?.[0]?.queuePriority}
      isBlocked={isBlocked}
      autoassignTimer={autoassignTimer}
      grabConversation={grabConversation}
      showContactMyClinicCounter={hasAccessToContactMyClinicQueue}
    />
  )
}

export default NextConversationButton
