import classNames from "classnames"

import { StaffLabelTextM } from "@doktor-se/bones-ui"

import styles from "./VerticalTab.module.scss"

interface VerticalTabProps {
  text: string
  onClick: () => void
  position: "top" | "bottom"
}

const VerticalTab = ({ text, onClick, position }: VerticalTabProps) => {
  return (
    <div className={classNames(styles.container, styles[position])}>
      <button data-testid="vertical-tab-button" className={styles.button} onClick={onClick}>
        <StaffLabelTextM as="span">{text}</StaffLabelTextM>
      </button>
    </div>
  )
}

export default VerticalTab
