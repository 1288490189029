import { useMsal } from "@azure/msal-react"
import { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"

import { InteractionStatus } from "@azure/msal-browser"

import { Alert, Login } from "@doktor-se/bones-ui/dist/web-shared/components"
import { acquireTokenSilentSSO, getAppId, loginRequest } from "@doktor-se/bones-ui/dist/web-shared/config"

import { authorize, authorizeWithMS, itsmeContinue, itsmeStart } from "api"
import { featureFlags, itsmeEnabled, platform } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { toggleWelcomeMessage } from "reducers/app"

interface LogInProps {
  redirectedFrom?: "itsme"
}

const LogIn = ({ redirectedFrom }: LogInProps) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const loginError = useAppSelector(state => state.error.loginError)
  const passwordLoginError = useAppSelector(state => state.error.passwordLoginError)
  const location = useLocation()
  const [logo, setLogo] = useState("")
  const [phone, setPhone] = useState("")
  const [showCreatePassSuccess, setShowCreatePassSuccess] = useState(false)
  const [loadingAfterRedirect, setLoadingAfterRedirect] = useState(false)

  const authorized = useAppSelector(state => state.auth.authorized)
  const token = useAppSelector(state => state.auth.accessToken)
  const navigate = useNavigate()
  const { inProgress, instance } = useMsal()

  useEffect(() => {
    let ignore = false

    const getImage = async (image: string) => {
      try {
        return await import(`../../img/platform/${platform}/${image}.png`)
      } catch {
        return undefined
      }
    }

    const fetchImages = async () => {
      const logo = await getImage("logo")
      const phone = await getImage("login_phone")

      if (!ignore) {
        setLogo(logo && logo.default)
        setPhone(phone && phone.default)
      }
    }

    fetchImages()
    return () => {
      ignore = true
    }
  }, [])

  const login = (data: { username: string; password: string; role?: string }) => {
    // Backend api is looking for the role which is staff in this case
    data.role = "staff"
    dispatch(authorize(data))
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (redirectedFrom === "itsme") {
      const code = params.get("code")
      const state = params.get("state")
      if (code && state) {
        setLoadingAfterRedirect(true)
        dispatch(itsmeContinue(code, state, intl))
      }
    }

    if (params.get("create_password") === "success") {
      setShowCreatePassSuccess(true)
    }
  }, [dispatch, redirectedFrom, location.search, intl])

  useEffect(() => {
    if (!authorized && !token) {
      if (featureFlags.has("sso") && inProgress === InteractionStatus.None) {
        acquireTokenSilentSSO(instance).then(res => res && dispatch(authorizeWithMS(res?.idToken, getAppId(instance))))
      }
    }

    if (authorized && token) {
      dispatch(toggleWelcomeMessage(true))
      navigate("/")
    }
  }, [dispatch, authorized, navigate, token, redirectedFrom, inProgress, instance])

  useEffect(() => {
    if (loginError) {
      setLoadingAfterRedirect(false)
    }
  }, [loginError])

  const loginVariant = useMemo(() => {
    return featureFlags.has("sso") ? () => instance.loginRedirect(loginRequest) : undefined
  }, [instance])

  return (
    <>
      {showCreatePassSuccess && (
        <Alert
          close={() => setShowCreatePassSuccess(false)}
          text={intl.formatMessage({ id: "message.create_password.success.title" })}
          bodyText={intl.formatMessage({ id: "message.create_password.success.text" })}
          buttonText={intl.formatMessage({ id: "btn.ok" })}
          color="primary"
        />
      )}
      <Login
        logo={logo}
        phone={phone}
        externalProviderLoginError={loginError}
        passwordLoginError={passwordLoginError}
        loginSso={loginVariant}
        loginPassword={!featureFlags.has("disable_email_password_login") ? login : undefined}
        loginItsme={itsmeEnabled ? () => dispatch(itsmeStart()) : undefined}
        loading={loadingAfterRedirect}
        texts={{
          title: intl.formatMessage({ id: "login.title" }),
          loginSso: intl.formatMessage({ id: "login.sso" }),
          loginItsme: intl.formatMessage({ id: "login.itsme" }),
          loginAlternative: intl.formatMessage({ id: "login.internal" }),
          errorRequired: intl.formatMessage({ id: "error.required" }),
          placeholderUsername: intl.formatMessage({ id: "label.username" }),
          placeholderPassword: intl.formatMessage({ id: "label.password" }),
          loginButton: intl.formatMessage({ id: "btn.login" }),
          loading: intl.formatMessage({ id: "login.loading" })
        }}
      />
    </>
  )
}

export default LogIn
