import { useIntl } from "react-intl"

import { Dialog } from "@doktor-se/bones-ui"
import { PaymentDialogProps } from "@doktor-se/bones-ui/dist/web-shared/components"
import { useInput } from "@doktor-se/bones-ui/dist/web-shared/hooks"

import { createPayconiqPayment } from "api/payconiq/payconiq.api"
import { deepLink } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { updatePayconiqToken } from "reducers/auth"
import { selectConversation } from "reducers/conversations/conversations.reducer"
import { setShowPaymentLink } from "reducers/payment/payment.reducer"

import CopyLinkWindow from "./components/CopyLinkWindow/CopyLinkWindow"
import InputWindow from "./components/InputWindow/InputWindow"

import styles from "./PayconiqDialog.module.scss"

const PayconiqDialog = ({ isOpen, onClose, onClickCopyToConversation }: PaymentDialogProps) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const payconiqToken = useAppSelector(state => state.auth.payconiqToken)
  const conversation = useAppSelector(selectConversation)

  const conversationId = conversation!.id
  const paymentResponse = useAppSelector(state => state.payment[conversationId]?.response)
  const showPaymentLink = useAppSelector(state => state.payment[conversationId]?.showPaymentLink)

  const { value: amountValue, onChange: onChangeAmount } = useInput()
  const { value: tokenValue, onChange: onChangeToken } = useInput(payconiqToken)

  const paymentLink = `${paymentResponse?.deeplink}?returnUrl=${encodeURIComponent(
    `${deepLink}/chat?conversation_id=${conversation?.id}`
  )}`
  const paymentAmount = paymentResponse?.amount

  const convertToCents = (amount: number) => Math.round(amount * 100)

  const formatAmount = (rawAmount: number) => (rawAmount / 100).toString().replace(".", ",")

  const generateLink = (amount: string, token: string) => {
    dispatch(updatePayconiqToken(token))
    dispatch(
      createPayconiqPayment(
        convertToCents(parseFloat(amount)),
        token,
        intl.formatMessage({ id: "payment.description" }),
        conversationId
      )
    )
    dispatch(setShowPaymentLink({ conversationId, show: true }))
  }

  return (
    <Dialog isOpen={isOpen} onClose={onClose} closeAriaLabel={intl.formatMessage({ id: "modal.close" })}>
      <div className={styles.container}>
        {showPaymentLink && paymentAmount && paymentLink ? (
          <CopyLinkWindow
            amount={formatAmount(paymentAmount)}
            paymentLink={paymentLink}
            setShowPaymentLink={show => dispatch(setShowPaymentLink({ conversationId, show }))}
            onClickCopyToConversation={onClickCopyToConversation}
          />
        ) : (
          <InputWindow
            amountValue={amountValue}
            onChangeAmount={onChangeAmount}
            tokenValue={tokenValue}
            onChangeToken={onChangeToken}
            generateLink={generateLink}
          />
        )}
      </div>
    </Dialog>
  )
}

export default PayconiqDialog
