import { generateCustomErrorLokaliseKey, handleErrorsV2 } from "api/error/handler"

import apiFetch from "../../lib/fetch"
import { Thunk } from "../../lib/hooks"

interface RevisitRequest {
  conversationId: string
  revisitDate: string
  patientPhoneNumber: string
  smsAgreement: boolean
  firstName: string
}
export const createRevisit = (revisit: RevisitRequest): Thunk<Promise<boolean>> => {
  const { conversationId, revisitDate, patientPhoneNumber, smsAgreement, firstName } = revisit
  return async dispatch => {
    return await dispatch(
      apiFetch({
        url: `/v1/revisits`,
        incomingOptions: {
          method: "POST",
          body: JSON.stringify({
            conversation_id: conversationId,
            revisit_date: revisitDate,
            patient_phone_number: patientPhoneNumber,
            sms_agreement: smsAgreement,
            first_name: firstName
          })
        },
        config: {
          errorHandling: "v2"
        }
      })
    )
      .then(() => true)
      .catch((error: any) => {
        dispatch(
          handleErrorsV2({
            error,
            customMessageLokaliseKey: generateCustomErrorLokaliseKey({
              errors: error.errors,
              customErrorCodes: ["error.validation.field"],
              lokaliseKeySuffix: "create.revisit"
            })
          })
        )
        return false
      })
  }
}
