import React from "react"

const ButtonPhone: React.FC<React.SVGProps<SVGSVGElement>> = ({ fill, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
      <path
        fill={fill || "#FFF"}
        fillRule="evenodd"
        d="M3.273 9.704c-.03-.002-.051-.012-.065-.029C.273 6.05.666 4.655.693 3.453.72 2.173 1.69 1.033 2.68.27c.99-.763 1.65.007 2.39 1.177.74 1.17.756 1.68.566 2.287-.19.607-1.565 1.045-1.673 1.921-.072.583.438 1.464 1.53 2.643l.022-.012c1.04 1.232 1.855 1.848 2.444 1.848.882 0 1.485-1.311 2.11-1.426.625-.116 1.13-.038 2.201.84 1.072.877 1.755 1.626.878 2.516-.878.89-2.129 1.712-3.402 1.584-1.268-.127-2.8.085-6.473-3.944z"
      />
    </svg>
  )
}

export default ButtonPhone
