import { handleErrors } from "api/error/handler"
import { apiFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"

export interface FreshdeskApiData {
  name?: string
  email?: string
  subject: string
  description: string
  tags: string[]
}

export const freshdeskCreateTicket =
  (data: FreshdeskApiData): Thunk =>
  async dispatch => {
    try {
      dispatch(
        apiFetch(`/freshdesk/ticket`, {
          method: "POST",
          body: JSON.stringify(data)
        })
      )
    } catch (error: any) {
      dispatch(handleErrors({ error, hide: true }))
    }
  }
