import { AppError } from "@doktor-se/bones-ui/dist/web-shared/classes"

import { featureFlags } from "config"

import { ChildProfileData, PetProfileData } from "../types"
import AddChild from "./AddChild"
import AddPet from "./AddPet"

interface AddProfileProps {
  addProfile: (data: ChildProfileData | PetProfileData) => void
  error?: AppError
  close: () => void
}

const AddProfile = ({ addProfile, error, close }: AddProfileProps) => {
  return (
    <>
      {featureFlags.has("child") && <AddChild addProfile={addProfile} error={error} close={close} />}
      {featureFlags.has("pet") && <AddPet addProfile={addProfile} error={error} close={close} />}
    </>
  )
}

export default AddProfile
