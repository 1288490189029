import { useIntl } from "react-intl"

import dayjs from "dayjs"

import { ageFromDate } from "@doktor-se/bones-ui/dist/web-shared/utils"

import PatientInfoRow from "./PatientInfoRow/PatientInfoRow"

type DateOfBirthProps = {
  dateOfBirth: string | undefined
}

const DateOfBirthRow = ({ dateOfBirth }: DateOfBirthProps) => {
  const intl = useIntl()
  const formattedDateOfBirth = dateOfBirth
    ? intl.formatDate(dayjs(dateOfBirth).format(), { year: "numeric", month: "2-digit", day: "2-digit" })
    : undefined
  return (
    <PatientInfoRow
      label={intl.formatMessage({ id: "patient.info.dateOfBirth" })}
      data={
        !!dateOfBirth
          ? intl.formatMessage(
              { id: "age.years" },
              {
                date: formattedDateOfBirth,
                age: ageFromDate(dateOfBirth)
              }
            )
          : undefined
      }
      copyData={formattedDateOfBirth}
    />
  )
}

export default DateOfBirthRow
