import { useIntl } from "react-intl"

import { Button, ICONS, StaffBodyTextM, StaffHeading3 } from "@doktor-se/bones-ui"

import styles from "./ConfirmCloseContent.module.scss"

interface ConfirmCloseContentProps {
  onConfirm: () => void
  isCallActive?: boolean
}

const ConfirmCloseContent = ({ onConfirm, isCallActive }: ConfirmCloseContentProps) => {
  const intl = useIntl()

  return (
    <>
      <div className={styles.textContainer}>
        <StaffHeading3>
          {intl.formatMessage({
            id: "control.close.confirm"
          })}
        </StaffHeading3>
        {isCallActive && (
          <StaffBodyTextM>
            {intl.formatMessage({
              id: "control.call.active.close"
            })}
          </StaffBodyTextM>
        )}
      </div>

      <Button
        variant="primary"
        color="primary"
        onPress={onConfirm}
        Icon={<ICONS.CheckCircleOutline width="18" height="18" />}
        fullWidth>
        {intl.formatMessage({ id: "label.close" })}
      </Button>
    </>
  )
}

export default ConfirmCloseContent
