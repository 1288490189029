import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { v4 as uuidv4 } from "uuid"

import { Error as AppError } from "@doktor-se/bones-ui/dist/web-shared/types"

import { authorizedAction } from "reducers/auth"

export interface LogErrorPayload {
  message: string
  error: IncomingError
  customError: boolean
  customMessage?: string
  api?: string
  image?: string
  customContext?: string
  hide: boolean
}

export interface LogErrorPayloadV2 {
  localisedKey: string
  customError: boolean
  hide: boolean
}

export interface IncomingError extends Error {
  status?: number
  url?: string
  context?: string
  details?: {
    code?: string
    reason?: string
    message?: string
  }
}

export interface ErrorState {
  loginError?: string
  passwordLoginError?: string
  error: AppError
  errors: AppError[]
}

const initialState: ErrorState = {
  error: {
    show: false,
    customError: false
  },
  errors: [
    {
      show: false,
      customError: false
    }
  ]
}

const error = createSlice({
  name: "error",
  initialState,
  reducers: {
    logError(state, action: PayloadAction<LogErrorPayload>) {
      const error = {
        id: uuidv4(),
        show: true,
        message: action.payload.message,
        customError: action.payload.customError,
        customMessage: action.payload.customMessage,
        api: action.payload.api,
        image: action.payload.image,
        hide: action.payload.hide,
        code: action.payload.error.details?.code
      }
      state.error = error
      state.errors ? state.errors.push(error) : (state.errors = [error])
    },
    logErrorV2(state, action: PayloadAction<LogErrorPayloadV2>) {
      const error = {
        id: uuidv4(),
        show: true,
        localisedKey: action.payload.localisedKey,
        customError: action.payload.customError,
        hide: action.payload.hide
      }
      state.error = error
      state.errors ? state.errors.push(error) : (state.errors = [error])
    },
    closeError(state, action: PayloadAction<AppError | undefined>) {
      if (action.payload) {
        state.errors = state.errors.filter(item => action.payload?.id !== item.id)
      }
      state.error = {
        show: false,
        message: undefined,
        api: undefined,
        customMessage: undefined,
        customError: false,
        hide: false,
        code: undefined
      }
    },
    loginError(state, action: PayloadAction<string | undefined>) {
      state.loginError = action.payload
      state.passwordLoginError = undefined
    },
    passwordLoginError(state, action: PayloadAction<string | undefined>) {
      state.loginError = undefined
      state.passwordLoginError = action.payload
    }
  },

  extraReducers: builder =>
    builder.addCase(authorizedAction, state => {
      state.loginError = undefined
      state.passwordLoginError = undefined
    })
})

export const { logError, logErrorV2, closeError, loginError, passwordLoginError } = error.actions

export type ErrorSliceAction = ObjectFunctionReturnTypes<typeof error.actions>

export default error.reducer
