import { useMemo } from "react"

import { ICONS } from "@doktor-se/bones-ui"

export interface CountryFlagProps {
  countryOrLanguageCode: string
  className?: string
  height?: number
  width?: number
}

const CountryFlag = ({ countryOrLanguageCode, className, height, width }: CountryFlagProps) => {
  const Flag = useMemo(() => {
    const props = {
      className,
      height: height || 13,
      width: width || 13
    }

    switch (countryOrLanguageCode) {
      case "se":
      case "sv":
        return <ICONS.FlagSweden {...props} />
      case "dk":
      case "da":
        return <ICONS.FlagDenmark {...props} />
      case "no":
      case "nb":
        return <ICONS.FlagNorway {...props} />
      case "de":
        return <ICONS.FlagGermany {...props} />
      case "en":
        return <ICONS.FlagUnitedKingdom {...props} />
      case "nl":
        return <ICONS.FlagNetherlands {...props} />
      case "fr":
        return <ICONS.FlagFrance {...props} />
      default:
        return null
    }
  }, [className, countryOrLanguageCode, height, width])

  return Flag
}

export default CountryFlag
