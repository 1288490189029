import { ConversationTypingRequest, TypingMessage } from "@doktor-se/bones-ui/dist/web-shared/types"

import { Thunk } from "lib/hooks"
import { setTypingTimeout, typingMessage } from "reducers/app"

import { wsSend } from "../ws.api"

export const iAmTyping = (conversationId: string) => {
  if (conversationId) {
    const data: ConversationTypingRequest = {
      conversation_id: conversationId,
      type: "typing"
    }
    wsSend(data)
  }
}

export const handleTyping =
  (eventData: TypingMessage): Thunk =>
  (dispatch, getState) => {
    const { conversationId } = getState().selected
    const { typingTimeout } = getState().app

    if (conversationId && eventData.conversationId === conversationId) {
      if (typingTimeout) {
        clearTimeout(typingTimeout)
      }
      dispatch(
        setTypingTimeout(
          setTimeout(() => {
            dispatch(typingMessage(false))
          }, 1500)
        )
      )
      if (!getState().app.isTyping) {
        dispatch(typingMessage(true))
      }
    }
  }

export default iAmTyping
