import React, { useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { SearchInput } from "@doktor-se/bones-ui"
import { AppError } from "@doktor-se/bones-ui/dist/web-shared/classes"
import { Avatar } from "@doktor-se/bones-ui/dist/web-shared/components"

import { createDraftUserEnabled, searchConfig } from "config"
import { useAppSelector } from "lib/hooks"
import { getSearchParams } from "lib/search"
import { SearchAccount } from "types"

import { Selected } from "components/CreateConversation/CreateConversation"

import styles from "./SearchPatients.module.scss"

const DraftUserError = () => (
  <div className={styles.errorMessage}>
    <FormattedMessage id="draft.user.warning" />
  </div>
)

interface SearchPatientsProps {
  patients: SearchAccount[]
  error?: AppError
  changePatient: (value: Selected["patient"]) => void
  searchPatients: (value: { query?: string; pnr?: string }) => void
  clearPatients: () => void
}

const SearchPatients = ({ patients, error, changePatient, searchPatients, clearPatients }: SearchPatientsProps) => {
  const [searchString, setSearchString] = useState("")
  const [searched, setSearched] = useState<string>()
  const createDraftUser = createDraftUserEnabled(useAppSelector(state => state.auth.defaultRole))
  const newPatient = error && error.type === "error.user.notfound" && createDraftUser
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearPatients(), [])
  const intl = useIntl()

  const onKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt && evt.key === "Enter") {
      const value = evt.currentTarget.value
      const params = getSearchParams(value)

      searchPatients(params)
      setSearchString(value)
      if (!!params.query) setSearched(value)
    }
  }

  const changeSearchString = (value: string) => {
    setSearchString(value)
    if (!value || value.length < 8) clearPatients()
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <SearchInput
          variant="onSurface"
          aria-label={intl.formatMessage({ id: "input.search.patient.aria.label" })}
          caption={searchConfig.format || intl.formatMessage({ id: "search.patient.placeholder" })}
          value={searchString}
          onKeyDown={evt => onKeyDown(evt)}
          onChange={value => changeSearchString(value)}
          fullWidth
          errorMessage={!newPatient ? error?.message : undefined}
        />
      </div>
      {createDraftUser && !!newPatient && (
        <button
          className={styles.patientRow}
          onClick={() => {
            changePatient({
              id: "draft",
              region: "se", // TODO: needs to be fixed if feature should be enabled for any one other than doktor.se
              searchedValue: searched!
            })
          }}>
          <div className={styles.patientCreateRow}>
            <div className={styles.labelTop}>{intl.formatMessage({ id: "patient.missing.label" })}</div>
            <div className={styles.labelRow}>
              <Avatar />
              <div className={styles.label}>{intl.formatMessage({ id: "patient.missing.create" })}</div>
            </div>
          </div>
        </button>
      )}

      {patients.map(patient => {
        if (!patient.displayName && !createDraftUser) {
          return <DraftUserError />
        }
        return (
          <React.Fragment key={patient.id}>
            <button
              className={styles.patientRow}
              id={patient.id}
              onClick={() =>
                changePatient({
                  id: patient.id,
                  displayName: patient.displayName,
                  customerMetadata: patient.customerMetadata,
                  searchedValue: searched!
                })
              }>
              <Avatar />
              <span className={styles.label}>{patient.displayName || searched}</span>
            </button>
            {!patient.displayName && <DraftUserError />}
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default SearchPatients
