import React from "react"
import { useIntl } from "react-intl"

import { CustomerMetadata } from "@doktor-se/bones-ui/dist/web-shared/types"

import { featureFlags } from "config"

import PatientInfoRow from "../profiles/components/PatientInfoRow/PatientInfoRow"
import PaymentsMetadata from "./components/PaymentsMetadata"

import styles from "./ConversationCustomerMetadata.module.scss"

interface ConversationCustomerMetadataProps {
  metadata?: CustomerMetadata
}

const ConversationCustomerMetadata = React.memo(({ metadata }: ConversationCustomerMetadataProps) => {
  const intl = useIntl()

  const hasVisibleMetadata = metadata?.listedAtClinicName || (featureFlags.has("payments") && metadata?.payment)
  if (!hasVisibleMetadata) {
    return null
  }

  return (
    <div className={styles.container}>
      {metadata.listedAtClinicName && (
        <PatientInfoRow
          label={intl.formatMessage({ id: "patient.info.clinic.listed" })}
          data={metadata.listedAtClinicName as string}
        />
      )}

      {featureFlags.has("payments") && <PaymentsMetadata metadata={metadata} />}
    </div>
  )
})

export default ConversationCustomerMetadata
