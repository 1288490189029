import React from "react"

const IconLogOut: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="49" viewBox="0 0 70 49" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="#6CAAB3">
          <path
            fillRule="nonzero"
            d="M9.666 29.109h4.46V37.3a2.725 2.725 0 0 0 2.724 2.725h23.091a2.725 2.725 0 0 0 2.725-2.725V7.539a2.725 2.725 0 0 0-2.725-2.725h-23.09a2.725 2.725 0 0 0-2.726 2.725v9.222H9.666V7.54A7.184 7.184 0 0 1 16.85.355h23.091a7.184 7.184 0 0 1 7.184 7.184V37.3a7.184 7.184 0 0 1-7.184 7.184h-23.09A7.184 7.184 0 0 1 9.665 37.3v-8.192zm0 0h4.46V37.3a2.725 2.725 0 0 0 2.724 2.725h23.091a2.725 2.725 0 0 0 2.725-2.725V7.539a2.725 2.725 0 0 0-2.725-2.725h-23.09a2.725 2.725 0 0 0-2.726 2.725v9.222H9.666V7.54A7.184 7.184 0 0 1 16.85.355h23.091a7.184 7.184 0 0 1 7.184 7.184V37.3a7.184 7.184 0 0 1-7.184 7.184h-23.09A7.184 7.184 0 0 1 9.665 37.3v-8.192z"
          />
          <path d="M22.833 19.824v-7.612l12.892 10.07-12.892 10.346V25.11H3.565C1.88 25.11.547 23.719.547 22.42s1.098-2.596 3.018-2.596h19.268z" />
        </g>
        <path
          fill="#FFF"
          d="M51.432 48.816c9.989 0 18.087-8.098 18.087-18.087 0-9.99-8.098-18.087-18.087-18.087-9.99 0-18.087 8.098-18.087 18.087 0 3.577 1.039 6.912 2.83 9.72.735 1.149-.544 4.573.423 5.526.9.887 4.03-.684 5.099 0a18.002 18.002 0 0 0 9.735 2.84z"
        />
        <g transform="matrix(-1 0 0 1 65.787 16.374)">
          <path
            fill="#FCB000"
            d="M14.203 28.192c-7.754 0-14.04-6.286-14.04-14.04C.164 6.4 6.45.113 14.204.113s14.04 6.286 14.04 14.04c0 2.891-.875 5.579-2.374 7.812-.644.96.596 4.244 0 4.244 0 0-3.68-.472-4.469 0a13.974 13.974 0 0 1-7.197 1.983z"
          />
          <path
            fill="#D39400"
            d="M14.203 28.192c-7.754 0-14.04-6.286-14.04-14.04 0-7.753 2.373 9.507 14.04 9.507 11.666 0 14.04-17.26 14.04-9.507 0 2.892-.875 5.58-2.374 7.813-.644.96.596 4.244 0 4.244 0 0-3.68-.472-4.469 0a13.974 13.974 0 0 1-7.197 1.983z"
          />
          <path
            stroke="#4A4A4A"
            strokeWidth="3.067"
            d="M14.203 28.192c-7.754 0-14.04-6.286-14.04-14.04C.164 6.4 6.45.113 14.204.113s14.04 6.286 14.04 14.04c0 2.891-.875 5.579-2.374 7.812-.644.96.596 4.244 0 4.244 0 0-3.68-.472-4.469 0a13.974 13.974 0 0 1-7.197 1.983z"
          />
          <rect width="3.243" height="8.918" x="12.628" y="7.553" fill="#FFF" rx="1.621" />
          <circle cx="14.25" cy="19.911" r="1.772" fill="#FFF" />
        </g>
      </g>
    </svg>
  )
}

export default IconLogOut
