import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { AssignmentChangedMessage } from "@doktor-se/bones-ui/dist/web-shared/types"

import { ConversationSearchResponse } from "api"
import { updateCallState, updateConversationState } from "reducers/conversations/conversations.reducer"
import { SearchPageConversation } from "types"

export interface SearchState {
  conversations: SearchPageConversation[]
  pagination: {
    count: number
    limit: number
    offset: number
  }
}

const initialState: SearchState = {
  conversations: [],
  pagination: {
    count: 0,
    limit: 20,
    offset: 0
  }
}

const search = createSlice({
  name: "search",
  initialState,
  reducers: {
    loadConversationsSearch(
      state,
      action: PayloadAction<{
        data: ConversationSearchResponse
        more: boolean
      }>
    ) {
      const conversations = action.payload.data.conversations

      state.conversations = action.payload.more ? state.conversations.concat(conversations) : conversations
      state.pagination.count = action.payload.data.count
    },
    clearConversationsSearch(state) {
      state.conversations = []
    },
    setOffset(state, action: PayloadAction<number>) {
      state.pagination.offset = action.payload
    },
    assignSearchedConversation(state, action: PayloadAction<AssignmentChangedMessage>) {
      state.conversations = state.conversations.map(c => {
        if (c.id === action.payload.conversationId) {
          return { ...c, assignedStaffId: action.payload.data.assignedStaffId }
        }
        return c
      })
    }
  },

  extraReducers: builder =>
    builder
      .addCase(updateCallState, (state, action) => {
        state.conversations = state.conversations.map(c => {
          if (c.id === action.payload.conversationId) {
            return { ...c, callState: action.payload.data.callState }
          }
          return c
        })
      })
      .addCase(updateConversationState, (state, action) => {
        state.conversations = state.conversations.map(c => {
          if (c.id === action.payload.conversationId) {
            return { ...c, state: action.payload.data.state }
          }
          return c
        })
      })
})

export const { loadConversationsSearch, clearConversationsSearch, setOffset, assignSearchedConversation } =
  search.actions

export type SearchSliceAction = ObjectFunctionReturnTypes<typeof search.actions>

export default search.reducer
