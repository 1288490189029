import React from "react"
import { useIntl } from "react-intl"

import { Button, SearchInput, StaffHeading1 } from "@doktor-se/bones-ui"
import { AppError } from "@doktor-se/bones-ui/dist/web-shared/classes"

import { searchConfig, showStaffFilterForConversations, staffListForAssigning } from "config"
import { useAppSelector } from "lib/hooks"

import SelectStaff from "components/dropdowns/selectStaff/SelectStaff"

import styles from "./SearchHeader.module.scss"

interface SearchHeaderProps {
  error: AppError
  onKeyDown: (evt: React.KeyboardEvent<HTMLInputElement>) => void
  changeSearchString: (value: string) => void
  searchString?: string
  update: () => void
  staffId: string
  changeStaff: (staffId: string) => void
  removeStaff: () => void
}

const SearchHeader = ({
  error,
  onKeyDown,
  changeSearchString,
  searchString = "",
  update,
  staffId,
  changeStaff,
  removeStaff
}: SearchHeaderProps) => {
  const intl = useIntl()
  const defaultRole = useAppSelector(state => state.auth.defaultRole)
  const activeStaff = useAppSelector(state => state.users.activeStaff)
  const authUser = useAppSelector(state => state.auth.user)
  const user = activeStaff.find(s => s.id === authUser?.id)
  const filteredStaffList = staffListForAssigning(activeStaff, user?.data?.clinic)

  return (
    <div className={styles.container}>
      <StaffHeading1 margin={{ bottom: "40px" }} className={styles.title}>
        {intl.formatMessage({ id: "search.title" })}
      </StaffHeading1>

      <div className={styles.searchContainer}>
        {showStaffFilterForConversations(defaultRole) && (
          <div className={styles.selectStaff}>
            <SelectStaff
              variant="onBackground"
              selectedKey={staffId}
              staffList={filteredStaffList}
              onSelectionChange={changeStaff}
              clearFunction={removeStaff}
              user={user}
              unselectedText={intl.formatMessage({ id: "search.title" })}
            />
          </div>
        )}
        <SearchInput
          variant="onBackground"
          value={searchString}
          onKeyDown={evt => onKeyDown(evt)}
          onChange={value => changeSearchString(value)}
          caption={
            searchConfig.format || intl.formatMessage({ id: "search.conversation.placeholder", defaultMessage: " " })
          }
          errorMessage={
            error.show && error.api === "conversationsSearch" && error.code?.includes("error.pnr.invalid")
              ? error.message
              : undefined
          }
          aria-label={intl.formatMessage({ id: "input.search.patient.aria.label" })}
        />
        <div className={styles.searchButton}>
          <Button variant="outline" color="primary" onPress={() => update()}>
            {intl.formatMessage({ id: "label.search" })}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SearchHeader
