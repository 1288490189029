import { useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import { claimNextConversationFromQueue } from "api"
import { useAppDispatch } from "lib/hooks"

import { NextConversationComponentProps } from "./NextConversation"
import ClaimNextConversation from "./shared/ClaimNextConversation"

const NextConversationList = ({
  autoassignTimer,
  isBlocked,
  queues,
  appLanguage,
  personalQueue,
  personalQueueKeys
}: NextConversationComponentProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [isClaiming, setIsClaiming] = useState(false)

  const getQueueName = useCallback(
    (queueKey: string) => {
      return queues.find(queue => queue.queueKey === queueKey)?.translations[appLanguage] || ""
    },
    [appLanguage, queues]
  )

  const statsPerQueue = useMemo(() => {
    return (
      personalQueueKeys?.map(pQueueKey => {
        const convs = personalQueue.filter(c => c.queue === pQueueKey)
        const waitTime = convs[0]?.queuePriority
        const count = convs.length

        return {
          key: pQueueKey,
          count,
          waitTime,
          showContactMyClinic: pQueueKey === "contact_my_clinic"
        }
      }) || []
    )
  }, [personalQueue, personalQueueKeys])

  const grabConversation = async (queueKey: string) => {
    if (!isBlocked && !isClaiming) {
      setIsClaiming(true)
      await dispatch(claimNextConversationFromQueue(navigate, queueKey))
      setIsClaiming(false)
    }
  }

  return (
    <>
      {statsPerQueue &&
        statsPerQueue?.map(queue => (
          <ClaimNextConversation
            queueName={getQueueName(queue.key)}
            conversationCount={queue.count}
            queueTime={queue.waitTime}
            isBlocked={isBlocked}
            autoassignTimer={autoassignTimer}
            grabConversation={() => grabConversation(queue.key)}
            key={queue.key}
            showContactMyClinicCounter={queue.showContactMyClinic}
          />
        ))}
    </>
  )
}

export default NextConversationList
