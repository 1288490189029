import { useState } from "react"
import { useIntl } from "react-intl"

import classnames from "classnames"

import { Button } from "@doktor-se/bones-ui"
import { Category } from "@doktor-se/bones-ui/dist/web-shared/types"

import styles from "./CommentStep.module.scss"

interface Selected {
  data?: string
}

interface CommentStepProps {
  showFlow?: boolean | false
  category: Category
  setCommentData: (selected: Selected) => void
}

const CommentStep = ({ setCommentData }: CommentStepProps) => {
  const [selected, setSelected] = useState<{
    data?: string
  }>({ data: undefined })
  const intl = useIntl()

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <textarea
          className={styles.textArea}
          placeholder={intl.formatMessage({ id: "draft.patient.comment.placeholder" })}
          onChange={evt => {
            evt.persist()
            setSelected(s => ({ ...s, data: evt.target.value }))
          }}
        />
      </div>
      <div className={classnames(styles.block, styles.button)}>
        <Button variant="primary" color="primary" onPress={() => setCommentData(selected)}>
          {intl.formatMessage({ id: "label.next" })}
        </Button>
      </div>
    </div>
  )
}

export default CommentStep
