import { useMemo, useState } from "react"
import { useIntl } from "react-intl"

import { SearchInput } from "@doktor-se/bones-ui"
import { Staff } from "@doktor-se/bones-ui/dist/web-shared/types"

import UserRow from "components/UserRow/UserRow"

import styles from "./StaffSearch.module.scss"

interface StaffSearchProps {
  staffList: Staff[]
  onSelectStaff: (staff: Staff) => void
  currentUser?: Staff
}

const StaffSearch = ({ staffList, onSelectStaff, currentUser }: StaffSearchProps) => {
  const intl = useIntl()
  const [filterQuery, setFilter] = useState("")

  const filteredStaffList = useMemo(
    () => staffList.filter(p => p.displayName.toLowerCase().includes(filterQuery.toLowerCase())),
    [staffList, filterQuery]
  )

  return (
    <div className={styles.container}>
      {currentUser && (
        <div className={styles.userRow}>
          <UserRow user={currentUser} onClick={() => onSelectStaff(currentUser)} />
        </div>
      )}
      <div className={styles.searchContainer}>
        <SearchInput
          variant="onSurface"
          aria-label={intl.formatMessage({ id: "input.search.staff.aria.label" })}
          value={filterQuery}
          onChange={value => setFilter(value)}
          fullWidth
        />
      </div>

      <ol className={styles.list}>
        {filteredStaffList.map((staff, index) => (
          <UserRow user={staff} key={staff.id} onClick={() => onSelectStaff(staff)} tabIndex={index} />
        ))}
      </ol>
    </div>
  )
}

export default StaffSearch
