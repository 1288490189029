import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { Loader } from "@doktor-se/bones-ui/dist/web-shared/components"

import { postLogout } from "api"
import { useAppDispatch, useAppSelector } from "lib/hooks"

export const PostLogout = () => {
  const authorized = useAppSelector(state => state.auth.authorized)
  const token = useAppSelector(state => state.auth.accessToken)

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (authorized && token) {
      dispatch(postLogout())
    } else {
      navigate("/login")
    }
  }, [dispatch, authorized, token, navigate])

  return (
    <div>
      <Loader type="full" />
    </div>
  )
}

export default PostLogout
