import { ConversationCustomerMetadataUpdatedEvent } from "@doktor-se/bones-ui/dist/web-shared/types"

import { fetchAssignedConversation } from "api/conversations/conversations.api"
import { Thunk } from "lib/hooks"
import { updateConversationCustomerMetadata } from "reducers/conversations/conversations.reducer"

export const handleCustomerMetadataUpdate =
  (event: ConversationCustomerMetadataUpdatedEvent): Thunk =>
  dispatch => {
    if (event.customerMetadata.encryption) {
      dispatch(fetchAssignedConversation(event.conversationId))
    }
    dispatch(updateConversationCustomerMetadata(event))
  }
