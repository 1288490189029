import { useIntl } from "react-intl"

import { Button, StaffHeading3 } from "@doktor-se/bones-ui"

import styles from "./ConfirmReassign.module.scss"

interface ConfirmReassignProps {
  message: string
  onConfirm: () => void
  confirmText: string
  onCancel?: () => void
}

const ConfirmReassign = ({ message, onCancel, onConfirm, confirmText }: ConfirmReassignProps) => {
  const intl = useIntl()

  return (
    <>
      <StaffHeading3 data-testid="confirm-reassign" className={styles.text} margin={{ bottom: "var(--size-200)" }}>
        {message}
      </StaffHeading3>
      <div className={styles.buttonContainer}>
        {!!onCancel && (
          <Button color="primary" variant="secondary" onPress={onCancel} fullWidth>
            {intl.formatMessage({ id: "btn.cancel" })}
          </Button>
        )}
        <Button color="primary" variant="primary" onPress={onConfirm} fullWidth>
          {confirmText}
        </Button>
      </div>
    </>
  )
}

export default ConfirmReassign
