import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type States = "REQUEST" | "SUCCESS" | "FAILURE"

export interface LoaderState {
  [loader: string]: boolean | undefined
}

const initialState: LoaderState = {}

const loader = createSlice({
  name: "loader",
  initialState,
  reducers: {
    updateLoader(state, action: PayloadAction<{ loader: string; state: States }>) {
      state[action.payload.loader] = action.payload.state === "REQUEST"
    }
  }
})

export const { updateLoader } = loader.actions

export type LoaderSliceAction = ObjectFunctionReturnTypes<typeof loader.actions>

export default loader.reducer
