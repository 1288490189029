import classNames from "classnames"

import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import { useAppSelector } from "lib/hooks"

import AudioOnlyDisplay from "./components/AudioOnlyDisplay/AudioOnlyDisplay"
import HideVideo from "./components/HideVideo/HideVideo"
import VideoDisplay from "./components/VideoDisplay/VideoDisplay"

import styles from "./PatientVideoContainer.module.scss"

interface VideoContainerProps {
  displayedText: string
  conversation: AssignedConversation
  showVideo?: boolean
}

const VideoContainer = ({ showVideo = false, conversation, displayedText }: VideoContainerProps) => {
  const isFullScreen = useAppSelector(state => state.tokbox.expanded)
  const hidePatientVideo = useAppSelector(state => state.tokbox.hidePatientVideo)

  return (
    <div className={classNames(styles.container, { [styles.fullScreen]: isFullScreen })}>
      <VideoDisplay showVideo={showVideo && !hidePatientVideo} />

      {hidePatientVideo && <HideVideo />}

      {!showVideo && !hidePatientVideo && (
        <AudioOnlyDisplay
          categoryId={conversation.categoryId}
          displayName={conversation.account?.displayName}
          displayedText={displayedText}
        />
      )}
    </div>
  )
}

export default VideoContainer
