import humps from "humps"

import {
  AnyEvent,
  AssignmentChangedMessage,
  CallStateChangedMessage,
  ConversationStateChangedMessage,
  MetadataMessage,
  SnoozeMessage,
  SocketReconnect
} from "@doktor-se/bones-ui/dist/web-shared/types"

import { conversationsConnect, reloadCalendarBookings, reloadCalendarSlots } from "api"
import { Thunk } from "lib/hooks"
import { queueOpeningHourUpdated, setBanner } from "reducers/app"
import { addPersonalBooking, removeBooking, updateBooking } from "reducers/booking"
import {
  addMessage,
  metadata,
  patientComment,
  snooze,
  updateConversationState
} from "reducers/conversations/conversations.reducer"
import {
  clearUserStatus,
  profileCreated,
  profileRemoved,
  profileUpdated,
  staffAdded,
  staffRemoved,
  userStatus
} from "reducers/users/users.reducer"

import { addPostponeTimer, handleAssignment } from "./handlers/handleAssignment"
import handleCall from "./handlers/handleCall"
import { handleCustomerMetadataUpdate } from "./handlers/handleCustomerMetadata"
import { handleNavbarNotifications } from "./handlers/handleNotifications"
import { handleProfile } from "./handlers/handleProfile"
import { snoozeTimer } from "./handlers/handleSnooze"
import { handleStaffLogout } from "./handlers/handleStaffLogout"

const handleEvents =
  (event: AnyEvent): Thunk =>
  (dispatch, getState) => {
    const eventData: AnyEvent = humps.camelizeKeys({ ...event }) as unknown as AnyEvent
    switch (eventData.event) {
      case "message":
        const { message } = eventData
        const { conversationId } = message
        if (message.type) {
          switch (message.type) {
            case "snooze":
              if (!!conversationId) {
                dispatch(snoozeTimer(conversationId, (message as SnoozeMessage).data.snoozedUntil || undefined))
              }
              dispatch(snooze(message as SnoozeMessage))
              break
            case "call":
              dispatch(handleCall(message as CallStateChangedMessage))
              dispatch(handleNavbarNotifications(message))
              break
            case "assignment":
              const postponedUntil = (message as AssignmentChangedMessage).data.postponedUntil
              if (!!postponedUntil && !!conversationId)
                dispatch(addPostponeTimer(conversationId, postponedUntil, message.data.queue))
              dispatch(handleAssignment(message as AssignmentChangedMessage))
              break
            case "state":
              dispatch(updateConversationState(message as ConversationStateChangedMessage))
              break
            case "metadata":
              dispatch(metadata(message as MetadataMessage))
              break
            case "profile":
              dispatch(handleNavbarNotifications(message))
              dispatch(handleProfile(message))
              break
            case "system":
            case "image":
            case "attachment":
            case "chat":
            case "sip":
            case "category":
            case "comment":
            case "button":
              dispatch(handleNavbarNotifications(message))
              dispatch(addMessage(message))
              break
            default:
              break
          }
        }
        break
      case "socket.open":
        dispatch(setBanner(undefined))
        break
      case "socket.disconnected":
        dispatch(setBanner({ type: "stop" }))
        break
      case "socket.failed":
        dispatch(setBanner({ type: "close" }))
        break
      case "socket.reconnect":
        dispatch(setBanner({ type: "reconnect", meta: { retry: (event as SocketReconnect).retry } }))
        dispatch(conversationsConnect())
        dispatch(clearUserStatus())
        break
      case "user.status":
        if (eventData.userGroup === "staff") dispatch(userStatus(eventData))
        break
      case "staff.added":
        dispatch(staffAdded(eventData))
        break
      case "staff.removed":
        dispatch(staffRemoved(eventData))
        break
      case "profile.created":
        dispatch(profileCreated(eventData))
        break
      case "profile.removed":
        dispatch(profileRemoved(eventData))
        break
      case "profile.updated":
        dispatch(profileUpdated(eventData))
        break
      case "patient.comment":
        dispatch(patientComment(eventData))
        break
      case "opening_hour.updated":
        dispatch(queueOpeningHourUpdated(eventData.openingHour))
        break
      case "booking.create":
      case "booking.assign":
        dispatch(reloadCalendarBookings())
        dispatch(reloadCalendarSlots())
        dispatch(addPersonalBooking({ booking: eventData.booking, userId: getState().auth.user?.id }))
        break
      case "booking.cancel":
        if (eventData.booking.staffId === getState().auth.user?.id) {
          dispatch(removeBooking(eventData.booking.id))
          dispatch(reloadCalendarSlots())
        }
        break
      case "booking.unassign":
        const staffFilter = getState().booking.calendar.filters.staff
        if (!staffFilter || staffFilter === getState().auth.user?.id) {
          dispatch(removeBooking(eventData.booking.id))
          dispatch(reloadCalendarSlots())
        }
        break
      case "booking.reschedule":
        dispatch(updateBooking(eventData.booking))
        dispatch(reloadCalendarSlots())
        break
      case "customer_metadata.conversation.updated":
        dispatch(handleCustomerMetadataUpdate(eventData))
        break
      case "staff.logout":
        dispatch(handleStaffLogout(eventData))
        break
      default:
        break
    }
  }

export default handleEvents
