import React from "react"

// TODO Icon does not follow new color palette

const IconCameraInfo: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="74" height="49" viewBox="0 0 74 49" {...props}>
      <g fill="none" fillRule="evenodd">
        <g stroke="#4db4ab" strokeWidth="4.2" transform="translate(-.5 -.416)">
          <path d="M36.361 4.526c.153.405.415.993.8 1.802 0 0 .223.48.296.626.857 1.707 2.098 2.216 4.773 2.216h4.663c1.528 0 2.831 1.402 2.831 3.204v24.85c0 1.802-1.303 3.204-2.83 3.204h-20.88v4.2h.345v-4.2H5.479c-1.527 0-2.83-1.402-2.83-3.205V12.374c0-1.802 1.303-3.204 2.83-3.204h4.664c2.675 0 3.917-.51 4.773-2.216.073-.145.297-.626.296-.626.385-.809.647-1.397.8-1.802.495-1.07 1.47-1.72 2.507-1.72h7.848v-4.2h-.36v4.2h7.847c1.037 0 2.012.65 2.507 1.72z" />
          <circle cx="26.246" cy="23.715" r="8.263" />
        </g>
        <path
          fill="#FFF"
          d="M55.043 48.668c10.047 0 18.191-8.144 18.191-18.191 0-10.047-8.144-18.191-18.191-18.191-10.047 0-18.191 8.144-18.191 18.19 0 3.599 1.044 6.953 2.847 9.776.738 1.156-.549 4.6.424 5.559.905.892 4.054-.688 5.129 0a18.106 18.106 0 0 0 9.79 2.857z"
        />
        <g transform="matrix(-1 0 0 1 69.48 16.04)">
          <path
            fill="#009487"
            d="M14.285 28.355c-7.799 0-14.12-6.322-14.12-14.12 0-7.8 6.321-14.121 14.12-14.121 7.798 0 14.12 6.322 14.12 14.12 0 2.908-.879 5.611-2.386 7.858-.649.966.599 4.268 0 4.268 0 0-3.701-.475-4.495 0a14.055 14.055 0 0 1-7.24 1.995z"
          />
          <path
            fill="#2b7a86"
            d="M14.285 28.355c-7.799 0-14.12-6.322-14.12-14.12 0-7.8 2.386 9.56 14.12 9.56 11.734 0 14.12-17.36 14.12-9.56 0 2.907-.879 5.61-2.386 7.857-.649.966.599 4.268 0 4.268 0 0-3.701-.475-4.495 0a14.055 14.055 0 0 1-7.24 1.995z"
          />
          <path
            stroke="#4A4A4A"
            strokeWidth="3.195"
            d="M14.285 28.355c-7.799 0-14.12-6.322-14.12-14.12 0-7.8 6.321-14.121 14.12-14.121 7.798 0 14.12 6.322 14.12 14.12 0 2.908-.879 5.611-2.386 7.858-.649.966.599 4.268 0 4.268 0 0-3.701-.475-4.495 0a14.055 14.055 0 0 1-7.24 1.995z"
          />
          <rect width="3.262" height="8.969" x="12.701" y="7.596" fill="#FFF" rx="1.631" />
          <circle cx="14.332" cy="20.026" r="1.782" fill="#FFF" />
        </g>
      </g>
    </svg>
  )
}

export default IconCameraInfo
