import { ComponentType, useEffect, useState } from "react"
import ReactCountryFlag from "react-country-flag"
import { useIntl } from "react-intl"
import { QueryClient } from "react-query"

import classnames from "classnames"

import { Dropdown, StaffBodyTextL, StaffBodyTextM, StaffHeading1 } from "@doktor-se/bones-ui"
import { ToggleButton } from "@doktor-se/bones-ui/dist/web-shared/components"
import { languageCodeToCountryCode } from "@doktor-se/bones-ui/dist/web-shared/types"

import { getDevices } from "api"
import { appLanguages, featureFlags, videoBackgroundImage } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import {
  changeLanguage,
  ringtoneVolume,
  setCalendarScrollTime,
  toggleNewConversationInInboxNotification,
  toggleQueuePing,
  toggleVideoBackground
} from "reducers/app"
import { updateTokboxPubOptions } from "reducers/tokbox"

import SectionHeading from "./components/SectionHeading"
import TokboxNetworkTest from "./components/TokboxNetworkTest"
import VideoFeed from "./components/VideoFeed"
import PageContainer from "components/PageContainer/PageContainer"
import { IconLanguage, IconRingtone, IconSettingMicrophone, IconSettingNetwork, IconSettingVideo } from "icons"

import styles from "./SettingsPage.module.scss"

declare global {
  interface Window {
    stream: MediaStream
  }
}

interface SettingsPageProps {
  queryClient: QueryClient
}

const SettingsPage = ({ queryClient }: SettingsPageProps) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const calls = useAppSelector(state => state.tokbox)
  const callState = useAppSelector(state => state.tokbox.callState)
  const isVideoBackgroundActive = useAppSelector(state => state.app.isVideoBackgroundActive)
  const isNewConversationInInboxNotificationActive = useAppSelector(
    state => state.app.isNewConversationInInboxNotificationActive
  )
  const isQueuePingActive = useAppSelector(state => state.app.isQueuePingActive)
  const volume = useAppSelector(state => state.app.ringtoneVolume)
  const calendarScrollTime = useAppSelector(state => state.app.calendarScrollTime)
  const activeLanguage = useAppSelector(state => state.app.language)
  const languagesWithActiveStatus = appLanguages.map(lang => ({ ...lang, active: lang.code === activeLanguage }))

  const [isCallOnGoing, setIsCallOnGoing] = useState<boolean>(false)
  const [permissionsImg, setPermissionsImg] = useState<string>()

  useEffect(() => {
    Promise.all([import(`../../img/language/${activeLanguage}/permissions.png`)]).then(([img]) => {
      setPermissionsImg(img.default)
    })
  }, [activeLanguage])

  useEffect(() => {
    dispatch(getDevices())
  }, [dispatch])

  useEffect(() => {
    setIsCallOnGoing(["active", "outgoing"].includes(callState) ? true : false)
  }, [callState])

  return (
    <PageContainer>
      <StaffHeading1 margin={{ bottom: "30px" }}>{intl.formatMessage({ id: "label.settings" })}</StaffHeading1>
      <div className={styles.body}>
        <StaffBodyTextL className={classnames(styles.section, styles.subtitle)}>
          {intl.formatMessage({ id: "settings.subtitle" })}
        </StaffBodyTextL>

        <div className={classnames(styles.section, styles.select, styles.audio)}>
          <SectionHeading icon={<IconSettingMicrophone />} translationId="settings.title.microphone" />
          <Dropdown
            variant="onBackground"
            items={calls.inputDevices.audio
              .filter(item => !!item.deviceId && !!item.label)
              .map(item => ({ id: item.deviceId, name: item.label }))}
            label={intl.formatMessage({ id: "dropdown.select.microphone.aria.label" })}
            placeholder={intl.formatMessage({ id: "label.choose" })}
            onSelectionChange={value => dispatch(updateTokboxPubOptions({ audioSource: value as string }))}
            selectedKey={calls.pubOptions.audioSource}
            fullWidth
          />
        </div>

        <div className={classnames(styles.section, styles.select)}>
          <SectionHeading icon={<IconSettingVideo />} translationId="settings.title.camera" />
          <Dropdown
            variant="onBackground"
            items={calls.inputDevices.video
              .filter(item => !!item.deviceId && !!item.label)
              .map(item => ({ id: item.deviceId, name: item.label }))}
            label={intl.formatMessage({ id: "dropdown.select.camera.aria.label" })}
            placeholder={intl.formatMessage({ id: "label.choose" })}
            onSelectionChange={value => dispatch(updateTokboxPubOptions({ videoSource: value as string }))}
            selectedKey={calls.pubOptions.videoSource}
            fullWidth
          />
        </div>
        <VideoFeed />
        <div className={classnames(styles.section, styles.access)}>
          <StaffBodyTextM>{intl.formatMessage({ id: "settings.allow.access" })}</StaffBodyTextM>
          {permissionsImg && <img className={styles.permissionsImg} src={permissionsImg} alt="Permissions" />}
        </div>

        <div className={classnames(styles.section, styles.right)}>
          {featureFlags.has("video_call_background") && (
            <div className={styles.subsection}>
              <SectionHeading icon={<IconSettingVideo />} translationId="label.videoBackground" />
              <div className={styles.subsectionContent}>
                <div className={styles.toggleButton}>
                  <ToggleButton
                    variant={isCallOnGoing ? "grey" : "autoassign"}
                    state={isVideoBackgroundActive}
                    onClick={() => {
                      if (!isCallOnGoing) dispatch(toggleVideoBackground(!isVideoBackgroundActive))
                    }}
                    label={intl.formatMessage({ id: isVideoBackgroundActive ? "settings.on" : "settings.off" })}
                    labelVariant="settings"
                  />
                </div>
                {videoBackgroundImage && (
                  <img className={styles.videoBackgroundImage} src={videoBackgroundImage} alt="Video call background" />
                )}
              </div>
            </div>
          )}

          <div className={classnames(styles.subsection, styles.networkTest)}>
            <SectionHeading icon={<IconSettingNetwork />} translationId="networktest.title" />
            <TokboxNetworkTest />
          </div>

          <div className={styles.subsection}>
            <SectionHeading icon={<IconLanguage />} translationId="label.language" />
            <div className={classnames(styles.flags, styles.subsectionContent)}>
              {languagesWithActiveStatus.map(l => (
                <button
                  className={classnames(styles.flag, { [styles.active]: l.active })}
                  key={l.code}
                  onClick={() => {
                    queryClient.invalidateQueries("closeOptions")
                    dispatch(changeLanguage(l.code))
                  }}>
                  <ReactCountryFlag
                    countryCode={languageCodeToCountryCode[l.code]}
                    style={{ height: "3em", width: "3em" }}
                    svg
                  />
                </button>
              ))}
            </div>
          </div>

          {featureFlags.has("new_conversation_in_inbox_notification") && (
            <div className={classnames(styles.subsection)}>
              <SectionHeading icon={<IconRingtone />} translationId="label.newConversationInInboxNotification" />
              <div className={classnames(styles.subsectionContent)}>
                <div className={styles.toggleButton}>
                  <ToggleButton
                    variant="autoassign"
                    state={isNewConversationInInboxNotificationActive}
                    onClick={() => {
                      const newToggleState = !isNewConversationInInboxNotificationActive
                      if (newToggleState) {
                        Notification.requestPermission()
                      }
                      dispatch(toggleNewConversationInInboxNotification(newToggleState))
                    }}
                    label={intl.formatMessage({
                      id: isNewConversationInInboxNotificationActive ? "settings.on" : "settings.off"
                    })}
                    labelVariant="settings"
                  />
                </div>
                <StaffBodyTextM>
                  {intl.formatMessage({ id: "label.newConversationInInboxNotification.description" })}
                </StaffBodyTextM>
              </div>
            </div>
          )}

          {featureFlags.has("queue_ping") && (
            <div className={classnames(styles.subsection)}>
              <SectionHeading icon={<IconRingtone />} translationId="label.queuePing" />
              <div className={classnames(styles.subsectionContent)}>
                <div className={styles.toggleButton}>
                  <ToggleButton
                    variant="autoassign"
                    state={isQueuePingActive}
                    onClick={() => dispatch(toggleQueuePing(!isQueuePingActive))}
                    label={intl.formatMessage({ id: isQueuePingActive ? "settings.on" : "settings.off" })}
                    labelVariant="settings"
                  />
                </div>
              </div>
            </div>
          )}

          <div className={classnames(styles.subsection, styles.ringtone)}>
            <SectionHeading icon={<IconRingtone />} translationId="label.ringtone" />
            <div className={classnames(styles.volumeContainer, styles.subsectionContent)}>
              <input
                type="range"
                className={styles.volumeInput}
                id="start"
                name="volume"
                min="0"
                max="1"
                step="0.1"
                value={volume}
                onChange={evt => dispatch(ringtoneVolume(parseFloat(evt.target.value)))}
              />
              <StaffBodyTextM>{intl.formatMessage({ id: "settings.volume" })}</StaffBodyTextM>
            </div>
          </div>

          {featureFlags.has("booking") && (
            <div className={styles.subsection}>
              <SectionHeading icon={<IconRingtone />} translationId="label.calendar.scroll" />
              <div className={styles.subsectionContent}>
                <input
                  className={styles.timeInput}
                  type="time"
                  value={calendarScrollTime}
                  onChange={evt => {
                    if (!!evt.target.value) {
                      dispatch(setCalendarScrollTime(evt.target.value))
                    }
                  }}
                />
                <StaffBodyTextM>{intl.formatMessage({ id: "settings.calendar.scroll" })}</StaffBodyTextM>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageContainer>
  )
}

export default SettingsPage as ComponentType<any>
