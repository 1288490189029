import { AnyEvent, AnyRequest } from "@doktor-se/bones-ui/dist/web-shared/types"

class SocketClient {
  limit: number
  retry: number
  client?: WebSocket
  eventHandler?: (data: AnyEvent) => void
  reconnecting: boolean
  constructor() {
    this.limit = 4
    this.retry = 0
    this.client = undefined
    this.eventHandler = undefined
    this.reconnecting = false
  }

  connect(eventHandler: (data: AnyEvent) => void, token: string, url?: string) {
    if (!this.client) {
      return new Promise((resolve, reject) => {
        this.client = new window.WebSocket(`${url}`, [token])
        this.eventHandler = eventHandler
        this.reconnecting = false

        this.client.onopen = () => this.openSocket(resolve)
        this.client.onmessage = (response: MessageEvent) => this.eventHandler!(JSON.parse(response.data))
        this.client.onclose = () => !this.reconnecting && this.reconnect()
        this.client.onerror = (error: any) => this.errorSocket(error, reject)
      })
    }
    return Promise.resolve()
  }

  disconnect() {
    if (this.client) {
      this.client.onclose = () => {}
      this.client.close()
    }
    this.client = undefined
  }

  openSocket(resolve: (value?: unknown) => void) {
    this.retry = 0
    resolve(this)
    this.eventHandler!({ event: "socket.open" })
  }

  errorSocket(error: any, reject: (reason?: any) => void) {
    reject(error)
    this.reconnecting = true
    this.eventHandler!({ event: "socket.failed" })
    this.reconnect()
  }

  reconnect() {
    this.retry = this.retry + 1
    if (this.retry <= this.limit) {
      this.client = undefined
      setTimeout(
        () =>
          this.eventHandler!({
            event: "socket.reconnect",
            retry: this.retry
          }),
        3000
      )
    } else {
      this.retry = 0
      this.client = undefined
      this.eventHandler!({ event: "socket.disconnected" })
    }
  }

  subscribe(conversationId: string) {
    if (this.client && this.client.readyState === 1) {
      this.client.send(
        JSON.stringify({
          type: "subscribe",
          conversation_id: conversationId
        })
      )
    }
  }

  unsubscribe(conversationId: string) {
    if (this.client && this.client.readyState === 1) {
      this.client.send(
        JSON.stringify({
          type: "unsubscribe",
          conversation_id: conversationId
        })
      )
    }
  }

  patientSubscribe(patientId: string) {
    if (this.client && this.client.readyState === 1) {
      this.client.send(
        JSON.stringify({
          type: "patient.subscribe",
          patient_id: patientId
        })
      )
    }
  }

  patientUnsubscribe(patientId: string) {
    if (this.client && this.client.readyState === 1) {
      this.client.send(
        JSON.stringify({
          type: "patient.unsubscribe",
          patient_id: patientId
        })
      )
    }
  }

  ping() {
    const data = {
      type: "ping"
    }
    if (this.client && this.client.readyState === 1) {
      this.client.send(JSON.stringify(data))
    }
  }

  send(data: AnyRequest) {
    if (this.client && this.client.readyState === 1) {
      this.client.send(JSON.stringify(data))
    }
  }
}

export default SocketClient
