import classNames from "classnames"

import { SymptomBubble } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Category } from "@doktor-se/bones-ui/dist/web-shared/types"

import { appStatic } from "config"

import styles from "./CallBubbles.module.scss"

interface CallBubblesProps {
  symptom?: Category
}

const categorySymptomBubbleStyle = (category: Category | undefined) => {
  if (category && !!category.metadata.conversationMetaInit?.premiumHealth) {
    return styles.premiumHealth
  }
  if (category && !!category.metadata.conversationMetaInit?.revisit) {
    return styles.revisit
  }
  return undefined
}

const CallBubbles = ({ symptom }: CallBubblesProps) => (
  <div className={styles.container}>
    <div className={classNames(styles.bubble, styles.outerBubble)} />
    <div className={classNames(styles.bubble, styles.middleBubble)} />
    <div className={styles.centerBubble}>
      <SymptomBubble
        className={categorySymptomBubbleStyle(symptom)}
        symptom={symptom}
        size="large"
        appStatic={appStatic}
        cssFilter={categorySymptomBubbleStyle(symptom) && "brightness(0);"}
      />
    </div>
  </div>
)

export default CallBubbles
