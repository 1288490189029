import { useIntl } from "react-intl"

import { Button, ICONS, StaffBodyTextM, StaffBodyTextXS } from "@doktor-se/bones-ui"

import styles from "./CopyLinkWindow.module.scss"

interface CopyLinkWindowProps {
  amount: string
  paymentLink: string
  setShowPaymentLink: (value: boolean) => void
  onClickCopyToConversation: (text: string) => void
}

const CopyLinkWindow = ({
  amount,
  paymentLink,
  setShowPaymentLink,
  onClickCopyToConversation
}: CopyLinkWindowProps) => {
  const intl = useIntl()
  const paymentLinkText = intl.formatMessage({ id: "payment.link" }, { amount, link: paymentLink }, { ignoreTag: true })

  return (
    <div className={styles.generatedTextContainer}>
      <Button
        variant="secondary"
        color="primary"
        onPress={() => setShowPaymentLink(false)}
        Icon={<ICONS.ChevronLeft className={styles.icon} height={15} width={15} />}>
        {intl.formatMessage({ id: "payment.button.back" })}
      </Button>

      <div className={styles.copyTextHeader}>
        <StaffBodyTextM>{intl.formatMessage({ id: "payment.summary" })}</StaffBodyTextM>
        <p className={styles.moneyText}>{`${amount} €`}</p>
      </div>

      <StaffBodyTextXS className={styles.copyText} margin={{ bottom: "32px" }}>
        {paymentLinkText}
      </StaffBodyTextXS>

      <div className={styles.buttonContainer}>
        <Button variant="primary" color="primary" onPress={() => onClickCopyToConversation(paymentLinkText)}>
          {intl.formatMessage({ id: "payment.button.copy" })}
        </Button>
      </div>
    </div>
  )
}

export default CopyLinkWindow
