import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"

import { Clinic } from "@doktor-se/bones-ui/dist/web-shared/types"

import { RootState } from "reducers/init"

const clinicAdapter = createEntityAdapter<Clinic>()

const clinics = createSlice({
  name: "clinics",
  initialState: clinicAdapter.getInitialState(),
  reducers: {
    loadClinics: clinicAdapter.setAll
  }
})

export const { loadClinics } = clinics.actions

export type ClinicsSliceAction = ObjectFunctionReturnTypes<typeof clinics.actions>

export const clinicSelectors = clinicAdapter.getSelectors<RootState>(state => state.clinics)

export default clinics.reducer
