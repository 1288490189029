import { Staff } from "@doktor-se/bones-ui/dist/web-shared/types"
import { toTitleCase } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { UsersState } from "reducers/users/users.reducer"
import { SearchAccount } from "types"

export const setStatus = (
  staff: Staff[],
  onlineStaff?: {
    [staffId: string]: boolean | undefined
  }
): Staff[] => {
  if (!onlineStaff || !staff.length) return staff
  return staff.map(s => ({ ...s, online: onlineStaff[s.id] || false }))
}

export const clearStatus = (staff: Staff[]) => staff.map(s => ({ ...s, online: false }))

const sortStaffByDisplayName = (staff: Staff[]): Staff[] => {
  return staff.sort((a, b) => {
    const nameA = a.displayName.toUpperCase()
    const nameB = b.displayName.toUpperCase()
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  })
}

const sortStaff = (staff: Staff[]): Staff[] => {
  const online = sortStaffByDisplayName(staff.filter(s => s.online))
  const offline = sortStaffByDisplayName(staff.filter(s => !s.online))
  return [...online, ...offline]
}

export const filterStaff = (
  state: UsersState,
  staff: Staff[],
  onlineStaff?: {
    [staffId: string]: boolean | undefined
  }
): UsersState => {
  const finalStaff = sortStaff(setStatus(staff, onlineStaff))

  return {
    ...state,
    staff: finalStaff,
    activeStaff: finalStaff.filter(s => !s.removed && s.role !== "bot"),
    onlineStaff
  }
}

export const removeStaff = (staff: Staff[], staffId: string): Staff[] =>
  staff.map(s => {
    if (s.id === staffId) {
      return { ...s, removed: true }
    }
    return s
  })

export const loadPatients = (state: UsersState, patients: SearchAccount[]): UsersState => ({
  ...state,
  patients: patients.map(p => ({
    ...p,
    displayName: toTitleCase(p.displayName!)!
  }))
})
