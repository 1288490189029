import { OpeningHour } from "@doktor-se/bones-ui/dist/web-shared/types"

import { handleErrors } from "api/error/handler"
import { apiFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"
import { loadQueueOpeningHours } from "reducers/app"

export const queueOpeningHoursFetch = (): Thunk => dispatch => {
  dispatch(apiFetch("/schedule/queues/weekdays"))
    .then((response: OpeningHour[]) => dispatch(loadQueueOpeningHours(response)))
    .catch((error: any) => dispatch(handleErrors({ error })))
}
