import dayjs from "dayjs"

import { BookingType } from "@doktor-se/bones-ui/dist/web-shared/types"

import { Thunk } from "lib/hooks"
import { Booking } from "reducers/booking"
import { clearBookingTimeout, setBookingTimeout } from "reducers/selected"

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000

interface HandleBookingStartArgs {
  booking: Booking
  kind?: BookingType
  action: () => void
  conversationId: string
}

export const handleBookingStart =
  ({ booking, kind, action, conversationId }: HandleBookingStartArgs): Thunk =>
  (dispatch): void => {
    const activationTime = dayjs(booking.start).subtract(kind?.preparationLength || 0, "minutes")

    if (dayjs().isAfter(activationTime)) {
      action()
      return
    }

    const timeUntilActivationInMs: number = dayjs(activationTime).diff(dayjs())
    if (timeUntilActivationInMs > ONE_DAY_IN_MS) {
      dispatch(clearBookingTimeout(conversationId))
      return
    }

    dispatch(
      setBookingTimeout({
        timeout: setTimeout(action, timeUntilActivationInMs),
        data: { conversationId }
      })
    )
  }
