import { AssignedConversation, CountryCode } from "@doktor-se/bones-ui/dist/web-shared/types"

import { handleErrors } from "api/error/handler"
import ElkCall from "lib/elk"
import { apiFetch } from "lib/fetch"
import { ReduxDispatch, Thunk } from "lib/hooks"
import { setConversationId, setElkAccount } from "reducers/elk"

export interface ApiElkAccount {
  number: string
  uri: string
  username: string
  password: string
  wsServers: string[]
}

const sipCall = new ElkCall()

export const releaseSipNumber = (dispatch: ReduxDispatch) => {
  dispatch(
    apiFetch("/sip/release", {
      method: "POST"
    })
  ).catch((error: any) => dispatch(handleErrors({ error })))
}

const startSipCall =
  (account: ApiElkAccount, number: string, region?: string): Thunk =>
  dispatch => {
    dispatch(
      apiFetch("/sip/calls", {
        method: "POST",
        body: JSON.stringify({
          from: account!.number,
          to: number,
          region
        })
      })
    ).catch((error: any) => {
      dispatch(handleErrors({ error }))
      releaseSipNumber(dispatch)
      dispatch(setElkAccount(undefined))
      dispatch(setConversationId(undefined))
    })
  }

export const claimSip =
  (
    number: string,
    audioElement: HTMLAudioElement,
    dtmfAudioElement: HTMLAudioElement,
    conversation?: AssignedConversation,
    region?: CountryCode
  ): Thunk =>
  dispatch => {
    dispatch(
      apiFetch("/sip/claim", {
        method: "POST",
        body: JSON.stringify({ region })
      })
    )
      .then((account: ApiElkAccount) => sipCall.connect(account, audioElement, dtmfAudioElement, number, conversation))
      .then((account: ApiElkAccount) => {
        dispatch(setElkAccount(account))
        dispatch(setConversationId(conversation?.id))
        dispatch(startSipCall(account, number, region))
      })
      .catch((error: any) => dispatch(handleErrors({ error })))
  }

export const closeSipCall = (): void => {
  sipCall.close()
}

export const sendSipDigit = (digit: string): void => {
  sipCall.sendDigit(digit)
}

export const logSipCall = (
  number: string,
  target: string,
  conversationId: string,
  dispatch: ReduxDispatch,
  start?: Date,
  end?: Date
) => {
  dispatch(
    apiFetch(`/conversations/${conversationId}/sip/log`, {
      method: "POST",
      body: JSON.stringify({
        start,
        end,
        number,
        target
      })
    })
  ).catch((error: any) => dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>/sip/log" })))
}
