import React, { useCallback, useMemo } from "react"

import { TextInput } from "@doktor-se/bones-ui"

import styles from "./PaymentInput.module.scss"

interface PaymentInputProps {
  type: "number" | "text"
  value?: string | undefined
  labelText: string
  onChange: (value: string) => void
  children: React.ReactNode
}

const INTEGER_OR_DECIMAL_WITH_MAX_2_PLACES_OR_EMPTY = new RegExp(/^\d+$|^\d+\.\d{0,2}$|^$/)
const PaymentInput = ({ value, labelText, onChange, children, type }: PaymentInputProps) => {
  const onNumberChange = useCallback(
    (newValue: string) => {
      const replaceCommasWithDots = () => {
        return newValue.replace(/,/, ".")
      }

      let modifiedValue = replaceCommasWithDots()

      if (!INTEGER_OR_DECIMAL_WITH_MAX_2_PLACES_OR_EMPTY.test(modifiedValue)) {
        modifiedValue = value || ""
      }
      onChange(modifiedValue)
    },
    [onChange, value]
  )

  const inputComponent = useMemo(() => {
    switch (type) {
      case "number":
        // TODO Replace with number input component when that is available in bones-ui
        return <TextInput variant="onSurface" label={labelText} value={value} onChange={onNumberChange} />

      case "text":
        return <TextInput variant="onSurface" label={labelText} value={value} onChange={onChange} />

      default:
        return null
    }
  }, [onNumberChange, onChange, labelText, type, value])

  return (
    <div className={styles.container}>
      <span className={styles.placeholderIcon}>{children}</span>
      {inputComponent}
    </div>
  )
}

export default PaymentInput
