import classNames from "classnames"

import styles from "./Section.module.scss"

interface SectionProps {
  bottomMargin?: boolean
  children: React.ReactNode
}

const Section = ({ children, bottomMargin }: SectionProps) => {
  return <div className={classNames(styles.section, { [styles.margin]: bottomMargin })}>{children}</div>
}

export default Section
