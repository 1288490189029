import { Staff } from "@doktor-se/bones-ui/dist/web-shared/types"

import UserRow from "components/UserRow/UserRow"

import styles from "./AssignToMyself.module.scss"

interface AssignToRowProps {
  user: Staff
  onClick: () => void
}

const AssignToMyself = ({ user, onClick }: AssignToRowProps) => {
  return (
    <div data-testid="assign-to-myself" className={styles.container}>
      <UserRow user={user} onClick={onClick} />
    </div>
  )
}

export default AssignToMyself
