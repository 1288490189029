import React from "react"

import classnames from "classnames"

import styles from "./BannerContainer.module.scss"

interface BannerContainerProps {
  className?: string
  children: React.ReactNode
}

const BannerContainer = ({ className, children }: BannerContainerProps) => {
  return (
    <div className={classnames(styles.bannerContainer, className)}>
      <div className={styles.banner}>{children}</div>
    </div>
  )
}

export default BannerContainer
