import { datadogLogs, LogsInitConfiguration } from "@datadog/browser-logs"

import { DatadogOptions } from "@doktor-se/more-rum-please"

import { featureFlags } from "config"

export { datadog } from "@doktor-se/more-rum-please"

export const datadogLogsInit = (options: DatadogOptions & LogsInitConfiguration) => {
  datadogLogs.init({
    ...options,
    forwardErrorsToLogs: featureFlags.has("forward_errors_to_logs"),
    sessionSampleRate: 100
  })

  datadogLogs.setGlobalContextProperty("project", options.project)
}
