import classNames from "classnames"

import { Category } from "@doktor-se/bones-ui/dist/web-shared/types"

import styles from "./CategoryBadge.module.scss"

interface SymptomBadgeProps {
  category?: Pick<Category, "name" | "image">
  size: "small" | "big"
  type: "premiumHealth" | "revisit" | "general"
}

// TODO: Move into bones-ui
const SymptomBadge = ({ category, size, type }: SymptomBadgeProps): JSX.Element => {
  return (
    <div className={classNames(styles.container, styles[size], styles[type])}>
      <img
        className={classNames(styles[size], type === "premiumHealth" && styles.filteredImg)}
        src={`${import.meta.env.VITE_APP_APP_STATIC}/${!!category ? category.image : "symptom/other"}.png`}
        alt={category?.name}
      />
    </div>
  )
}

export default SymptomBadge
