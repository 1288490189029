import dayjs from "dayjs"
import humps from "humps"
import { UAParser } from "ua-parser-js"

import { AssignedConversation, CallStateChangedMessage, MetaData } from "@doktor-se/bones-ui/dist/web-shared/types"

import { connectWs, fetchCallToken, retriesWS } from "api"
import { handleErrors } from "api/error/handler"
import handleEvents from "api/ws/handler"
import { addPostponeTimer } from "api/ws/handlers/handleAssignment"
import { datadogConfig, featureFlags } from "config"
import { apiFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"
import { removeUnassignedConversationsFromLocalStorage } from "lib/seald"
import {
  loadConversation,
  loadConversations,
  updateConversationMetadata
} from "reducers/conversations/conversations.reducer"
import { updateJournalDraft, updateJournalDrafts } from "reducers/selected"
import { updateCall } from "reducers/tokbox"
import "types"

const resetCall =
  (conversations: AssignedConversation[]): Thunk =>
  (dispatch, getState) => {
    const { user } = getState().auth
    const conversationWithCall = conversations.find(
      c => c.assignedStaffId === user?.id && ["active", "outgoing"].includes(c.callState)
    )

    if (conversationWithCall) {
      const callMessages = conversationWithCall?.messages?.filter(
        m => m.type === "call" && ["incoming", "outgoing"].includes((m as CallStateChangedMessage).data.callState)
      ) as CallStateChangedMessage[]
      const sessionId = callMessages[callMessages.length - 1].data.sessionId
      dispatch(fetchCallToken(conversationWithCall, sessionId)).then(() =>
        dispatch(updateCall({ callState: conversationWithCall.callState, conversationId: conversationWithCall.id }))
      )
    } else {
      dispatch(updateCall({ callState: "inactive" }))
    }
  }

interface AssignedConversationsListResponse {
  conversations: AssignedConversation[]
  paginationCount: number
}

export const conversationsConnect = (): Thunk<Promise<true | void>> => async (dispatch, getState) => {
  try {
    const response: AssignedConversationsListResponse = await dispatch(
      apiFetch({ url: `/v2/conversations/assigned/list` })
    )
    await dispatch(connectWs(response.conversations, getState().auth.accessToken!))
    return true
  } catch (error: any) {
    if (error.status !== 401) {
      setTimeout(
        () =>
          dispatch(
            handleEvents({
              event: "socket.reconnect",
              retry: retriesWS()
            })
          ),
        3000
      )
    }
    dispatch(handleErrors({ error, customError: true }))
  }
}

const parseConversation = (conversation: any): AssignedConversation => {
  return humps.camelizeKeys({
    ...conversation,
    history: conversation.history && [
      ...conversation.history.map((h: any) => ({
        ...h,
        journalNote: h.journalNote && {
          ...h.journalNote,
          data: JSON.parse(h.journalNote.data)
        }
      }))
    ],
    messages: [
      ...conversation.messages.map((m: any) => ({
        ...m,
        data: m.data && typeof m.data !== "string" ? { ...m.data } : JSON.parse(m.data)
      }))
    ]
  }) as AssignedConversation
}

export const fetchAssignedConversations = (): Thunk => async (dispatch, getState) => {
  try {
    const response: AssignedConversationsListResponse = await dispatch(
      apiFetch({
        url: `/v2/conversations/assigned/list`,
        incomingOptions: {
          loader: "ASSIGNED_CONVERSATIONS"
        }
      })
    )
    let conversations: AssignedConversation[] = response.conversations.map(parseConversation)
    dispatch(loadConversations(conversations))

    conversations.forEach(conversation => {
      if (
        !conversation.messages.some(
          message => message.type === "staff_platform_log" && message.createdById === getState().auth.user?.id
        )
      ) {
        dispatch(platformLog(conversation.id))
      }
    })

    if (featureFlags.has("journal")) dispatch(updateJournalDrafts(conversations))

    if (featureFlags.has("e2ee")) {
      removeUnassignedConversationsFromLocalStorage(conversations)
    }

    conversations.forEach(conversation => {
      if (!!conversation.postponedUntil && dayjs(conversation.postponedUntil) > dayjs()) {
        dispatch(addPostponeTimer(conversation.id, conversation.postponedUntil))
      }
    })

    dispatch(resetCall(conversations))
  } catch (error: any) {
    dispatch(handleErrors({ error }))
  }
}

export const fetchAssignedConversation =
  (conversationId: string): Thunk<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const response = await dispatch(apiFetch(`/conversations/assigned/${conversationId}`))
      let conversation = parseConversation(response)
      const user = getState().auth.user
      if (!user) return
      dispatch(loadConversation({ conversation, user }))
      if (featureFlags.has("journal")) {
        dispatch(updateJournalDraft(conversation))
      }
      if (
        conversation.assignedStaffId === user.id &&
        !conversation.messages.some(message => message.type === "staff_platform_log" && message.createdById === user.id)
      ) {
        dispatch(platformLog(conversation.id))
      }
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/conversations/assigned/<conversationId>" }))
    }
  }

export const platformLog =
  (conversationId: string): Thunk =>
  dispatch => {
    const result = new UAParser().setUA(navigator.userAgent).getResult()
    dispatch(
      apiFetch(`/conversations/${conversationId}/platform`, {
        method: "POST",
        body: JSON.stringify({
          type: "carealot",
          manufacturer: result.device.vendor,
          os: `${result.os.name} ${result.os.version}`,
          app_version: datadogConfig.version,
          device_type: result.device.type,
          browser: `${result.browser.name} ${result.browser.version}`
        })
      })
    ).catch((error: any) => {
      dispatch(handleErrors({ error, customError: true, customContext: "/conversations/<conversationId>/platform" }))
    })
  }

export const setMetadataOnConversation = (conversationId: string, metadata: MetaData): Thunk => {
  const { clinic, closingStaffRole, paymentRequired, isUpcomingBooking, preventReopen } = metadata
  return async dispatch => {
    try {
      const response: boolean = await dispatch(
        apiFetch(`/conversations/${conversationId}/metadata`, {
          method: "POST",
          body: JSON.stringify({
            clinic,
            closing_staff_role: closingStaffRole,
            payment_required: paymentRequired,
            is_upcoming_booking: isUpcomingBooking,
            prevent_reopen: preventReopen
          })
        })
      )
      if (response)
        dispatch(
          updateConversationMetadata({
            conversationId,
            metadata: { clinic, closingStaffRole, paymentRequired, isUpcomingBooking, preventReopen }
          })
        )
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>/metadata" }))
    }
  }
}

export interface PostponeTimesResponse {
  times: string[]
  showAsap: boolean
}
export const getPostponeTimes = (
  conversationId: string,
  queueName: string
): Thunk<Promise<PostponeTimesResponse | undefined>> => {
  return async dispatch => {
    try {
      return await dispatch(apiFetch(`/conversations/${conversationId}/postpone_times?queue_key=${queueName}`))
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>/postpone_times" }))
    }
  }
}
