import React from "react"

const IconUnsnooze: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor" fillRule="nonzero">
        <path d="M16.77 2.64A9.973 9.973 0 0 1 20 10c0 5.523-4.477 10-10 10a9.973 9.973 0 0 1-7.36-3.23l1.348-1.35A8.095 8.095 0 1 0 15.422 3.989zM10 0c1.885 0 3.648.521 5.153 1.428l-1.399 1.398A8.095 8.095 0 0 0 2.826 13.754l-1.398 1.399A9.953 9.953 0 0 1 0 10C0 4.477 4.477 0 10 0z" />
        <path d="M10.554 8.855v1.918l4.025 1.889c.35.164.505.537.376.866l-.042.088c-.18.319-.589.46-.95.343l-.097-.038L9 11.636V10.41l1.554-1.554zM9.778 5c.393 0 .719.267.77.612l.007.096v.316L9 7.58V5.708c0-.39.348-.708.778-.708z" />
        <path d="m20 0 .005 1.404L1.402 20.002 0 20v-1.412L18.577 0z" />
      </g>
    </svg>
  )
}

export default IconUnsnooze
