import { useEffect } from "react"
import { useIntl } from "react-intl"

import { Button, ICONS, StaffLabelTextS } from "@doktor-se/bones-ui"

import PaymentInput from "../PaymentInput/PaymentInput"

import styles from "./InputWindow.module.scss"

interface InputWindowProps {
  amountValue: string | undefined
  onChangeAmount: (value: string) => void
  tokenValue: string | undefined
  onChangeToken: (value: string) => void
  generateLink: (amount: string, token: string) => void
}

const InputWindow = ({ amountValue, onChangeAmount, tokenValue, onChangeToken, generateLink }: InputWindowProps) => {
  const intl = useIntl()

  useEffect(() => {
    const keyPressHandler = (e: KeyboardEvent) => {
      if (e.code === "Enter" && amountValue && tokenValue) generateLink(amountValue!, tokenValue!)
    }
    document.addEventListener("keydown", keyPressHandler)

    return () => document.removeEventListener("keydown", keyPressHandler)
  }, [generateLink, amountValue, tokenValue])

  return (
    <div>
      <div className={styles.logo}>
        <ICONS.PayconiqLogo />
      </div>

      <div className={styles.row}>
        <PaymentInput
          type="number"
          value={amountValue}
          labelText={intl.formatMessage({ id: "payment.amount.placeholder" })}
          onChange={onChangeAmount}>
          <ICONS.Euro width={18} height={18} />
        </PaymentInput>
        <StaffLabelTextS margin={{ top: "var(--size-100)" }}>
          {intl.formatMessage({ id: "payment.amount.description" })}
        </StaffLabelTextS>
      </div>

      <div className={styles.row}>
        <PaymentInput
          type="text"
          value={tokenValue}
          labelText={intl.formatMessage({ id: "payment.token.placeholder" })}
          onChange={onChangeToken}>
          <ICONS.PayconiqLogoVariant width={18} height={18} />
        </PaymentInput>
        <StaffLabelTextS margin={{ top: "var(--size-100)" }}>
          {intl.formatMessage({ id: "payment.token.description" })}
        </StaffLabelTextS>
      </div>

      <div className={styles.buttonWrapper}>
        <Button
          variant="primary"
          color="primary"
          isDisabled={!(amountValue && tokenValue)}
          onPress={() => generateLink(amountValue!, tokenValue!)}>
          {intl.formatMessage({ id: "payment.button.generate" })}
        </Button>
      </div>
    </div>
  )
}

export default InputWindow
