import classNames from "classnames"

import styles from "./PageContainer.module.scss"

type Children = JSX.Element | false

interface PageContainerProps {
  children: Children | Children[]
  className?: string
}

const PageContainer = ({ children, className }: PageContainerProps) => {
  return <div className={classNames(styles.container, className)}>{children}</div>
}

export default PageContainer
