import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { Dropdown, StaffHeading1 } from "@doktor-se/bones-ui"
import { usePrevious } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { CountryCode } from "@doktor-se/bones-ui/dist/web-shared/types"

import { closeSipCall } from "api"
import { callCountryCodes, featureFlags } from "config"
import { useAppSelector } from "lib/hooks"

import DialNumber from "components/DialNumber/DialNumber"
import PageContainer from "components/PageContainer/PageContainer"

import styles from "./DialNumberPage.module.scss"

const DialNumberPage = () => {
  const intl = useIntl()
  const account = useAppSelector(state => state.elk.account)
  const prevAccount = usePrevious(account)
  const defaultCountryCode = callCountryCodes[0]?.region
  const [countryCode, setCountryCode] = useState<CountryCode>(defaultCountryCode)

  useEffect(() => {
    if (prevAccount && !account) closeSipCall()
  }, [account, prevAccount])

  const endCall = () => {
    if (account) closeSipCall()
  }

  return (
    <PageContainer>
      <StaffHeading1 margin={{ bottom: "30px" }}>{intl.formatMessage({ id: "dial.title" })}</StaffHeading1>

      <div className={styles.content}>
        {featureFlags.has("country_code_dial_dropdown") && (
          <div className={styles.dropdownContainer}>
            <Dropdown
              variant="onBackground"
              label={intl.formatMessage({ id: "dropdown.select.country.code.aria.label" })}
              items={callCountryCodes.map(code => ({
                id: code.region,
                name: `${intl.formatMessage({ id: `region.${code.region}` })} (${code.number})`
              }))}
              selectedKey={countryCode}
              onSelectionChange={key => setCountryCode(key as CountryCode)}
              fullWidth
            />
          </div>
        )}

        <DialNumber endCall={endCall} countryCode={countryCode} />
      </div>
    </PageContainer>
  )
}

export default DialNumberPage
