import React, { useCallback, useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"

import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"

import { Button } from "@doktor-se/bones-ui"

import { conversationsSearch, reopenConversation, SearchConversationsBody } from "api"
import { blockDefaultSearch } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { getSearchParams } from "lib/search"
import { categorySelectors } from "reducers/categories"
import { closeError } from "reducers/error"
import { setOffset } from "reducers/search/search.reducer"
import { clearConversationsSearch } from "reducers/search/search.reducer"

import ConversationRow from "./components/ConversationRow/ConversationRow"
import EmptyResult from "./components/EmptyResult/EmptyResult"
import SearchHeader from "./components/SearchHeader/SearchHeader"
import PageContainer from "components/PageContainer/PageContainer"

import styles from "./SearchPage.module.scss"

dayjs.extend(customParseFormat)

const SearchPage = () => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const { conversations, pagination } = useAppSelector(state => state.search)
  const { staff, patients } = useAppSelector(state => state.users)
  const categories = useAppSelector(categorySelectors.selectEntities)
  const error = useAppSelector(state => state.error.error)
  const defaultRole = useAppSelector(state => state.auth.defaultRole)
  const user = useAppSelector(state => state.auth.user)
  const [show, setShow] = useState("")
  const [conversationId, setConversationId] = useState<string>()
  const [filter, setFilter] = useState({ searchQuery: "", staffId: "" })
  const [searchString, setSearchString] = useState("")
  const [refresh, setRefresh] = useState(false)
  const [paginationParams, setPaginationParams] = useState({
    offset: 0,
    limit: pagination.limit,
    more: false
  })

  const loadTimeout = useRef<ReturnType<typeof setTimeout>>()
  const refreshTimeout = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    setFilter(f => ({ ...f, searchQuery: searchString || "" }))
  }, [searchString])

  const resetPagination = useCallback(() => {
    setPaginationParams({
      offset: 0,
      limit: pagination.limit,
      more: false
    })
  }, [pagination.limit])

  const setConversation = (conversationId?: string) => {
    setConversationId(conversationId)
    setShow("")
  }

  const search = useCallback(() => {
    if (blockDefaultSearch(defaultRole) && !filter.searchQuery) return
    if (!filter.searchQuery && !filter.staffId) {
      dispatch(clearConversationsSearch())
      return
    }

    let body: SearchConversationsBody = {
      limit: paginationParams.limit,
      offset: paginationParams.offset,
      clinic_id: user?.data?.clinic
    }
    if (filter.searchQuery) {
      body = { ...body, ...getSearchParams(filter.searchQuery) }
    }
    if (filter.staffId) body.staff_id = filter.staffId
    dispatch(conversationsSearch(body, paginationParams.more))
    dispatch(setOffset(paginationParams.offset))
    dispatch(closeError(error))
    if (!paginationParams.more) {
      setConversation(undefined)
      setRefresh(true)
      refreshTimeout.current = setTimeout(() => {
        setRefresh(false)
      }, 250)
    }
  }, [
    defaultRole,
    dispatch,
    error,
    filter.searchQuery,
    filter.staffId,
    paginationParams.limit,
    paginationParams.more,
    paginationParams.offset,
    user?.data?.clinic
  ])
  useEffect(() => {
    search()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationParams])

  useEffect(
    () => () => {
      if (loadTimeout.current) clearTimeout(loadTimeout.current)
      if (refreshTimeout.current) clearTimeout(refreshTimeout.current)
    },
    []
  )

  const loadMoreConversations = () => {
    setPaginationParams({
      offset: pagination.offset + pagination.limit,
      limit: pagination.limit,
      more: true
    })
  }

  const changeSearchString = (value: string) => {
    if (!value) {
      resetPagination()
      setFilter(f => ({ ...f, searchQuery: "" }))
    }
    setSearchString(value)
  }

  const onKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === "Enter") {
      resetPagination()
      setFilter(f => ({ ...f, searchQuery: searchString }))
    }
  }

  const changeStaffFilter = (staffId: string) => {
    setFilter(f => ({ ...f, staffId }))
    setShow("")
    resetPagination()
  }

  const removeStaffFilter = () => {
    setFilter(f => ({ ...f, staffId: "" }))
    setShow("")
    resetPagination()
  }

  return (
    <PageContainer>
      <SearchHeader
        error={error}
        onKeyDown={onKeyDown}
        changeSearchString={changeSearchString}
        searchString={searchString}
        update={() => {
          setFilter(f => ({ ...f, searchQuery: searchString }))
          resetPagination()
        }}
        staffId={filter.staffId}
        changeStaff={changeStaffFilter}
        removeStaff={removeStaffFilter}
      />
      <div className={styles.body}>
        {!refresh && (
          <div>
            {conversations.map(conversation => (
              <ConversationRow
                key={conversation.id}
                conversation={conversation}
                assigned={!!conversation.assignedStaffId}
                assignedStaff={staff.find(s => s.id === conversation.assignedStaffId)}
                selectedConversationId={conversationId}
                setConversation={setConversation}
                reopen={show === "reopen"}
                toQueue={show === "toQueue"}
                reassign={show === "reassign"}
                close={show === "close"}
                showReopen={() => setShow("reopen")}
                closeControl={() => setShow("")}
                reopenConversation={staffId => dispatch(reopenConversation(conversation.id, staffId))}
                showClose={() => setShow("close")}
                showToQueue={() => setShow("toQueue")}
                showReassign={() => setShow("reassign")}
                category={conversation.categoryId ? categories[conversation.categoryId] : undefined}
                showCategory={true}
              />
            ))}

            {!conversations.length && (
              <EmptyResult
                searchQuery={filter.searchQuery}
                staffId={filter.staffId}
                error={error}
                patients={patients}
              />
            )}
            {conversations.length > 0 && pagination.offset + pagination.limit < pagination.count && (
              <Button variant="outline" color="primary" onPress={loadMoreConversations} fullWidth>
                {intl.formatMessage({ id: "btn.pagination" })}
              </Button>
            )}
          </div>
        )}
      </div>
    </PageContainer>
  )
}

export default SearchPage
