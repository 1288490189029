import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dayjs } from "dayjs"

import { Role } from "@doktor-se/bones-ui/dist/web-shared/types"

import { allowCherryPick, defaultRole } from "config"
import { CarealotUser } from "types"

export interface AuthState {
  authorized: boolean
  accessToken: string
  refreshToken?: string
  AD: boolean
  tokenTimeOut?: ReturnType<typeof setTimeout>
  tokenExpBannerState?: Dayjs
  user?: CarealotUser
  roles: Role[]
  cherryPick: boolean
  defaultRole?: Role
  payconiqToken?: string
  webdocTokens?: {
    accessToken: string
    refreshToken: string
  }
  webdocAuthState?: string
}

const initialState: AuthState = {
  authorized: false,
  refreshToken: undefined,
  accessToken: "",
  AD: false,
  tokenTimeOut: undefined,
  tokenExpBannerState: undefined,
  user: undefined,
  roles: [],
  cherryPick: true,
  webdocTokens: undefined,
  webdocAuthState: undefined
}

const getUserRoles = (roles: Role[]) => ({
  roles: roles,
  cherryPick: allowCherryPick(roles),
  defaultRole: defaultRole(roles)
})

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginUser(state, action: PayloadAction<CarealotUser>) {
      const roles = getUserRoles(action.payload.roles || [])
      state.user = {
        ...action.payload,
        roles: roles.roles
      }
    },
    updateUser(state, action: PayloadAction<CarealotUser>) {
      state.user = action.payload
    },
    setTokenTimeout(state, action: PayloadAction<ReturnType<typeof setTimeout> | undefined>) {
      state.tokenTimeOut = action.payload
    },
    authorizedAction(
      state,
      action: PayloadAction<{
        accessToken: string
        refreshToken?: string
        roles: Role[]
        AD?: boolean
      }>
    ) {
      const roles = getUserRoles(action.payload.roles)
      state.roles = roles.roles
      state.cherryPick = roles.cherryPick
      state.defaultRole = roles.defaultRole
      state.authorized = true
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
      if (action.payload.AD) {
        state.AD = true
      }
    },
    unauthorize(state) {
      state.authorized = false
      state.accessToken = ""
      state.refreshToken = undefined
      state.AD = false
      state.tokenExpBannerState = undefined
      state.roles = []
      state.webdocTokens = undefined
    },
    tokenExpWarning(state, action: PayloadAction<Dayjs | undefined>) {
      state.tokenTimeOut = undefined
      state.tokenExpBannerState = action.payload
    },
    updatePayconiqToken(state, action: PayloadAction<string>) {
      state.payconiqToken = action.payload
    },
    setAuthTokens(state, action: PayloadAction<{ accessToken: string; refreshToken: string }>) {
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
    },
    setWebdocTokens(state, action: PayloadAction<{ accessToken: string; refreshToken: string }>) {
      state.webdocTokens = action.payload
    },
    setWebdocAuthState(state, action: PayloadAction<string>) {
      state.webdocAuthState = action.payload
    }
  }
})

export const {
  loginUser,
  setTokenTimeout,
  authorizedAction,
  unauthorize,
  tokenExpWarning,
  updateUser,
  updatePayconiqToken,
  setAuthTokens,
  setWebdocTokens,
  setWebdocAuthState
} = auth.actions

export type AuthSliceAction = ObjectFunctionReturnTypes<typeof auth.actions>

export default auth.reducer
