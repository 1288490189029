import dayjs from "dayjs"

import { AssignmentChangedMessage } from "@doktor-se/bones-ui/dist/web-shared/types"

import { fetchAndLoadQueueConversation, fetchAssignedConversation } from "api"
import { blockFetchQueueConversations } from "config"
import { Thunk } from "lib/hooks"
import { removeConversation, removePostpone } from "reducers/conversations/conversations.reducer"
import { removeFromQueue, removeFromQueueOutline, updateQueueCount } from "reducers/queue/queue.reducer"
import { assignSearchedConversation } from "reducers/search/search.reducer"
import { setPostponeTimeout } from "reducers/selected"

let messageIds: number[] = []

export const addPostponeTimer =
  (conversationId: string, postponeUntil: string, queue?: string | null): Thunk =>
  (dispatch, getState) => {
    const currentTimeout = getState().selected.timeouts.postpone.find(timeout => timeout.id === conversationId)
    if (currentTimeout) clearTimeout(currentTimeout.timeout)

    const timeout = setTimeout(() => {
      dispatch(removePostpone(conversationId))
      if (queue) {
        dispatch(updateQueueCount({ key: queue as string, postponedConversationCount: -1 }))
        dispatch(updateQueueCount({ key: queue as string, conversationCount: 1 }))
      }
    }, dayjs(postponeUntil).diff(dayjs()))
    dispatch(setPostponeTimeout({ timeout, data: { conversationId, postponeUntil } }))
  }

const isPostponed = (postponedUntil?: string | null) => !!postponedUntil && dayjs(postponedUntil).diff(dayjs()) > 0

const checkQueueCount =
  (data: AssignmentChangedMessage["data"], userClinicId: string | null = null): Thunk =>
  dispatch => {
    const {
      assignedStaffId,
      lastAssignedStaffId,
      postponedUntil,
      queue,
      lastPostponedUntil,
      lastQueue,
      clinicId = null
    } = data

    const assignedToStaffFromQueue =
      assignedStaffId && !lastAssignedStaffId && lastQueue && !isPostponed(lastPostponedUntil)

    const assignedToStaffFromPostponedInQueue =
      assignedStaffId && !lastAssignedStaffId && lastQueue && isPostponed(lastPostponedUntil)

    const assignedToQueueFromStaff = !assignedStaffId && lastAssignedStaffId && !isPostponed(postponedUntil) && queue

    const postponedToQueueFromStaff = !assignedStaffId && lastAssignedStaffId && isPostponed(postponedUntil) && queue

    const postponedWhenInQueue =
      !assignedStaffId &&
      !lastAssignedStaffId &&
      isPostponed(postponedUntil) &&
      !isPostponed(lastPostponedUntil) &&
      queue

    const unPostponedWhenInQueue =
      !assignedStaffId &&
      !lastAssignedStaffId &&
      !isPostponed(postponedUntil) &&
      isPostponed(lastPostponedUntil) &&
      queue

    if (assignedToStaffFromQueue) {
      if (userClinicId === clinicId) {
        dispatch(updateQueueCount({ key: lastQueue, conversationCount: -1 }))
      }
    } else if (assignedToStaffFromPostponedInQueue) {
      dispatch(updateQueueCount({ key: lastQueue, postponedConversationCount: -1 }))
    } else if (assignedToQueueFromStaff) {
      if (userClinicId === clinicId) {
        dispatch(updateQueueCount({ key: queue, conversationCount: 1 }))
      }
    } else if (postponedToQueueFromStaff) {
      dispatch(updateQueueCount({ key: queue, postponedConversationCount: 1 }))
    } else if (postponedWhenInQueue) {
      dispatch(updateQueueCount({ key: queue, conversationCount: -1 }))
      dispatch(updateQueueCount({ key: queue, postponedConversationCount: 1 }))
    } else if (unPostponedWhenInQueue) {
      dispatch(updateQueueCount({ key: queue, conversationCount: 1 }))
      dispatch(updateQueueCount({ key: queue, postponedConversationCount: -1 }))
    }
  }

export const handleAssignment =
  (message: AssignmentChangedMessage): Thunk =>
  (dispatch, getState) => {
    const { user, defaultRole } = getState().auth
    const { conversationId, id } = message
    const { assignedStaffId, queue, clinicId = null } = message.data
    const userClinicId = user?.data?.clinic || null
    if (id && !messageIds.includes(id)) {
      if (user?.id && assignedStaffId === user.id) {
        // Assigned to logged in user
        dispatch(fetchAssignedConversation(conversationId!))
        dispatch(removeFromQueue(conversationId!))
        dispatch(removeFromQueueOutline(conversationId!))
      } else if (!assignedStaffId && !blockFetchQueueConversations(defaultRole) && queue) {
        // New conversation in queue and user is allowed to fetch conversations in queue
        if (userClinicId === clinicId) {
          dispatch(fetchAndLoadQueueConversation(conversationId!))
        }
        dispatch(removeConversation(conversationId!))
      } else {
        dispatch(removeConversation(conversationId!))
        if (userClinicId === clinicId) {
          dispatch(removeFromQueue(conversationId!))
          dispatch(removeFromQueueOutline(conversationId!))
        }
      }
      dispatch(assignSearchedConversation(message))
      dispatch(checkQueueCount(message.data, userClinicId))
    }
    if (id) messageIds.push(id)
  }
