import {
  AnyMessage,
  AssignedConversation,
  CallStateChangedMessage,
  CategoryMessage
} from "@doktor-se/bones-ui/dist/web-shared/types"
import { setLastMessage } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { filterMessages } from "./filter"

export const transformMessage = (conversation: AssignedConversation, message: AnyMessage): AssignedConversation => {
  const messagesToDisplay = filterMessages(
    [...(conversation.messages || []).filter(m => m.id !== message.id), message],
    conversation.patientId
  )
  return {
    ...conversation,
    messagesToDisplay,
    messages: [...(conversation.messages || []), message],
    lastMessage: setLastMessage(messagesToDisplay, conversation.patientId),
    categoryId: message.type === "category" ? (message as CategoryMessage).data.categoryId : conversation.categoryId
  }
}

export const setCall = (conversation: AssignedConversation, payload: CallStateChangedMessage): AssignedConversation => {
  const message = { ...payload }
  const messages = [...(conversation.messages || []), message]
  const messagesToDisplay = filterMessages(messages, conversation.patientId)
  return {
    ...conversation,
    messages,
    messagesToDisplay,
    lastMessage: setLastMessage(messagesToDisplay, conversation.patientId),
    callState: message.data.callState
  }
}
