import { useIntl } from "react-intl"

import classNames from "classnames"

import { useAppSelector } from "lib/hooks"

import styles from "./VideoDisplay.module.scss"

const VideoDisplay = ({ showVideo }: { showVideo: boolean }) => {
  const isFullScreen = useAppSelector(state => state.tokbox.expanded)
  const intl = useIntl()

  return (
    <div className={classNames(styles.container, { [styles.show]: showVideo, [styles.fullScreen]: isFullScreen })}>
      <div
        id="subscriber"
        aria-label={intl.formatMessage({ id: "calls.video.patient" })}
        className={styles.videoContainer}
      />
    </div>
  )
}

export default VideoDisplay
