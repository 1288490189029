import { useIntl } from "react-intl"

import { Button, Dialog, StaffBodyTextL, StaffBodyTextM } from "@doktor-se/bones-ui"

import { IconLogOut } from "icons"

import styles from "./LogoutDialog.module.scss"

interface ConfirmLogoutProps {
  isOpen: boolean
  logout: () => void
  onClose: () => void
  assigned: number
  snoozed: number
}

const LogoutDialog = ({ isOpen, logout, onClose, assigned, snoozed }: ConfirmLogoutProps) => {
  const intl = useIntl()

  return (
    <Dialog isOpen={isOpen} onClose={onClose} closeAriaLabel={intl.formatMessage({ id: "modal.close" })} fixedWidth>
      <IconLogOut />

      <StaffBodyTextL margin="var(--size-200) 0">{intl.formatMessage({ id: "warning.logout.confirm" })}</StaffBodyTextL>

      {assigned > 0 || snoozed > 0 ? (
        <div className={styles.warning}>
          <StaffBodyTextM>{intl.formatMessage({ id: "warning.logout.conversations" })}</StaffBodyTextM>
          <div className={styles.boxes}>
            <StaffBodyTextM>
              {`${intl.formatMessage({ id: "label.inbox" })}
            : ${assigned}`}
            </StaffBodyTextM>
            <StaffBodyTextM>
              {`${intl.formatMessage({ id: "label.snoozed" })}
              : ${snoozed}`}
            </StaffBodyTextM>
          </div>
        </div>
      ) : undefined}

      <Button variant="primary" color="accent" onPress={logout}>
        {intl.formatMessage({ id: "btn.logout" })}
      </Button>
    </Dialog>
  )
}

export default LogoutDialog
