import { useState } from "react"
import { useIntl } from "react-intl"

import { Button, Dialog, StaffHeading3, TextArea, TextInput } from "@doktor-se/bones-ui"

interface FlagProps {
  isOpen: boolean
  onClose: () => void
  flag: (flagBody: string, flagSubject: string) => void
  offset: {
    left: number
    top: number
  }
}

const ReportBugDialog = ({ isOpen, onClose, flag, offset }: FlagProps) => {
  const intl = useIntl()
  const [flagBody, setFlagBody] = useState("")
  const [flagSubject, setFlagSubject] = useState("")

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      offset={{ top: `${offset.top + 30}px`, left: `${offset.left - 400}px` }}
      closeAriaLabel={intl.formatMessage({ id: "modal.close" })}
      fixedWidth>
      <StaffHeading3>{intl.formatMessage({ id: "flag.report.title" })}</StaffHeading3>

      <TextInput
        variant="onSurface"
        label={intl.formatMessage({ id: "flag.subject.label" })}
        value={flagSubject}
        caption={intl.formatMessage({ id: "flag.subject.placeholder" })}
        onChange={evt => setFlagSubject(evt)}
        fullWidth={true}
      />

      <TextArea
        variant="onSurface"
        label={intl.formatMessage({ id: "flag.body.label" })}
        value={flagBody}
        caption={intl.formatMessage({ id: "flag.body.placeholder" })}
        onChange={evt => setFlagBody(evt)}
        fullWidth={true}
      />

      <Button
        color="primary"
        variant="primary"
        onPress={() => {
          flag(flagBody.trim(), flagSubject.trim())
          setFlagBody("")
          setFlagSubject("")
        }}
        isDisabled={!flagSubject || !flagBody}>
        {intl.formatMessage({ id: "flag.send.report" })}
      </Button>
    </Dialog>
  )
}

export default ReportBugDialog
