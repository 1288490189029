import { AnyMessage } from "@doktor-se/bones-ui/dist/web-shared/types"

import { Thunk } from "lib/hooks"
import { setInboxNotification } from "reducers/app"

export const handleNavbarNotifications =
  (data: AnyMessage): Thunk =>
  (dispatch, getState) => {
    const { hash, pathname } = window.location
    if (!hash && (pathname !== "/" || !getState().app.isInboxPanelOpen)) {
      dispatch(setInboxNotification(!!data.conversationId))
    }
  }

export default handleNavbarNotifications
