import { useIntl } from "react-intl"

import { Button, StaffHeading3 } from "@doktor-se/bones-ui"

import styles from "./ConfirmToQueue.module.scss"

interface ConfirmToQueueProps {
  message: string
  onCancel: () => void
  onConfirm: () => void
}

const ConfirmToQueue = ({ message, onCancel, onConfirm }: ConfirmToQueueProps) => {
  const intl = useIntl()

  return (
    <>
      <StaffHeading3 data-testid="confirm-to-queue" margin={{ bottom: "var(--size-200)" }} className={styles.text}>
        {message}
      </StaffHeading3>
      <div className={styles.buttonContainer}>
        <Button color="primary" variant="secondary" onPress={onCancel} fullWidth>
          {intl.formatMessage({ id: "btn.cancel" })}
        </Button>
        <Button color="primary" variant="primary" onPress={onConfirm} fullWidth>
          {intl.formatMessage({ id: "label.send" })}
        </Button>
      </div>
    </>
  )
}

export default ConfirmToQueue
