import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { PayconiqResponse } from "api"

export type PaymentState = Record<string, { response?: PayconiqResponse; showPaymentLink: boolean }>
const initialState: PaymentState = {}

const payment = createSlice({
  name: "payment",
  initialState,
  reducers: {
    loadPaymentResponse(state, action: PayloadAction<{ conversationId: string; response: PayconiqResponse }>) {
      const conversationId = action.payload.conversationId

      state[conversationId] = {
        ...state[conversationId],
        response: action.payload.response
      }
    },
    setShowPaymentLink(state, action: PayloadAction<{ conversationId: string; show: boolean }>) {
      const conversationId = action.payload.conversationId

      state[conversationId] = {
        ...state[conversationId],
        showPaymentLink: action.payload.show
      }
    }
  }
})

export const { loadPaymentResponse, setShowPaymentLink } = payment.actions

export type PaymentSliceAction = ObjectFunctionReturnTypes<typeof payment.actions>

export default payment.reducer
