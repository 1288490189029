import { createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit"

import {
  Profile,
  ProfileCreatedEvent,
  ProfileRemovedEvent,
  ProfileUpdatedEvent,
  Staff,
  StaffAdded,
  StaffRemoved,
  UserStatusChangeEvent
} from "@doktor-se/bones-ui/dist/web-shared/types"

import { RootState } from "reducers/init"
import { SearchAccount } from "types"

import { clearStatus, filterStaff, loadPatients as loadPatientsHelper, removeStaff } from "./filter"

interface PatientProfiles {
  patientId: string
  profiles: Profile[]
}

export interface UsersState {
  staff: Staff[]
  patients: SearchAccount[]
  activeStaff: Staff[]
  onlineStaff?: { [staffId: string]: boolean | undefined }
  patientProfiles: EntityState<PatientProfiles, string>
}

const patientProfilesAdapter = createEntityAdapter<PatientProfiles, string>({
  selectId: profiles => profiles.patientId
})

const initialState: UsersState = {
  staff: [],
  patients: [],
  activeStaff: [],
  onlineStaff: {},
  patientProfiles: patientProfilesAdapter.getInitialState()
}

const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    loadStaff(state, action: PayloadAction<Staff[]>) {
      return filterStaff(state, action.payload, state.onlineStaff)
    },
    loadPatients(state, action: PayloadAction<SearchAccount[]>) {
      return loadPatientsHelper(state, action.payload)
    },
    clearPatients(state) {
      state.patients = []
    },
    userStatus(state, action: PayloadAction<UserStatusChangeEvent>) {
      const online:
        | {
            [staffId: string]: boolean | undefined
          }
        | undefined = { ...state.onlineStaff }
      action.payload.connected.forEach(c => {
        online[c] = true
      })
      action.payload.disconnected.forEach(c => {
        online[c] = false
      })
      return filterStaff(state, state.staff, online)
    },
    clearUserStatus(state) {
      return filterStaff(state, clearStatus(state.staff))
    },
    staffAdded(state, action: PayloadAction<StaffAdded>) {
      return filterStaff(state, [...state.staff, action.payload.staff])
    },
    staffRemoved(state, action: PayloadAction<StaffRemoved>) {
      return filterStaff(state, removeStaff(state.staff, action.payload.staffId))
    },
    loadPatientProfiles(state, action: PayloadAction<PatientProfiles>) {
      patientProfilesAdapter.setOne(state.patientProfiles, action.payload)
    },
    profileCreated(state, action: PayloadAction<ProfileCreatedEvent>) {
      let profiles =
        simplePatientProfilesSelectors.selectById(state.patientProfiles, action.payload.patientId)?.profiles || []
      profiles = [...profiles, action.payload.profile]

      patientProfilesAdapter.updateOne(state.patientProfiles, {
        id: action.payload.patientId,
        changes: { profiles }
      })
    },
    profileRemoved(state, action: PayloadAction<ProfileRemovedEvent>) {
      const profiles = (
        simplePatientProfilesSelectors.selectById(state.patientProfiles, action.payload.patientId)?.profiles || []
      ).filter(profile => profile.id !== action.payload.profileId)

      patientProfilesAdapter.updateOne(state.patientProfiles, {
        id: action.payload.patientId,
        changes: { profiles }
      })
    },
    profileUpdated(state, action: PayloadAction<ProfileUpdatedEvent>) {
      const profiles = (
        simplePatientProfilesSelectors.selectById(state.patientProfiles, action.payload.patientId)?.profiles || []
      ).filter(profile => profile.id !== action.payload.profile.id)
      profiles.push(action.payload.profile)

      patientProfilesAdapter.updateOne(state.patientProfiles, {
        id: action.payload.patientId,
        changes: { profiles }
      })
    }
  }
})

const simplePatientProfilesSelectors = patientProfilesAdapter.getSelectors()

export const patientProfilesSelectors = patientProfilesAdapter.getSelectors<RootState>(
  state => state.users.patientProfiles
)

export const {
  loadStaff,
  loadPatients,
  clearPatients,
  userStatus,
  clearUserStatus,
  staffAdded,
  staffRemoved,
  loadPatientProfiles,
  profileCreated,
  profileRemoved,
  profileUpdated
} = users.actions

export type UsersSliceAction = ObjectFunctionReturnTypes<typeof users.actions>

export default users.reducer
