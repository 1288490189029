import { useIntl } from "react-intl"

import classNames from "classnames"

import { ICONS } from "@doktor-se/bones-ui"

import { toggleLocalAudio, toggleLocalExpanded, toggleLocalVideo } from "api"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { togglePatientVideo } from "reducers/tokbox"

import CallControlButton from "./components/CallControlButton/CallControlButton"
import StaffVideoContainer from "./components/StaffVideoContainer/StaffVideoContainer"

import styles from "./CallControls.module.scss"

export interface CallControlsProps {
  endCall: () => void
}

const CallControls = ({ endCall }: CallControlsProps) => {
  const dispatch = useAppDispatch()

  const isFullscreen = useAppSelector(state => state.tokbox.expanded)
  const isStaffVideoOn = useAppSelector(state => state.tokbox.hasVideo.publisher)
  const isStaffAudioOn = useAppSelector(state => state.tokbox.pubOptions.publishAudio)
  const hidePatientVideo = useAppSelector(state => state.tokbox.hidePatientVideo)

  const intl = useIntl()

  return (
    <div className={classNames(styles.container)}>
      <StaffVideoContainer />

      <div className={classNames(styles.controlsContainer, { [styles.fullScreen]: isFullscreen })}>
        <CallControlButton
          Icon={hidePatientVideo ? ICONS.VisibilityOff : ICONS.Visibility}
          onClick={() => dispatch(togglePatientVideo())}
          variant={hidePatientVideo ? "default" : "selected"}
          label={intl.formatMessage({ id: "app.call.patient.video.hide" })}
          hasVisibleLabel
        />

        <div className={styles.separator} />

        <CallControlButton
          Icon={ICONS.Maximize}
          onClick={() => dispatch(toggleLocalExpanded())}
          variant={isFullscreen ? "selected" : "default"}
          label={intl.formatMessage({ id: "calls.fullscreen" })}
        />
        <CallControlButton
          Icon={isStaffAudioOn ? ICONS.Mic : ICONS.MicOff}
          onClick={() => dispatch(toggleLocalAudio())}
          variant={isStaffAudioOn ? "selected" : "default"}
          label={intl.formatMessage({ id: "calls.mute" })}
        />
        <CallControlButton
          Icon={isStaffVideoOn ? ICONS.VideoCam : ICONS.VideoCamOff}
          onClick={() => dispatch(toggleLocalVideo())}
          variant={isStaffVideoOn ? "selected" : "default"}
          label={intl.formatMessage({ id: "calls.video" })}
        />
        <CallControlButton
          Icon={ICONS.CallEnd}
          onClick={endCall}
          variant="warning"
          label={intl.formatMessage({ id: "calls.hangup" })}
        />
      </div>
    </div>
  )
}

export default CallControls
