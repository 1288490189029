import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import { SearchPageConversation } from "types"

export const checkDeclineCall = (conversation: SearchPageConversation | AssignedConversation) => {
  switch (conversation.callState) {
    case "incoming":
      return "declined"
    case "outgoing":
      return "no_answer"
    case "active":
      return "hangup"
    default:
      return
  }
}
