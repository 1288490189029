import React from "react"

const IconToQueue: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path
          d="M10 0C7.96 0 6.01.613 4.365 1.738l-.305.217 1.175 1.588A7.983 7.983 0 0 1 10 1.975a8.025 8.025 0 1 1-8.02 8.275L1.974 10H0c0 5.523 4.477 10 10 10s10-4.477 10-10S15.523 0 10 0z"
          fillRule="nonzero"
        />
        <path d="m2.548.254 4.623 4.974L.087 7.802z" />
      </g>
    </svg>
  )
}

export default IconToQueue
