import { useIntl } from "react-intl"

import { StaffBodyTextM } from "@doktor-se/bones-ui"
import { Loader } from "@doktor-se/bones-ui/dist/web-shared/components"
import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import { createLoadingSelector } from "api"
import { useAppSelector } from "lib/hooks"
import { categorySelectors } from "reducers/categories"

import ConversationBox from "../ConversationBox/ConversationBox"

import styles from "./ConversationList.module.scss"

export interface ConversationListProps {
  selectedConversationId?: string
  setConversation: (c: AssignedConversation) => void
  assignedConversations: AssignedConversation[]
  assignToUserBlocked: boolean
  children?: JSX.Element
}

const loadingSelector = createLoadingSelector(["ASSIGNED_CONVERSATIONS", "PERSONAL_QUEUE", "QUEUE_COUNT"])

const ConversationList = ({
  selectedConversationId,
  assignedConversations,
  setConversation,
  children
}: ConversationListProps) => {
  const intl = useIntl()

  const loader = useAppSelector(state => loadingSelector(state))
  const categories = useAppSelector(categorySelectors.selectEntities)
  const activeTab = useAppSelector(state => state.app.activeInboxTab)

  return (
    <div className={styles.contentWrapper}>
      {loader ? (
        <Loader type="small" dotColor="white" />
      ) : (
        <>
          {assignedConversations.length === 0 && (
            <div className={styles.emptyList}>
              <StaffBodyTextM>
                {intl.formatMessage({
                  id: activeTab === "inbox" ? "conversations.empty.inbox" : "conversations.empty.waiting"
                })}
              </StaffBodyTextM>
            </div>
          )}

          {assignedConversations &&
            assignedConversations.map(conversation => (
              <ConversationBox
                key={conversation.id}
                conversation={conversation}
                isSelected={selectedConversationId === conversation.id}
                setConversation={setConversation}
                categories={categories}
                notify={
                  (conversation.lastMessage?.id &&
                    conversation.lastMessage.id > (conversation.lastSeenMessageId ?? 0)) ||
                  conversation.callState === "incoming"
                }
              />
            ))}

          {children}
        </>
      )}
    </div>
  )
}

export default ConversationList
