import React from "react"

import classNames from "classnames"

import styles from "./TabsHeader.module.scss"

interface TabsHeaderProps {
  children: JSX.Element[]
}

const TabsHeader = ({ children }: TabsHeaderProps) => {
  return (
    <div className={classNames(styles.header)}>
      {children.map((child, i) => React.cloneElement(child, { tabsCount: children.length, key: i }))}
    </div>
  )
}
export default TabsHeader
