import { useIntl } from "react-intl"

import { StaffHeading4 } from "@doktor-se/bones-ui"

import styles from "./SectionHeading.module.scss"

interface SubSectionHeadingProps {
  icon: JSX.Element
  translationId: string
}

const SectionHeading = ({ icon, translationId }: SubSectionHeadingProps): JSX.Element => {
  const intl = useIntl()

  return (
    <div className={styles.container}>
      <div>{icon}</div>
      <StaffHeading4 as="h2" margin={{ left: "10px" }}>
        {intl.formatMessage({ id: translationId })}
      </StaffHeading4>
    </div>
  )
}

export default SectionHeading
