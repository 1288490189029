import { useIntl } from "react-intl"

import { Button, Dialog, StaffHeading3 } from "@doktor-se/bones-ui"

import styles from "./ConfirmDialog.module.scss"

interface ConfirmDialogProps {
  isOpen: boolean
  offset?: { top?: string; right?: string; bottom?: string; left?: string }
  message: string
  onCancel?: () => void
  onConfirm: () => void
  confirmText: string
}

const ConfirmDialog = ({ isOpen, offset, message, onCancel, onConfirm, confirmText }: ConfirmDialogProps) => {
  const intl = useIntl()

  return (
    <Dialog
      isOpen={isOpen}
      offset={offset}
      onClose={onCancel || onConfirm}
      closeAriaLabel={intl.formatMessage({ id: "modal.close" })}
      fixedWidth>
      <StaffHeading3 margin={{ bottom: "var(--size-200)" }}>{message}</StaffHeading3>
      <div className={styles.buttonContainer}>
        {!!onCancel && (
          <Button color="primary" variant="secondary" onPress={onCancel} fullWidth>
            {intl.formatMessage({ id: "btn.cancel" })}
          </Button>
        )}
        <Button color="primary" variant="primary" onPress={onConfirm} fullWidth>
          {confirmText}
        </Button>
      </div>
    </Dialog>
  )
}

export default ConfirmDialog
