import { useMemo } from "react"
import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import dayjs from "dayjs"

import { ICONS } from "@doktor-se/bones-ui"

import { useAppDispatch } from "lib/hooks"
import { Booking, setCalendarRange, setSelectedEvent } from "reducers/booking"

import styles from "./ConversationBookingDetails.module.scss"

export interface ConversationBookingDetailsProps {
  booking: Booking
  onlyTime?: boolean
}

export const ConversationBookingDetails = ({ booking, onlyTime }: ConversationBookingDetailsProps) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const bookingData = useMemo(() => {
    const day = intl.formatDate(booking.start, { day: "numeric", month: "short" })
    const startTime = intl.formatTime(dayjs(booking.start).toDate(), {
      hour: "numeric",
      minute: "numeric"
    })
    const endTime = intl.formatTime(dayjs(booking.end).toDate(), {
      hour: "numeric",
      minute: "numeric"
    })

    return {
      day,
      startTime,
      endTime
    }
  }, [booking, intl])

  const navigateToBooking = () => {
    navigate("/booking")
    dispatch(setCalendarRange({ from: booking.start, to: booking.end }))
    dispatch(setSelectedEvent({ id: booking.id, type: "booking" }))
  }

  return (
    <div className={styles.timeInfo}>
      <div className={styles.bookingTime}>
        {onlyTime ? "" : bookingData.day} {bookingData.startTime} - {bookingData.endTime}
      </div>
      <div className={styles.iconWrapper}>
        <ICONS.CalendarToday onClick={navigateToBooking} className={styles.icon} />
      </div>
    </div>
  )
}
