import React from "react"

const IconReassign = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="25" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor" fillRule="evenodd">
        <path
          d="M10 0c2.04 0 3.99.613 5.635 1.738l.305.217-1.175 1.588A7.983 7.983 0 0 0 10 1.975a8.025 8.025 0 1 0 8.02 8.275l.005-.25H20c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0z"
          fillRule="nonzero"
        />
        <path d="m17.452.254-4.623 4.974 7.084 2.574z" />
      </g>
    </svg>
  )
}

export default IconReassign
