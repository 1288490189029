import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useLocation, useNavigate } from "react-router-dom"
import { useParams } from "react-router-dom"

import { Alert, CreatePassword } from "@doktor-se/bones-ui/dist/web-shared/components"
import { LanguageCode } from "@doktor-se/bones-ui/dist/web-shared/types"

import { createStaffPassword, logout } from "api"
import { platform } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { changeLanguage } from "reducers/app"

interface ErrorMessage {
  title: string
  message: string
}

const CreatePasswordPage = () => {
  const [logo, setLogo] = useState("")
  const [phone, setPhone] = useState("")
  const [errorMessage, setErrorMessage] = useState<ErrorMessage | null>()
  const [code, setCode] = useState<string | null>(null)
  const intl = useIntl()
  const authorized = useAppSelector(state => state.auth.authorized)

  const { language } = useParams<{ language?: LanguageCode; country?: string }>()

  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    setCode(new URLSearchParams(location.search).get("code"))
  }, [location])

  useEffect(() => {
    const getImage = async (image: string) => {
      try {
        return await import(`../../img/platform/${platform}/${image}.png`)
      } catch {
        return undefined
      }
    }

    const fetchImages = async () => {
      const logo = await getImage("logo")
      const phone = await getImage("login_phone")

      setLogo(logo && logo.default)
      setPhone(phone && phone.default)
    }

    fetchImages()
  }, [])

  useEffect(() => {
    if (language) {
      dispatch(changeLanguage(language))
    }
  }, [language, dispatch])

  const createPassword = (data: { password: string; confirmPassword: string }) => {
    if (data?.password && code) {
      dispatch(createStaffPassword(data.password, code))
        .then(() => {
          if (authorized) {
            dispatch(logout()) // Log out user in the edge case where user is logged in when creating a new password
          }
        })
        .then(() => {
          navigate("/login?create_password=success")
        })
        .catch(err => {
          switch (err.details?.code) {
            case "error.jwt.invalid_token":
              setErrorMessage({
                title: intl.formatMessage({ id: "message.expired_token.title" }),
                message: intl.formatMessage({ id: "message.expired_token" })
              })
              break
            default:
              setErrorMessage({
                title: intl.formatMessage({ id: "message.create_password.fail.title" }),
                message: intl.formatMessage({ id: "message.create_password.fail" })
              })
          }
        })
    }
  }

  return (
    <>
      {errorMessage && (
        <Alert
          close={() => setErrorMessage(null)}
          text={errorMessage.title}
          bodyText={errorMessage.message}
          buttonText={intl.formatMessage({ id: "btn.ok" })}
          color="accent"
        />
      )}

      <CreatePassword
        logo={logo}
        phone={phone}
        createPassword={createPassword}
        passwordRequirementsList={intl.formatMessage({ id: "label.create_password.requirements" })}
        passwordCharsRegex={/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$%&'()*+, -./:;<=>?@[\]^_`{|}~])/}
        texts={{
          title: intl.formatMessage({ id: "label.create_password.title" }),
          buttonTextUppercase: true,
          placeholderNewPassword: intl.formatMessage({ id: "label.create_password.placeholder.new_password" }),
          placeholderConfirmPassword: intl.formatMessage({
            id: "label.create_password.placeholder.confirm_new_password"
          }),
          confirmButton: intl.formatMessage({ id: "btn.submit" }),
          loading: intl.formatMessage({ id: "label.create_password.loading" }),
          formTitle: intl.formatMessage({ id: "label.create_password.title" }),
          passwordLengthErrorMsg: intl.formatMessage({ id: "label.create_password.error.length" }),
          passwordCharsErrorMsg: intl.formatMessage({ id: "label.create_password.error.characters" }),
          passwordMatchErrorMsg: intl.formatMessage({ id: "label.create_password.error.match" }),
          passwordRequiredErrorMsg: intl.formatMessage({ id: "label.create_password.error.required" })
        }}
      />
    </>
  )
}

export default CreatePasswordPage
