import { useIntl } from "react-intl"

import { ICONS, StaffBodyTextL } from "@doktor-se/bones-ui"
import { AppError } from "@doktor-se/bones-ui/dist/web-shared/classes"

import { SearchAccount } from "types"

import styles from "./EmptyResult.module.scss"

interface EmptyResultProps {
  error: AppError
  searchQuery?: string
  staffId?: string
  patients: SearchAccount[]
}

const EmptyResult = ({ error, searchQuery = "", staffId, patients }: EmptyResultProps) => {
  const intl = useIntl()

  return (
    <div className={styles.noResult}>
      {error.api === "conversationsSearch" && error.type === "error.user.notfound" ? (
        <div className={styles.noUser}>
          <ICONS.MessageAlert className={styles.warningIcon} />
          <StaffBodyTextL className={styles.message}>
            {error.message && error.message.replace(". ", ".\n")}
          </StaffBodyTextL>
        </div>
      ) : (
        <>
          {searchQuery && !staffId && patients.length ? (
            <div className={styles.noConversation}>
              <ICONS.MessageAlert className={styles.warningIcon} />
              <StaffBodyTextL>
                {intl.formatMessage({ id: "search.result.patient.none" }, { user: patients[0].displayName })}
              </StaffBodyTextL>
            </div>
          ) : (
            <StaffBodyTextL>{intl.formatMessage({ id: "search.result.none" })}</StaffBodyTextL>
          )}
        </>
      )}
    </div>
  )
}
export default EmptyResult
