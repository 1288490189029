import dayjs from "dayjs"

import { WSConnectError } from "@doktor-se/bones-ui/dist/web-shared/classes"
import { AnyRequest, AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"
import { AnyEvent } from "@doktor-se/bones-ui/dist/web-shared/types"

import { handleErrors } from "api/error/handler"
import { appWsUrl } from "config"
import { Thunk } from "lib/hooks"
import SocketClient from "lib/websockets/socket"

import handleEvents from "./handler"
import { snoozeTimer } from "./handlers/handleSnooze"

export const wsClient = new SocketClient()

export const websocketHandler =
  (data: AnyEvent): Thunk =>
  dispatch => {
    dispatch(handleEvents(data))
  }

export const subscribeToConversation = (conversationId: string) => {
  wsClient.subscribe(conversationId)
}

export const unsubscribeToConversation = (conversationId: string) => {
  wsClient.unsubscribe(conversationId)
}

export const subscribeToPatient = (patientId: string) => {
  wsClient.patientSubscribe(patientId)
}

export const subscribeToAllConversations =
  (conversations: AssignedConversation[]): Thunk =>
  dispatch => {
    conversations.forEach(conversation => {
      if (conversation.snoozedUntil && dayjs(conversation.snoozedUntil).format() > dayjs().format()) {
        dispatch(snoozeTimer(conversation.id, conversation.snoozedUntil))
      }
      subscribeToConversation(conversation.id)
    })
    const patients: Set<string> = new Set(conversations.map(conversation => conversation.patientId))
    patients.forEach(p => subscribeToPatient(p))
  }

export const wsSend = (data: AnyRequest) => wsClient.send(data)

export const connectWs =
  (conversations: AssignedConversation[], token: string): Thunk<Promise<void>> =>
  async dispatch => {
    return wsClient
      .connect((data: AnyEvent) => dispatch(websocketHandler(data)), token, appWsUrl)
      .then(() => dispatch(subscribeToAllConversations(conversations)))
      .catch(error => {
        dispatch(handleErrors({ error: new WSConnectError(), customError: true }))
        throw error
      })
  }

export const retriesWS = () => wsClient.retry

export const disconnectWs = () => wsClient.disconnect()
