import { useEffect, useMemo, useRef, useState } from "react"
import { useIntl } from "react-intl"

import classNames from "classnames"

import { Dropdown } from "@doktor-se/bones-ui"
import { Loader } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

import { useAppDispatch, useAppSelector } from "lib/hooks"
import { setQueuePanelState } from "reducers/app"
import { queueCountSelectors, queueSelectors } from "reducers/queue/queue.reducer"

import QueueConversationsList from "./components/QueueConversationsList/QueueConversationsList"
import VerticalTab from "components/VerticalTab/VerticalTab"

import styles from "./QueuesContainer.module.scss"

interface QueuesContainerProps {
  assignToUserBlocked: boolean
}

const QueuesContainer = ({ assignToUserBlocked }: QueuesContainerProps) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const defaultRole = useAppSelector(state => state.auth.defaultRole)
  const queues = useAppSelector(queueSelectors.selectAll)
  const queueCount = useAppSelector(queueCountSelectors.selectEntities)
  const isQueuePanelOpen = useAppSelector(state => state.app.isQueuePanelOpen)
  const appLanguage = useAppSelector(state => state.app.language)
  const [selectedQueueValue, setSelectedQueueValue] = useState<string>()
  const [isQueuePanelFullyOpen, setIsQueuePanelFullyOpen] = useState<boolean>(isQueuePanelOpen ? true : false)
  const openTimeout = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    setSelectedQueueValue(queues.find(q => q.queueKey === defaultRole)?.queueKey || queues[0]?.queueKey)
  }, [defaultRole, dispatch, queues])

  useEffect(() => {
    return () => {
      if (openTimeout.current) clearTimeout(openTimeout.current)
    }
  }, [])

  const selectedQueue = useMemo(() => {
    return queues.find(queue => queue.queueKey === selectedQueueValue)
  }, [queues, selectedQueueValue])

  const queueLabel = (queue: Queue) =>
    `${queue.translations[appLanguage]} (${queueCount[queue.queueKey]?.conversationCount})`

  const onClick = () => {
    if (!isQueuePanelOpen) {
      openTimeout.current = setTimeout(() => {
        setIsQueuePanelFullyOpen(true)
      }, 500)
    } else {
      setIsQueuePanelFullyOpen(false)
    }

    dispatch(setQueuePanelState(!isQueuePanelOpen))
  }

  return (
    <div className={classNames(styles.container, { [styles.slideIn]: !isQueuePanelOpen })}>
      <VerticalTab text={selectedQueue ? queueLabel(selectedQueue) : ""} onClick={onClick} position="bottom" />

      <div className={styles.panelContainer}>
        {!isQueuePanelFullyOpen && isQueuePanelOpen && <Loader type="small" />}
        {isQueuePanelFullyOpen && selectedQueue && (
          <>
            <div className={styles.selectContainer}>
              <Dropdown
                variant="onSurface"
                label={intl.formatMessage({ id: "dropdown.select.queue.aria.label" })}
                items={queues.map(item => ({
                  id: item.queueKey,
                  name: queueLabel(item)
                }))}
                selectedKey={selectedQueueValue}
                onSelectionChange={queue => setSelectedQueueValue(queue as string)}
                fullWidth
              />
            </div>
            <QueueConversationsList assignToUserBlocked={assignToUserBlocked} selectedQueue={selectedQueue} />
          </>
        )}
      </div>
    </div>
  )
}

export default QueuesContainer
