import { useMsal } from "@azure/msal-react"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { InteractionStatus } from "@azure/msal-browser"
import { v4 as uuidv4 } from "uuid"

import { ApiErrorV2 } from "@doktor-se/bones-ui/dist/web-shared/classes"
import { Loader } from "@doktor-se/bones-ui/dist/web-shared/components"
import { getAppId, loginRequest } from "@doktor-se/bones-ui/dist/web-shared/config"

import { authorizeWithMS, getAccount, getWebdocTokens } from "api"
import { handleTokenExpiry } from "api/auth/handler"
import { handleErrorsV2 } from "api/error/handler"
import { featureFlags, webdocAuthUrl, webdocFeatureEnabled } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"

import LoggedInLayout from "./LoggedInLayout/LoggedInLayout"

interface AuthorizedRouteProps {
  children: JSX.Element
}

const AuthorizedRoute = ({ children }: AuthorizedRouteProps) => {
  const dispatch = useAppDispatch()
  const authorized = useAppSelector(state => state.auth.authorized)
  const token = useAppSelector(state => state.auth.accessToken)
  const refreshToken = useAppSelector(state => state.auth.refreshToken)
  const webdocAuthState = useAppSelector(state => state.auth.webdocAuthState)
  const defaultRole = useAppSelector(state => state.auth.defaultRole)
  const { instance, inProgress } = useMsal()
  const navigate = useNavigate()

  const [serverTokenStatus, setServerTokenStatus] = useState<"unverified" | "verifying" | "verified">("unverified")

  useEffect(() => {
    if (!authorized && !token) {
      if (featureFlags.has("sso") && inProgress === InteractionStatus.None) {
        const account = instance.getActiveAccount()
        if (account) {
          instance
            .acquireTokenSilent({ ...loginRequest, account })
            .then(res => {
              if (res) {
                dispatch(authorizeWithMS(res?.idToken, getAppId(instance)))
              }
            })
            .catch(() => navigate("/login"))
        } else {
          navigate("/login")
        }
      } else {
        navigate("/login")
      }
    } else if (authorized && token) {
      if (serverTokenStatus === "unverified") {
        dispatch(handleTokenExpiry(refreshToken ? refreshToken : token))
        setServerTokenStatus("verifying")
        dispatch(getAccount()).then(result => {
          if (result) setServerTokenStatus("verified")
        })
      }
    }
  }, [token, refreshToken, authorized, navigate, dispatch, serverTokenStatus, inProgress, instance])

  const checkWebdocAuthErrors = useCallback(
    (params: { [key: string]: string }) => {
      if (!!params.error) {
        throw new ApiErrorV2(
          500,
          [{ id: uuidv4(), code: params.error, meta: { description: params.error_description }, status: 500 }],
          `${webdocAuthUrl}/oauth/authorize`
        )
      }

      if (!!params.code && params.state !== webdocAuthState) {
        throw new ApiErrorV2(
          500,
          [
            {
              id: uuidv4(),
              code: "error.invalid_webdoc_state",
              meta: { description: "Response state does not match called state" },
              status: 500
            }
          ],
          `${webdocAuthUrl}/oauth/authorize`
        )
      }
    },
    [webdocAuthState]
  )

  useEffect(() => {
    if (!webdocFeatureEnabled(defaultRole)) return

    try {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      checkWebdocAuthErrors(params)
      if (!!params.code) {
        dispatch(getWebdocTokens(params.code, window.location.origin))
        navigate("/")
      }
    } catch (error: any) {
      dispatch(handleErrorsV2({ error }))
    }
  }, [checkWebdocAuthErrors, defaultRole, dispatch, navigate, webdocAuthState])

  return <>{serverTokenStatus === "verified" ? <LoggedInLayout>{children}</LoggedInLayout> : <Loader type="full" />}</>
}

export default AuthorizedRoute
