import React from "react"

const IconRingtone = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={31} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={15} cy={15} r={15} fill="var(--color-primary-50)" transform="translate(0 .37)" />
        <path
          fill="#FFF"
          d="M7.405 17.27H8.67v-3.798H7.405v3.798zm1.899 1.266h1.265v-6.33H9.304v6.33zm12.025-1.266h1.266v-3.798H21.33v3.798zm-1.898 1.266h1.265v-6.33h-1.265v6.33zm-1.583-8.861h-5.696a.948.948 0 00-.95.949v9.494c0 .525.424.95.95.95h5.696c.526 0 .95-.425.95-.95v-9.494a.948.948 0 00-.95-.95zm-5.38 1.265h5.064v8.861h-5.064v-8.86z"
        />
      </g>
    </svg>
  )
}

export default IconRingtone
