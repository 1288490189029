import { useMemo } from "react"
import { useIntl } from "react-intl"

import {
  AnyMessage,
  AssignedConversation,
  ChatBotUploadReplyMessage,
  ImageMessage
} from "@doktor-se/bones-ui/dist/web-shared/types"

import DownloadImagesButton from "./DownloadImagesButton"
import ImageList from "./ImageList"
import UploadImagesButton from "./UploadImagesButton"

import styles from "./ConversationImages.module.scss"

interface ConversationImagesProps {
  conversation: AssignedConversation
}

const extractKeyFromMessage = (message: AnyMessage) => {
  if (message.type === "image") {
    return (message as ImageMessage).data.key
  }
  if (message.type === "upload.reply") {
    return (message as ChatBotUploadReplyMessage).data.uploads?.map(k => k.key)
  }
}

const ConversationImages = ({ conversation }: ConversationImagesProps) => {
  const intl = useIntl()

  const imageKeys = useMemo(
    () =>
      conversation.messages
        .filter(message => message.createdById === conversation.patientId)
        .filter(message => message.type === "image" || message.type === "upload.reply")
        .sort((a, b) => a.id - b.id)
        .map(extractKeyFromMessage)
        .flat(),
    [conversation]
  )

  if (!imageKeys?.length) return null

  return (
    <>
      <div className={styles.patientImagesHeader}>{intl.formatMessage({ id: "journal.images.header" })}</div>
      <div className={styles.imagesAction}>
        <div className={styles.imagesActionText}>{intl.formatMessage({ id: "journal.images.upload.all" })}</div>
        <UploadImagesButton conversation={conversation} imagesKeys={imageKeys} />
      </div>
      <div className={styles.imagesAction}>
        <div className={styles.imagesActionText}>{intl.formatMessage({ id: "journal.images.download.all" })}</div>
        <DownloadImagesButton conversationId={conversation.id} imagesKeys={imageKeys} account={conversation.account} />
      </div>
      <div className={styles.imagesContainer}>
        <ImageList conversationId={conversation.id} imagesKeys={imageKeys} />
      </div>
    </>
  )
}

export default ConversationImages
