import React from "react"

export const IconReopenActive = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="currentColor"
          strokeWidth="2.385"
          d="M19.534 3.763a10.832 10.832 0 0 0-6.463-2.126c-6.01 0-10.88 4.871-10.88 10.88"
        />
        <path fill="currentColor" d="M22.068.75l-5.58 6.006 8.552 3.108z" />
        <g>
          <path
            stroke="currentColor"
            strokeWidth="2.385"
            d="M6.444 20.425a10.832 10.832 0 0 0 6.463 2.126c5.687 0 10.354-4.362 10.84-9.923.027-.316.041-.635.041-.958"
          />
          <path fill="currentColor" d="M3.91 23.437l5.58-6.005-8.552-3.108z" />
        </g>
      </g>
    </svg>
  )
}

export default IconReopenActive
