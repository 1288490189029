import { Device } from "@opentok/client"
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Dayjs } from "dayjs"

import { CallState } from "@doktor-se/bones-ui/dist/web-shared/types"

import { updateCallState } from "reducers/conversations/conversations.reducer"
import { RootState } from "reducers/init"

export interface TokboxState {
  conversationId: string | null
  callState: CallState
  connectTimeout?: ReturnType<typeof setTimeout>
  expanded: boolean
  connect?: {
    sessionId?: string
    token?: string
    apiKey?: string
  }
  proxyUrl?: string
  timer: {
    startTimer?: Dayjs
    callTimerInterval?: ReturnType<typeof setInterval>
    text: string
  }
  hasVideo: {
    publisher: boolean
    subscriber: boolean
  }
  inputDevices: {
    audio: Device[]
    video: Device[]
  }
  pubOptions: {
    publishVideo: boolean
    publishAudio: boolean
    audioSource?: string
    videoSource?: string
    frameRate?: number
    resolution?: string
  }
  sessionReconnecting: boolean
  hidePatientVideo: boolean
}

const initialState: TokboxState = {
  conversationId: null,
  callState: "inactive" as const,
  expanded: false,
  connect: undefined,
  timer: {
    text: "calls.connecting"
  },
  hasVideo: {
    publisher: false,
    subscriber: false
  },
  inputDevices: {
    audio: [],
    video: []
  },
  pubOptions: {
    publishVideo: false,
    publishAudio: true
  },
  sessionReconnecting: false,
  hidePatientVideo: false
}

const tokbox = createSlice({
  name: "tokbox",
  initialState,
  reducers: {
    resetCallsState(state) {
      state.expanded = false
      state.connectTimeout = undefined
      state.timer.text = "calls.connecting"
      state.hasVideo = {
        publisher: false,
        subscriber: false
      }
      state.pubOptions.publishVideo = false
      state.pubOptions.publishAudio = true
      state.sessionReconnecting = false
      state.hidePatientVideo = false
    },
    setTokboxConnect(
      state,
      action: PayloadAction<{
        sessionId?: string
        token?: string
        apiKey?: string
      }>
    ) {
      state.connect = action.payload
    },
    setProxyUrl(state, action: PayloadAction<string | undefined>) {
      state.proxyUrl = action.payload
    },
    clearTokboxConnect(state) {
      state.connect = undefined
    },
    openCall(state, action: PayloadAction<string>) {
      state.conversationId = action.payload
    },
    updateCall(
      state,
      action: PayloadAction<{
        callState: CallState
        conversationId?: string
      }>
    ) {
      state.callState = action.payload.callState
      state.conversationId = action.payload.conversationId || null
    },
    updateTokboxPubOptions(
      state,
      action: PayloadAction<{
        publishVideo?: boolean
        publishAudio?: boolean
        audioSource?: string
        videoSource?: string
        frameRate?: number
        resolution?: string
      }>
    ) {
      state.pubOptions = {
        ...state.pubOptions,
        ...action.payload
      }
    },
    updateTokboxTimer(
      state,
      action: PayloadAction<{
        startTimer?: Dayjs
        callTimerInterval?: ReturnType<typeof setInterval>
        text?: string
      }>
    ) {
      state.timer = {
        ...state.timer,
        ...action.payload
      }
    },
    updateTokboxInputDevices(
      state,
      action: PayloadAction<{
        audio: Device[]
        video: Device[]
      }>
    ) {
      state.inputDevices = action.payload
    },
    setTokboxConnectTimeout(state, action: PayloadAction<ReturnType<typeof setTimeout>>) {
      state.connectTimeout = action.payload
    },
    toggleTokboxExpanded(state) {
      state.expanded = !state.expanded
    },
    updateTokboxHasVideo(state, action: PayloadAction<{ publisher?: boolean; subscriber?: boolean }>) {
      state.hasVideo = {
        ...state.hasVideo,
        ...action.payload
      }
    },
    setSessionReconnecting(state, action: PayloadAction<boolean>) {
      state.sessionReconnecting = action.payload
    },
    togglePatientVideo(state) {
      state.hidePatientVideo = !state.hidePatientVideo
    }
  },

  extraReducers: builder =>
    builder.addCase(updateCallState, (state, action) => {
      if (state.conversationId === action.payload.conversationId) {
        const { callState } = action.payload.data
        const inactive = callState === "inactive"

        state.callState = callState
        state.conversationId = inactive ? null : state.conversationId
        state.connect = inactive ? undefined : state.connect
      }
    })
})

export const {
  resetCallsState,
  setTokboxConnect,
  setProxyUrl,
  clearTokboxConnect,
  openCall,
  updateCall,
  updateTokboxPubOptions,
  updateTokboxTimer,
  updateTokboxInputDevices,
  setTokboxConnectTimeout,
  toggleTokboxExpanded,
  updateTokboxHasVideo,
  setSessionReconnecting,
  togglePatientVideo
} = tokbox.actions

export type TokboxSliceAction = ObjectFunctionReturnTypes<typeof tokbox.actions>

export const patientOnCall = createSelector(
  (state: RootState) => state.tokbox.callState,
  (state: RootState) => state.tokbox.conversationId,
  (state: RootState) => state.elk.conversationId,
  (state: RootState) => state.elk.account,
  (state: RootState) => state.conversations.entities,
  (videoCallState, videoCallConversationId, sipCallConversationId, sipAccount, conversations) => {
    const callConversationId = videoCallConversationId ? videoCallConversationId : sipCallConversationId
    if ((videoCallState !== "active" && !sipAccount) || !callConversationId) {
      return null
    }
    return conversations[callConversationId]?.account
  }
)

export default tokbox.reducer
