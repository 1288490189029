import { apiFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"
import { loadPhrases } from "reducers/phrases"

import { handleErrors } from "../error/handler"

export interface ApiPhrase {
  id: string
  modified: string
  name: { [language: string]: string }
  content?: { [language: string]: string }
  parentId?: string
}

export const fetchPhrasesData = (): Thunk => dispatch =>
  dispatch(apiFetch("/phrases"))
    .then((response: { phrases: ApiPhrase[] }) => dispatch(loadPhrases(response.phrases)))
    .catch((error: any) => dispatch(handleErrors({ error })))
