import { useEffect } from "react"

import { usePrevious } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import { useAppSelector } from "lib/hooks"

import DialNumber from "components/DialNumber/DialNumber"

interface DialNumberDialogProps {
  closeModal: () => void
  conversation: AssignedConversation
}

const DialNumberDialog = ({ conversation, closeModal }: DialNumberDialogProps): JSX.Element => {
  const account = useAppSelector(state => state.elk.account)
  const prevAccount = usePrevious(account)

  useEffect(() => {
    if (prevAccount && !account) closeModal()
  }, [account, prevAccount, closeModal])

  return <DialNumber conversation={conversation} endCall={closeModal} />
}

export default DialNumberDialog
