// These does not get sent to datadog
export const expectedErrors = [
  "error.pnr.invalid",
  "error.user.notfound",
  "error.login.notastaff",
  "error.login.password",
  "error.sip.phonenumber.invalid",
  "error.claim.conflict.assigned_not_unassigned",
  "error.sip.phonenumber.invalid",
  "error.pnr.childtooold",
  "error.child.conflict.alreadyexists",
  "error.bookings.delete.failed",
  "error.calendar.booking_overlapping",
  "error.calendar.no_concurrent_slots",
  "error.encryption.init.failed",
  "error.encryption.encrypt.failed",
  "error.encryption.decrypt.failed"
]

// Display in some other way then the default dialog
export const customErrors = [
  "error.pnr.invalid",
  "error.user.notfound",
  "error.claim.conflict.assigned_not_unassigned",
  "error.pnr.childtooold",
  "error.child.conflict.alreadyexists"
]
