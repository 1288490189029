import { useCallback, useEffect, useRef } from "react"
import { useIntl } from "react-intl"

import { usePrevious } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import audioFile from "audio/monitor-queue.mp3"
import { featureFlags } from "config"
import { useAppSelector } from "lib/hooks"
import { selectConversations } from "reducers/conversations/conversations.reducer"

const APP_STARTUP_GRACE_PERIOD_MS = 5 * 1000
let APP_STARTUP_EPOCH_MS: number

export const NewConversationInInboxNotifier = () => {
  const newConversationInInboxNotificationSettingActive = useAppSelector(
    state => state.app.isNewConversationInInboxNotificationActive
  )
  const featureFlagActive = featureFlags.has("new_conversation_in_inbox_notification")
  const notificationsSupported = "Notification" in window

  if (notificationsSupported && featureFlagActive && newConversationInInboxNotificationSettingActive) {
    return <NewConversationNotifier />
  }

  return null
}

/**
 * A component notifying the staff when there's a new conversation in the inbox.
 * It  caches previous active conversations list and on update compares it with the current list.
 * It ignores new conversation which were created by the same staff
 */
const NewConversationNotifier = () => {
  const intl = useIntl()
  const { active: activeConversations } = useAppSelector(selectConversations)
  const prevConversations = usePrevious(activeConversations)
  const user = useAppSelector(state => state.auth.user)
  const audio = useRef<HTMLAudioElement>(null)

  const notCreatedByThisStaff = useCallback(
    (currentConversations: AssignedConversation[], prevConversations: AssignedConversation[]) => {
      const newConversations = currentConversations.filter(c => !prevConversations.find(pc => pc.id === c.id))
      const anyConvCreatedBySomeoneElse = newConversations.filter(c => c.createdById !== user?.id).length
      return anyConvCreatedBySomeoneElse
    },
    [user?.id]
  )

  /*
  At app startup the numbers change in this way:
  newConversations, prevConversations
  0, undefined
  0, 0 (initial state)
  1, 0 (conversations loaded, 1 conv present, might be also 0, 0 again)
  1, 1

  It's hard to distinguish between the different versions of the initial state and new conversations appearing later on, hence this hack.
   */
  const isAppStarting = () => {
    return new Date().getTime() - APP_STARTUP_EPOCH_MS < APP_STARTUP_GRACE_PERIOD_MS
  }

  useEffect(function setStartupTimeAndRequestPermission() {
    APP_STARTUP_EPOCH_MS = new Date().getTime()
    if (Notification.permission !== "granted") {
      Notification.requestPermission()
    }
  }, [])

  useEffect(
    function checkAndSendNotifications() {
      const conversationsLength = activeConversations.length

      const noConversations = !conversationsLength
      const firstRender = !prevConversations
      if (noConversations || firstRender) {
        return
      }

      const prevConversationsLength = prevConversations.length || 0
      const conversationsNumberIncreased = conversationsLength > prevConversationsLength

      const sendNotification = () => {
        audio.current?.play()

        const notificationTitle = intl.formatMessage({ id: "notification.newConversation.title" })

        new Notification(notificationTitle, {
          icon: `/favicon.ico?v=2`,
          // @ts-ignore
          priority: 2
        })
      }

      if (
        !isAppStarting() &&
        conversationsNumberIncreased &&
        notCreatedByThisStaff(activeConversations, prevConversations)
      ) {
        sendNotification()
      }
    },
    [activeConversations, prevConversations, notCreatedByThisStaff, intl]
  )

  return (
    <audio ref={audio}>
      <source src={audioFile} type="audio/mp3" />
      <track kind="captions" />
    </audio>
  )
}
