import dayjs from "dayjs"

import { AnyFilteredMessage, AnyMessage, AssignedConversation, User } from "@doktor-se/bones-ui/dist/web-shared/types"
import { buildMessages } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { featureFlags } from "config"

import { conversationByPostponeUntil, conversationsByLastMessageId, conversationsBySnoozedUntil } from "./sort"

export const filterMessages = (messages: AnyMessage[], patientId: string): AnyFilteredMessage[] => {
  const options = [
    "sip",
    "image",
    "attachment",
    "chat",
    "system",
    "snooze",
    "state",
    "call",
    "category",
    "prescription",
    "multiplechoice",
    "assignment",
    "form",
    "profile",
    "comment",
    "button",
    "upload.reply",
    "customer_metadata"
  ]
  return buildMessages(messages, patientId, options)
}

const conversationIsPostponed = (conversation: AssignedConversation) => {
  const postponedUntil = conversation.postponedUntil
  return !!postponedUntil && dayjs(postponedUntil).diff(dayjs()) > 0
}

const conversationIsSnoozed = (conversation: AssignedConversation) => {
  return conversation.snoozedUntil !== null && dayjs(conversation.snoozedUntil).diff(dayjs()) > 0
}

export const filterActiveConversations = (conversations: AssignedConversation[], user?: User) => {
  return conversations
    .filter(conversation => conversation.assignedStaffId === user?.id)
    .filter(conversation => !conversationIsSnoozed(conversation))
    .filter(conversation => !(featureFlags.has("postpone_to_staff") && conversationIsPostponed(conversation)))
    .filter(conversation => !isUpcomingBooking(conversation))
    .sort(conversationsByLastMessageId)
}

export const filterActiveAndPostponedConversations = (conversations: AssignedConversation[], user?: User) => {
  return conversations
    .filter(conversation => conversation.assignedStaffId === user?.id)
    .filter(conversation => !conversationIsSnoozed(conversation))
    .filter(conversation => featureFlags.has("postpone_to_staff") && conversationIsPostponed(conversation))
    .sort(conversationByPostponeUntil)
}

export const filterSnoozedConversations = (conversations: AssignedConversation[], user?: User) => {
  return conversations
    .filter(conversation => conversation.assignedStaffId === user?.id)
    .filter(conversation => conversationIsSnoozed(conversation))
    .sort(conversationsBySnoozedUntil)
}

const isUpcomingBooking = (conversation: AssignedConversation) =>
  featureFlags.has("booking") &&
  conversation.metadata?.bookingId &&
  (conversation.metadata?.isUpcomingBooking || conversation.state === "booked")

export const filterUpcomingBookings = (conversations: AssignedConversation[]) => {
  return conversations.filter(isUpcomingBooking)
}
