import dtmfzero from "dtmf/dtmf-0.mp3"
import dtmfone from "dtmf/dtmf-1.mp3"
import dtmftwo from "dtmf/dtmf-2.mp3"
import dtmfthree from "dtmf/dtmf-3.mp3"
import dtmffour from "dtmf/dtmf-4.mp3"
import dtmffive from "dtmf/dtmf-5.mp3"
import dtmfsix from "dtmf/dtmf-6.mp3"
import dtmfseven from "dtmf/dtmf-7.mp3"
import dtmfeight from "dtmf/dtmf-8.mp3"
import dtmfnine from "dtmf/dtmf-9.mp3"
import dtmfhash from "dtmf/dtmf-hash.mp3"
import dtmfstar from "dtmf/dtmf-star.mp3"

const setDtmfAudio = (tone: string) => {
  switch (tone) {
    case "0":
      return dtmfzero
    case "1":
      return dtmfone
    case "2":
      return dtmftwo
    case "3":
      return dtmfthree
    case "4":
      return dtmffour
    case "5":
      return dtmffive
    case "6":
      return dtmfsix
    case "7":
      return dtmfseven
    case "8":
      return dtmfeight
    case "9":
      return dtmfnine
    case "#":
      return dtmfhash
    case "*":
      return dtmfstar
    default:
      return null
  }
}

export default setDtmfAudio
