import { useMemo } from "react"
import { useIntl } from "react-intl"

import dayjs, { Dayjs } from "dayjs"

import { OpeningHour, Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

import { defaultTimezone, platform } from "config"

export const useQueueIsOpen = (queueOpeningHours: OpeningHour[], selectedQueue?: Queue) => {
  const timezone = useMemo(() => defaultTimezone[platform] ?? "", [])
  const intl = useIntl()

  return useMemo(() => {
    const findOpeningHours = (day: Dayjs) => {
      return queueOpeningHours.find(o => o.queue === selectedQueue?.queueKey && o.weekday + 1 === day.isoWeekday()) // Quick fix until backend fix it on their side. Backend: monday = 0, Frontend: monday = 1
    }

    const timezoneDateFormat = (time: string, day: dayjs.Dayjs) => {
      return dayjs.tz(`${day.format("YYYY-MM-DD")} ${time}`, timezone)
    }

    const now = dayjs(dayjs()).tz(timezone)
    const tomorrow = dayjs(dayjs().add(1, "day")).tz(timezone)
    const openingHoursToday = findOpeningHours(now)
    const openingHoursTomorrow = findOpeningHours(tomorrow)

    if (openingHoursToday && openingHoursTomorrow) {
      const openingTimeTomorrow = timezoneDateFormat(openingHoursTomorrow.openingTime, tomorrow)
      const openingTimeToday = timezoneDateFormat(openingHoursToday.openingTime, now)
      let closingTimeToday = timezoneDateFormat(openingHoursToday.closingTime, now)

      // Closing time is before opening time means closing time is tomorrow
      if (closingTimeToday.diff(openingTimeToday) < 0) {
        closingTimeToday = timezoneDateFormat(openingHoursToday.closingTime, tomorrow)
      }

      const closeDiff = openingTimeTomorrow.diff(closingTimeToday)
      // If now is in open period today or if time between closing time today and opening time tomorrow is less then or equal to 1 minute
      if ((now > openingTimeToday && now < closingTimeToday) || (closeDiff > 0 && closeDiff <= 1000 * 60)) {
        return true
      }

      return now < openingTimeToday
        ? `${openingTimeToday.format("HH:mm")} ${intl.formatMessage({ id: "unassign.postpone.today" })}`
        : `${openingTimeTomorrow.format("HH:mm")} ${intl.formatMessage({ id: "unassign.postpone.tomorrow" })}`
    }
    return true
  }, [queueOpeningHours, selectedQueue, timezone, intl])
}
