import classNames from "classnames"

import styles from "./TimeInput.module.scss"

interface TimeInputProps {
  value: string
  onChange: (value: string) => void
  darkMode?: boolean
  className?: string
}

const TimeInput = ({ value, onChange, darkMode, className }: TimeInputProps): JSX.Element => {
  return (
    <input
      data-testid="time-input"
      type="time"
      className={classNames(styles.input, className, { [styles.darkMode]: darkMode })}
      value={value}
      onChange={evt => onChange(evt.target.value)}
    />
  )
}

export default TimeInput
