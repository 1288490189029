import React from "react"

const IconFailure = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="12" fill="#D30D0D" />
      <path
        d="M12 12.6667C12.1889 12.6667 12.3473 12.6027 12.4753 12.4747C12.6029 12.3472 12.6667 12.1889 12.6667 12V9.31671C12.6667 9.12782 12.6029 8.97226 12.4753 8.85004C12.3473 8.72782 12.1889 8.66671 12 8.66671C11.8111 8.66671 11.6529 8.73049 11.5253 8.85804C11.3973 8.98604 11.3333 9.14449 11.3333 9.33337V12.0167C11.3333 12.2056 11.3973 12.3612 11.5253 12.4834C11.6529 12.6056 11.8111 12.6667 12 12.6667ZM12 15.3334C12.1889 15.3334 12.3473 15.2694 12.4753 15.1414C12.6029 15.0138 12.6667 14.8556 12.6667 14.6667C12.6667 14.4778 12.6029 14.3194 12.4753 14.1914C12.3473 14.0638 12.1889 14 12 14C11.8111 14 11.6529 14.0638 11.5253 14.1914C11.3973 14.3194 11.3333 14.4778 11.3333 14.6667C11.3333 14.8556 11.3973 15.0138 11.5253 15.1414C11.6529 15.2694 11.8111 15.3334 12 15.3334ZM12 18.6667C11.0778 18.6667 10.2111 18.4916 9.4 18.1414C8.58888 17.7916 7.88333 17.3167 7.28333 16.7167C6.68333 16.1167 6.20844 15.4112 5.85866 14.6C5.50844 13.7889 5.33333 12.9223 5.33333 12C5.33333 11.0778 5.50844 10.2112 5.85866 9.40004C6.20844 8.58893 6.68333 7.88337 7.28333 7.28337C7.88333 6.68337 8.58888 6.20826 9.4 5.85804C10.2111 5.50826 11.0778 5.33337 12 5.33337C12.9222 5.33337 13.7889 5.50826 14.6 5.85804C15.4111 6.20826 16.1167 6.68337 16.7167 7.28337C17.3167 7.88337 17.7916 8.58893 18.1413 9.40004C18.4916 10.2112 18.6667 11.0778 18.6667 12C18.6667 12.9223 18.4916 13.7889 18.1413 14.6C17.7916 15.4112 17.3167 16.1167 16.7167 16.7167C16.1167 17.3167 15.4111 17.7916 14.6 18.1414C13.7889 18.4916 12.9222 18.6667 12 18.6667ZM12 17.3334C13.4778 17.3334 14.7362 16.814 15.7753 15.7754C16.814 14.7363 17.3333 13.4778 17.3333 12C17.3333 10.5223 16.814 9.26382 15.7753 8.22471C14.7362 7.18604 13.4778 6.66671 12 6.66671C10.5222 6.66671 9.26399 7.18604 8.22533 8.22471C7.18622 9.26382 6.66666 10.5223 6.66666 12C6.66666 13.4778 7.18622 14.7363 8.22533 15.7754C9.26399 16.814 10.5222 17.3334 12 17.3334Z"
        fill="white"
      />
    </svg>
  )
}

export default IconFailure
