import React from "react"

const IconSettingNetwork = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={30} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={15} cy={15} r={15} fill="var(--color-primary-50)" />
        <path
          fill="#FFF"
          d="M15 13.434a1.61 1.61 0 000 3.217 1.608 1.608 0 000-3.217zm4.8 1.609c0-2.662-2.152-4.826-4.8-4.826-2.648 0-4.8 2.164-4.8 4.826 0 1.785.968 3.338 2.4 4.174l.8-1.4a3.215 3.215 0 01-1.6-2.774 3.207 3.207 0 013.2-3.217c1.768 0 3.2 1.44 3.2 3.217 0 1.19-.648 2.212-1.6 2.775l.8 1.4a4.83 4.83 0 002.4-4.175zM15 7c-4.416 0-8 3.603-8 8.043A8.04 8.04 0 0010.992 22l.8-1.391A6.446 6.446 0 018.6 15.043c0-3.555 2.864-6.434 6.4-6.434 3.536 0 6.4 2.879 6.4 6.434a6.436 6.436 0 01-3.2 5.566L19 22a8.031 8.031 0 004-6.957C23 10.603 19.416 7 15 7z"
        />
      </g>
    </svg>
  )
}

export default IconSettingNetwork
