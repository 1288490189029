import React from "react"

import styles from "./LoadingContainer.module.scss"

interface LoadingContainerProps {}

const LoadingContainer: React.FC<LoadingContainerProps> = () => {
  return <div className={styles.container}></div>
}

export default LoadingContainer
