import { Category } from "@doktor-se/bones-ui/dist/web-shared/types"

import { handleErrors } from "api/error/handler"
import { apiFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"
import { loadCategories } from "reducers/categories"

export const categoriesFetch = (): Thunk => dispatch => {
  dispatch(apiFetch("/v2/categories"))
    .then((response: Category[]) => dispatch(loadCategories(response)))
    .catch((error: any) => dispatch(handleErrors({ error })))
}

export const updateCategory =
  (categoryId: number, conversationId: string): Thunk =>
  dispatch => {
    dispatch(
      apiFetch(`/conversations/${conversationId}/category`, {
        method: "POST",
        body: JSON.stringify({ category: categoryId })
      })
    ).catch((error: any) =>
      dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>/category" }))
    )
  }
