import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { Phrases } from "@doktor-se/bones-ui/dist/web-shared/types"
import { createMapObjectWithChildren } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { ApiPhrase } from "api"

export interface PhrasesState {
  phrases?: Phrases | null
}
const initialState: PhrasesState = {
  phrases: {}
}

const phrases = createSlice({
  name: "phrases",
  initialState,
  reducers: {
    loadPhrases(state, action: PayloadAction<ApiPhrase[]>) {
      state.phrases = createMapObjectWithChildren(action.payload)
    }
  }
})

export const { loadPhrases } = phrases.actions

export type PhrasesSliceAction = ObjectFunctionReturnTypes<typeof phrases.actions>

export default phrases.reducer
