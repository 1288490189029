import { useMemo } from "react"

import classNames from "classnames"

import { TabsContext } from "./TabsContext"

interface TabsProps {
  children: React.ReactNode
  className?: string
  activeTab: string
  setActiveTab: (id: string) => void
}

const Tabs = ({ children, className, activeTab, setActiveTab }: TabsProps) => {
  const value = useMemo(() => ({ activeTab, setActiveTab }), [activeTab, setActiveTab])
  return (
    <TabsContext.Provider value={value}>
      <div className={classNames(className)}>{children}</div>
    </TabsContext.Provider>
  )
}
export default Tabs
