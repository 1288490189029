import { useEffect, useState } from "react"

import { usePrevious } from "@doktor-se/bones-ui/dist/web-shared/hooks"

import { fetchSignedUrl } from "../../../../../../../../api"
import { useAppDispatch } from "../../../../../../../../lib/hooks"

import styles from "./ImageList.module.scss"

export interface ImageListProps {
  conversationId: string
  imagesKeys: (undefined | string)[]
}

const ImageList = ({ conversationId, imagesKeys }: ImageListProps) => {
  const dispatch = useAppDispatch()
  const [imageUrls, setImageUrls] = useState<(string | undefined)[]>([])
  const [previousKeysLength, setPreviousKeysLength] = useState(0)
  const prevConversationId = usePrevious(conversationId)

  useEffect(() => {
    const length = prevConversationId === conversationId ? previousKeysLength : 0
    const fetchImages = async (keys: (string | undefined)[]) => {
      const imagePromises = keys.map(async key => {
        return key && (await dispatch(fetchSignedUrl(key, conversationId)))
      })
      const formUrls = imagePromises ? await Promise.all(imagePromises) : []
      setImageUrls(prev => [...prev, ...formUrls])
    }

    if (imagesKeys.length > length) {
      const newElements = imagesKeys.slice(length)

      fetchImages(newElements)
      setPreviousKeysLength(imagesKeys.length)
    }

    return () => {
      prevConversationId !== conversationId && setImageUrls([])
    }
  }, [dispatch, previousKeysLength, prevConversationId, conversationId, imagesKeys])

  return (
    <>
      {imageUrls.map((url, index) => (
        <div key={index} className={styles.image}>
          <>{url && <img src={url} style={{ width: 100 }} alt={""} />}</>
        </div>
      ))}
    </>
  )
}

export default ImageList
