import dayjs from "dayjs"

import { parseJWT } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { refreshTokenDefaultValidity } from "config"
import { Thunk } from "lib/hooks"
import { setTokenTimeout, tokenExpWarning } from "reducers/auth"

import { logout } from "./auth.api"

export const handleTokenExpiry =
  (token: string): Thunk =>
  (dispatch, getState) => {
    let exp = dayjs(parseJWT(token).exp * 1000)

    // The refresh token validity has been sent by the frontend for now. It is an additional check here to
    // verify that server returned refresh token validity is same as of client. This can be removed once server
    // will take control of the refresh tokens

    // Too long of the expiry can break the setTimeout. The setTimeout function is using a 32-bit int to
    // store the delay. The max value allowed for the delay is "2147483647" milliseconds. Any number
    // greater than that breaks the set timeout. In my case, it was firing the setTimout right away if the value is greater than "2147483647"
    if (exp.isAfter(refreshTokenDefaultValidity, "seconds")) {
      exp = dayjs().add(refreshTokenDefaultValidity, "seconds")
    }

    const warning = dayjs(exp).subtract(1, "hour")
    const now = dayjs()
    const { tokenTimeOut } = getState().auth
    if (tokenTimeOut) {
      clearTimeout(tokenTimeOut)
      setTokenTimeout(undefined)
    }
    if (now > exp) {
      dispatch(logout())
    } else if (now >= warning) {
      dispatch(tokenExpWarning(exp))
    } else {
      const milli = dayjs(warning).diff(now)
      dispatch(
        setTokenTimeout(
          setTimeout(() => {
            dispatch(tokenExpWarning(exp))
          }, milli)
        )
      )
    }
  }

export default handleTokenExpiry
