import dayjs from "dayjs"

import { QueueConversation, QueueOutline } from "types"

const conversationsByQueuePriority = <T extends QueueConversation | QueueOutline>(a: T, b: T) => {
  return (a.queuePriority ?? 0) - (b.queuePriority ?? 0)
}

export const conversationByPostponeUntil = <T extends QueueConversation | QueueOutline>(a: T, b: T) => {
  return dayjs(a.postponedUntil).diff(dayjs(b.postponedUntil))
}

export const sortQueue = <T extends QueueConversation | QueueOutline>(conversations: T[]): T[] => {
  return [...conversations].sort(conversationsByQueuePriority)
}
