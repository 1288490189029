import dayjs from "dayjs"
import humps from "humps"

import { Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

import { handleErrors, handleErrorsV2 } from "api/error/handler"
import { addPostponeTimer } from "api/ws/handlers/handleAssignment"
import { apiFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"
import { conversationIsPostponed } from "reducers/queue/filter"
import {
  addConversationsOutline,
  addQueueCount,
  addToConversationsOutline,
  loadPersonalQueue,
  loadQueueConversation,
  loadQueues,
  loadSelectedQueue,
  updateSelectedQueue
} from "reducers/queue/queue.reducer"
import { QueueConversation, QueueOutline } from "types"

export const fetchQueues = (): Thunk => async dispatch => {
  try {
    const response: { queues: Queue[] } = await dispatch(
      apiFetch({
        url: "/queues",
        incomingOptions: { loader: "QUEUES" },
        config: {
          errorHandling: "v2"
        }
      })
    )

    dispatch(loadQueues(response.queues))
  } catch (error: any) {
    dispatch(handleErrorsV2({ error }))
  }
}

export const fetchQueueCount =
  (queues: string[]): Thunk =>
  async dispatch => {
    try {
      const queryParams = new URLSearchParams({ queues: queues.toString() }).toString()
      const response: {
        conversationsInQueue: { key: string; conversationCount: number; postponedConversationCount: number }[]
      } = await dispatch(
        apiFetch({
          url: `/queues/conversation_count?${queryParams}`,
          incomingOptions: { loader: "QUEUE_COUNT" },
          config: {
            errorHandling: "v2"
          }
        })
      )

      dispatch(addQueueCount(response.conversationsInQueue))
    } catch (error: any) {
      dispatch(handleErrorsV2({ error }))
    }
  }

const parseConversation = <T extends QueueConversation | QueueOutline>(conversation: any): T => {
  return humps.camelizeKeys({
    ...conversation,
    queuePriority: Date.parse(conversation.queuePriority)
  }) as T
}

interface FetchQueueResponse {
  conversations: any[]
  paginationCount: number
}

export const fetchPersonalQueue = (): Thunk => async dispatch => {
  const queuesResponse: { queues: Queue[] } = await dispatch(
    apiFetch({
      url: "/queues/staff",
      incomingOptions: {
        loader: "PERSONAL_QUEUE"
      }
    })
  )
  const queues = queuesResponse.queues?.map((q: Queue) => q.queueKey)
  if (!queues) return
  try {
    let fullResponse: any[] = []

    for (let i = 0; i < queues.length; i++) {
      const response: FetchQueueResponse = await dispatch(
        apiFetch({
          url: `/queues/${queues[i]}/conversations`,
          incomingOptions: {
            loader: "PERSONAL_QUEUE"
          }
        })
      )
      fullResponse = [...fullResponse, ...response.conversations]
    }

    const queueConversations = fullResponse.map(parseConversation) as QueueConversation[]
    dispatch(loadPersonalQueue({ queues, conversations: queueConversations }))

    queueConversations.forEach(conversation => {
      if (!!conversation.postponedUntil && dayjs(conversation.postponedUntil) > dayjs()) {
        dispatch(addPostponeTimer(conversation.id, conversation.postponedUntil, conversation.queue))
      }
    })
  } catch (error: any) {
    dispatch(handleErrors({ error }))
  }
}

const sortQueueAscending = <T extends QueueConversation | QueueOutline>(a: T, b: T) => {
  return a.queuePriority - b.queuePriority
}
const sortPostponedQueueAscending = <T extends QueueConversation | QueueOutline>(a: T, b: T) => {
  return dayjs(a.postponedUntil).valueOf() - dayjs(b.postponedUntil).valueOf()
}

export const fetchSelectedQueue =
  (queue: string): Thunk =>
  async dispatch => {
    try {
      const response: FetchQueueResponse = await dispatch(
        apiFetch({
          url: `/queues/${queue}/conversations`,
          incomingOptions: {
            loader: "QUEUE"
          }
        })
      )
      const queueConversations: QueueConversation[] = response.conversations
        .map(conversation => parseConversation(conversation) as QueueConversation)
        .sort(sortQueueAscending)
      dispatch(loadSelectedQueue({ queue, conversations: queueConversations }))

      queueConversations.forEach(conversation => {
        if (!!conversation.postponedUntil && dayjs(conversation.postponedUntil) > dayjs()) {
          dispatch(addPostponeTimer(conversation.id, conversation.postponedUntil, conversation.queue))
        }
      })
    } catch (error: any) {
      dispatch(handleErrors({ error }))
    }
  }

export const fetchSelectedQueueOutline =
  (queue: string, numberOfElementsToLoad: number): Thunk =>
  async dispatch => {
    try {
      const response: FetchQueueResponse = await dispatch(
        apiFetch({
          url: `/queues/${queue}/conversations/outline`,
          incomingOptions: {
            loader: "QUEUE"
          }
        })
      )
      const rawConversations = response.conversations.map(parseConversation) as QueueOutline[]
      const queueConversationsPostponed = rawConversations
        .filter(conversationIsPostponed)
        .sort(sortPostponedQueueAscending)
      const queueConversationsActive = rawConversations
        .filter(item => !queueConversationsPostponed.includes(item))
        .sort(sortQueueAscending)
      const queueConversations = queueConversationsActive.concat(queueConversationsPostponed)
      dispatch(addConversationsOutline({ queue: queue, conversations: queueConversations }))
      const ids = queueConversationsActive.slice(0, numberOfElementsToLoad).map(item => item.id)
      const postponedIds = queueConversationsPostponed.slice(0, numberOfElementsToLoad).map(item => item.id)
      dispatch(fetchConversationDetails(queue, ids.concat(postponedIds), true))
    } catch (error: any) {
      dispatch(handleErrors({ error }))
    }
  }
export const fetchMoreConversationsWithDetails =
  (outlineIds: string[], conversationIds: string[], queue: string, numberOfElementsToLoad: number): Thunk =>
  async dispatch => {
    try {
      const idsToAdd = outlineIds.slice(0, numberOfElementsToLoad).filter(item => !conversationIds.includes(item))
      dispatch(fetchConversationDetails(queue, idsToAdd, false))
    } catch (error: any) {
      dispatch(handleErrors({ error }))
    }
  }
const fetchConversationDetails =
  (queue: string, ids: string[], reload: boolean): Thunk =>
  async dispatch => {
    try {
      if (ids.length === 0) {
        dispatch(loadSelectedQueue({ queue, conversations: [] }))
        return
      }
      const responseDetails: FetchQueueResponse = await dispatch(
        apiFetch({
          url: `/queues/${queue}/conversations?conversation_ids=${ids}`,
          incomingOptions: {
            loader: "QUEUE"
          }
        })
      )
      const queueDetailedConversations = responseDetails.conversations.map(parseConversation) as QueueConversation[]
      reload
        ? dispatch(loadSelectedQueue({ queue, conversations: queueDetailedConversations }))
        : dispatch(updateSelectedQueue({ queue, conversations: queueDetailedConversations }))
      const postponedConversations = queueDetailedConversations.filter(
        conversation => !!conversation.postponedUntil && dayjs(conversation.postponedUntil) > dayjs()
      )
      postponedConversations.forEach(conversation => {
        conversation.postponedUntil &&
          dispatch(addPostponeTimer(conversation.id, conversation.postponedUntil, conversation.queue))
      })
    } catch (error: any) {
      dispatch(handleErrors({ error }))
    }
  }
export const fetchAndLoadQueueConversation =
  (conversationId: string): Thunk =>
  async dispatch => {
    const conversation = await dispatch(fetchQueueConversation(conversationId))
    if (!conversation) return
    dispatch(loadQueueConversation({ conversation }))
    dispatch(
      addToConversationsOutline({
        conversation: {
          id: conversation.id,
          queuePriority: conversation.queuePriority,
          postponedUntil: conversation.postponedUntil
        }
      })
    )
  }
export const fetchQueueConversation =
  (conversationId: string): Thunk<Promise<QueueConversation | null>> =>
  async dispatch => {
    try {
      const response = await dispatch(apiFetch(`/queues/conversations/${conversationId}`))
      return parseConversation(response) as QueueConversation
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/queues/conversations/<conversationId>" }))
      return null
    }
  }
