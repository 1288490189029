import { IntlShape } from "react-intl"

import { logoutMSAppOnly } from "@doktor-se/bones-ui/dist/web-shared/config"
import { Role, User } from "@doktor-se/bones-ui/dist/web-shared/types"
import { parseJWT } from "@doktor-se/bones-ui/dist/web-shared/utils"
import { datadog } from "@doktor-se/more-rum-please"

import { disconnectWs, forceEndCall } from "api"
import { handleErrors, handleLoginErrors } from "api/error/handler"
import { featureFlags } from "config"
import { appApiUrl } from "config"
import { setAmplitudeUserId } from "lib/amplitude/amplitude"
import { apiFetch } from "lib/fetch"
import { ReduxDispatch, Thunk } from "lib/hooks"
import { authorizedAction, loginUser, setTokenTimeout, tokenExpWarning, unauthorize, updateUser } from "reducers/auth"
import { setEncryptionStatus } from "reducers/encryption/encryption.reducer"
import { IncomingError } from "reducers/error"
import { RootState } from "reducers/init"
import { removeClaimConversationTimeout, removeSelectedConversation } from "reducers/selected"
import { clearUserStatus } from "reducers/users/users.reducer"
import { CarealotUser } from "types"

import { handleTokenExpiry } from "./handler"

export interface AuthTokens {
  token?: string
  accessToken?: string
  refreshToken?: string
}

export const getAccessToken = (tokens: AuthTokens) => {
  return {
    accessToken: tokens.accessToken || "",
    refreshToken: tokens.refreshToken || ""
  }
}

const handleSuccessfulLogin = async (dispatch: ReduxDispatch, authTokens: AuthTokens, AD?: boolean) => {
  const { accessToken, refreshToken } = getAccessToken(authTokens)

  const roles = parseJWT(accessToken).r as Role[]
  dispatch(authorizedAction({ accessToken, refreshToken, roles, AD }))
  dispatch(handleTokenExpiry(refreshToken))
  const user: User = await dispatch(apiFetch("/account"))
  dispatch(loginUser({ ...user, roles: roles }))

  if (user && user.id) {
    setAmplitudeUserId(user.id)
  }

  if (featureFlags.has("e2ee")) {
    if (user.customerMetadata?.encryption && user.customerMetadata?.encryption?.eligibleForEncryption) {
      dispatch(setEncryptionStatus(true))
    }
  }

  // Set Freshdesk chat user data
  if (featureFlags.has("freshdesk_chat")) {
    ;(window as any).fcWidget?.user.setProperties({
      firstName: user.firstname,
      lastName: user.lastname,
      email: user.email
    })
  }
}

const handleFailedLogin = (dispatch: ReduxDispatch, error: IncomingError, password = false) => {
  dispatch(logout())
  dispatch(handleLoginErrors({ error }, password))
}

interface LoginResponse {
  token?: string
  refreshToken?: string
  accessToken?: string
  roles: Role[]
}

export const authorize =
  (data: { username: string; password: string }): Thunk =>
  async dispatch => {
    try {
      const response = await dispatch(
        apiFetch<LoginResponse>(`/v1/auth/password`, {
          method: "POST",
          body: JSON.stringify(data)
        })
      )
      const authTokens = {
        accessToken: response.accessToken,
        refreshToken: response.refreshToken,
        token: response.token
      }

      await handleSuccessfulLogin(dispatch, authTokens)
    } catch (error: any) {
      handleFailedLogin(dispatch, error, true)
    }
  }

export const authorizeWithMS =
  (token: string, appId: string): Thunk =>
  dispatch => {
    dispatch(
      apiFetch<LoginResponse>("/v1/auth/azure_ad", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ azure_id_token: token, app_id: appId })
      })
    )
      .then(({ accessToken, refreshToken, token }) => {
        handleSuccessfulLogin(dispatch, { accessToken, refreshToken, token }, true)
      })
      .catch(error => dispatch(handleLoginErrors({ error })))
  }

interface ItsmeStartResponse {
  type: "continue_redirect"
  redirectUri: string
  state: string
}

const ItsmeStateStorageKey = "itsmeState"

export const itsmeStart = (): Thunk => async dispatch => {
  try {
    const response = await dispatch(
      apiFetch<ItsmeStartResponse>("/login/itsme/start", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ use_test_itsme: featureFlags.has("itsme_test") })
      })
    )
    localStorage.setItem(ItsmeStateStorageKey, response.state)
    window.location.assign(response.redirectUri)
  } catch (error: any) {
    handleFailedLogin(dispatch, error)
  }
}

type ItsmeContinueResponse =
  | {
      type: "success"
      token: string
      accessToken?: string
      refreshToken?: string
      roles: Role[]
      state: string
    }
  | {
      type: "must_register"
      state: string
    }

export const itsmeContinue =
  (code: string, state: string, intl: IntlShape): Thunk =>
  async dispatch => {
    try {
      const response = await dispatch(
        apiFetch<ItsmeContinueResponse>("/login/itsme/continue", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            use_test_itsme: featureFlags.has("itsme_test"),
            state: localStorage.getItem(ItsmeStateStorageKey),
            query: { code, state }
          })
        })
      )
      switch (response.type) {
        case "success":
          const authTokens = {
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
            token: response.token
          }
          await handleSuccessfulLogin(dispatch, authTokens)
          break
        case "must_register":
          throw new Error(intl.formatMessage({ id: "login.error.nomatch" }))
        default:
          // @ts-ignore: Expected type error
          datadog.addError(new Error(`Unknown response type ${response.type} for itsme login`))
          throw new Error("Something went wrong")
      }
    } catch (error: any) {
      handleFailedLogin(dispatch, error)
    }
  }

export const getAccount = (): Thunk<Promise<boolean>> => async (dispatch, getState) => {
  try {
    const response = await dispatch(apiFetch<CarealotUser>("/account"))
    dispatch(updateUser({ ...response, roles: getState().auth.user?.roles || [] }))
    if (response && response.id) {
      setAmplitudeUserId(response.id)
    }
    if (featureFlags.has("e2ee") && response) {
      if (response.customerMetadata?.encryption && response.customerMetadata?.encryption?.eligibleForEncryption) {
        dispatch(setEncryptionStatus(true))
      }
    }
    return true
  } catch (error: any) {
    dispatch(handleErrors({ error }))
    return false
  }
}

export const getRefreshTokenRequest =
  (refreshToken: string, refreshTokenDefaultValidity: number, getState: () => RootState): (() => Promise<Response>) =>
  () =>
    fetch(`${appApiUrl}/user-svc/v1/auth/refresh`, {
      method: "POST",
      body: JSON.stringify({
        refresh_token: refreshToken,
        refresh_token_expires_in_seconds: refreshTokenDefaultValidity
      }),
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "Accept-language": getState().app.language
      }
    })

export const postLogout = (): Thunk => (dispatch, getState) => {
  const { tokenTimeOut } = getState().auth
  dispatch(unauthorize())
  dispatch(removeSelectedConversation())
  dispatch(tokenExpWarning(undefined))
  if (tokenTimeOut) {
    clearTimeout(tokenTimeOut)
    setTokenTimeout(undefined)
  }
  dispatch(removeClaimConversationTimeout())
  dispatch(clearUserStatus())
}

export const logout = (): Thunk => async (dispatch, getState) => {
  const { AD } = getState().auth
  disconnectWs()

  try {
    await dispatch(forceEndCall())
  } catch (err) {}

  if (AD) {
    logoutMSAppOnly()
  }

  dispatch(postLogout())
}
