import { useState } from "react"
import { useIntl } from "react-intl"

import { Dialog, ICONS, StaffBodyTextM, StaffHeading1 } from "@doktor-se/bones-ui"
import { Loader } from "@doktor-se/bones-ui/dist/web-shared/components"

import { useAppDispatch, useAppSelector } from "lib/hooks"
import { dropDatabaseAndLogout } from "lib/seald"

import styles from "./SealdRevokedDialog.module.scss"

const SealdRevokedDialog = () => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const [isLoading, setLoading] = useState(false)
  const sealdRevoked = useAppSelector(state => state.encryption.sealdRevoked)

  const onConfirm = () => {
    setLoading(true)
    dispatch(dropDatabaseAndLogout())
  }

  return (
    <Dialog isOpen={sealdRevoked} closeAriaLabel={"Close"} fixedWidth={true}>
      <ICONS.Warning />
      <StaffHeading1 margin={{ bottom: "10px" }}>
        {intl.formatMessage({ id: "encryption.seald.revoked.headline" })}
      </StaffHeading1>
      <StaffBodyTextM margin={{ bottom: "10px" }} as="span">
        {intl.formatMessage({ id: "encryption.seald.revoked.content" })}
      </StaffBodyTextM>
      {isLoading ? <Loader type="small" /> : undefined}
      <button className={styles.deviceSwitchDialogButton} onClick={onConfirm}>
        {intl.formatMessage({ id: "encryption.seald.revoked.button" })}
      </button>
    </Dialog>
  )
}

export default SealdRevokedDialog
