import { Profile, Staff } from "@doktor-se/bones-ui/dist/web-shared/types"

import { handleErrors } from "api/error/handler"
import { apiFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"
import { loadPatientProfiles, loadPatients, loadStaff } from "reducers/users/users.reducer"
import { SearchAccount } from "types"

import { ChildProfileData, PetProfileData } from "pages/patientInfo/components/profiles/types"

export const staffFetch = (): Thunk => dispatch => {
  dispatch(apiFetch("/staff"))
    .then((response: Staff[]) => response.length && dispatch(loadStaff(response)))
    .catch((error: any) => dispatch(handleErrors({ error })))
}

export const updatePatientComment =
  (patientId: string, comment: string): Thunk =>
  dispatch => {
    dispatch(
      apiFetch(`/patients/${patientId}/comment`, {
        method: "POST",
        body: JSON.stringify({ comment })
      })
    ).catch((error: any) => dispatch(handleErrors({ error, customContext: "/patients/<patientId>/comment" })))
  }

export const createStaffPassword =
  (password: string, code: string): Thunk<Promise<boolean>> =>
  async dispatch => {
    return dispatch(
      apiFetch("/staff/password/verify", {
        method: "POST",
        body: JSON.stringify({
          password,
          code
        })
      })
    )
  }

export const searchPatients =
  (params: { query?: string; pnr?: string }): Thunk =>
  dispatch => {
    const queryParams = new URLSearchParams(params).toString()
    dispatch(apiFetch(`/patients/search?${queryParams}`))
      .then((response: SearchAccount[]) => response.length && dispatch(loadPatients(response)))
      .catch((error: any) => {
        dispatch(handleErrors({ error, api: "searchPatients", customContext: "/patients/search?<params>" }))
        dispatch(loadPatients([]))
      })
  }

export const addPatientProfile =
  (patientId: string, data: ChildProfileData | PetProfileData): Thunk<Promise<Profile>> =>
  dispatch =>
    dispatch(
      apiFetch(`/patients/${patientId}/profiles`, {
        method: "POST",
        body: JSON.stringify(data)
      })
    )

export const createDraftPatient =
  (pnr: string): Thunk<Promise<any>> =>
  async dispatch =>
    await dispatch(
      apiFetch(`/patients/draft`, {
        loader: "PATIENTS",
        method: "POST",
        body: JSON.stringify({ pnr, region: "se" })
      })
    ).catch((error: any) => dispatch(handleErrors({ error })))

export const setPatientProfile =
  (conversationId: string, profileId?: string): Thunk<Promise<any>> =>
  dispatch =>
    dispatch(
      apiFetch(`/conversations/${conversationId}/profile`, {
        method: "POST",
        body: JSON.stringify({ profile_id: profileId })
      })
    )

export const deletePatientProfile =
  (profileId: string): Thunk =>
  dispatch => {
    dispatch(
      apiFetch(`/patient/profile/${profileId}`, {
        method: "DELETE"
      })
    ).catch((error: any) => dispatch(handleErrors({ error, customContext: "/patient/profile/<profileId>" })))
  }

export const patientProfilesFetch =
  (patientId: string): Thunk<Promise<Profile[] | undefined>> =>
  async dispatch => {
    try {
      const profiles: Profile[] = await dispatch(apiFetch(`/patients/${patientId}/profiles`))
      dispatch(loadPatientProfiles({ patientId, profiles }))
      return profiles
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/patients/<patientId>/profiles" }))
    }
  }
