import { deepARLicenseKey, videoBackgroundImage } from "config"
import { CanvasElement } from "types"

// For more explanation of the methods look here: https://help.deepar.ai/en/articles/3545295-api-reference
interface DeepAR {
  startVideo: (state: boolean) => void
  stopVideo: () => void
  pause: () => void
  resume: () => void
  switchEffect: (face: number, slot: string, path: string, callback?: Function) => void
  downloadFaceTrackingModel: (path: string, callback?: Function) => void
  changeParameter: (gameObject: string, component: string, parameter: string, value: string) => void
  changeParameterTexture: (gameObject: string, component: string, parameter: string, textureUrl: string) => void
  shutdown: () => void
}

export const startDeepAR = (canvas: CanvasElement, width = 769, height = 824) => {
  if (!deepARLicenseKey) {
    console.error("The env variable VITE_APP_DEEPAR_LICENSE_KEY is not provided.")
    return
  }

  const DeepAR = (window as any).DeepAR
  const deepAR: DeepAR = DeepAR({
    canvasWidth: width, // Max width of call window in web app
    canvasHeight: height,
    licenseKey: deepARLicenseKey,
    canvas: canvas,
    numberOfFaces: 1,
    libPath: "./deepar",
    segmentationInfoZip: "segmentation.zip",
    onInitialize: () => {
      // start video immediately after the initialization, mirror = true
      deepAR.startVideo(true)
      deepAR.switchEffect(0, "slot", "./deepar/effects/background_segmentation", () =>
        deepAR.changeParameterTexture("Background", "MeshRenderer", "s_texColor", videoBackgroundImage)
      )
    }
  })

  // Because we have to use a canvas to render to and then stream to the
  // Vonage publisher, changing tabs has to pause the video streaming otherwise it will cause a crash
  // by pausing the 'window.requestAnimationFrame', more can be seen in the documentation:
  // https://developer.mozilla.org/en-US/docs/Web/API/window/requestAnimationFrame
  var visible = true
  const pauseResumeDeepAR = () => {
    visible = !visible
    // pause and resume are not required, but it will pause the calls to 'window.requestAnimationFrame'
    // and the entire rendering loop, which should improve general performance and battery life
    if (!visible) {
      deepAR.pause()
      deepAR.stopVideo()
    } else {
      deepAR.resume()
      deepAR.startVideo(true)
    }
  }

  document.addEventListener("visibilitychange", pauseResumeDeepAR)

  const cleanup = () => {
    document.removeEventListener("visibilitychange", pauseResumeDeepAR)
    deepAR.shutdown()
  }

  return cleanup
}
