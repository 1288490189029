import { configureStore } from "@reduxjs/toolkit"
import { appMigrations, authMigrations, migrations, selectedMigrations, tokboxMigrations } from "migrations"
import { combineReducers } from "redux"
import { createMigrate, persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import storageSession from "redux-persist/lib/storage/session"

import app from "./app"
import auth from "./auth"
import booking from "./booking"
import categories from "./categories"
import clinics from "./clinics"
import conversations from "./conversations/conversations.reducer"
import elk from "./elk"
import encryption from "./encryption/encryption.reducer"
import error from "./error"
import loader from "./loader"
import payment from "./payment/payment.reducer"
import phrases from "./phrases"
import queue from "./queue/queue.reducer"
import search from "./search/search.reducer"
import selected from "./selected"
import tokbox from "./tokbox"
import users from "./users/users.reducer"

const persistConfig = {
  key: "carealot",
  version: 17,
  storage,
  throttle: 1000,
  whitelist: [],
  migrate: createMigrate(migrations, { debug: import.meta.env.VITE_APP_DEBUG_REDUX_PERSIST === "true" })
}

export const selectedPersistConfig = {
  key: "selected",
  version: 0,
  storage: storageSession,
  migrate: createMigrate(selectedMigrations, { debug: import.meta.env.VITE_APP_DEBUG_REDUX_PERSIST === "true" })
}

export const elkPersistConfig = {
  key: "elk",
  version: -1,
  storage,
  migrate: createMigrate(migrations, { debug: import.meta.env.VITE_APP_DEBUG_REDUX_PERSIST === "true" })
}

export const tokboxPersistConfig = {
  key: "tokbox",
  version: -1,
  storage,
  blacklist: ["callState"],
  migrate: createMigrate(tokboxMigrations, { debug: import.meta.env.VITE_APP_DEBUG_REDUX_PERSIST === "true" })
}

export const authPersistConfig = {
  key: "auth",
  version: 3,
  storage,
  migrate: createMigrate(authMigrations, { debug: import.meta.env.VITE_APP_DEBUG_REDUX_PERSIST === "true" })
}

export const appPersistConfig = {
  key: "app",
  version: -1,
  storage,
  blacklist: ["autoassign", "showDialNumber"],
  migrate: createMigrate(appMigrations, { debug: import.meta.env.VITE_APP_DEBUG_REDUX_PERSIST === "true" })
}

const rootReducer = combineReducers({
  conversations,
  selected: persistReducer(selectedPersistConfig, selected),
  auth: persistReducer(authPersistConfig, auth),
  app: persistReducer(appPersistConfig, app),
  elk: persistReducer(elkPersistConfig, elk),
  phrases,
  users,
  categories,
  search,
  error,
  tokbox: persistReducer(tokboxPersistConfig, tokbox),
  loader,
  clinics,
  booking,
  payment,
  queue,
  encryption
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware(),
  devTools: true
})

export const persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
