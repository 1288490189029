import { Fragment, useMemo } from "react"

import { usePrevious } from "@doktor-se/bones-ui/dist/web-shared/hooks"

import { openJournalNotes, updatePatientComment } from "api"
import { featureFlags, orderCommentAndDetails } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { categorySelectors } from "reducers/categories"
import { selectConversation } from "reducers/conversations/conversations.reducer"
import { selectLocalConversationState } from "reducers/selected"

import Comment from "./components/comment/Comment"
import ConversationCustomerMetadata from "./components/ConversationCustomerMetadata/ConversationCustomerMetadata"
import History from "./components/history/History"
import HistoryJournal from "./components/history/HistoryJournal"
import Details from "./components/profiles/Details"

import styles from "./PatientInfo.module.scss"

const PatientInfo = () => {
  const dispatch = useAppDispatch()
  const sortedItemList = orderCommentAndDetails()
  const conversation = useAppSelector(selectConversation)
  const localConversationState = useAppSelector(selectLocalConversationState)
  const categories = useAppSelector(categorySelectors.selectEntities)
  const error = useAppSelector(state => state.error.error)
  const prevConversationId = usePrevious(conversation?.id)

  const history = useMemo(() => {
    const historyList = conversation?.history || []
    if (conversation) {
      return historyList.filter(h => h.profileId === conversation?.profile?.id)
    }
    return historyList.filter(h => !h.profileId)
  }, [conversation])

  const [activeConversationHistory, otherConversationHistory] = useMemo(
    () => [history.find(h => h.id === conversation?.id), history.filter(h => h.id !== conversation?.id)],
    [history, conversation]
  )

  if (!conversation) return null

  return (
    <div className={styles.container}>
      {conversation?.id && conversation.account && (
        <>
          {sortedItemList.map((item, idx) => (
            <Fragment key={`${item}${idx}`}>
              {item === "comment" && !featureFlags.has("hide_patient_comments") && (
                <Comment
                  key={item}
                  comment={conversation.account?.comment}
                  updatePatientComment={(id, c) => dispatch(updatePatientComment(id, c))}
                  conversation={conversation}
                  prevConversationId={prevConversationId}
                />
              )}

              {item === "details" && (
                <Details key={item} conversation={conversation} error={error} prevConversationId={prevConversationId} />
              )}
            </Fragment>
          ))}
          {conversation.customerMetadata && <ConversationCustomerMetadata metadata={conversation.customerMetadata} />}
          {featureFlags.has("journal") && (
            <HistoryJournal
              history={otherConversationHistory}
              activeConversation={activeConversationHistory}
              categories={categories}
              openJournal={selectedJournal => dispatch(openJournalNotes(conversation.id, selectedJournal))}
              selectedJournalId={localConversationState?.state.selectedJournal?.id}
            />
          )}
          {featureFlags.has("patient_history") && <History history={history} categories={categories} />}
        </>
      )}
    </div>
  )
}

export default PatientInfo
