import { useField } from "formik"

import { TextInput } from "@doktor-se/bones-ui"

import styles from "./ControlledTextInput.module.scss"

export interface ControlledTextInputProps {
  name: string
  caption?: string
  variant: "onBackground" | "onSurface"
  label: string
  // Need custom onChange handler because react-aria useTextField is returning value instead of the on change event
  onChange: (value: string) => void
  error?: string
}

const ControlledTextInput = ({ name, variant, label, caption, onChange, error }: ControlledTextInputProps) => {
  const [field, meta] = useField({ name })

  return (
    <div className={styles.wrapper}>
      <TextInput
        {...field}
        onChange={onChange}
        variant={variant}
        label={label}
        caption={caption}
        autoComplete="off"
        data-lpignore="true"
        fullWidth
        errorMessage={meta.touched ? meta.error : error}
      />
    </div>
  )
}

export default ControlledTextInput
