import { createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { createSelector } from "reselect"

import { Category } from "@doktor-se/bones-ui/dist/web-shared/types"

import { categoriesPerRole } from "config"
import { RootState } from "reducers/init"

const categoryAdapter = createEntityAdapter<Category>()

const categories = createSlice({
  name: "categories",
  initialState: categoryAdapter.getInitialState(),
  reducers: {
    loadCategories: categoryAdapter.setAll
  }
})

export const { loadCategories } = categories.actions

export type CategoriesSliceAction = ObjectFunctionReturnTypes<typeof categories.actions>

export const simpleCategorySelectors = categoryAdapter.getSelectors()

export const categorySelectors = categoryAdapter.getSelectors<RootState>(state => state.categories)

export const selectCategoriesPerRole = createSelector(
  categorySelectors.selectAll,
  (state: RootState) => state.auth.defaultRole,
  (categories, defaultRole) => (defaultRole ? categoriesPerRole(categories, defaultRole) : [])
)

export const selectCategoryById = (state: RootState, id?: number) =>
  id ? categorySelectors.selectById(state, id) : undefined

export default categories.reducer
