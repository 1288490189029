import classNames from "classnames"

import { Draggable } from "@doktor-se/bones-ui/dist/web-shared/components"

import { useAppSelector } from "lib/hooks"

import TokBox from "components/tokbox/TokBox"

import styles from "./Call.module.scss"

const position = (clientHeight?: number, clientWidth?: number) => ({
  x: window.innerWidth - (clientWidth || 0) - 40,
  y: window.innerHeight - (clientHeight || 0) - 20
})

const Call = () => {
  const calls = useAppSelector(state => state.tokbox)
  if (!calls.connect) return null
  return (
    <Draggable
      expanded={calls.expanded}
      position={position}
      classNames={classNames(styles.draggable, { [styles.expanded]: calls.expanded })}>
      <TokBox />
    </Draggable>
  )
}

export default Call
