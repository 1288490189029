import {
  Account,
  AnyMessage,
  AppPlatform,
  AssignedConversation,
  ConversationHistory,
  JournalNote,
  SnoozeMessage
} from "@doktor-se/bones-ui/dist/web-shared/types"
import { setLastMessage, toTitleCase } from "@doktor-se/bones-ui/dist/web-shared/utils"

import { featureFlags } from "../../../config"
import { filterMessages } from "./filter"
import { sortMessagesByCreated } from "./sort"

const filterPlatformLogs = (conversation: AssignedConversation): AppPlatform[] =>
  conversation.messages
    .filter(m => m.type === "platform_log")
    .sort((a, b) => {
      if (!a.id || !b.id) return 0
      if (a.id < b.id) return -1
      if (a.id > b.id) return 1
      return 0
    })
    .map(p => p.data) as AppPlatform[]

const loadJournalNoteMessages = (messages: AnyMessage[]) =>
  messages
    .filter(message => message.type === "journal_note")
    .sort(sortMessagesByCreated)
    .map(message => message as JournalNote)
    .pop()

export const transformConversation = (conversation: AssignedConversation): AssignedConversation => {
  const messages = filterMessages([...conversation.messages], conversation.patientId)
  return {
    ...conversation,
    messagesToDisplay: messages,
    lastMessage: setLastMessage(messages, conversation.patientId),
    account: conversation.account && {
      ...conversation.account,
      displayName: toTitleCase(conversation.account.displayName!)!
    },
    platforms: filterPlatformLogs(conversation),
    journalNote: featureFlags.has("webdoc_journal_note") ? loadJournalNoteMessages(conversation.messages) : undefined
  }
}

export const addConversations = (conversations: AssignedConversation[]): AssignedConversation[] =>
  conversations.map(conversation => transformConversation(conversation))

export const setSnoozeConversation = (
  conversation: AssignedConversation,
  incomingMessage: SnoozeMessage
): AssignedConversation => {
  const messages = [...(conversation.messages || []), { ...incomingMessage }]
  return {
    ...conversation,
    messages,
    messagesToDisplay: filterMessages(messages, conversation.patientId)
  }
}

export const unpostponeConversation = (conversation: AssignedConversation): AssignedConversation => {
  const postponeUntil = { postponedUntil: undefined }
  const metadata = {
    ...conversation.metadata,
    postponeUntil
  }
  return {
    ...conversation,
    metadata
  }
}

export const setComment = (conversation: AssignedConversation, comment: string): AssignedConversation => {
  const account = {
    ...conversation.account,
    comment
  } as Account
  return {
    ...conversation,
    account
  }
}

export const removeMessageInConversation = (conversation: AssignedConversation, messageId: number) => {
  const messages = conversation.messages?.filter(message => message.id !== messageId)
  const messagesToDisplay = conversation.messagesToDisplay?.filter(message => message.id !== messageId) || []
  const lastMessage = setLastMessage(messagesToDisplay, conversation.patientId)
  return { ...conversation, messages, messagesToDisplay, lastMessage }
}

export const setJournalNote = (conversation: AssignedConversation, journalNote: JournalNote): AssignedConversation => {
  return {
    ...conversation,
    history: conversation.history
      ? conversation.history.map((h: ConversationHistory) => {
          if (h.id === conversation.id) {
            return { ...h, journalNote }
          }
          return h
        })
      : conversation.history
  }
}

export const setJournalNoteWebdoc = (
  conversation: AssignedConversation,
  journalNote: JournalNote
): AssignedConversation => {
  return {
    ...conversation,
    journalNote: conversation.journalNote ? journalNote : undefined
  }
}
