import dayjs from "dayjs"

import { Thunk } from "lib/hooks"
import { removeSnooze, setSnoozeTimeout } from "reducers/conversations/conversations.reducer"

export const snoozeTimer =
  (conversationId: string, snoozedUntil?: string): Thunk =>
  dispatch => {
    const timeout = setTimeout(() => {
      dispatch(removeSnooze(conversationId))
    }, dayjs(snoozedUntil).diff(dayjs()))
    dispatch(setSnoozeTimeout({ timeout, data: { conversationId, snoozedUntil } }))
  }
