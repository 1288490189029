import { useIntl } from "react-intl"

import { Button, Dialog, StaffBodyTextS, StaffHeading3 } from "@doktor-se/bones-ui"

interface FlagProps {
  isOpen: boolean
  onClose: () => void
  offset: {
    left: number
    top: number
  }
}

const ConfirmReportSent = ({ isOpen, onClose, offset }: FlagProps) => {
  const intl = useIntl()
  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      offset={{ top: `${offset.top + 30}px`, left: `${offset.left - 400}px` }}
      closeAriaLabel={intl.formatMessage({ id: "modal.close" })}
      fixedWidth>
      <StaffHeading3 margin={{ bottom: "15px" }}>{intl.formatMessage({ id: "flag.popup.header" })}</StaffHeading3>
      <StaffBodyTextS margin={{ bottom: "15px" }}>{intl.formatMessage({ id: "flag.popup.message" })}</StaffBodyTextS>

      <Button color="primary" variant="primary" onPress={onClose}>
        {intl.formatMessage({ id: "flag.popup.button" })}
      </Button>
    </Dialog>
  )
}

export default ConfirmReportSent
