import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import dayjs, { ManipulateType } from "dayjs"

import { Dropdown, TextInput } from "@doktor-se/bones-ui"
import { Checkbox } from "@doktor-se/bones-ui/dist/web-shared/components"

import styles from "./PeriodContainer.module.scss"

interface OptionProps extends Object {
  id: ManipulateType
  name: string
}

export interface CalculationResult {
  value: string
  formattedDate: string
  smsAgreement: boolean
  error?: boolean
}

interface PeriodContainerParams {
  onValueChange: (param: CalculationResult) => void
}

const MAX_NUMBER_OF_DAYS = 183
const PeriodContainer = ({ onValueChange }: PeriodContainerParams) => {
  const intl = useIntl()
  const periodOptions: OptionProps[] = [
    { id: "day", name: intl.formatMessage({ id: "revisits.period.days" }) },
    { id: "week", name: intl.formatMessage({ id: "revisits.period.weeks" }) },
    { id: "month", name: intl.formatMessage({ id: "revisits.period.months" }) }
  ]
  const [selectedPeriod, setSelectedPeriod] = useState<ManipulateType>("day")
  const [providedNumber, setProvidedNumber] = useState("1")
  const [smsAgreement, setSmsAgreement] = useState(false)
  const [error, setError] = useState(false)
  const updateProvidedNumber = (value: string) => {
    const valueToUpdate = value.length < 4 ? value : value.slice(0, 3)
    setProvidedNumber(valueToUpdate)
  }
  const agreementChanged = () => {
    setSmsAgreement(!smsAgreement)
  }
  useEffect(() => {
    const now = dayjs()
    const calculatedDate = now.add(Number(providedNumber), selectedPeriod)
    const calculatedDateFormatted = calculatedDate.format("dddd D MMMM")
    const error = calculatedDate.diff(now, "day") > MAX_NUMBER_OF_DAYS || providedNumber.length === 0
    setError(error)
    onValueChange({
      value: calculatedDate.toISOString(),
      formattedDate: calculatedDateFormatted,
      smsAgreement: smsAgreement,
      error
    })
  }, [onValueChange, providedNumber, selectedPeriod, smsAgreement])

  return (
    <>
      <div className={styles.periodSelector}>
        <div className={styles.prefix}>{intl.formatMessage({ id: "revisits.period.prefix" })}</div>
        <div className={styles.providedNumber}>
          <TextInput
            variant="onSurface"
            label={""}
            noVisualLabel
            value={providedNumber}
            onChange={updateProvidedNumber}
            fullWidth
            errorMessage={error ? " " : undefined}
          />
        </div>
        <div className={styles.providedType}>
          <Dropdown
            variant="onSurface"
            label=" "
            labelPosition="top"
            items={periodOptions}
            onSelectionChange={setSelectedPeriod}
            selectedKey={selectedPeriod}
            fullWidth
          />
        </div>
      </div>
      <div className={styles.smsAgreement}>
        <Checkbox
          checked={smsAgreement}
          onChange={agreementChanged}
          text={intl.formatMessage({ id: "revisits.agreement" })}
        />
      </div>
    </>
  )
}
export default PeriodContainer
