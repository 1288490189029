import { useState } from "react"
import { useIntl } from "react-intl"

import dayjs from "dayjs"

import { Dialog, StaffHeading3 } from "@doktor-se/bones-ui"
import { AssignedConversation, CallEndReason, Staff } from "@doktor-se/bones-ui/dist/web-shared/types"

import { claimForOther, reassignConversation } from "api"
import { conversationLimit, featureFlags, staffListForAssigning } from "config"
import { checkDeclineCall } from "lib/conversations"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { selectConversations } from "reducers/conversations/conversations.reducer"
import { SearchPageConversation } from "types"

import ConfirmReassign from "./components/ConfirmReassign/ConfirmReassign"
import PostponeToStaff from "./components/PostponeToStaff/PostponeToStaff"
import StaffSearch from "components/StaffSearch/StaffSearch"
import { Offset } from "pages/dashboard/components/ConversationControls/ConversationControls"
import WarningMessage from "pages/search/components/WarningMessage/WarningMessage"

interface ReassignDialogProps {
  isOpen: boolean
  conversation: AssignedConversation | SearchPageConversation
  onClose: () => void
  offset?: Offset
}

const ReassignDialog = ({ isOpen, offset, conversation, onClose }: ReassignDialogProps) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const authUser = useAppSelector(state => state.auth.user)
  const { activeStaff } = useAppSelector(state => state.users)
  const user = activeStaff.find(s => s.id === authUser?.id)
  const filteredStaffList = staffListForAssigning(activeStaff, user?.data?.clinic).filter(s => s.id !== user?.id)

  const { active } = useAppSelector(selectConversations)
  const defaultRole = useAppSelector(state => state.auth.defaultRole)
  const limitForRole = conversationLimit?.find(limit => limit.role === defaultRole)
  const isConversationsLimitReached = !!limitForRole && active.length >= limitForRole.amount

  const [dialogState, setDialogState] = useState<"search" | "postpone" | "confirm">("search")
  const [selectedStaff, setSelectedStaff] = useState<Staff>()
  const [time, setTime] = useState<string>("")
  const [date, setDate] = useState<string>("")
  const isNowSelected = !time && !date

  const onSelectStaff = (selectedStaff: Staff) => {
    setSelectedStaff(activeStaff.find(staff => staff.id === selectedStaff.id))
    featureFlags.has("postpone_to_staff") ? setDialogState("postpone") : setDialogState("confirm")
  }

  const resetSearch = () => {
    setSelectedStaff(undefined)
    setDialogState("search")
  }

  const reassignConfirmed = (assignToId: string) => {
    if (!conversation.assignedStaffId) {
      dispatch(claimForOther(conversation as SearchPageConversation, assignToId))
    } else {
      let reason: CallEndReason | undefined
      if (conversation.callState !== "incoming") reason = checkDeclineCall(conversation)
      dispatch(
        reassignConversation({
          conversation,
          assignedId: assignToId,
          postponedUntil: date && time ? dayjs(`${date} ${time}`).format() : undefined,
          reason
        })
      )
    }
    onClose()
    setDialogState("search")
  }

  const onCloseDialog = () => {
    setDialogState("search")
    onClose()
  }

  return (
    <Dialog
      isOpen={isOpen}
      offset={offset && { top: `${offset.top}px`, left: `${offset.left}px` }}
      onClose={onCloseDialog}
      closeAriaLabel={intl.formatMessage({ id: "modal.close" })}
      fixedWidth>
      <StaffHeading3 margin={{ bottom: "var(--size-200)" }}>
        {intl.formatMessage({ id: "search.new.title.assign" })}
      </StaffHeading3>

      {dialogState === "search" && (
        <>
          {conversation.assignedStaffId === user?.id && isConversationsLimitReached && (
            <WarningMessage message={intl.formatMessage({ id: "conversation.maximum.limit" })} sectioned />
          )}
          <StaffSearch
            staffList={filteredStaffList}
            onSelectStaff={onSelectStaff}
            currentUser={!isConversationsLimitReached ? user : undefined}
          />
        </>
      )}

      {dialogState === "postpone" && (
        <PostponeToStaff
          resetSearch={resetSearch}
          selectedStaff={selectedStaff!}
          time={time}
          setTime={setTime}
          date={date}
          setDate={setDate}
          isNowSelected={isNowSelected}
          onSend={() => setDialogState("confirm")}
          closeModal={onClose}
        />
      )}

      {dialogState === "confirm" && (
        <ConfirmReassign
          message={`${
            !isNowSelected
              ? intl.formatMessage(
                  {
                    id: "control.assign.postpone"
                  },
                  { staff: selectedStaff?.displayName, date, time }
                )
              : selectedStaff?.online
                ? selectedStaff.displayName
                : intl.formatMessage(
                    { id: "control.assign.offline" },
                    { staff: selectedStaff?.displayName, date, time }
                  )
          }

          ${
            conversation.callState !== "inactive"
              ? intl.formatMessage({
                  id: "control.call.active.assign"
                })
              : ""
          }`}
          onConfirm={() => reassignConfirmed(selectedStaff?.id!)}
          confirmText={intl.formatMessage({ id: "label.reassign" })}
          onCancel={() => {
            featureFlags.has("postpone_to_staff") ? setDialogState("postpone") : setDialogState("search")
          }}
        />
      )}
    </Dialog>
  )
}

export default ReassignDialog
