import { StaffLabelTextL } from "@doktor-se/bones-ui"
import { Avatar } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Staff } from "@doktor-se/bones-ui/dist/web-shared/types"

import OnlineStatus from "components/OnlineStatus/OnlineStatus"

import styles from "./UserRow.module.scss"

interface UserRowProps {
  user: Staff
  onClick: () => void
  tabIndex?: number
}

const UserRow = ({ user, onClick }: UserRowProps) => {
  return (
    <li id={user.id} key={user.id} className={styles.item}>
      <button className={styles.button} onClick={() => onClick()}>
        <div className={styles.user}>
          <Avatar avatarUrl={user.avatarUrl} className={styles.avatar} />
          <StaffLabelTextL margin={{ left: "var(--size-100)" }}>{user.displayName}</StaffLabelTextL>
        </div>
        <OnlineStatus online={!!user.online} />
      </button>
    </li>
  )
}

export default UserRow
