import dayjs from "dayjs"

import { searchConfig } from "../config"

export const getSearchParams = (searchValue: string) => {
  const emailRegex = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/g
  const trimmedValue = emailRegex.test(searchValue) ? searchValue : searchValue.trim().replace(/\.|-/g, "")
  let params: { query?: string } = {}

  if (searchConfig.params.includes("query") && searchConfig.regex?.test(trimmedValue)) {
    params.query = "format" in searchConfig ? dayjs(trimmedValue, searchConfig.format).format("YYYYMMDD") : trimmedValue
  } else {
    params.query = trimmedValue
  }

  return params
}
