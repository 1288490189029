import classNames from "classnames"
import dayjs from "dayjs"

import styles from "./DateInput.module.scss"

interface DateInputProps {
  value: string
  onChange: (value: string) => void
  darkMode?: boolean
}

const DateInput = ({ value, onChange, darkMode }: DateInputProps) => {
  return (
    <input
      data-testid="date-input"
      type="date"
      min={dayjs().format("YYYY-MM-DD")}
      value={value}
      onChange={evt => {
        onChange(evt.target.value)
      }}
      className={classNames(styles.dateInput, { [styles.darkMode]: darkMode })}
    />
  )
}

export default DateInput
