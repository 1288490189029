import { LanguageCode, Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

import { featureFlags } from "config"
import { useAppSelector } from "lib/hooks"
import { queueSelectors, selectPersonalQueue } from "reducers/queue/queue.reducer"
import { QueueConversation } from "types"

import NextConversationButton from "./NextConversationButton"
import NextConversationList from "./NextConversationList"
import AutoassignToggle from "./shared/AutoassignToggle"

export interface NextConversationComponentProps {
  autoassignTimer?: number
  isBlocked: boolean
  queues: Queue[]
  appLanguage: LanguageCode
  personalQueue: QueueConversation[]
  personalQueueKeys: string[] | undefined
}

interface NextConversationProps {
  autoassignTimer?: number
  autoassignFeature: boolean
  isBlocked: boolean
}

const NextConversation = ({ autoassignTimer, autoassignFeature, isBlocked }: NextConversationProps) => {
  const queues = useAppSelector(queueSelectors.selectAll)
  const appLanguage = useAppSelector(state => state.app.language)
  const personalQueue = useAppSelector(selectPersonalQueue)
  const personalQueueKeys = useAppSelector(state => state.queue.personalQueueKeys)

  const NextConversationComponent = featureFlags.has("personal_queue_select")
    ? NextConversationList
    : NextConversationButton

  const nextConversationComponentProps = {
    autoassignTimer,
    isBlocked,
    queues,
    appLanguage,
    personalQueue,
    personalQueueKeys
  }

  return (
    <div data-testid="nextConversation">
      <NextConversationComponent {...nextConversationComponentProps} />
      {autoassignFeature && <AutoassignToggle />}
    </div>
  )
}

export default NextConversation
