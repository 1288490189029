import React from "react"

const IconGreenTick = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="12" cy="12" r="12" fill="#63D59E" />
      <path
        d="M10.3667 16L6.56667 12.2L7.51667 11.25L10.3667 14.1L16.4833 7.98334L17.4333 8.93334L10.3667 16Z"
        fill="#161616"
      />
    </svg>
  )
}

export default IconGreenTick
