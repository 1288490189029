import { StaffLogoutEvent } from "@doktor-se/bones-ui/dist/web-shared/types"

import { Thunk } from "lib/hooks"
import { setSealdRevoked } from "reducers/encryption/encryption.reducer"

export const handleStaffLogout =
  (event: StaffLogoutEvent): Thunk =>
  (dispatch, getState) => {
    const { userSealdId } = getState().encryption
    const { payload } = event
    if (payload) {
      if (payload.sealdId && payload.sealdId === userSealdId) {
        dispatch(setSealdRevoked(true))
      }
    }
  }
