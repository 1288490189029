import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { createSelector } from "reselect"

import {
  AssignedConversation,
  ConversationHistory,
  JournalNote,
  LocalConversationState
} from "@doktor-se/bones-ui/dist/web-shared/types"

import { setSnoozeTimeout } from "reducers/conversations/conversations.reducer"
import { RootState } from "reducers/init"
import { Timeout } from "types"

import {
  newTimeout,
  reduceConversationHistory,
  setJournalDraft,
  setJournalDraftWebdoc,
  updateLocalState
} from "./selected"

export interface Timeouts {
  snooze: Timeout[]
  postpone: Timeout[]
  booking: Timeout[]
}
export interface SelectedState {
  conversationId?: string
  timeouts: Timeouts
  localConversationStates?: LocalConversationState[]
  claimConversationTimeout?: number
}

const initialState: SelectedState = {
  conversationId: undefined,
  timeouts: { snooze: [], postpone: [], booking: [] }
}

const selected = createSlice({
  name: "selected",
  initialState,
  reducers: {
    setConversation(state, action: PayloadAction<string>) {
      state.conversationId = action.payload
    },
    removeSelectedConversation(state) {
      state.conversationId = undefined
    },
    updateLocalConversationState(
      state,
      action: PayloadAction<{
        conversationId: string
        state: {
          commentDraft?: string
          messageDraft?: string
          commentState?: boolean
          journalDraft?: JournalNote
          selectedJournal?: ConversationHistory
        }
      }>
    ) {
      return updateLocalState(state, action.payload.conversationId, action.payload.state)
    },
    updateJournalDrafts(state, action: PayloadAction<AssignedConversation[]>) {
      return setJournalDraft(state, reduceConversationHistory(action.payload))
    },
    updateJournalDraft(state, action: PayloadAction<AssignedConversation>) {
      return setJournalDraft(state, action.payload.history)
    },
    updateJournalDraftWebdoc(state, action: PayloadAction<JournalNote>) {
      return setJournalDraftWebdoc(state, action.payload)
    },
    setClaimConversationTimeout(state, action: PayloadAction<number>) {
      if (state.claimConversationTimeout) clearTimeout(state.claimConversationTimeout)
      state.claimConversationTimeout = action.payload
    },
    removeClaimConversationTimeout(state) {
      if (state.claimConversationTimeout) clearTimeout(state.claimConversationTimeout)
      state.claimConversationTimeout = undefined
    },
    setPostponeTimeout(
      state,
      action: PayloadAction<{
        timeout: ReturnType<typeof setTimeout>
        data: { conversationId: string; postponeUntil?: string }
      }>
    ) {
      return {
        ...state,
        ...newTimeout("postpone", state.timeouts, action.payload)
      }
    },
    setBookingTimeout(
      state,
      action: PayloadAction<{
        timeout: ReturnType<typeof setTimeout>
        data: { conversationId: string }
      }>
    ) {
      return {
        ...state,
        ...newTimeout("booking", state.timeouts, action.payload)
      }
    },
    clearBookingTimeout(state, action: PayloadAction<string>) {
      const timeoutToCancel = state.timeouts.booking.find(({ id }) => action.payload === id)
      if (!timeoutToCancel) {
        return state
      }
      const remainingTimeouts = state.timeouts.booking.filter(timeout => timeout !== timeoutToCancel)
      clearTimeout(timeoutToCancel.timeout)

      return {
        ...state,
        timeouts: {
          ...state.timeouts,
          booking: remainingTimeouts
        }
      }
    }
  },

  extraReducers: builder =>
    builder.addCase(
      setSnoozeTimeout,
      (
        state,
        action: PayloadAction<{
          timeout: ReturnType<typeof setTimeout>
          data: { conversationId: string; snoozedUntil?: string }
        }>
      ) => {
        return {
          ...state,
          ...newTimeout("snooze", state.timeouts, action.payload)
        }
      }
    )
})

export const {
  setConversation,
  removeSelectedConversation,
  setPostponeTimeout,
  setBookingTimeout,
  clearBookingTimeout,
  updateLocalConversationState,
  updateJournalDrafts,
  updateJournalDraft,
  updateJournalDraftWebdoc,
  setClaimConversationTimeout,
  removeClaimConversationTimeout
} = selected.actions

export type SelectedSliceAction = ObjectFunctionReturnTypes<typeof selected.actions>

export const selectLocalConversationState = createSelector(
  (state: RootState) => state.selected.conversationId,
  (state: RootState) => state.selected.localConversationStates,
  (conversationId, localConversationStates) =>
    (localConversationStates &&
      !!conversationId &&
      localConversationStates.find(s => s.conversationId === conversationId)) ||
    undefined
)

export default selected.reducer
