import { useState } from "react"
import { useIntl } from "react-intl"

import OT from "@opentok/client"
import classnames from "classnames"
import NetworkTest, { ErrorNames } from "opentok-network-test-js"

import { Button, StaffBodyTextL, StaffBodyTextM } from "@doktor-se/bones-ui"
import { Loader } from "@doktor-se/bones-ui/dist/web-shared/components"

import { tokboxNetworkTest } from "api"
import { handleErrors } from "api/error/handler"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { updateTokboxPubOptions } from "reducers/tokbox"

import styles from "./TokboxNetworkTest.module.scss"

interface ResultState {
  text?: string
  status: "ok" | "warn" | "error"
}

const TokboxNetworkTest = () => {
  const dispatch = useAppDispatch()
  const intl = useIntl()
  const proxyUrl = useAppSelector(state => state.tokbox.proxyUrl)

  const [result, setResult] = useState<ResultState>()
  const [errors, setErrors] = useState<string[]>([])
  const [runningTest, setRunningTest] = useState(false)

  const newNetworkTest = (networkTestConnect: { token: string; sessionId: string; apiKey: string }) => {
    try {
      return new NetworkTest(
        OT as any,
        {
          apiKey: networkTestConnect.apiKey,
          sessionId: networkTestConnect.sessionId,
          token: networkTestConnect.token
        },
        { proxyServerUrl: proxyUrl }
      )
    } catch (error: any) {
      dispatch(handleErrors({ error }))
    }
  }

  const testConnectivity = async (otNetworkTest: NetworkTest) => {
    try {
      const results = await otNetworkTest.testConnectivity()

      console.log("OpenTok connectivity test results", results)

      if (results?.failedTests) {
        setErrors(e => [
          ...e,
          ...results.failedTests.map(failedTest => {
            switch (failedTest.error.name) {
              case ErrorNames.FAILED_TO_OBTAIN_MEDIA_DEVICES:
                return "networktest.error.devices.access"
              case ErrorNames.NO_AUDIO_CAPTURE_DEVICES:
                return "networktest.error.device.audio.unavailiable"
              case ErrorNames.NO_VIDEO_CAPTURE_DEVICES:
                return "networktest.error.device.video.unavailiable"
              default:
                return "networktest.error.unknown"
            }
          })
        ])
      }
      return results.success
    } catch (error: any) {
      dispatch(handleErrors({ error }))
    }
  }

  const testQuality = async (otNetworkTest: NetworkTest) => {
    try {
      const qualityResults = await otNetworkTest.testQuality(function updateCallback(stats) {
        console.log("intermediate testQuality stats", stats)
      })
      if (qualityResults) {
        console.log("OpenTok quality results", qualityResults)

        let message: ResultState = {
          text: intl.formatMessage({ id: "networktest.ok" }),
          status: "ok" as const
        }

        if (!qualityResults.video.supported) {
          message = {
            text: intl.formatMessage(
              {
                id: "networktest.device.video.notsupported"
              },
              {
                reason: qualityResults.video.reason
              }
            ),
            status: "warn" as const
          }
        } else {
          dispatch(
            updateTokboxPubOptions({
              frameRate: qualityResults.video.recommendedFrameRate,
              resolution: qualityResults.video.recommendedResolution
            })
          )
        }

        if (!qualityResults.audio.supported) {
          message = {
            text: intl.formatMessage(
              {
                id: "networktest.device.audio.notsupported"
              },
              {
                reason: qualityResults.audio.reason
              }
            ),
            status: "error" as const
          }
        }

        if (!qualityResults.video.supported && !qualityResults.audio.supported) {
          message = {
            text: intl.formatMessage(
              {
                id: "networktest.devices.notsupported"
              },
              {
                reason: `${qualityResults.audio.reason}, ${qualityResults.video.reason}`
              }
            ),
            status: "error" as const
          }
        }

        setResult(message)
      }
    } catch (error: any) {
      let errorMessage = "networktest.error.unknown"
      switch (error.name) {
        case ErrorNames.FAILED_TO_OBTAIN_MEDIA_DEVICES:
          errorMessage = "networktest.error.devices.access"
          break
        case ErrorNames.NO_AUDIO_CAPTURE_DEVICES:
          errorMessage = "networktest.error.device.audio.unavailiable"
          break
        case ErrorNames.NO_VIDEO_CAPTURE_DEVICES:
          errorMessage = "networktest.error.device.video.unavailiable"
          break
        default:
          break
      }
      setErrors(e => [...e, errorMessage])
    }
  }

  const initNetworkTest = async () => {
    setErrors([])
    setResult(undefined)
    setRunningTest(true)

    const networkTestConnect = await dispatch(tokboxNetworkTest())

    if (networkTestConnect) {
      let otNetworkTest = newNetworkTest(networkTestConnect)
      if (otNetworkTest) {
        const connectivityResults = await testConnectivity(otNetworkTest)
        if (connectivityResults) {
          await testQuality(otNetworkTest)
        }
      }
    }
    setRunningTest(false)
  }

  return (
    <>
      <div className={styles.networktestContainer}>
        <div className={styles.networktestButtonWrapper}>
          <Button
            variant="outline"
            color="primary"
            onPress={initNetworkTest}
            Icon={runningTest ? <Loader type="small" /> : undefined}
            fullWidth>
            {!runningTest ? intl.formatMessage({ id: "networktest.start" }) : undefined}
          </Button>
        </div>

        {errors.length > 0 && (
          <div className={classnames(styles.networktestErrors, styles.box)}>
            {errors.map((error: any, i) => (
              <StaffBodyTextM key={i}>{intl.formatMessage({ id: error })}</StaffBodyTextM>
            ))}
          </div>
        )}

        {result && (
          <StaffBodyTextL className={classnames(styles.box, styles[result.status])}>{result.text}</StaffBodyTextL>
        )}

        <StaffBodyTextM className={styles.description}>
          {intl.formatMessage({ id: "networktest.subtitle" })}
        </StaffBodyTextM>
      </div>
    </>
  )
}

export default TokboxNetworkTest
