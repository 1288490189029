import { searchPatients } from "api"
import { handleErrors } from "api/error/handler"
import { apiFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"
import { clearConversationsSearch, loadConversationsSearch } from "reducers/search/search.reducer"
import { SearchPageConversation } from "types"

export interface SearchConversationsBody {
  limit: number
  offset: number
  query?: string
  staff_id?: string
  clinic_id?: string
}

export interface ConversationSearchResponse {
  conversations: SearchPageConversation[]
  count: number
}

export const conversationsSearch =
  (body: SearchConversationsBody, more: boolean): Thunk =>
  dispatch => {
    dispatch(
      apiFetch(`/v3/conversations/search`, {
        method: "POST",
        body: JSON.stringify(body)
      })
    )
      .then((response: ConversationSearchResponse) => {
        dispatch(loadConversationsSearch({ data: response, more }))

        const patientParams = { query: body.query }
        if (!response.conversations.length && !body.staff_id) {
          dispatch(searchPatients(patientParams))
        }
      })
      .catch((error: any) => {
        dispatch(handleErrors({ error, api: "conversationsSearch", customContext: "/conversations/search?<params>" }))
        dispatch(clearConversationsSearch())
      })
  }
