import { Clinic, CustomerMetadata, MetaData } from "@doktor-se/bones-ui/dist/web-shared/types"

export const getConversationClinic = ({
  clinics,
  metadata
}: {
  clinics?: Clinic[]
  metadata: { platform?: MetaData; customer?: CustomerMetadata }
}) => {
  const metadataClinic = clinics?.find(clinic => clinic.id === metadata.platform?.clinic)
  const customerMetadataClinic = clinics?.find(clinic => clinic.id === metadata.customer?.listedAtClinicId)
  return metadataClinic || customerMetadataClinic
}
