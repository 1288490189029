import { NavigateFunction } from "react-router-dom"

import dayjs from "dayjs"

import {
  AssignedConversation,
  CallEndReason,
  Conversation,
  LanguageCode
} from "@doktor-se/bones-ui/dist/web-shared/types"
import { ApiError } from "@doktor-se/bones-ui/dist/web-shared/types"

import { apiEndCall, callEndError, setMetadataOnConversation } from "api"
import { generateCustomErrorLokaliseKey, handleErrors, handleErrorsV2 } from "api/error/handler"
import { amplitudeTrack } from "lib/amplitude/amplitude"
import { apiFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"
import { setIsReadyForAssignment } from "reducers/app"
import { selectCategoryById } from "reducers/categories"
import { removeClaimConversationTimeout, removeSelectedConversation, setConversation } from "reducers/selected"
import { QueueConversation, SearchPageConversation } from "types"

export const fetchCloseOptions =
  (categoryId: number | undefined): Thunk<Promise<any>> =>
  async dispatch => {
    return await dispatch(
      apiFetch({
        url: categoryId ? `/close_options?category=${categoryId}` : `/close_options`,
        config: {
          errorHandling: "v2"
        }
      })
    ).catch((error: any) =>
      dispatch(
        handleErrorsV2({
          error,
          customMessageLokaliseKey: generateCustomErrorLokaliseKey({
            errors: error.errors,
            customErrorCodes: ["error.not_acceptable"],
            lokaliseKeySuffix: "close_options"
          })
        })
      )
    )
  }

interface ApiCloseConversationParams {
  conversationId: string
  closeOptionId?: string
}
export const apiCloseConversation =
  ({ conversationId, closeOptionId }: ApiCloseConversationParams): Thunk<Promise<boolean>> =>
  dispatch =>
    dispatch(
      apiFetch({
        url: `/v2/conversations/${conversationId}/close`,
        incomingOptions: {
          loader: "CONTROLS",
          method: "POST",
          body: JSON.stringify({
            close_option_id: closeOptionId
          })
        },
        config: { errorHandling: "v2" }
      })
    ).catch((error: ApiError) => {
      dispatch(
        handleErrorsV2({
          error,
          customMessageLokaliseKey: generateCustomErrorLokaliseKey({
            errors: error.errors,
            customErrorCodes: ["error.entity.conflict", "error.not_found", "error.forbidden"],
            lokaliseKeySuffix: "conversation.close"
          })
        })
      )
    })

interface CloseConversationParams {
  conversation: SearchPageConversation | AssignedConversation
  closeOptionId?: string
  staffFirstName?: string
  reason?: CallEndReason
}
export const closeConversation =
  ({ conversation, closeOptionId, reason }: CloseConversationParams): Thunk =>
  async (dispatch, getState) => {
    if (reason) {
      try {
        await dispatch(apiEndCall(conversation.id, reason, conversation.account?.id))
      } catch (error) {
        return dispatch(callEndError(error))
      }
    }

    try {
      await dispatch(
        apiCloseConversation({
          conversationId: conversation.id,
          closeOptionId
        })
      )
      const category = selectCategoryById(getState(), conversation.categoryId)
      amplitudeTrack({
        type: "conversation.chat.closed_by_staff",
        eventProperties: { category_id: conversation.categoryId, category_name: category?.name }
      })
      dispatch(removeSelectedConversation())
    } catch (error) {
      return
    }
  }

export const apiSnooze =
  (conversationId: string, date: string): Thunk =>
  dispatch => {
    dispatch(
      apiFetch(`/conversations/${conversationId}/snooze`, {
        loader: "CONTROLS",
        method: "POST",
        body: JSON.stringify({ snooze: dayjs(date).format("YYYY-MM-DD HH:mm:ssZ") })
      })
    ).catch((error: any) =>
      dispatch(
        handleErrors({
          error,
          customContext: "/conversations/<conversationId>/snooze"
        })
      )
    )
  }

export const snooze =
  (conversation: AssignedConversation, date: string, reason?: CallEndReason): Thunk =>
  dispatch => {
    if (reason) {
      dispatch(apiEndCall(conversation.id, reason, conversation.account?.id))
        .then(() => dispatch(apiSnooze(conversation.id, date)))
        .catch((error: any) => dispatch(callEndError(error)))
    } else {
      dispatch(apiSnooze(conversation.id, date))
    }
  }

export const unSnooze =
  (conversationId: string): Thunk =>
  dispatch => {
    dispatch(
      apiFetch(`/conversations/${conversationId}/unsnooze`, {
        loader: "CONTROLS",
        method: "POST"
      })
    ).catch((error: any) =>
      dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>/unsnooze" }))
    )
  }

export const snoozeConversation =
  (conversation: AssignedConversation, date?: string, reason?: CallEndReason): Thunk =>
  dispatch => {
    if (date) {
      dispatch(snooze(conversation, date, reason))
    } else {
      dispatch(unSnooze(conversation.id))
    }
  }

export const apiReassignConversation =
  (conversationId: string, assignedId: string, postponedUntil?: string): Thunk<Promise<boolean>> =>
  dispatch =>
    dispatch(
      apiFetch(`/conversations/${conversationId}/reassign`, {
        loader: "CONTROLS",
        method: "PATCH",
        body: JSON.stringify({ staff_id: assignedId, postponed_until: postponedUntil })
      })
    ).catch((error: any) =>
      dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>/reassign" }))
    )

export const reassignConversation =
  ({
    conversation,
    assignedId,
    postponedUntil,
    reason
  }: {
    conversation: SearchPageConversation | AssignedConversation
    assignedId: string
    postponedUntil?: string
    reason?: CallEndReason
  }): Thunk =>
  dispatch => {
    if (reason) {
      dispatch(apiEndCall(conversation.id, reason, conversation.account?.id))
        .then(() => dispatch(apiReassignConversation(conversation.id, assignedId)))
        .catch((error: any) => dispatch(callEndError(error)))
    } else {
      dispatch(apiReassignConversation(conversation.id, assignedId, postponedUntil))
    }
  }

export const apiAssignToQueue =
  (conversationId: string, queue: string, postponed?: string, clinicId?: string): Thunk =>
  async dispatch => {
    try {
      await dispatch(
        apiFetch(`/conversations/${conversationId}/unassign`, {
          loader: "CONTROLS",
          method: "POST",
          body: JSON.stringify({
            queue,
            postponed_until: postponed,
            clinic_id: clinicId,
            is_digital: !clinicId
          })
        })
      )
      if (queue === "external_nurse") {
        dispatch(setMetadataOnConversation(conversationId, { preventReopen: true }))
      }
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/conversations/<conversationId>/unassign" }))
    }
  }

export const assignToQueue =
  (
    conversation: SearchPageConversation | AssignedConversation,
    queue: string,
    postponed?: string,
    reason?: CallEndReason,
    clinicId?: string
  ): Thunk =>
  async dispatch => {
    if (reason) {
      try {
        await dispatch(apiEndCall(conversation.id, reason, conversation.account?.id))
        dispatch(apiAssignToQueue(conversation.id, queue, postponed, clinicId))
      } catch (error: any) {
        dispatch(callEndError(error))
      }
    } else {
      dispatch(apiAssignToQueue(conversation.id, queue, postponed, clinicId))
    }
  }

export const makeBookedConversationActive = (conversationId: string) =>
  setMetadataOnConversation(conversationId, { isUpcomingBooking: false })

export const openBookedConversation =
  (conversationId: string): Thunk =>
  async dispatch => {
    try {
      await dispatch(
        apiFetch(`/conversations/${conversationId}/open_booked`, {
          method: "POST"
        })
      )
    } catch (error: any) {
      dispatch(handleErrors({ error, customContext: "/conversations/" }))
    }
  }

const claim =
  (
    requestedConversation: QueueConversation | SearchPageConversation,
    navigate?: NavigateFunction,
    staffId?: string
  ): Thunk<Promise<boolean>> =>
  dispatch => {
    let conversation = requestedConversation

    return dispatch(
      apiFetch(`/conversations/${conversation.id}/claim`, {
        loader: "CONTROLS",
        method: "PATCH",
        body: JSON.stringify({ staff_id: staffId })
      })
    ).then(() => {
      if (!staffId) {
        // Side effects after successfully claiming for self
        conversation && dispatch(setConversation(conversation.id))
        navigate && navigate("/")
        dispatch(setIsReadyForAssignment(true))
        dispatch(removeClaimConversationTimeout())
      }
      return true
    })
  }

const claimNext =
  (navigate: NavigateFunction, queue?: string): Thunk<Promise<boolean>> =>
  async dispatch => {
    const url = queue ? `/queues/${queue}/conversations/next` : `/queues/conversations/next`
    const response = await dispatch(
      apiFetch(url, {
        loader: "CONTROLS",
        method: "POST"
      })
    )
    const { conversationId } = response
    dispatch(setConversation(conversationId))
    navigate && navigate("/")
    dispatch(setIsReadyForAssignment(true))
    return true
  }

export const claimConversation =
  (conversation: QueueConversation, navigate: NavigateFunction): Thunk<Promise<boolean>> =>
  dispatch => {
    return dispatch(claim(conversation, navigate))
  }

export const claimForOther =
  (conversation: QueueConversation | SearchPageConversation, staffId: string): Thunk<Promise<boolean>> =>
  dispatch => {
    return dispatch(claim(conversation, undefined, staffId))
  }

export const claimFirstAvailableConversation =
  (navigate: NavigateFunction): Thunk<Promise<boolean>> =>
  dispatch => {
    return dispatch(claimNext(navigate))
  }

export const claimNextConversationFromQueue =
  (navigate: NavigateFunction, queue: string): Thunk<Promise<boolean>> =>
  dispatch =>
    dispatch(claimNext(navigate, queue))

export const reopenConversation =
  (conversationId: string, assignedStaffId?: string): Thunk =>
  dispatch => {
    dispatch(
      apiFetch(`/conversations/${conversationId}/reopen`, {
        loader: "CONTROLS",
        method: "POST",
        body: JSON.stringify({ assigned_staff_id: assignedStaffId })
      })
    ).catch((error: any) =>
      dispatch(
        handleErrors({
          error,
          customContext: "/conversations/<conversationId>/reopen"
        })
      )
    )
  }

interface CreateConversation {
  patientId?: string
  categoryId?: number
  staffId?: string
  profileId?: string
  clinicId?: string
  comment?: string
  templateId?: string | null
  language?: LanguageCode
  premiumHealth?: boolean
}

export const createConversation =
  ({
    patientId,
    categoryId,
    staffId,
    clinicId,
    comment,
    templateId,
    profileId,
    language,
    premiumHealth
  }: CreateConversation): Thunk<Promise<Conversation>> =>
  async dispatch =>
    await dispatch(
      apiFetch("/conversations", {
        loader: "CONTROLS",
        method: "POST",
        body: JSON.stringify({
          patient_id: patientId,
          category_id: categoryId,
          staff_id: staffId,
          clinic_id: clinicId,
          comment,
          template_id: templateId,
          profile_id: profileId,
          language,
          customer_metadata: {
            premium_health: premiumHealth
          }
        })
      })
    ).catch((error: any) => dispatch(handleErrors({ error })))
