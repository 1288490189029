import { useMemo } from "react"
import ReactCountryFlag from "react-country-flag"
import { useIntl } from "react-intl"

import dayjs from "dayjs"
import { AsYouType, isValidPhoneNumber } from "libphonenumber-js"

import { AppError } from "@doktor-se/bones-ui/dist/web-shared/classes"
import { useAccessRights } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { AssignedConversation, Profile } from "@doktor-se/bones-ui/dist/web-shared/types"
import {
  ageFromDate,
  ageFromDateWithMonths,
  ageFromPnr,
  countyCodeNames,
  formatPnr
} from "@doktor-se/bones-ui/dist/web-shared/utils"

import { featureFlags, getPatientInfoFields, webdocFeatureEnabled } from "config"
import { useAppSelector } from "lib/hooks"

import DateOfBirthRow from "./components/DateOfBirthRow"
import Header from "./components/Header"
import InsuranceSection from "./components/InsuranceSection"
import OpenPatientInTakeCare from "./components/OpenPatientInTakeCare/OpenPatientInTakeCare"
import OpenPatientInWebdoc from "./components/OpenPatientInWebdoc/OpenPatientInWebdoc"
import PatientInfoRow from "./components/PatientInfoRow/PatientInfoRow"
import PatientName from "./components/PatientName"
import Section from "./components/Section"
import Separator from "./components/Separator"

import styles from "./Details.module.scss"

interface DetailsProps {
  conversation: AssignedConversation
  prevConversationId?: string
  error: AppError
}

const petProfile: (keyof Profile["data"])[] = [
  "species",
  "breed",
  "sex",
  "dateOfBirth",
  "insuranceCompany",
  "insuranceNumber"
]

const Details = ({ conversation, prevConversationId, error }: DetailsProps) => {
  const intl = useIntl()
  const { account, profile, metadata } = conversation
  const fields = getPatientInfoFields()
  const hasAccessRights = useAccessRights(account?.accessRights, featureFlags.has("bypass_access_rights"))
  const hasProfileAccessRights = useAccessRights(account?.profileAccessRights, featureFlags.has("bypass_access_rights"))
  const defaultRole = useAppSelector(state => state.auth.defaultRole)

  const activePnr = useMemo(() => {
    if (!!profile) return profile.data.securityNumber
    return conversation.account.pnr
  }, [conversation.account.pnr, profile])

  const region = useMemo(
    () =>
      featureFlags.has("region") && metadata?.region ? (
        <PatientInfoRow
          key="region"
          label={intl.formatMessage({ id: "patient.info.region" })}
          data={intl.formatMessage({ id: `region.${metadata.region}` })}
        />
      ) : null,
    [metadata?.region, intl]
  )

  const language = useMemo(
    () =>
      featureFlags.has("language") && metadata?.language ? (
        <PatientInfoRow
          key="language"
          label={intl.formatMessage({ id: "patient.info.language" })}
          data={intl.formatMessage({ id: `language.${metadata.language}` })}
        />
      ) : null,
    [metadata?.language, intl]
  )

  const languages = useMemo(
    () =>
      hasAccessRights("languages", "read") && !!account?.data?.languages ? (
        <PatientInfoRow
          key="languages"
          label={intl.formatMessage({ id: "patient.info.languages.user" })}
          data={account.data?.languages?.reduce((acc, language, i) => {
            if (i === 0) return `${intl.formatMessage({ id: `language.${language}` })}`
            return `${acc}, ${intl.formatMessage({ id: `language.${language}` })}`
          }, "")}
        />
      ) : null,
    [account?.data?.languages, intl, hasAccessRights]
  )

  const county = useMemo(
    () =>
      metadata?.county ? (
        <PatientInfoRow
          key="county"
          label={intl.formatMessage({ id: "patient.info.county" })}
          data={countyCodeNames[metadata.county]}
        />
      ) : null,
    [metadata?.county, intl]
  )

  const organisation = useMemo(
    () =>
      featureFlags.has("patient_organization") && metadata?.organizationId ? (
        <PatientInfoRow
          key="organisation"
          label={intl.formatMessage({ id: "patient.info.type" })}
          data={intl.formatMessage({ id: "label.patient.organisation.b2b" })}
        />
      ) : null,
    [metadata?.organizationId, intl]
  )

  const insurance = useMemo(() => {
    const insuranceInfo = account?.data?.insuranceInfo
    return insuranceInfo
      ? hasAccessRights("insurance_info", "read") && <InsuranceSection key="insurance" info={insuranceInfo} />
      : null
  }, [account?.data?.insuranceInfo, hasAccessRights])

  const profileInsurance = useMemo(() => {
    const insuranceInfo = profile?.data?.insurance || profile?.data?.insuranceInfo
    return hasProfileAccessRights("insurance_info", "read") && insuranceInfo ? (
      <InsuranceSection key="profile.insurance" info={insuranceInfo} />
    ) : null
  }, [profile?.data?.insurance, profile?.data?.insuranceInfo, hasProfileAccessRights])

  const countryCode = useMemo(() => {
    if (account?.phone && isValidPhoneNumber(account.phone)) {
      const asYouType = new AsYouType()
      asYouType.input(account.phone)
      return asYouType.getCountry()
    }
  }, [account?.phone])

  if (!account) return null

  const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
  const takeCareIntegrationEnabled = featureFlags.has("takecare")

  return (
    <Section>
      <PatientName
        error={error}
        conversation={conversation}
        displayName={account.displayName}
        prevConversationId={prevConversationId}
        profile={profile}
      />

      {webdocFeatureEnabled(defaultRole) && activePnr && <OpenPatientInWebdoc patientPnr={activePnr} />}

      {takeCareIntegrationEnabled && activePnr && <OpenPatientInTakeCare patientPnr={activePnr} />}

      {(!!profile ? fields.profile : fields.user)?.map((field, i) => {
        switch (field) {
          case "region":
            return region
          case "language":
            return language
          case "languages":
            return languages
          case "pnr":
            return (
              hasAccessRights("pnr", "read") && (
                <PatientInfoRow key={field} label={intl.formatMessage({ id: "patient.info.pnr" })} data={account.pnr} />
              )
            )
          case "profile.id":
            return (
              hasProfileAccessRights("id", "read") && (
                <PatientInfoRow
                  key={field}
                  label={intl.formatMessage({ id: "patient.info.profile.id" })}
                  data={profile?.id}
                />
              )
            )
          case "pnrAge":
            return (
              hasAccessRights("pnr", "read") && (
                <PatientInfoRow
                  key={field}
                  label={intl.formatMessage({ id: "patient.info.pnr" })}
                  data={
                    !!account.data?.dateOfBirth
                      ? intl.formatMessage(
                          { id: "age.years" },
                          {
                            date: formatPnr(account.pnr, account.region),
                            age: ageFromDate(account.data?.dateOfBirth)
                          }
                        )
                      : !account.data?.dateOfBirth && account.region === "se"
                        ? intl.formatMessage(
                            { id: "age.years" },
                            {
                              date: formatPnr(account.pnr, account.region),
                              age: ageFromPnr(account.pnr)
                            }
                          )
                        : account.pnr ?? "-"
                  }
                  copyData={formatPnr(account.pnr, account.region)}
                />
              )
            )
          case "age":
            return (
              hasAccessRights("age", "read") && (
                <PatientInfoRow
                  key={field}
                  label={intl.formatMessage({ id: "patient.info.age" })}
                  data={!!account.data?.dateOfBirth ? ageFromDate(account.data.dateOfBirth).toString() : undefined}
                />
              )
            )
          case "profile.pnrAge":
            return (
              hasProfileAccessRights("security_number", "read") && (
                <PatientInfoRow
                  key={field}
                  label={intl.formatMessage({ id: "patient.info.pnr" })}
                  data={
                    intl.formatMessage(
                      { id: "age.years" },
                      {
                        date: formatPnr(profile?.data.securityNumber, account.region),
                        age: ageFromDate(profile?.data.dateOfBirth)
                      }
                    ) as string
                  }
                  copyData={formatPnr(profile?.data.securityNumber, account.region)}
                />
              )
            )
          case "phone":
            return (
              hasAccessRights("phone", "read") && (
                <PatientInfoRow
                  key={field}
                  label={intl.formatMessage({ id: "patient.info.phone" })}
                  data={account.phone}
                  icon={
                    countryCode && <ReactCountryFlag className={styles.countryFlag} countryCode={countryCode} svg />
                  }
                />
              )
            )
          case "email":
            return (
              hasAccessRights("email", "read") && (
                <PatientInfoRow
                  key={field}
                  label={intl.formatMessage({ id: "patient.info.email" })}
                  data={account.email}
                />
              )
            )
          case "county":
            return county
          case "organisation":
            return organisation
          case "eid":
            return (
              hasAccessRights("eid", "read") && (
                <PatientInfoRow
                  key={field}
                  label={intl.formatMessage({ id: "patient.info.eid" })}
                  data={account.data?.eid}
                />
              )
            )
          case "sex":
            return (
              hasAccessRights("sex", "read") && (
                <PatientInfoRow
                  key={field}
                  label={intl.formatMessage({ id: "patient.info.sex" })}
                  data={account.data?.sex ? intl.formatMessage({ id: `sex.${account.data.sex}` }) : undefined}
                />
              )
            )
          case "profile.sex":
            return (
              hasProfileAccessRights("sex", "read") && (
                <PatientInfoRow
                  key={field}
                  label={intl.formatMessage({ id: "patient.info.sex" })}
                  data={profile?.data?.sex ? intl.formatMessage({ id: `sex.${profile?.data.sex}` }) : undefined}
                />
              )
            )
          case "dateOfBirth":
            return (
              hasAccessRights("date_of_birth", "read") && (
                <DateOfBirthRow key={field} dateOfBirth={account.data?.dateOfBirth} />
              )
            )

          case "profile.dateOfBirth":
            return (
              hasProfileAccessRights("date_of_birth", "read") && (
                <DateOfBirthRow key={field} dateOfBirth={profile?.data?.dateOfBirth} />
              )
            )
          case "address":
            return (
              hasAccessRights("address", "read") && (
                <PatientInfoRow
                  key={field}
                  label={intl.formatMessage({ id: "patient.info.address" })}
                  data={
                    account.address
                      ? `${hasAccessRights("address", "read") ? account.address + "\n" : ""}${
                          hasAccessRights("care_of", "read") && account.careOf ? account.careOf + "\n" : ""
                        }${hasAccessRights("zip", "read") ? account.zip + "\n" : ""}${
                          hasAccessRights("city", "read") ? account.city : ""
                        }`
                      : undefined
                  }
                />
              )
            )
          case "profile.address":
            return (
              hasProfileAccessRights("address", "read") && (
                <PatientInfoRow
                  key={field}
                  label={intl.formatMessage({ id: "patient.info.address" })}
                  data={
                    profile?.address
                      ? `${hasProfileAccessRights("address", "read") ? profile.address + "\n" : ""}${
                          hasProfileAccessRights("zip", "read") ? profile.zip + "\n" : ""
                        }${hasProfileAccessRights("city", "read") ? profile.city : ""}`
                      : undefined
                  }
                />
              )
            )
          case "insurance":
            return insurance
          case "profile.insurance":
            return profileInsurance
          case "header.personal":
            return <Header key={field} text={intl.formatMessage({ id: "patient.info.header.personal" })} />
          case "profile.header.personal":
            return <Header key={field} text={intl.formatMessage({ id: "patient.info.profile.header.personal" })} />
          case "parent.header.personal":
            return <Header key={field} text={intl.formatMessage({ id: "patient.info.parent.header.personal" })} />
          case "header.contact":
            return <Header key={field} text={intl.formatMessage({ id: "patient.info.header.contact" })} />
          case "header.insurance":
            return <Header key={field} text={intl.formatMessage({ id: "patient.info.header.insurance" })} />
          case "profile.header.insurance":
            return <Header key={field} text={intl.formatMessage({ id: "patient.info.profile.header.insurance" })} />
          case "name":
            return (
              hasAccessRights("display_name", "read") && (
                <PatientInfoRow
                  key={field}
                  label={intl.formatMessage({ id: "patient.info.title" })}
                  data={account.displayName}
                />
              )
            )
          case "separator":
            return <Separator key={i} />
          case "profile.pet":
            return petProfile.map(profileField => {
              let data = hasProfileAccessRights(camelToSnakeCase(profileField), "read")
                ? profile?.data[profileField]
                : ""
              let copyData: string | undefined
              if (data && typeof data === "string") {
                switch (profileField) {
                  case "species":
                    data = intl.formatMessage({ id: `species.${data}` })
                    break
                  case "sex":
                    data = intl.formatMessage({ id: `sex.${data}` })
                    break
                  case "insuranceCompany":
                    if (["none", "other"].includes(data)) {
                      data = intl.formatMessage({ id: `label.insurance.${data}` })
                    }
                    break
                  case "dateOfBirth":
                    const date = intl.formatDate(dayjs(data).format(), {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit"
                    })
                    const [months, years] = ageFromDateWithMonths(data)
                    let dataToDisplay = date
                    if (typeof years === "number" && years < 1) {
                      if (typeof months === "number" && months === 1) {
                        dataToDisplay = intl.formatMessage({ id: "age.month" }, { date })
                      } else if (typeof months === "number" && months < 1) {
                        dataToDisplay = intl.formatMessage({ id: "age.under.month" }, { date })
                      } else {
                        dataToDisplay = intl.formatMessage({ id: "age.months" }, { date, age: months })
                      }
                    } else if (typeof years === "number" && years === 1) {
                      dataToDisplay = intl.formatMessage({ id: "age.year" }, { date })
                    } else if (years) {
                      dataToDisplay = intl.formatMessage({ id: "age.years" }, { date, age: years })
                    }
                    data = dataToDisplay
                    copyData = date
                    break
                  default:
                    break
                }

                return (
                  hasProfileAccessRights(camelToSnakeCase(profileField), "read") && (
                    <PatientInfoRow
                      key={profileField}
                      label={intl.formatMessage({ id: `patient.info.${profileField}` })}
                      data={data}
                      copyData={copyData}
                    />
                  )
                )
              }
              return null
            })
          default:
            return null
        }
      })}
    </Section>
  )
}

export default Details
