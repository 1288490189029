import { useIntl } from "react-intl"

import { ICONS, StaffBodyTextS, StaffHeading5 } from "@doktor-se/bones-ui"

import styles from "./HideVideo.module.scss"

const HideVideo = () => {
  const intl = useIntl()

  return (
    <div className={styles.container}>
      <div>
        <ICONS.VisibilityOff className={styles.icon} />
        <StaffHeading5 margin={"var(--size-100) 0"}>
          {intl.formatMessage({ id: "app.call.hide.patient.video.title" })}
        </StaffHeading5>
        <StaffBodyTextS>{intl.formatMessage({ id: "app.call.hide.patient.video.body" })}</StaffBodyTextS>
      </div>
    </div>
  )
}

export default HideVideo
