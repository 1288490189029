import { FlaggedError } from "@doktor-se/bones-ui/dist/web-shared/classes"
import { ApiError, User } from "@doktor-se/bones-ui/dist/web-shared/types"
import { datadog } from "@doktor-se/more-rum-please"

import { platform } from "config"
import { IncomingError } from "reducers/error"

import { expectedErrors } from "./expectedErrors"

export const ErrorEventHandler = (error: IncomingError | ApiError, customContext?: string, user?: User) => {
  if (import.meta.env.VITE_APP_LOG_ERROR) console.log(error)
  if (!expectedErrors.some(e => (("details" in error && error.details?.code) || "").includes(e))) {
    datadog.setUser({
      id: user?.id
    })
    if (error instanceof Error) {
      datadog.addError(error, {
        context: ["ApiError", "CustomError"].includes(error.name)
          ? customContext || error.context || error.url || undefined
          : customContext,
        metadata: {
          status: error.status,
          url: error.url,
          details: error.details,
          platform: platform
        }
      })
    } else {
      datadog.addError(new Error("Unknown error"), {
        context: customContext,
        metadata: {
          details: { error },
          platform: platform
        }
      })
    }
    datadog.clearUser()
  }
}

export const flagConversation = (
  conversationId: string,
  patientId: string,
  user: User,
  flagBody: string,
  flagSubject: string
) => {
  datadog.setUser({
    id: user.id
  })
  datadog.addError(new FlaggedError(), {
    conversationId,
    patientId,
    metadata: {
      flagBody,
      flagSubject,
      platform
    }
  })
  datadog.clearUser()
}

export const flagCall = (patientId: string, user: User, flagBody: string, flagSubject: string) => {
  datadog.setUser({
    id: user.id
  })
  datadog.addError(new FlaggedError(), {
    patientId,
    metadata: {
      flagBody,
      flagSubject,
      platform
    }
  })
  datadog.clearUser()
}
