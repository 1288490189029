import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { unauthorize } from "reducers/auth"

export type EncryptionState = {
  encryptionEnabled: boolean
  sealdSDKInitialisationStatus: boolean
  userSealdId: string | undefined
  browserSwitch: BrowserSwitchInfo
  sealdRevoked: boolean
}
export interface BrowserSwitchInfo {
  browserSwitch: boolean
  alreadyRegistered: boolean
}

const initialState: EncryptionState = {
  encryptionEnabled: false,
  sealdSDKInitialisationStatus: false,
  userSealdId: undefined,
  sealdRevoked: false,
  browserSwitch: {
    browserSwitch: false,
    alreadyRegistered: false
  }
}

const encryption = createSlice({
  name: "encryption",
  initialState,
  reducers: {
    setEncryptionStatus(state, action: PayloadAction<boolean>) {
      state.encryptionEnabled = action.payload
    },
    setSealdSDKInitialisationStatus(state, action: PayloadAction<{ success: boolean }>) {
      state.sealdSDKInitialisationStatus = action.payload.success
    },
    setUserSealdId(state, action: PayloadAction<{ sealdId: string | undefined }>) {
      state.userSealdId = action.payload.sealdId
    },
    setBrowserSwitch(state, action: PayloadAction<BrowserSwitchInfo>) {
      state.browserSwitch = action.payload
    },
    setSealdRevoked(state, action: PayloadAction<boolean>) {
      state.sealdRevoked = action.payload
    }
  },
  extraReducers: builder =>
    builder.addCase(unauthorize, state => {
      state.encryptionEnabled = false
      state.sealdSDKInitialisationStatus = false
    })
})

export const {
  setSealdSDKInitialisationStatus,
  setUserSealdId,
  setBrowserSwitch,
  setEncryptionStatus,
  setSealdRevoked
} = encryption.actions

export type EncryptionSliceAction = ObjectFunctionReturnTypes<typeof encryption.actions>

export default encryption.reducer
