import { useIntl } from "react-intl"

import dayjs from "dayjs"

import { InsuranceInfo } from "@doktor-se/bones-ui/dist/web-shared/types"

import PatientInfoRow from "./PatientInfoRow/PatientInfoRow"

import styles from "./InsuranceSection.module.scss"

type InsuranceSectionProps = {
  info: InsuranceInfo
}

interface TranslationKeys {
  [key: string]: string
}

const translationKeys: TranslationKeys = {
  member: "insurance_status.member",
  familymember: "insurance_status.family_member",
  pensioner: "insurance_status.pensioner",
  public: "insurance_type.public",
  private: "insurance_type.private",
  self_payer: "insurance_type.self_payer"
}

const getTranslationKey = (translationKeys: TranslationKeys, key: string) => translationKeys[key] || key

const InsuranceSection = ({
  info: { companyName, insuranceNumber, companyId, insuranceEndDate, insuranceStatus, insuranceType }
}: InsuranceSectionProps) => {
  const intl = useIntl()

  return (
    <div className={styles.container}>
      {companyName && (
        <PatientInfoRow label={intl.formatMessage({ id: "patient.info.insurance.company" })} data={companyName} />
      )}
      {insuranceType && (
        <PatientInfoRow
          label={intl.formatMessage({ id: "patient.info.insurance.type" })}
          data={intl.formatMessage({ id: getTranslationKey(translationKeys, insuranceType) })}
        />
      )}
      {insuranceNumber && (
        <PatientInfoRow label={intl.formatMessage({ id: "patient.info.insurance.number" })} data={insuranceNumber} />
      )}
      {companyId && <PatientInfoRow label={intl.formatMessage({ id: "patient.info.insurance.id" })} data={companyId} />}
      {insuranceEndDate && (
        <PatientInfoRow
          label={intl.formatMessage({ id: "patient.info.insurance.end" })}
          data={intl.formatDate(dayjs(insuranceEndDate).format(), {
            day: "2-digit",
            month: "2-digit",
            year: "numeric"
          })}
        />
      )}
      {insuranceStatus && (
        <PatientInfoRow
          label={intl.formatMessage({ id: "patient.info.insurance.status" })}
          data={intl.formatMessage({ id: getTranslationKey(translationKeys, insuranceStatus) })}
        />
      )}
    </div>
  )
}

export default InsuranceSection
