import { useMemo } from "react"
import { useIntl } from "react-intl"

import { Dialog, StaffBodyTextXS, StaffHeading5 } from "@doktor-se/bones-ui"
import { DashedTitle, SymptomBubble } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Category, GroupedCategories } from "@doktor-se/bones-ui/dist/web-shared/types"

import { appStatic } from "config"
import { groupCategories } from "lib/format"
import { useAppSelector, useSubcategories } from "lib/hooks"
import { selectCategoriesPerRole } from "reducers/categories"

import styles from "./CategoriesModal.module.scss"

interface CategoriesProps {
  isOpen: boolean
  closeModal: () => void
  updateCategory: (categoryId: Category["id"]) => void
  selectedCategory?: Category
}

const CategoriesModal = ({ isOpen, closeModal, updateCategory, selectedCategory }: CategoriesProps): JSX.Element => {
  const intl = useIntl()
  const categories = useAppSelector(selectCategoriesPerRole)
  const filteredCategories = categories.filter(c => selectedCategory?.id !== c.id)
  const subcategories = useSubcategories(filteredCategories)
  const mainCategories: GroupedCategories[] = useMemo(() => {
    let filtered = filteredCategories
    if (selectedCategory) {
      const premiumHealth = !!selectedCategory.metadata.conversationMetaInit?.premiumHealth
      filtered = filtered.filter(c => !!c.metadata.conversationMetaInit?.premiumHealth === premiumHealth)
    }

    return groupCategories(
      filtered.filter(category => !category.parentId && !Object.keys(subcategories).includes(category.id.toString()))
    )
  }, [filteredCategories, selectedCategory, subcategories])

  const categoryOnClick = (category: Category) => {
    updateCategory(category.id)
    closeModal()
  }

  const categorySymptomBubbleStyle = (category: Category) => {
    if (!!category.metadata.conversationMetaInit?.premiumHealth) {
      return styles.premiumHealth
    }
    if (!!category.metadata.conversationMetaInit?.revisit) {
      return styles.revisit
    }
    return undefined
  }

  const categorySection = (section: GroupedCategories[], subsection?: boolean) =>
    section.map((category: GroupedCategories, i: number) => (
      <div key={i}>
        {category.header && (
          <DashedTitle
            title={category.header}
            color={subsection ? "primary10" : "surface"}
            bold
            className={styles.subCategoryHeader}
          />
        )}
        <ul className={styles.categoryList}>
          {category.categories.map(category => (
            <li key={category.id} className={styles.category}>
              <button className={styles.symptomButton} onClick={() => categoryOnClick(category)}>
                <SymptomBubble
                  className={categorySymptomBubbleStyle(category)}
                  symptom={category}
                  appStatic={appStatic}
                  cssFilter={categorySymptomBubbleStyle(category) && "brightness(0);"}
                />
                <StaffBodyTextXS className={styles.categoryName} margin={{ top: "4px" }}>
                  {category.name}
                </StaffBodyTextXS>
              </button>
            </li>
          ))}
        </ul>
      </div>
    ))

  return (
    <Dialog
      isOpen={isOpen}
      closeAriaLabel={intl.formatMessage({ id: "modal.close" })}
      onClose={() => closeModal()}
      offset={{ left: "-25%" }}>
      <div className={styles.container}>
        <div>{categorySection(mainCategories)}</div>
        {Object.keys(subcategories).map(id => (
          <div className={styles.subSection} key={id}>
            <div className={styles.subSectionTitle}>
              <img
                className={styles.icon}
                src={`${import.meta.env.VITE_APP_APP_STATIC}/${
                  categories.find(category => category.id.toString() === id)!.image
                }.png`}
                alt={id}
              />
              <StaffHeading5 margin="auto 0 auto 10px" className={styles.header}>
                {categories.find(category => category.id.toString() === id)!.name}
              </StaffHeading5>
            </div>
            <div>{categorySection(subcategories[parseInt(id)], true)}</div>
          </div>
        ))}
      </div>
    </Dialog>
  )
}

export default CategoriesModal
