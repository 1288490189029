import { useIntl } from "react-intl"

import { Button, StaffHeading3 } from "@doktor-se/bones-ui"

import styles from "./BlockedToQueue.module.scss"

interface BlockedToQueueProps {
  message: string
  onConfirm: () => void
}

const BlockedToQueue = ({ message, onConfirm }: BlockedToQueueProps) => {
  const intl = useIntl()

  return (
    <>
      <StaffHeading3 margin={{ bottom: "var(--size-200)" }}>{message}</StaffHeading3>
      <div className={styles.buttonContainer}>
        <Button color="primary" variant="primary" onPress={onConfirm} fullWidth>
          {intl.formatMessage({ id: "btn.okay" })}
        </Button>
      </div>
    </>
  )
}

export default BlockedToQueue
