import { useState } from "react"
import { useIntl } from "react-intl"

import { Dialog, ICONS } from "@doktor-se/bones-ui"
import { AssignedConversation, PaymentRequired } from "@doktor-se/bones-ui/dist/web-shared/types"

import { closeConversation, setMetadataOnConversation } from "api"
import { closeOptionsFeature, featureFlags } from "config"
import { checkDeclineCall } from "lib/conversations"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { SearchPageConversation } from "types"

import CloseOptionsContent from "./components/CloseOptionsContent/CloseOptionsContent"
import ConfirmCloseContent from "./components/ConfirmCloseContent/ConfirmCloseContent"
import PaymentContent from "./components/PaymentConent/PaymentContent"
import { Offset } from "pages/dashboard/components/ConversationControls/ConversationControls"

import styles from "./CloseDialog.module.scss"

type DialogState = "payment" | "closeOptions" | "confirm"

interface CloseDialogProps {
  isOpen: boolean
  onClose: () => void
  conversation: SearchPageConversation | AssignedConversation
  offset?: Offset
}
const CloseDialog = ({ isOpen, onClose, conversation, offset }: CloseDialogProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()

  const defaultRole = useAppSelector(state => state.auth.defaultRole)

  const createdByStaff = conversation.createdById !== conversation.patientId

  const hasPaymentTag =
    featureFlags.has("close_tag") &&
    !!conversation.metadata?.internal &&
    createdByStaff &&
    !conversation.metadata?.paymentRequired

  const hasCloseOptions = closeOptionsFeature(defaultRole) && !conversation.closeOptionId

  const isCallActive = conversation.callState !== "inactive"

  let initialState: DialogState = "confirm"
  if (hasPaymentTag) initialState = "payment"
  else if (hasCloseOptions) initialState = "closeOptions"

  const [dialogState, setDialogState] = useState<DialogState>(initialState)
  const [paymentTag, setPaymentTag] = useState<PaymentRequired>()
  const [closeOptionId, setCloseOptionId] = useState<string>("")

  const onCloseDialog = () => {
    setPaymentTag(undefined)
    setCloseOptionId("")
    setDialogState(initialState)
    onClose()
  }

  const onConfirm = () => {
    const reason = checkDeclineCall(conversation)
    const metadata = { closingStaffRole: defaultRole, ...(paymentTag && { paymentRequired: paymentTag }) }
    dispatch(setMetadataOnConversation(conversation.id, metadata))
    dispatch(
      closeConversation({
        conversation,
        closeOptionId: closeOptionId || undefined,
        reason
      })
    )
    onCloseDialog()
  }

  return (
    <Dialog
      isOpen={isOpen}
      fixedWidth
      offset={offset && { top: `${offset.top}px`, left: `${offset.left}px` }}
      onClose={onCloseDialog}
      closeAriaLabel={intl.formatMessage({ id: "modal.close" })}>
      <ICONS.ErrorCircle className={styles.icon} />

      {dialogState === "payment" && (
        <PaymentContent
          conversation={conversation}
          paymentTag={paymentTag}
          setPaymentTag={setPaymentTag}
          onConfirm={() => setDialogState(hasCloseOptions ? "closeOptions" : "confirm")}
        />
      )}
      {dialogState === "closeOptions" && (
        <CloseOptionsContent
          categoryId={conversation.categoryId}
          closeOptionId={closeOptionId}
          setCloseOptionId={setCloseOptionId}
          onConfirm={() => setDialogState("confirm")}
        />
      )}
      {dialogState === "confirm" && <ConfirmCloseContent onConfirm={onConfirm} isCallActive={isCallActive} />}
    </Dialog>
  )
}

export default CloseDialog
