import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit"
import dayjs from "dayjs"

import { Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

import { featureFlags } from "config"
import { unauthorize } from "reducers/auth"
import { removePostpone, updateConversationState } from "reducers/conversations/conversations.reducer"
import { RootState } from "reducers/init"
import { QueueConversation, QueueOutline } from "types"

import { addConversations, filterPersonalQueue, filterSelectedQueue, transformConversation } from "./filter"
import { sortQueue } from "./sort"

interface QueueCount {
  key: string
  conversationCount: number
  postponedConversationCount: number
}

export interface QueueState {
  queues: EntityState<Queue, string>
  queueCount: EntityState<QueueCount, string>
  personalQueueKeys?: string[]
  selectedQueueKey?: string
  personalQueue: EntityState<QueueConversation, string>
  selectedQueue: EntityState<QueueConversation, string>
  selectedQueueOutline: EntityState<QueueOutline, string>
}

const queueAdapter = createEntityAdapter<Queue, string>({ selectId: queue => queue.queueKey })
const queueCountAdapter = createEntityAdapter<QueueCount, string>({ selectId: queue => queue.key })
const personalQueueAdapter = createEntityAdapter<QueueConversation, string>({
  selectId: conversation => conversation.id
})
const selectedQueueAdapter = createEntityAdapter<QueueConversation, string>({
  selectId: conversation => conversation.id
})
const selectedQueueOutlineAdapter = createEntityAdapter<QueueOutline, string>({
  selectId: conversation => conversation.id
})

const initialState: QueueState = {
  queues: queueAdapter.getInitialState(),
  queueCount: queueCountAdapter.getInitialState(),
  personalQueue: personalQueueAdapter.getInitialState(),
  selectedQueue: selectedQueueAdapter.getInitialState(),
  selectedQueueOutline: selectedQueueOutlineAdapter.getInitialState()
}

const isVisibleInQueue = (selectedQueue: EntityState<QueueConversation, string>, conversation: QueueConversation) => {
  const selectedConversationsList = selectedQueueAdapter.getSelectors().selectAll(selectedQueue)
  const { active, postponed } = filterSelectedQueue(sortQueue(selectedConversationsList))
  let lastIndex
  if (conversation.postponedUntil && dayjs(conversation.postponedUntil) > dayjs()) {
    lastIndex = postponed.findLastIndex(
      item => dayjs(conversation.postponedUntil).valueOf() > dayjs(item.postponedUntil).valueOf()
    )
    return lastIndex <= postponed.length - 1
  } else {
    lastIndex = active.findLastIndex(item => conversation.queuePriority > item.queuePriority)
    return lastIndex <= active.length - 1
  }
}

const queue = createSlice({
  name: "queue",
  initialState,
  reducers: {
    loadQueues(state, action: PayloadAction<Queue[]>) {
      queueAdapter.setAll(state.queues, action.payload)
    },
    addQueueCount(
      state,
      action: PayloadAction<{ key: string; conversationCount: number; postponedConversationCount: number }[]>
    ) {
      queueCountAdapter.setAll(state.queueCount, action.payload)
    },
    updateQueueCount(
      state,
      action: PayloadAction<{ key: string; conversationCount?: -1 | 1; postponedConversationCount?: -1 | 1 }>
    ) {
      const currentCount = state.queueCount.entities[action.payload.key]
      queueCountAdapter.updateOne(state.queueCount, {
        id: action.payload.key,
        changes: {
          conversationCount: Math.max(
            0,
            (currentCount?.conversationCount || 0) + (action.payload.conversationCount || 0)
          ),
          postponedConversationCount: Math.max(
            0,
            (currentCount?.postponedConversationCount || 0) + (action.payload.postponedConversationCount || 0)
          )
        }
      })
    },
    loadPersonalQueue(state, action: PayloadAction<{ queues: string[]; conversations: QueueConversation[] }>) {
      state.personalQueueKeys = action.payload.queues
      personalQueueAdapter.setAll(state.personalQueue, addConversations(action.payload.conversations))
    },
    loadSelectedQueue(state, action: PayloadAction<{ queue: string; conversations: QueueConversation[] }>) {
      state.selectedQueueKey = action.payload.queue
      selectedQueueAdapter.setAll(state.selectedQueue, addConversations(action.payload.conversations))
    },
    updateSelectedQueue(state, action: PayloadAction<{ queue: string; conversations: QueueConversation[] }>) {
      selectedQueueAdapter.addMany(state.selectedQueue, addConversations(action.payload.conversations))
    },
    loadQueueConversation(state, action: PayloadAction<{ conversation: QueueConversation }>) {
      const { conversation } = action.payload
      personalQueueAdapter.removeOne(state.personalQueue, conversation.id)
      selectedQueueAdapter.removeOne(state.selectedQueue, conversation.id)

      const transformedConversation = transformConversation(conversation)
      if (!transformedConversation.queue) return

      if (state.personalQueueKeys?.includes(transformedConversation.queue)) {
        personalQueueAdapter.addOne(state.personalQueue, transformedConversation)
      }
      if (state.selectedQueueKey === transformedConversation.queue) {
        if (featureFlags.has("queue_pagination")) {
          if (isVisibleInQueue(state.selectedQueue, conversation)) {
            selectedQueueAdapter.addOne(state.selectedQueue, transformedConversation)
          }
        } else {
          selectedQueueAdapter.addOne(state.selectedQueue, transformedConversation)
        }
      }
    },
    removeFromQueue(state, action: PayloadAction<string>) {
      personalQueueAdapter.removeOne(state.personalQueue, action.payload)
      selectedQueueAdapter.removeOne(state.selectedQueue, action.payload)
    },
    addConversationsOutline(state, action: PayloadAction<{ queue: string; conversations: QueueOutline[] }>) {
      state.selectedQueueKey = action.payload.queue
      selectedQueueOutlineAdapter.setAll(state.selectedQueueOutline, action.payload.conversations)
    },
    addToConversationsOutline(state, action: PayloadAction<{ conversation: QueueOutline }>) {
      selectedQueueOutlineAdapter.addOne(state.selectedQueueOutline, action.payload.conversation)
    },
    removeFromQueueOutline(state, action: PayloadAction<string>) {
      selectedQueueOutlineAdapter.removeOne(state.personalQueue, action.payload)
    }
  },

  extraReducers: builder =>
    builder
      .addCase(updateConversationState, (state, action) => {
        if (!action.payload.conversationId) return

        const update = {
          id: action.payload.conversationId,
          changes: { state: action.payload.data.state }
        }

        personalQueueAdapter.updateOne(state.personalQueue, update)
        selectedQueueAdapter.updateOne(state.selectedQueue, update)
      })
      .addCase(removePostpone, (state, action) => {
        personalQueueAdapter.updateOne(state.personalQueue, {
          id: action.payload,
          changes: { postponedUntil: undefined }
        })
        selectedQueueAdapter.updateOne(state.selectedQueue, {
          id: action.payload,
          changes: { postponedUntil: undefined }
        })
      })
      .addCase(unauthorize, state => {
        personalQueueAdapter.removeAll(state.personalQueue)
        selectedQueueAdapter.removeAll(state.selectedQueue)
        state.personalQueueKeys = undefined
      })
})

export const queueSelectors = queueAdapter.getSelectors<RootState>(state => state.queue.queues)

export const queueCountSelectors = queueCountAdapter.getSelectors<RootState>(state => state.queue.queueCount)

export const {
  loadQueues,
  addQueueCount,
  updateQueueCount,
  loadPersonalQueue,
  loadSelectedQueue,
  updateSelectedQueue,
  loadQueueConversation,
  removeFromQueue,
  addConversationsOutline,
  addToConversationsOutline,
  removeFromQueueOutline
} = queue.actions

export type QueueSliceAction = ObjectFunctionReturnTypes<typeof queue.actions>

export const selectPersonalQueue = createSelector(
  (state: RootState) => state.queue,
  (state: RootState) => state.auth.user,
  (queue, user) => {
    const open = personalQueueAdapter
      .getSelectors()
      .selectAll(queue.personalQueue)
      .filter(c => c.state === "opened")

    return filterPersonalQueue(sortQueue(open), user)
  }
)

const filterOutBeforeToday4PM = ({ queuePriority }: QueueConversation) => {
  const time = dayjs(queuePriority)

  const today4PM = dayjs().hour(16).minute(0).second(0).millisecond(0)

  return time.isBefore(today4PM)
}

export const selectContactMyClinicQueue = createSelector(
  (state: RootState) => state.queue,
  queue => {
    const open = personalQueueAdapter
      .getSelectors()
      .selectAll(queue.personalQueue)
      .filter(c => c.queue === "contact_my_clinic")
      .filter(c => c.state === "opened")
      .filter(filterOutBeforeToday4PM)

    return filterSelectedQueue(open).active
  }
)

export const selectSelectedQueue = createSelector(
  (state: RootState) => state.queue,
  queue => {
    const open = selectedQueueAdapter
      .getSelectors()
      .selectAll(queue.selectedQueue)
      .filter(c => c.state === "opened")

    return filterSelectedQueue(sortQueue(open))
  }
)

export const selectOutlineQueue = createSelector(
  (state: RootState) => state.queue,
  queue => {
    const open = selectedQueueOutlineAdapter.getSelectors().selectAll(queue.selectedQueueOutline)
    return filterSelectedQueue(sortQueue(open))
  }
)
export default queue.reducer
