import React from "react"

import classnames from "classnames"

import styles from "./OnlineStatus.module.scss"

interface OnlineStatusProps {
  online: boolean
  className?: string
}

const OnlineStatus: React.FC<OnlineStatusProps> = ({ online, className }) => {
  return (
    <div
      data-testid="online-status"
      className={classnames(styles.bubble, online ? styles.online : styles.offline, className)}
    />
  )
}

export default OnlineStatus
