import { Role } from "@doktor-se/bones-ui/dist/web-shared/types"

import { platform } from "config"

interface Link {
  href: string
  label: { [LanguageCode: string]: string }
}
interface LinkSection {
  link?: Link
  info?: {
    text: { [LanguageCode: string]: string }
    placement: "before" | "after"
  }
  showForRoles?: Role[]
}

interface Links {
  title: {
    text: { [LanguageCode: string]: string | ((role: string) => string) }
    subtitle?: { [LanguageCode: string]: string }
    link?: Link
    showForRoles?: Role[]
  }
  links?: LinkSection[]
}

export const getHelpPageLinks = (): Links[] | undefined => {
  switch (platform) {
    case "doktor":
      return [
        {
          title: {
            text: {
              sv: (role: string) => `Länkar för dig som jobbar som ${role}`,
              en: (role: string) => `Links for you who is working as a ${role}`
            },
            subtitle: {
              sv: "Dessa länkar är obligatoriska att öppna och använda under ditt arbetspass",
              en: "These links are mandatory to open and use during your working hours"
            }
          },
          links: [
            {
              link: { href: "https://webdoc.atlan.se/", label: { sv: "Webdoc", en: "Webdoc" } },
              showForRoles: ["nurse", "doctor", "midwife", "psych", "physio", "coord", "assistant_nurse"]
            },
            {
              link: {
                href: "https://www.rgswebb.se/Login/#",
                label: { sv: "Rådgivningsstödet webb", en: "Rådgivningsstödet webb" }
              },
              showForRoles: ["nurse", "midwife", "coord", "assistant_nurse"]
            },
            {
              link: {
                href: "https://doktorse.sharepoint.com/sites/rutinboken",
                label: { sv: "Rutinboken", en: "Routines" }
              },
              showForRoles: ["nurse", "doctor", "midwife", "psych", "physio", "coord", "assistant_nurse"]
            },
            {
              link: {
                href: "https://www.eordinationpascal.se/",
                label: { sv: "Pascal / Läkemedelsförteckning", en: "Pascal / Drugs list" }
              },
              info: { text: { sv: "öppnas i Microsoft Edge", en: "open in Microsoft Edge" }, placement: "after" },
              showForRoles: ["doctor"]
            }
          ]
        },

        {
          title: {
            text: { sv: "Användbara externa länkar", en: "Useful external links" },
            showForRoles: ["doctor", "nurse", "midwife", "coord", "assistant_nurse"]
          },
          links: [
            {
              link: { href: "https://www.fass.se/LIF/startpage?userType=0", label: { sv: "Fass", en: "Fass" } },
              showForRoles: ["doctor"]
            },
            {
              link: { href: "http://viss.nu/", label: { sv: "Viss", en: "Viss" } },
              showForRoles: ["doctor"]
            },
            {
              link: {
                href: "https://hanvisning.sll.se/#/categories/documents/5c5c237bbb859488c0e7591d",
                label: { sv: "SLL hänvisningsstöd", en: "SLL referral support" }
              },
              showForRoles: ["nurse", "midwife", "coord", "assistant_nurse"]
            }
          ]
        },

        {
          title: {
            text: { sv: "Användbara interna länkar", en: "Useful internal links" }
          },
          links: [
            {
              link: {
                href: "https://carealot-dashboard.web.doktorapi.se/#/login",
                label: { sv: "Dashboard", en: "Dashboard" }
              },
              showForRoles: ["nurse", "doctor", "midwife", "psych", "physio", "coord", "assistant_nurse"]
            },
            {
              link: { href: "https://doktorse.learnster.com/", label: { sv: "Learnalot", en: "Learnalot" } },
              showForRoles: ["nurse", "doctor", "midwife", "psych", "physio", "coord", "assistant_nurse"]
            },
            {
              link: {
                href: "https://doktorse.sharepoint.com/sites/rutinboken/SitePages/iKBT.aspx",
                label: { sv: "IKBT-rutin", en: "ICBT routine" }
              },
              showForRoles: ["psych"]
            },
            {
              link: {
                href: "https://caregiver.doktor.se/login",
                label: { sv: "Länk till IKBT (Psykologgränssnitt)", en: "Link to ICBT" }
              },
              showForRoles: ["psych"]
            }
          ]
        },

        {
          title: {
            text: { sv: "IT-stöd", en: "IT support" }
          },
          links: [
            {
              link: {
                href: "https://medsupport.doktor.se/sv-SE/support/solutions",
                label: { sv: "Supportalot portal", en: "Supportalot portal" }
              },
              info: {
                text: {
                  sv: 'Klicka "Är du medarbetare?" för att logga in och komma i kontakt med teknisk support.\nTänk på att du ej får skicka patientinformation/hälsouppgifter eller liknande till supportteknikerna.',
                  en: 'Click "Är du medarbetare?" to log in and get in touch with technical support team.\nPlease do not share any patient information, health information or any other sensitive data with the support technicians. '
                },
                placement: "after"
              },
              showForRoles: ["nurse", "doctor", "midwife", "psych", "physio", "coord", "assistant_nurse"]
            }
          ]
        },
        {
          title: {
            text: { sv: "Dokumentation", en: "Documentation" }
          },
          links: [
            {
              link: {
                href: "https://user-docs.k1.doktorapi.se/login/",
                label: { sv: "Användardokumentation", en: "User Documentation" }
              },
              showForRoles: ["nurse", "doctor", "midwife", "psych", "physio", "coord", "assistant_nurse"]
            }
          ]
        }
      ]

    case "proximus":
      return [
        {
          title: {
            text: { en: "OPEN TEAMS & SHAREPOINT ONLINE - " },
            link: {
              href: "https://proximuscorp.sharepoint.com/:f:/r/sites/GRP_002320/Shared%20Documents/General?csf=1&web=1&e=arUnI9",
              label: { en: "Link here" }
            }
          },
          links: [
            {
              info: {
                text: { en: 'Use always "the web application" - click on "use the web app instead"' },
                placement: "before"
              }
            }
          ]
        },

        {
          title: {
            text: { en: 'Access to the documents "Medical & Operational"' }
          },
          links: [
            {
              link: {
                href: "https://proximuscorp.sharepoint.com/:f:/r/sites/GRP_002320/Shared%20Documents/General/01_Support%20%26%20Training%20Material?csf=1&web=1&e=t74CgL",
                label: { en: "Book of Routines & Useful Medical Info" }
              }
            },
            {
              link: {
                href: "https://proximuscorp.sharepoint.com/:f:/r/sites/GRP_002320/Shared%20Documents/General/01_Support%20%26%20Training%20Material?csf=1&web=1&e=MQF3LU",
                label: { en: "Support & Training (ex: CareAlot, videos,...)" }
              }
            }
          ]
        },

        {
          title: {
            text: { en: "Seen something strange?" }
          },
          links: [
            {
              link: {
                href: "https://proximuscorp.sharepoint.com/:b:/r/sites/GRP_002320/Shared%20Documents/General/01_Support%20%26%20Training%20Material/04_%20Technical/A%20technical%20problem.pdf?csf=1&web=1&e=qIWtGE",
                label: { en: "Technical Problems" }
              }
            },
            {
              info: {
                text: { en: "Urgent question during your shift - 0479 999 991 (Whatsapp)" },
                placement: "before"
              }
            },
            {
              link: {
                href: "https://teams.microsoft.com/l/channel/19%3a43ed0f52eb1f4cae926ae3fd3012fcfd%40thread.tacv2/General?groupId=4dd842ca-2eeb-416b-a61f-d46512b687dd&tenantId=e7ab81b2-1e84-4bf7-9dcb-b6fec01ed138",
                label: { en: "Share your ideas & feedback" }
              }
            },
            {
              link: {
                href: "https://teams.microsoft.com/l/channel/19%3aea35f4d5dbe4487e8868bacc81a2a659%40thread.tacv2/Post%2520your%2520case?groupId=4dd842ca-2eeb-416b-a61f-d46512b687dd&tenantId=e7ab81b2-1e84-4bf7-9dcb-b6fec01ed138",
                label: { en: "Post your case" }
              }
            }
          ]
        }
      ]
    default:
      return
  }
}
