import { useEffect, useRef } from "react"

import { createSelector } from "@reduxjs/toolkit"

import { makeBookedConversationActive, openBookedConversation } from "api"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { personalBookingsSelectors, selectMeetingKinds } from "reducers/booking"
import { simpleConversationSelectors } from "reducers/conversations/conversations.reducer"
import { RootState } from "reducers/init"
import { clearBookingTimeout } from "reducers/selected"

import { handleBookingStart } from "./handleBookingStart"

const selectUpcomingBookedConversations = createSelector(
  (state: RootState) => state.conversations,
  conversations =>
    simpleConversationSelectors
      .selectAll(conversations)
      .filter(
        conversation =>
          conversation.metadata?.bookingId &&
          (conversation.metadata?.isUpcomingBooking || conversation.state === "booked")
      )
)

export const BookingInboxScheduler = () => {
  const dispatch = useAppDispatch()
  const { bookingTypes } = useAppSelector(selectMeetingKinds)
  const upcomingBookedConversations = useAppSelector(selectUpcomingBookedConversations)
  const bookingDictionary = useAppSelector(personalBookingsSelectors.selectEntities)

  // used to prevent an extra call when request is in progress
  const scheduledMetadataUpdate = useRef<Record<string, boolean>>({})

  useEffect(() => {
    if (upcomingBookedConversations.length === 0) {
      return
    }
    upcomingBookedConversations.forEach(conversation => {
      const booking = bookingDictionary[conversation.metadata?.bookingId!]
      const kind = bookingTypes.find(({ id }) => id === booking?.kindId)

      if (conversation.state === "closed") {
        dispatch(clearBookingTimeout(conversation.id))
        return
      }

      if (!booking || scheduledMetadataUpdate.current[conversation.id]) {
        return
      }

      const makeActiveLegacy = () => {
        scheduledMetadataUpdate.current[conversation.id] = true
        dispatch(makeBookedConversationActive(conversation.id))
      }

      const makeBookedOpen = () => {
        scheduledMetadataUpdate.current[conversation.id] = true
        dispatch(openBookedConversation(conversation.id))
      }

      const action = conversation.state === "booked" ? makeBookedOpen : makeActiveLegacy

      dispatch(
        handleBookingStart({
          booking,
          kind,
          conversationId: conversation.id,
          action
        })
      )
    })
  }, [upcomingBookedConversations, bookingDictionary, bookingTypes, dispatch])

  return null
}
