import { useState } from "react"
import { useIntl } from "react-intl"

import classNames from "classnames"

import { ICONS, StaffBodyTextM, StaffHeading5 } from "@doktor-se/bones-ui"
import { partnerConfig } from "@doktor-se/bones-ui/dist/web-shared/config"

import { deletePatientProfile } from "api"
import { featureFlags, platform } from "config"
import { useAppDispatch } from "lib/hooks"

import ConfirmDialog from "components/ConfirmDialog/ConfirmDialog"

import { ProfileList } from "../types"

import styles from "./SelectProfile.module.scss"

interface SelectProfileProps {
  profiles: ProfileList[]
  setProfile: (profileId?: string) => void
  newProfile: () => void
}

const SelectProfile = ({ profiles, setProfile, newProfile }: SelectProfileProps) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const [removeProfile, setRemoveProfile] = useState<ProfileList | undefined>()

  const deleteProfile = () => {
    if (removeProfile?.id) {
      dispatch(deletePatientProfile(removeProfile.id))
      setRemoveProfile(undefined)
    }
  }

  return (
    <>
      {profiles &&
        profiles.map(p => (
          <button className={styles.button} key={p.id || Math.random()} onClick={() => setProfile(p.id)}>
            <div className={styles.profile}>
              {p.type === "child" && <ICONS.Child className={styles.profileIcon} />}
              {p.type === "pet" && p.data && (
                <img
                  src={`${import.meta.env.VITE_APP_APP_STATIC}/${p.data.icon}.png`}
                  className={classNames(styles.profileIcon, styles.profileIconPet)}
                  alt={p.data.icon}
                />
              )}

              <StaffBodyTextM margin="auto 0">{p.name}</StaffBodyTextM>
            </div>

            {!!p.id && (
              <button
                className={styles.removeRow}
                onClick={evt => {
                  setRemoveProfile(p)
                  evt.stopPropagation()
                }}>
                X
              </button>
            )}
          </button>
        ))}
      {partnerConfig(platform, featureFlags as any).features.showAddProfile && (
        <button className={classNames(styles.button, styles.new)} onClick={newProfile}>
          <ICONS.AddCircleFilled className={styles.addIcon} height={15} width={15} />
          <StaffHeading5>{intl.formatMessage({ id: "profile.add" })}</StaffHeading5>
        </button>
      )}

      <ConfirmDialog
        isOpen={!!removeProfile}
        message={intl.formatMessage({ id: "remove.profile.warning" }, { name: removeProfile?.name })}
        onConfirm={() => deleteProfile()}
        confirmText={intl.formatMessage({ id: "btn.remove" })}
        onCancel={() => setRemoveProfile(undefined)}
      />
    </>
  )
}

export default SelectProfile
