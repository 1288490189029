import React from "react"

const IconSettingMicrophone = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={31} height={31} {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(.516 .622)">
        <circle cx={15.084} cy={15} r={15} fill="var(--color-primary-50)" />
        <g transform="translate(10.084 7.05)">
          <rect width={5.059} height={10.792} x={2.382} y={0.037} fill="#FFF" rx={2.529} />
          <path stroke="#FFF" strokeWidth={1.091} d="M8.927 9.076H.897a4.052 4.052 0 008.03 0z" />
          <path stroke="#FFF" strokeLinecap="round" strokeWidth={1.091} d="M4.912 13.025v2.348m-2.43.065h4.86" />
        </g>
      </g>
    </svg>
  )
}

export default IconSettingMicrophone
