import { createEntityAdapter, createSelector, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import { v4 as uuidv4 } from "uuid"

import { Account, BookingType, Category, Profile } from "@doktor-se/bones-ui/dist/web-shared/types"

import { RootState } from "reducers/init"

import { featureFlags } from "../../config"

export interface BookingAction {
  id: string
  nameKey: string
}

export interface Booking {
  id: string
  staffId: string
  clientId?: string
  conversationId?: string
  start: string
  end: string
  status: "booked" | "cancelled"
  metadata?: {
    custom?: "event"
    details?: { title: string; description?: string }
    hiddenFromClient: boolean
    slot: string
    profileDetails?: {
      profileId?: string
      profileType?: string
    }
  }
  kindId: string
  account?: Account
  clinicId?: string
}

export interface Slot {
  id: string
  kindIds: string[]
  staffId?: string
  start: string
  end: string
  bookingId?: string
  note?: string
}

export interface BookingDraftForm {
  patient?: Account
  kind?: BookingType
  category?: Category
  profile?: Profile
}

export interface CalendarSelection {
  start: Date
  end: Date
  selectionType?: BookingType | BookingAction
  resizeWarning?: "too_small" | "uneven"
  note?: string
}

export interface SelectedEvent {
  id: string
  type: "slot" | "booking"
}

export type CalendarRange = { from: string; to: string }

const bookingAdapter = createEntityAdapter<Booking>()
const slotAdapter = createEntityAdapter<Slot>()

export type CalendarStaffFilter = "ALL" | "UNASSIGNED" | string | undefined

export interface CalendarKindFilter {
  id: string
  selected: boolean
}

export interface BookingState {
  personalBookings: EntityState<Booking, string>
  bookingTypes: BookingType[]
  bookingActions: BookingAction[]
  calendarBookings: EntityState<Booking, string>
  slots: EntityState<Slot, string>
  draftForm?: BookingDraftForm
  calendar: {
    selectedEvent?: SelectedEvent
    range?: CalendarRange
    selection?: CalendarSelection
    filters: {
      staff?: CalendarStaffFilter
      kind?: CalendarKindFilter[]
      group?: string
    }
  }
}

const initialState: BookingState = {
  personalBookings: bookingAdapter.getInitialState(),
  bookingTypes: [],
  bookingActions: [
    {
      id: uuidv4(),
      nameKey: "kind.patient"
    },
    {
      id: uuidv4(),
      nameKey: "kind.delete.booking"
    }
  ],
  calendarBookings: bookingAdapter.getInitialState(),
  slots: slotAdapter.getInitialState(),
  draftForm: undefined,
  calendar: {
    filters: {}
  }
}

const booking = createSlice({
  name: "booking",
  initialState,
  reducers: {
    loadBookingTypes(state, action: PayloadAction<BookingType[]>) {
      state.bookingTypes = action.payload
    },
    addSlots(state, action: PayloadAction<Slot[]>) {
      slotAdapter.upsertMany(state.slots, action.payload)
    },
    setSlots(state, action: PayloadAction<Slot[]>) {
      slotAdapter.setMany(state.slots, action.payload)
    },
    addCalendarBooking(state, action: PayloadAction<Booking>) {
      bookingAdapter.addOne(state.calendarBookings, action.payload)
    },
    addPersonalBooking(state, action: PayloadAction<{ booking: Booking; userId: string | undefined }>) {
      if (action.payload.booking.staffId === action.payload.userId) {
        bookingAdapter.addOne(state.personalBookings, action.payload.booking)
      }
    },
    addCalendarBookings(state, action: PayloadAction<Booking[]>) {
      // Filter out canceled booking until we decide how to handle that.
      // Possibly a flag when fetching the bookings wether to include cancelled or not
      const booked = action.payload.filter(b => b.status === "booked")
      if (booked.length) {
        bookingAdapter.upsertMany(state.calendarBookings, booked)
      } else {
        bookingAdapter.removeAll(state.calendarBookings)
      }
    },
    setPersonalBookings(state, action: PayloadAction<Booking[]>) {
      bookingAdapter.setAll(state.personalBookings, action.payload)
    },
    updateBooking(state, action: PayloadAction<Booking>) {
      bookingAdapter.updateOne(state.calendarBookings, { id: action.payload.id, changes: action.payload })
      bookingAdapter.updateOne(state.personalBookings, { id: action.payload.id, changes: action.payload })
    },
    updateBookingsAssignee(state, action: PayloadAction<{ bookingIds: string[]; staffId: string }>) {
      const updates = action.payload.bookingIds.map(b => ({ id: b, changes: { staffId: action.payload.staffId } }))
      bookingAdapter.updateMany(state.calendarBookings, updates)
      bookingAdapter.updateMany(state.personalBookings, updates)
    },
    setDraftBookingPatient(state, action: PayloadAction<Account>) {
      state.draftForm = { ...state.draftForm, patient: action.payload }
    },
    clearDraftBookingPatient(state) {
      state.draftForm = state.draftForm ? { ...state.draftForm, patient: undefined } : undefined
    },
    clearDraftBookingProfile(state) {
      state.draftForm = state.draftForm ? { ...state.draftForm, profile: undefined } : undefined
    },
    setDraftBookingKind(state, action: PayloadAction<BookingType>) {
      state.draftForm = { ...state.draftForm, kind: action.payload }
      if (action.payload && "slotLength" in action.payload && state.calendar.selection) {
        const totalSlotLength = action.payload.slotLength
        const clientSlotLength = totalSlotLength - action.payload.bufferLength
        const currentSelection = state.calendar.selection

        state.calendar.selection = {
          ...state.calendar.selection,
          end: dayjs(currentSelection.start).add(clientSlotLength, "minutes").toDate()
        }
      }
    },
    setDraftBookingCategory(state, action: PayloadAction<Category>) {
      state.draftForm = { ...state.draftForm, category: action.payload }
    },
    clearDraftBookingCategory(state) {
      state.draftForm = { ...state.draftForm, category: undefined }
    },
    setDraftBookingProfile(state, action: PayloadAction<Profile>) {
      state.draftForm = { ...state.draftForm, profile: action.payload }
    },
    clearDraftBooking(state) {
      state.draftForm = undefined
    },
    clearCalendar(state) {
      bookingAdapter.removeAll(state.calendarBookings)
      slotAdapter.removeAll(state.slots)
      state.draftForm = undefined
    },
    removeSlots(state, action: PayloadAction<string[]>) {
      slotAdapter.removeMany(state.slots, action.payload)
    },
    removeBooking(state, action: PayloadAction<string>) {
      bookingAdapter.removeOne(state.calendarBookings, action.payload)
      bookingAdapter.removeOne(state.personalBookings, action.payload)
    },
    removeBookings(state, action: PayloadAction<string[]>) {
      bookingAdapter.removeMany(state.calendarBookings, action.payload)
      bookingAdapter.removeMany(state.personalBookings, action.payload)
    },
    setCalendarRange(state, action: PayloadAction<CalendarRange | undefined>) {
      state.calendar.range = action.payload
    },
    setCalendarSelection(state, action: PayloadAction<Pick<CalendarSelection, "start" | "end">>) {
      state.calendar.selection = action.payload
    },
    clearCalendarSelection(state) {
      state.calendar.selection = undefined
    },
    setCalendarSelectionType(state, action: PayloadAction<BookingType | BookingAction | undefined>) {
      if (!state.calendar.selection?.start || !state.calendar.selection?.end) {
        return
      }
      if (action.payload && "slotLength" in action.payload) {
        const totalSlotLength = action.payload.slotLength
        const clientSlotLength = totalSlotLength - action.payload.bufferLength
        const currentSelection = state.calendar.selection
        const selectionLengthInMinutes = dayjs(currentSelection.end).diff(dayjs(currentSelection.start), "minutes")

        if (selectionLengthInMinutes < clientSlotLength) {
          state.calendar.selection = {
            ...state.calendar.selection,
            selectionType: action.payload,
            resizeWarning: "too_small"
          }
          return
        }

        const remainingTimeAfterFirstSlot = selectionLengthInMinutes - clientSlotLength
        const additionalSlotsAmount = remainingTimeAfterFirstSlot / totalSlotLength

        if (!Number.isInteger(additionalSlotsAmount)) {
          state.calendar.selection = {
            ...state.calendar.selection,
            selectionType: action.payload,
            end: dayjs(currentSelection.start)
              .add(clientSlotLength + Math.floor(additionalSlotsAmount) * totalSlotLength, "minutes")
              .toDate(),
            resizeWarning: "uneven"
          }
        }
      }
      state.calendar.selection = {
        ...state.calendar.selection,
        selectionType: action.payload
      }
    },
    setCalendarKindFilter(state, action: PayloadAction<string[]>) {
      const types = state.bookingTypes.filter(type => action.payload.some(id => type.metadata?.role === id))
      state.calendar.filters.kind = types.map(t => ({ id: t.id, selected: true }))
    },
    setInitialCalendarFilter(state, action: PayloadAction<string[]>) {
      if (state.calendar.filters.kind === undefined || state.calendar.filters.kind.length === 0) {
        const types = state.bookingTypes.filter(type => action.payload.some(id => type.metadata?.role === id))
        state.calendar.filters = { kind: types.map(t => ({ id: t.id, selected: true })) }
      }
    },
    toggleCalendarKindFilterSelected(state, action: PayloadAction<string>) {
      state.calendar.filters.kind = state.calendar.filters.kind?.map(item => {
        if (item.id === action.payload) {
          return { ...item, selected: !item.selected }
        }
        return item
      })
    },
    toggleCalendarKindFilterGroup(state, action: PayloadAction<string>) {
      const types = state.bookingTypes.filter(t => t.metadata?.role === action.payload)
      const typesForRole = state.calendar.filters.kind?.filter(f => types.find(t => t.id === f.id))
      const allSelected = typesForRole?.every(t => t.selected)
      state.calendar.filters.kind = state.calendar.filters.kind?.map(f => {
        if (typesForRole?.find(t => t.id === f.id)) {
          return { ...f, selected: !allSelected }
        }
        return f
      })
    },
    toggleRoleFilter(state, action: PayloadAction<string[]>) {
      state.calendar.filters.kind = action.payload.map(id => ({ id, selected: true }))
    },
    clearCalendarKindFilter(state) {
      state.calendar.filters.kind = state.calendar.filters.kind?.map(k => ({ ...k, selected: false }))
    },
    setCalendarGroupFilter(state, action: PayloadAction<string>) {
      state.calendar.filters.group = action.payload
    },
    clearCalendarGroupFilter(state) {
      state.calendar.filters.group = undefined
    },
    setCalendarStaffFilter(state, action: PayloadAction<CalendarStaffFilter>) {
      state.calendar.filters.staff = action.payload
    },
    setSelectedEvent(state, action: PayloadAction<SelectedEvent | undefined>) {
      state.calendar.selectedEvent = action.payload
    }
  }
})

export const {
  loadBookingTypes,
  addSlots,
  setSlots,
  addPersonalBooking,
  addCalendarBooking,
  addCalendarBookings,
  setPersonalBookings,
  updateBooking,
  updateBookingsAssignee,
  setDraftBookingPatient,
  clearDraftBookingPatient,
  clearDraftBookingProfile,
  setDraftBookingKind,
  setDraftBookingCategory,
  clearDraftBookingCategory,
  setDraftBookingProfile,
  clearDraftBooking,
  clearCalendar,
  removeSlots,
  removeBooking,
  removeBookings,
  setCalendarRange,
  setCalendarSelection,
  clearCalendarSelection,
  setCalendarSelectionType,
  setCalendarKindFilter,
  setInitialCalendarFilter,
  toggleCalendarKindFilterSelected,
  toggleCalendarKindFilterGroup,
  toggleRoleFilter,
  setCalendarStaffFilter,
  setSelectedEvent,
  setCalendarGroupFilter,
  clearCalendarGroupFilter
} = booking.actions

export type BookingSliceAction = ObjectFunctionReturnTypes<typeof booking.actions>

export const selectMeetingKinds = createSelector(
  (state: RootState) => state.booking.bookingTypes,
  (state: RootState) => state.booking.bookingActions,
  (bookingTypes, bookingActions) => {
    const kinds: (BookingType | BookingAction)[] = [
      ...[...bookingTypes].sort((a, b) => b.slotLength - a.slotLength),
      ...bookingActions
    ]

    return {
      kinds,
      bookingTypes,
      editableSlotActions: [
        ...bookingTypes,
        {
          id: uuidv4(),
          nameKey: "kind.delete.slot"
        }
      ] as (BookingType | BookingAction)[],
      editableBookingActions: bookingActions
    }
  }
)

export const draftBookingFormValid = createSelector(
  (state: RootState) => state.booking.draftForm,
  draftForm => {
    const formValid = !!draftForm?.kind && !!draftForm?.patient && !!draftForm?.category
    const isProfileNecessary =
      featureFlags.has("booking_create_with_profile") && !featureFlags.has("booking_create_personal_and_profile")
    if (isProfileNecessary) {
      return formValid && !!draftForm?.profile
    }
    return formValid
  }
)

export const selectSlots = slotAdapter.getSelectors<RootState>(state => state.booking.slots)
export const selectCalendarBookings = bookingAdapter.getSelectors<RootState>(state => state.booking.calendarBookings)

export const personalBookingsSelectors = bookingAdapter.getSelectors<RootState>(state => state.booking.personalBookings)

export const selectBookingById =
  (id?: string) =>
  (state: RootState): Booking | undefined => {
    return id ? personalBookingsSelectors.selectById(state, id) : undefined
  }

export const selectSlotById =
  (id?: string) =>
  (state: RootState): Slot | undefined => {
    return id ? selectSlots.selectById(state, id) : undefined
  }

export const selectedCalendarStaffId =
  () =>
  (state: RootState): string | undefined => {
    const { staff: staffFilter } = state.booking.calendar.filters
    if (staffFilter === undefined) {
      return state.auth.user?.id
    }
    return staffFilter !== "ALL" && staffFilter !== "UNASSIGNED" ? staffFilter : undefined
  }

export const selectBookingTypeById =
  (id?: string) =>
  (state: RootState): BookingType | undefined =>
    id ? state.booking.bookingTypes.find(t => t.id === id) : undefined

export default booking.reducer
