import { StaffHeading5 } from "@doktor-se/bones-ui"
import { InitialsBubble, ProfileBubble } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Profile } from "@doktor-se/bones-ui/dist/web-shared/types"

import { appStatic, featureFlags } from "config"
import { useAppSelector } from "lib/hooks"
import { patientProfilesSelectors } from "reducers/users/users.reducer"

import styles from "./ProfileStep.module.scss"

interface ProfileStepProps {
  changeProfile: (profile: Profile) => void
  patientId: string
}

const ProfileStep = ({ changeProfile, patientId }: ProfileStepProps) => {
  const patientProfiles = useAppSelector(state => patientProfilesSelectors.selectById(state, patientId))?.profiles

  return (
    <ul className={styles.wrapper}>
      {patientProfiles
        ?.filter(profile => !profile.removed)
        .map(profile => (
          <li>
            <button key={profile.id} className={styles.row} onClick={() => changeProfile(profile)}>
              {!featureFlags.has("profile_icon") && <InitialsBubble letter={profile.name.charAt(0)} />}

              {featureFlags.has("profile_icon") && (
                <ProfileBubble icon={profile.data.icon} letter={profile.name.charAt(0)} appStatic={appStatic} />
              )}

              <StaffHeading5 as="span" margin={{ left: "10px" }}>
                {profile.name}
              </StaffHeading5>
            </button>
          </li>
        ))}
    </ul>
  )
}

export default ProfileStep
