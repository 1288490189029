import { useCallback, useEffect } from "react"
import { useIntl } from "react-intl"

import { Button, CopyToClipboard, ICONS, TextArea } from "@doktor-se/bones-ui"
import { JournalSection } from "@doktor-se/bones-ui/dist/web-shared/types"

import ConversationImages from "./components/images/ConversationImages"

import { sendJournalNote } from "../../../../../../api"
import { amplitudeTrack } from "../../../../../../lib/amplitude/amplitude"
import { useAppDispatch, useAppSelector } from "../../../../../../lib/hooks"
import { selectConversation } from "../../../../../../reducers/conversations/conversations.reducer"
import { updateJournalDraftWebdoc } from "../../../../../../reducers/selected"

import styles from "./JournalForm.module.scss"

const JournalForm = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const conversation = useAppSelector(selectConversation)
  const currentJournalNoteState = useAppSelector(
    state =>
      state.selected.localConversationStates
        ?.filter(item => item.conversationId === conversation?.id)
        .map(item => item.state.journalDraft?.data.sections)
        .pop() ||
      conversation?.journalNote?.data.sections ||
      []
  )
  const sectionsEquals = (): boolean => {
    const savedSections = conversation?.journalNote?.data.sections
    if (currentJournalNoteState.length !== savedSections?.length) {
      return false
    }

    const sortList = (list: JournalSection[]): JournalSection[] => {
      return list.slice().sort((a, b) => {
        if (a.title !== b.title) {
          return a.title.localeCompare(b.title)
        }
        return a.body.localeCompare(b.body)
      })
    }
    const sortedList1 = sortList(currentJournalNoteState)
    const sortedList2 = sortList(savedSections)
    for (let i = 0; i < sortedList1.length; i++) {
      if (sortedList1[i].title !== sortedList2[i].title || sortedList1[i].body !== sortedList2[i].body) {
        return false
      }
    }
    return true
  }
  const onCopy = () => {
    amplitudeTrack({
      type: "carealot.chat_journal.copy_note_pressed",
      eventProperties: {
        category_id: conversation?.categoryId
      }
    })
  }
  const updateValue = useCallback(
    (key: string, value: string) => {
      const journalNoteDraft = conversation?.journalNote && {
        ...conversation?.journalNote,
        data: {
          sections: currentJournalNoteState.map(section =>
            section.title === key ? { ...section, body: value } : section
          )
        }
      }
      journalNoteDraft && dispatch(updateJournalDraftWebdoc(journalNoteDraft))
    },
    [conversation?.journalNote, currentJournalNoteState, dispatch]
  )

  const saveJournalNote = () => {
    conversation && dispatch(sendJournalNote(conversation.id, currentJournalNoteState))
  }
  useEffect(() => {
    conversation?.journalNote && updateJournalDraftWebdoc(conversation?.journalNote)
  }, [conversation])
  return (
    <>
      <div className={styles.container}>
        {intl.formatMessage({ id: "journal.header" })}
        {currentJournalNoteState.map((item, index) => (
          <div className={styles.journalSection}>
            <div className={styles.header}>
              <div key={item.title + index} className={styles.sectionTitle}>
                {intl.formatMessage({ id: item.title })}
              </div>
              {item.body !==
              conversation?.journalNote?.data.sections.find(section => item.title === section.title)?.body ? (
                <div key={item.title + "_i_" + index} className={styles.edited}>
                  {intl.formatMessage({ id: "journal.edited.indicator" })}
                </div>
              ) : undefined}
              <CopyToClipboard
                onCopy={onCopy}
                copyText={item.body || ""}
                confirmationText={intl.formatMessage({ id: "copied.confirmation" })}
              />
            </div>
            <div className={styles.sectionTextArea}>
              <TextArea
                variant={"onSurface"}
                noVisualLabel={true}
                label={"nolabel"}
                noBorder
                fullWidth={true}
                onChange={(value: string) => updateValue(item.title, value)}
                value={item.body}
              />
            </div>
          </div>
        ))}
        <Button variant="primary" color="primary" onPress={saveJournalNote} isDisabled={sectionsEquals()}>
          Save
        </Button>
        {conversation && <ConversationImages conversation={conversation} />}
        <div className={styles.copyAllContainer}>
          <div className={styles.infoText}>
            <div className={styles.warningIcon}>
              <ICONS.WarningCircle height={20} width={20} />
            </div>
            <div className={styles.warningText}>{intl.formatMessage({ id: "journal.copy.warning" })}</div>
          </div>
          <div className={styles.copyAllButton}>
            <div className={styles.copyAllText}>Kopiera allt</div>
            <CopyToClipboard
              onCopy={onCopy}
              copyText={currentJournalNoteState
                .map(section => `${intl.formatMessage({ id: section.title })}\n${section.body}`)
                .join("\n")}
              confirmationText={intl.formatMessage({ id: "copied.confirmation" })}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default JournalForm
