/* Migrations for store state
Introducing a new reducer does not require a migration.
The migration that is run has to match the version number in persistConfig.
*/
import { defaultRole, platform } from "config"

export const migrations = {
  0: (state: any) => ({
    ...state,
    app: {
      ...state.app,
      ringtoneVolume: "0.4"
    }
  }),

  1: (state: any) => ({
    ...state,
    conversations: {
      ...state.conversations,
      messageDrafts: []
    }
  }),

  2: (state: any) => ({
    ...state,
    auth: {
      ...state.auth,
      roles: []
    },
    conversations: {
      ...state.conversations,
      user: {
        ...state.conversations.user,
        roles: []
      },
      personal: []
    }
  }),

  3: (state: any) => ({
    ...state,
    app: {
      ...state.app,
      drawerState: false
    }
  }),

  4: (state: any) => ({
    ...state,
    conversations: {
      ...state.conversations,
      queues: {
        nurse: [],
        doctor: [],
        psych: []
      }
    }
  }),

  5: (state: any) => ({
    ...state,
    auth: {
      ...state.auth,
      AD: false
    }
  }),

  6: (state: any) => ({
    ...state,
    conversations: {
      ...state.conversations
    }
  }),

  // Set queue content has changed
  7: (state: any) => ({
    ...state,
    conversations: {
      ...state.conversations
    }
  }),
  8: (state: any) => ({
    ...state,
    profiles: {
      ...state.profiles,
      onlineStaff: {}
    }
  }),
  9: (state: any) => {
    if (platform === "anicura")
      return {
        ...state,
        conversations: {
          ...state.conversations,
          journalDrafts: []
        }
      }
    return state
  },
  10: (state: any) => ({
    ...state,
    conversations: {
      ...state.conversations,
      conversation: {}
    }
  }),
  11: (state: any) => ({
    ...state,
    app: {
      ...state.app,
      showingWelcomeMessage: false
    }
  }),
  13: (state: any) => ({
    ...state,
    conversations: {
      ...state.conversations,
      commentDrafts: []
    },
    app: {
      ...state.app,
      commentState: false
    }
  }),
  14: (state: any) => ({
    ...state,
    app: undefined
  }),
  15: (state: any) => ({
    ...state,
    conversations: {
      ...state.conversations,
      timeouts: {
        snooze: [],
        postpone: []
      }
    }
  }),
  16: (state: any) => ({
    ...state,
    categories: {
      ids: [],
      entities: {}
    },
    clinics: {
      ids: [],
      entities: {}
    }
  }),
  17: (state: any) => ({
    ...state,
    conversations: {
      ids: [],
      entities: {}
    }
  })
}

export const tokboxMigrations = {}

export const authMigrations = {
  0: (state: any) => ({
    ...state,
    AD: true
  }),
  1: (state: any) => ({
    ...state,
    defaultRole: defaultRole(state.roles)
  }),
  2: (state: any) => ({
    ...state,
    authorized: false,
    accessToken: "",
    refreshToken: undefined,
    token: undefined,
    AD: false,
    tokenExpBannerState: undefined,
    roles: []
  }),
  3: (state: any) => ({
    ...state,
    authorized: false,
    accessToken: "",
    refreshToken: undefined,
    token: undefined,
    AD: false,
    tokenExpBannerState: undefined,
    roles: []
  })
}

export const appMigrations = {}

export const selectedMigrations = {
  0: (state: any) => ({
    ...state,
    conversation: undefined
  })
}
