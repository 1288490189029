import OT from "@opentok/client"

import { fetchProxyUrl } from "api"

import { Thunk } from "./hooks"

export const opentokInit = (): Thunk => async dispatch => {
  const proxyUrl = await dispatch(fetchProxyUrl())

  if (proxyUrl) {
    OT.setProxyUrl(proxyUrl)
  }
  const logLevel = (OT as any).DEBUG
  if (isNaN(logLevel)) {
    throw new Error("There is something wrong with the @opentok/client types for log levels")
  }
  OT.setLogLevel(logLevel)

  OT.on("exception", event => console.log("OT exception", event, event.message))
}
