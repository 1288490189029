import { adaptationApiFetch } from "lib/fetch"
import { Thunk } from "lib/hooks"

export const fetchDatabaseRawKey = (): Thunk<Promise<string | undefined>> => async dispatch => {
  try {
    const response: { databaseRawKey: string } = await dispatch(
      adaptationApiFetch({
        url: `/encryption/database-raw-key`,
        incomingOptions: {
          method: "GET"
        },
        config: {
          errorHandling: "v2"
        }
      })
    )
    return response.databaseRawKey
  } catch (error: any) {
    throw error
  }
}

export const fetchSignupJWT = (): Thunk<Promise<string | undefined>> => async dispatch => {
  try {
    const response: { signupJwt: string } = await dispatch(
      adaptationApiFetch({
        url: `/encryption/signup-jwt`,
        incomingOptions: {
          method: "GET"
        },
        config: {
          errorHandling: "v2"
        }
      })
    )
    return response.signupJwt
  } catch (error: any) {
    throw error
  }
}

export const saveSealdID =
  (sealdId: string | undefined): Thunk =>
  async dispatch => {
    const data = {
      seald_id: sealdId
    }
    try {
      await dispatch(
        adaptationApiFetch({
          url: `/encryption/seald-user`,
          incomingOptions: {
            method: "POST",
            body: JSON.stringify(data)
          },
          config: {
            errorHandling: "v2"
          }
        })
      )
    } catch (error: any) {
      throw error
    }
  }
