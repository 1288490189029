import { useCallback, useEffect, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"

import { Button } from "@doktor-se/bones-ui"
import { DashedTitle, Loader } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Queue } from "@doktor-se/bones-ui/dist/web-shared/types"

import {
  createLoadingSelector,
  fetchMoreConversationsWithDetails,
  fetchSelectedQueue,
  fetchSelectedQueueOutline
} from "api"
import { defaultPageSizeForQueue, featureFlags, nextPageQueueSize } from "config"
import { useAppDispatch, useAppSelector, useCallbackWhenOnlineAgain } from "lib/hooks"
import { queueCountSelectors, selectOutlineQueue, selectSelectedQueue } from "reducers/queue/queue.reducer"

import QueueConversationBox from "../QueueConversationBox/QueueConversationBox"

import styles from "./QueueConversationsList.module.scss"

interface QueueConversationsListProps {
  assignToUserBlocked: boolean
  selectedQueue: Queue
}

const loadingSelector = createLoadingSelector(["QUEUE", "QUEUE_COUNT"])

const QueueConversationsList = ({ assignToUserBlocked, selectedQueue }: QueueConversationsListProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const queueCount = useAppSelector(queueCountSelectors.selectEntities)
  const conversations = useAppSelector(selectSelectedQueue)
  const loader = useAppSelector(state => loadingSelector(state))
  const conversationsOutline = useAppSelector(selectOutlineQueue)
  const appLanguage = useAppSelector(state => state.app.language)
  const [numberOfElementsToLoad, setNumberOfElementsToLoad] = useState(defaultPageSizeForQueue)
  const [numberOfPostponedElementsToLoad, setNumberOfPostponedElementsToLoad] = useState(defaultPageSizeForQueue)
  const fetchQueue = useCallback(() => {
    featureFlags.has("queue_pagination")
      ? dispatch(fetchSelectedQueueOutline(selectedQueue.queueKey, defaultPageSizeForQueue))
      : dispatch(fetchSelectedQueue(selectedQueue.queueKey))
  }, [dispatch, selectedQueue.queueKey])

  useEffect(() => {
    fetchQueue()
  }, [selectedQueue, dispatch, fetchQueue])

  useEffect(() => {
    setNumberOfElementsToLoad(defaultPageSizeForQueue)
    setNumberOfPostponedElementsToLoad(defaultPageSizeForQueue)
  }, [selectedQueue])

  useCallbackWhenOnlineAgain(fetchQueue)

  const updateNumberOfElementsInTheQueue = () => {
    const nextPage = numberOfElementsToLoad + nextPageQueueSize
    setNumberOfElementsToLoad(nextPage)
    dispatch(
      fetchMoreConversationsWithDetails(
        conversationsOutline.active.map(item => item.id),
        conversations.active.map(item => item.id),
        selectedQueue.queueKey,
        nextPage
      )
    )
  }
  const updateNumberOfPostponedElementsInTheQueue = () => {
    const nextPage = numberOfPostponedElementsToLoad + nextPageQueueSize
    setNumberOfPostponedElementsToLoad(nextPage)
    dispatch(
      fetchMoreConversationsWithDetails(
        conversationsOutline.postponed.map(item => item.id),
        conversations.postponed.map(item => item.id),
        selectedQueue.queueKey,
        nextPage
      )
    )
  }
  if (loader) return <Loader type="small" />

  return (
    <div data-testid={`queueConversations-${selectedQueue.queueKey}`}>
      {conversations?.active.map((conversation, i) => (
        <QueueConversationBox
          key={conversation.id}
          conversation={conversation}
          queueName={selectedQueue.translations[appLanguage]}
          assignToUserBlocked={assignToUserBlocked}
          postponed={false}
          noBorder={!!conversations?.postponed.length && i + 1 === conversations?.active.length}
        />
      ))}
      {featureFlags.has("queue_pagination") && queueCount[selectedQueue.queueKey]?.conversationCount ? (
        numberOfElementsToLoad < (queueCount[selectedQueue.queueKey]?.conversationCount ?? 0) && (
          <div className={styles.queueMoreButton}>
            <Button
              fullWidth
              small
              id={"queueMoreButton"}
              color={"primary"}
              variant={"primary"}
              onPress={updateNumberOfElementsInTheQueue}>
              {intl.formatMessage({ id: "btn.pagination" })}
            </Button>
          </div>
        )
      ) : (
        <></>
      )}
      {!!conversations?.postponed.length && (
        <>
          <DashedTitle
            title={
              <>
                <FormattedMessage id="unassigned.separator.postponed" /> (
                {queueCount[selectedQueue.queueKey]?.postponedConversationCount})
              </>
            }
            color="surface"
            className={styles.postponedSeparator}
            bold
          />

          {conversations?.postponed.map(e => (
            <QueueConversationBox
              key={e.id}
              conversation={e}
              queueName={selectedQueue.translations[appLanguage]}
              assignToUserBlocked={assignToUserBlocked}
              postponed={true}
            />
          ))}
          {featureFlags.has("queue_pagination") &&
            queueCount[selectedQueue.queueKey]?.postponedConversationCount &&
            numberOfPostponedElementsToLoad < (queueCount[selectedQueue.queueKey]?.postponedConversationCount ?? 0) && (
              <div className={styles.queueMoreButton}>
                <Button
                  fullWidth
                  small
                  id={"queueMoreButton"}
                  color={"primary"}
                  variant={"primary"}
                  onPress={updateNumberOfPostponedElementsInTheQueue}>
                  {intl.formatMessage({ id: "btn.pagination" })}
                </Button>
              </div>
            )}
        </>
      )}
    </div>
  )
}

export default QueueConversationsList
