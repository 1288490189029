import React, { Suspense } from "react"
import { QueryClient } from "react-query"
import { Route, Routes } from "react-router-dom"

import { featureFlags } from "config"

import AuthorizedRoute from "./AuthorizedRoute"

import PostLogout from "components/navigation/components/PostLogout"
import CreatePasswordPage from "pages/createPassword/CreatePasswordPage"
import DashboardPage from "pages/dashboard/DashboardPage"
import DialNumberPage from "pages/dialNumber/DialNumberPage"
import HelpPage from "pages/help/HelpPage"
import LogIn from "pages/login/Login"
import PhrasePage from "pages/phrases/PhrasesPage"
import SearchPage from "pages/search/SearchPage"
import SettingsPage from "pages/settings/SettingsPage"

import LoadingContainer from "./LoadingContainer"
import Localization from "./Localization/Localization"

const BookingPage = React.lazy(() => import("pages/booking/BookingPage"))

interface AppProps {
  queryClient: QueryClient
}

const App = ({ queryClient }: AppProps) => {
  return (
    <>
      <Localization>
        <Routes>
          <Route path="/login/itsme" element={<LogIn redirectedFrom="itsme" />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/:country/:language/create-password" element={<CreatePasswordPage />} />
          <Route path="/post-logout" element={<PostLogout />} />

          <Route
            path="/"
            element={
              <AuthorizedRoute>
                <DashboardPage />
              </AuthorizedRoute>
            }
          />
          <Route
            path="/search"
            element={
              <AuthorizedRoute>
                <SearchPage />
              </AuthorizedRoute>
            }
          />
          {featureFlags.has("sip") && (
            <Route
              path="/dial"
              element={
                <AuthorizedRoute>
                  <DialNumberPage />
                </AuthorizedRoute>
              }
            />
          )}
          <Route
            path="/settings"
            element={
              <AuthorizedRoute>
                <SettingsPage queryClient={queryClient} />
              </AuthorizedRoute>
            }
          />
          {featureFlags.has("phrases") && (
            <Route
              path="/phrases"
              element={
                <AuthorizedRoute>
                  <PhrasePage />
                </AuthorizedRoute>
              }
            />
          )}
          {featureFlags.has("help") && (
            <Route
              path="/help"
              element={
                <AuthorizedRoute>
                  <HelpPage />
                </AuthorizedRoute>
              }
            />
          )}
          {featureFlags.has("booking") && (
            <Route
              path="/booking"
              element={
                <AuthorizedRoute>
                  <Suspense fallback={<LoadingContainer />}>
                    <BookingPage />
                  </Suspense>
                </AuthorizedRoute>
              }
            />
          )}
        </Routes>
      </Localization>
    </>
  )
}

export default App
