import React from "react"

const IconLanguage = ({ fill, ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={31} height={31} {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={15.084} cy={15} r={15} fill={fill || "var(--color-primary-50)"} transform="translate(0 .213)" />
        <path
          fill="#FFF"
          d="M15.493 8.3a6.91 6.91 0 00-6.906 6.913 6.91 6.91 0 006.906 6.913 6.917 6.917 0 006.92-6.913 6.917 6.917 0 00-6.92-6.913zm4.79 4.147h-2.038a10.818 10.818 0 00-.955-2.46 5.551 5.551 0 012.994 2.46zM15.5 9.71a9.738 9.738 0 011.32 2.737h-2.64A9.738 9.738 0 0115.5 9.71zm-5.35 6.885a5.698 5.698 0 01-.18-1.382c0-.477.069-.94.18-1.383h2.336c-.055.456-.097.913-.097 1.383s.042.926.097 1.382h-2.337zm.566 1.383h2.04c.22.864.539 1.694.954 2.46a5.521 5.521 0 01-2.994-2.46zm2.04-5.53h-2.04a5.521 5.521 0 012.994-2.462 10.818 10.818 0 00-.955 2.461zm2.744 8.267a9.738 9.738 0 01-1.32-2.737h2.64a9.738 9.738 0 01-1.32 2.737zm1.618-4.12h-3.236c-.062-.456-.11-.912-.11-1.382 0-.47.048-.934.11-1.383h3.236c.062.45.11.913.11 1.383s-.048.926-.11 1.382zm.172 3.844c.415-.767.733-1.597.955-2.461h2.039a5.551 5.551 0 01-2.994 2.46zm1.224-3.844c.055-.456.097-.912.097-1.382 0-.47-.042-.927-.097-1.383h2.337c.11.442.18.906.18 1.383s-.07.94-.18 1.382h-2.337z"
        />
      </g>
    </svg>
  )
}

export default IconLanguage
