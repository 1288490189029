import { Category, GroupedCategories } from "@doktor-se/bones-ui/dist/web-shared/types"

export const groupCategories = (categories: Category[]): GroupedCategories[] => {
  const reduced = categories.reduce(
    (acc, category) => {
      const header = category.header || "base"
      acc[header] = acc[header] || []
      acc[header].push(category)
      return acc
    },
    {} as { [key: string]: Category[] }
  )
  const array = Object.keys(reduced).map(header => ({
    header,
    categories: reduced[header]
  }))
  return array
    .filter(c => c.header === "base")
    .map(c => ({ categories: c.categories }))
    .concat(array.filter(c => c.header !== "base"))
}

export const convertTimeStringToNumber = (time: string) => {
  var timeArray = time.split(/[.:]/)

  if (timeArray.length === 2) {
    return parseInt(timeArray[0]) * 60 + parseInt(timeArray[1])
  } else if (timeArray.length === 3) {
    return parseInt(timeArray[0]) * 60 * 60 + parseInt(timeArray[1]) * 60 + parseInt(timeArray[0])
  }

  return 0
}
