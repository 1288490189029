import { useEffect, useRef } from "react"

import classnames from "classnames"

import { checkPermissions } from "api"
import { featureFlags } from "config"
import { startDeepAR } from "lib/deepAR"
import { useAppDispatch, useAppSelector } from "lib/hooks"

import styles from "./VideoFeed.module.scss"

const stop = () => {
  window.stream = window.stream || {}
  window.stream.getTracks?.().forEach(track => {
    track.stop()
  })
}

const VideoFeed = () => {
  const dispatch = useAppDispatch()
  const calls = useAppSelector(state => state.tokbox)
  const isVideoBackgroundActive = useAppSelector(state => state.app.isVideoBackgroundActive)
  const canvasRef = useRef(null)
  const videoElement = useRef<HTMLVideoElement>(null)

  const gotStream = (stream?: MediaStream) => {
    if (stream) {
      window.stream = stream // make stream available to console
      if (videoElement.current) videoElement.current.srcObject = stream
    }
  }

  useEffect(() => {
    stop()
    dispatch(checkPermissions(stream => gotStream(stream), false))
  }, [dispatch, calls.pubOptions])

  useEffect(() => {
    if (featureFlags.has("video_call_background") && isVideoBackgroundActive) {
      const cleanupDeepAR = startDeepAR(canvasRef.current!, 180, 135)

      return () => cleanupDeepAR?.()
    }
  }, [isVideoBackgroundActive])

  useEffect(() => () => stop(), [])

  return (
    <div className={styles.player}>
      <video
        id="video"
        className={classnames(styles.video, styles.mirrored)}
        style={{ display: isVideoBackgroundActive ? "none" : "block" }}
        muted
        autoPlay
        ref={videoElement}>
        <track kind="captions" />
      </video>

      {featureFlags.has("video_call_background") && (
        <canvas
          style={{ display: isVideoBackgroundActive ? "block" : "none" }}
          className={styles.video}
          ref={canvasRef}
        />
      )}
    </div>
  )
}
export default VideoFeed
