import { useIntl } from "react-intl"

import { Button, ICONS } from "@doktor-se/bones-ui"

import { isClinicUsingTakecare } from "config"
import { useAppSelector } from "lib/hooks"
import { clinicSelectors } from "reducers/clinics"

import styles from "./OpenPatientInTakeCare.module.scss"

interface OpenPatientInTakeCareProps {
  patientPnr: string
}

const OpenPatientInTakeCare = ({ patientPnr }: OpenPatientInTakeCareProps) => {
  const intl = useIntl()
  const user = useAppSelector(state => state.auth.user)
  const userClinicId = user?.data?.clinic
  const clinics = useAppSelector(clinicSelectors.selectEntities)
  const userClinic = userClinicId ? clinics[userClinicId] : undefined

  if (!(userClinic && isClinicUsingTakecare(userClinic.name))) {
    return null
  }

  const openPatientInTakeCare = async (patientPnr: string) => {
    const patientId = patientPnr.replace(/-/g, "")
    const path = `agentcall://takecare.openpatient?patientid=${patientId}`
    window.location.replace(path)
  }

  return (
    <div className={styles.container}>
      <Button
        variant="primary"
        color="primary"
        onPress={() => openPatientInTakeCare(patientPnr)}
        Icon={<ICONS.OpenInBrowser />}
        small
        fullWidth>
        {intl.formatMessage({ id: "takecare.patient.open" })}
      </Button>
    </div>
  )
}

export default OpenPatientInTakeCare
