import { Registerer, RegistererState, Session, SessionState, UserAgent, Web } from "sip.js"

import { WSConnectError } from "@doktor-se/bones-ui/dist/web-shared/classes"
import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import { ApiElkAccount, logSipCall, releaseSipNumber } from "api"
import { handleErrors } from "api/error/handler"
import { setConversationId, setElkAccount } from "reducers/elk"
import { store } from "reducers/init"

import { ReduxDispatch } from "../hooks"
import setDtmfAudio from "./dtmf"

class ElkCall {
  session?: Session
  userAgent?: UserAgent
  wsDisconnectedCount: number
  dtmfAudioElement?: HTMLAudioElement

  constructor() {
    this.session = undefined
    this.userAgent = undefined
    this.wsDisconnectedCount = 0
    this.dtmfAudioElement = undefined
  }

  connect(
    account: ApiElkAccount,
    audio: HTMLAudioElement,
    dtmfAudio: HTMLAudioElement,
    number: string,
    conversation?: AssignedConversation
  ) {
    return new Promise<ApiElkAccount>((resolve, reject) => {
      const sipPassword = account.password
      const audioElement = audio
      this.dtmfAudioElement = dtmfAudio

      const uri = UserAgent.makeURI(account.uri.startsWith("sip:") ? account.uri : `sip:${account.uri}`)
      if (!uri) {
        reject(new Error("Could not create SIP uri"))
        return
      }

      this.userAgent = new UserAgent({
        transportOptions: {
          wsServers: account.wsServers
        },
        uri: uri,
        authorizationUsername: account.username,
        authorizationPassword: sipPassword,
        sessionDescriptionHandlerFactoryOptions: {
          constraints: {
            audio: true,
            video: false
          },
          peerConnectionOptions: {
            rtcConfiguration: {
              rtcpMuxPolicy: "negotiate"
            }
          }
        },
        delegate: {
          onDisconnect: () => {
            this.wsDisconnectedCount += 1
            if (this.wsDisconnectedCount % 4 === 0) {
              handleError({
                error: new WSConnectError("Could not connect to 46Elks websocket"),
                customMessage: "elks.ws.error"
              })
            }
          },
          onInvite: invitation => {
            if (this.session && this.session.state !== SessionState.Terminated) {
              invitation.reject()
              return
            }
            invitation.accept()
            this.session = invitation

            let startTime: Date | undefined
            let endTime: Date | undefined

            this.session.stateChange.addListener(newState => {
              switch (newState) {
                case SessionState.Established:
                  if (this.session) {
                    const sessionDescriptionHandler = this.session.sessionDescriptionHandler
                    try {
                      if (
                        !sessionDescriptionHandler ||
                        !(sessionDescriptionHandler instanceof Web.SessionDescriptionHandler)
                      ) {
                        throw new Error("Invalid session description handler.")
                      }
                      if (audioElement) {
                        assignStream(sessionDescriptionHandler.remoteMediaStream, audioElement)
                      }
                    } catch (error: any) {
                      handleError({ error })
                    }
                  }
                  startTime = new Date()
                  break
                case SessionState.Terminated:
                  if (conversation && this.session) {
                    endTime = new Date()
                    const target = conversation.account?.phone === number ? "patient" : "unknown"
                    logSipCall(number, target, conversation.id, store.dispatch, startTime, endTime)
                  }
                  startTime = undefined
                  endTime = undefined
                  this.session = undefined
                  this.close()
                  break
              }
            })
          }
        }
      })

      this.userAgent.start().then(() => {
        const registerer = new Registerer(this.userAgent!)
        registerer.stateChange.addListener(newState => {
          switch (newState) {
            case RegistererState.Registered:
              resolve(account)
              break
            case RegistererState.Unregistered:
              reject(new Error("SIP connection failed"))
              break
            case RegistererState.Terminated:
              reject(new Error("SIP connection failed"))
              break
          }
        })
        registerer.register()
      })
    })
  }

  sendDigit(digit: string) {
    this.session?.sessionDescriptionHandler?.sendDtmf(digit)
    const tone = setDtmfAudio(digit)
    if (tone && this.dtmfAudioElement) {
      this.dtmfAudioElement.src = tone
      this.dtmfAudioElement.play()
    }
  }

  close() {
    if (this.userAgent) {
      this.userAgent.stop()
      this.userAgent = undefined
    }
    store.dispatch(setElkAccount(undefined))
    store.dispatch(setConversationId(undefined))
    releaseSipNumber(store.dispatch)
  }
}

const assignStream = (stream: MediaStream, element: HTMLMediaElement): void => {
  element.autoplay = true
  element.srcObject = stream

  element.play()
  stream.onaddtrack = (): void => {
    element.load()
    element.play()
  }
  stream.onremovetrack = (): void => {
    element.load()
    element.play()
  }
}

const handleError = (error: any) => {
  ;(store.dispatch as ReduxDispatch)(handleErrors(error))
}

export default ElkCall
