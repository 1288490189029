import humps from "humps"

import { AnyMessage } from "@doktor-se/bones-ui/dist/web-shared/types"

export const parseMessage = (message: any): AnyMessage => {
  return humps.camelizeKeys({
    ...message,
    data: JSON.parse(message.data)
  }) as unknown as AnyMessage
}
