import React, { useEffect, useState } from "react"
import { IntlProvider } from "react-intl"

import { LanguageCode } from "@doktor-se/bones-ui/dist/web-shared/types"
import { datadog } from "@doktor-se/more-rum-please"

import { appLanguages } from "config"
import { useAppSelector } from "lib/hooks"

export interface Translations {
  [key: string]: string
}

export const getSupportedLanguage = (languageCode: LanguageCode) =>
  appLanguages.find(lang => languageCode === lang.code)?.code || appLanguages[0]?.code || "en"

export const fetchLanguage = async (languageCode: LanguageCode) => {
  // Using import.meta.env.VITE_APP_PARTNER here eliminates bundling of unused partners localise files
  return import(
    /* webpackChunkName: 'l10n-' */ `../../localise/${import.meta.env.VITE_APP_PARTNER}/${languageCode}.json`
  )
}

interface LocalizationProps {
  children: React.ReactNode
}

const Localization = ({ children }: LocalizationProps) => {
  const [translations, setTranslations] = useState<Translations | "not-loaded">("not-loaded")

  const currentLanguage = useAppSelector(state => state.app.language)
  const supportedLanguage = getSupportedLanguage(currentLanguage)
  const locale = appLanguages.find(lang => currentLanguage === lang.code)?.locale || appLanguages[0]?.locale || "en-GB"

  useEffect(() => {
    fetchLanguage(supportedLanguage)
      .then(importedTranslations => {
        setTranslations(importedTranslations.default)
      })
      .catch((error: any) => {
        setTranslations({})
        datadog.addError(error)
      })
  }, [supportedLanguage])

  return (
    <IntlProvider
      locale={locale}
      messages={translations === "not-loaded" ? {} : translations}
      onError={error => {
        if (!(error.code === "MISSING_TRANSLATION" && error.message.includes("using id as fallback"))) {
          return console.error(error)
        }
      }}>
      {translations === "not-loaded" ? null : children}
    </IntlProvider>
  )
}

export default Localization
