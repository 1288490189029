import { useIntl } from "react-intl"

import { ToggleButton } from "@doktor-se/bones-ui/dist/web-shared/components"

import { useAppDispatch, useAppSelector } from "lib/hooks"
import { setIsReadyForAssignment } from "reducers/app"

import styles from "./AutoassignToggle.module.scss"

const AutoassignToggle = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const isReadyForAssignment = useAppSelector(state => state.app.isReadyForAssignment)

  return (
    <div className={styles.autoassignToggleContainer}>
      <ToggleButton
        label={intl.formatMessage({ id: isReadyForAssignment ? "label.online" : "label.offline" })}
        variant="autoassign"
        state={isReadyForAssignment}
        onClick={() => dispatch(setIsReadyForAssignment(!isReadyForAssignment))}
      />
    </div>
  )
}

export default AutoassignToggle
