import { useIntl } from "react-intl"

import PhrasesContainer from "@doktor-se/bones-ui/dist/web-shared/components/phrases/PhrasesContainer"

import { phrasesLanguages } from "config"
import { useAppSelector } from "lib/hooks"

import PageContainer from "components/PageContainer/PageContainer"

import styles from "./PhrasesPage.module.scss"

const PhrasesPage = () => {
  const phraseData = useAppSelector(state => state.phrases.phrases)
  const user = useAppSelector(state => state.auth.user)
  const intl = useIntl()

  if (!phrasesLanguages) return null

  return (
    <PageContainer>
      <div className={styles.phrasesContainer}>
        <PhrasesContainer
          variableOpts={{
            staffFirstName: user?.firstname,
            staffLastName: user?.lastname,
            staffDisplayName: user?.displayName,
            title: user?.title,
            staffGender: user?.data?.sex
          }}
          dataList={phraseData}
          isPage={true}
          emptySearchText={intl.formatMessage({ id: "phrases.search.empty" })}
          copyConfirmText={intl.formatMessage({ id: "copied.confirmation" })}
          phrasesLanguages={phrasesLanguages}
        />
      </div>
    </PageContainer>
  )
}

export default PhrasesPage
