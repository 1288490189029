import { useIntl } from "react-intl"

import { CopyToClipboard, StaffHeading4 } from "@doktor-se/bones-ui"
import { AppError } from "@doktor-se/bones-ui/dist/web-shared/classes"
import { AssignedConversation, Profile } from "@doktor-se/bones-ui/dist/web-shared/types"

import { featureFlags } from "config"

import SetProfile from "./SetProfile"

import styles from "./PatientName.module.scss"

interface PatientNameProps {
  displayName: string
  conversation: AssignedConversation
  prevConversationId?: string
  error?: AppError
  profile?: Profile
}

const PatientName = ({ conversation, prevConversationId, error, displayName, profile }: PatientNameProps) => {
  const intl = useIntl()

  if (featureFlags.has("add_profile")) {
    return (
      <SetProfile
        error={error}
        conversation={conversation}
        patientName={!!profile ? profile.name : displayName}
        prevConversationId={prevConversationId}
        icon={profile ? (profile.type === "child" ? "child" : profile.data.icon) : undefined}
        type={profile ? profile.type : undefined}
      />
    )
  }

  return (
    <div className={styles.row}>
      <StaffHeading4 margin={{ right: "20px" }} className={styles.name}>
        {displayName}
      </StaffHeading4>
      <CopyToClipboard copyText={displayName} confirmationText={intl.formatMessage({ id: "copied.confirmation" })} />
    </div>
  )
}

export default PatientName
