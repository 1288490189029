import React, { useCallback, useState } from "react"
import { useIntl } from "react-intl"

import { Button, Dialog, StaffHeading3 } from "@doktor-se/bones-ui"
import CalendarAdd from "@doktor-se/bones-ui/dist/components/icons/components/CalendarAdd"
import CheckCircleOutline from "@doktor-se/bones-ui/dist/components/icons/components/CheckCircleOutline"
import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import { createRevisit } from "api/revisits/revisits.api"
import { amplitudeTrack } from "lib/amplitude/amplitude"
import { categorySelectors } from "reducers/categories"

import { Offset } from "pages/dashboard/components/ConversationControls/ConversationControls"

import { Thunk, useAppDispatch, useAppSelector } from "../../lib/hooks"
import PeriodContainer, { CalculationResult } from "./PeriodContainer"

import styles from "./RevisitsDialog.module.scss"

export interface RevisitsDialogProps {
  isOpen: boolean
  conversation: AssignedConversation
  onClose: () => void
  offset?: Offset
}

const callCreateRevisit =
  (param: {
    revisitDate: string
    patientPhoneNumber: string
    conversationId: string
    smsAgreement: boolean
    firstName: string
  }): Thunk<Promise<boolean>> =>
  dispatch => {
    return dispatch(createRevisit(param))
  }
const RevisitsDialog = ({ isOpen, conversation, offset, onClose }: RevisitsDialogProps) => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const [calculationResult, setCalculationResult] = useState<CalculationResult>({
    value: "",
    formattedDate: "",
    smsAgreement: false
  })
  const [showConfirmation, setShowConfirmation] = useState(false)
  const onCloseDialog = () => {
    onClose()
  }
  const onValueChange = useCallback((calculationResult: CalculationResult) => {
    setCalculationResult(calculationResult)
  }, [])

  const category = useAppSelector(state => categorySelectors.selectById(state, conversation.categoryId || 0))
  const success = () => {
    amplitudeTrack({
      type: "carealot.book_followup.schedule_revisit_cta_pressed",
      eventProperties: {
        category_name: category?.name,
        category_id: conversation.categoryId,
        selected_timeframe: calculationResult.formattedDate
      }
    })
    setShowConfirmation(true)
    setTimeout(onClose, 3000)
  }
  const schedule = () => {
    dispatch(
      callCreateRevisit({
        conversationId: conversation.id,
        revisitDate: calculationResult.value,
        smsAgreement: calculationResult.smsAgreement,
        patientPhoneNumber: conversation.account?.phone || "",
        firstName: conversation.account?.firstname
      })
    ).then(result => (result ? success() : undefined))
  }
  return (
    <Dialog
      isOpen={isOpen}
      offset={offset && { top: `${offset.top}px`, left: `${offset.left}px` }}
      onClose={onCloseDialog}
      closeAriaLabel={intl.formatMessage({ id: "modal.close" })}
      fixedWidth>
      {showConfirmation ? (
        <div className={styles.confirmationContainer}>
          <CheckCircleOutline width={31} height={33} className={styles.checkIcon} />
          <StaffHeading3 className={styles.heading}>
            {intl.formatMessage({ id: "revisits.confirmation" })}
          </StaffHeading3>
        </div>
      ) : (
        <>
          <CalendarAdd width={31} height={33} />
          <StaffHeading3 className={styles.heading}>{intl.formatMessage({ id: "revisits.title" })}</StaffHeading3>
          {calculationResult.error ? (
            <div className={styles.validationError}>{intl.formatMessage({ id: "revisits.validation.error" })}</div>
          ) : (
            <div className={styles.calculatedDate}>{calculationResult.formattedDate}</div>
          )}

          <PeriodContainer onValueChange={onValueChange} />
          <Button
            color="primary"
            variant="primary"
            fullWidth
            type="submit"
            onPress={schedule}
            isDisabled={calculationResult.error}>
            {intl.formatMessage({ id: "revisits.button" })}
          </Button>
        </>
      )}
    </Dialog>
  )
}
export default RevisitsDialog
