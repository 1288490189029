import { useState } from "react"
import { useIntl } from "react-intl"

import { Button, Dialog, StaffHeading3 } from "@doktor-se/bones-ui"
import { Staff } from "@doktor-se/bones-ui/dist/web-shared/types"

import { claimForOther, reopenConversation } from "api"
import { conversationLimit, staffListForAssigning } from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { selectConversations } from "reducers/conversations/conversations.reducer"
import { SearchPageConversation } from "types"

import StaffSearch from "components/StaffSearch/StaffSearch"
import { Offset } from "pages/dashboard/components/ConversationControls/ConversationControls"
import WarningMessage from "pages/search/components/WarningMessage/WarningMessage"

import AssignToMyself from "../AssignToMyself/AssignToMyself"

import styles from "./ReopenDialog.module.scss"

export interface ReopenDialogProps {
  isOpen: boolean
  conversation: SearchPageConversation
  onClose: () => void
  offset?: Offset
  hasAssignToOtherStaff: boolean
}

const ReopenDialog = ({ isOpen, offset, conversation, onClose, hasAssignToOtherStaff }: ReopenDialogProps) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const authUser = useAppSelector(state => state.auth.user)
  const { staff, activeStaff } = useAppSelector(state => state.users)
  const user = staff.find(s => s.id === authUser?.id)
  const filteredStaffList = staffListForAssigning(activeStaff, user?.data?.clinic).filter(
    staff => staff.id !== user?.id
  )

  const { active } = useAppSelector(selectConversations)
  const defaultRole = useAppSelector(state => state.auth.defaultRole)
  const limitForRole = conversationLimit?.find(limit => limit.role === defaultRole)
  const isConversationsLimitReached = !!limitForRole && active.length >= limitForRole.amount

  const [dialogState, setDialogState] = useState<"search" | "confirm">("search")
  const [selectedStaff, setSelectedStaff] = useState<Staff>()

  const onSelectStaff = (selectedStaff: Staff) => {
    setSelectedStaff(activeStaff.find(staff => staff.id === selectedStaff.id))
    setDialogState("confirm")
  }

  const resetSearch = () => {
    setSelectedStaff(undefined)
    setDialogState("search")
  }

  const reopenConfirmed = (assignToId: string) => {
    if (!conversation.assignedStaffId) {
      dispatch(claimForOther(conversation, assignToId))
    } else {
      dispatch(reopenConversation(conversation.id, assignToId))
    }
    onClose()
    setDialogState("search")
  }

  const onCloseDialog = () => {
    setDialogState("search")
    onClose()
  }

  return (
    <Dialog
      isOpen={isOpen}
      offset={offset && { top: `${offset.top}px`, left: `${offset.left}px` }}
      onClose={onCloseDialog}
      closeAriaLabel={intl.formatMessage({ id: "modal.close" })}
      fixedWidth>
      <StaffHeading3 margin={{ bottom: "var(--size-200)" }}>
        {intl.formatMessage({ id: "search.reopen.title" })}
      </StaffHeading3>

      {dialogState === "search" && (
        <>
          {conversation.assignedStaffId === user?.id && isConversationsLimitReached && (
            <WarningMessage message={intl.formatMessage({ id: "conversation.maximum.limit" })} sectioned />
          )}
          {user && conversation.assignedStaffId !== user?.id && !isConversationsLimitReached && (
            <AssignToMyself user={user} onClick={() => onSelectStaff(user)} />
          )}
          {hasAssignToOtherStaff && <StaffSearch staffList={filteredStaffList} onSelectStaff={onSelectStaff} />}
        </>
      )}

      {dialogState === "confirm" && (
        <>
          <StaffHeading3 margin={{ bottom: "var(--size-200)" }}>
            {!selectedStaff?.online
              ? intl.formatMessage({ id: "control.assign.offline" }, { staff: selectedStaff?.displayName })
              : selectedStaff.displayName}
          </StaffHeading3>

          <div className={styles.buttonContainer}>
            <Button color="primary" variant="secondary" onPress={resetSearch} fullWidth>
              {intl.formatMessage({ id: "btn.cancel" })}
            </Button>

            <Button color="primary" variant="primary" onPress={() => reopenConfirmed(selectedStaff!.id)} fullWidth>
              {intl.formatMessage({ id: "label.reopen" })}
            </Button>
          </div>
        </>
      )}
    </Dialog>
  )
}

export default ReopenDialog
