import { css } from "@emotion/react"
import { AriaButtonProps, useButton } from "react-aria"

import styled from "@emotion/styled"

const ButtonStyle = styled.button<{ buttonSize?: string; isDisabled?: any }>`
  position: relative;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid var(--color-primary-50);
  ${props =>
    !props.disabled &&
    css`
      cursor: pointer;
    `}

  ${props =>
    props.buttonSize &&
    css`
      height: ${props.buttonSize};
      width: ${props.buttonSize};
    `}
`

export interface ActionButtonProps extends AriaButtonProps<"button"> {
  children: React.ReactNode
  buttonRef: React.RefObject<HTMLButtonElement>
  buttonSize?: number
}

const ActionButton = (props: ActionButtonProps): JSX.Element => {
  const { children, buttonRef, buttonSize } = props
  const { buttonProps } = useButton({ ...props }, buttonRef)
  return (
    <ButtonStyle {...buttonProps} ref={buttonRef} buttonSize={buttonSize ? `${buttonSize}px` : undefined}>
      {children}
    </ButtonStyle>
  )
}

export default ActionButton
