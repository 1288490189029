import classNames from "classnames"

import { ICONS } from "@doktor-se/bones-ui"

import styles from "./WarningMessage.module.scss"

interface WarningMessageProps {
  message: JSX.Element | string
  sectioned?: boolean
}

const WarningMessage = ({ message, sectioned }: WarningMessageProps) => {
  return (
    <div data-testid="warningMessage" className={classNames(styles.warning, { [styles.sectioned]: sectioned })}>
      <ICONS.MessageAlert className={styles.warningIcon} />
      <div className={styles.warningText}>{message}</div>
    </div>
  )
}

export default WarningMessage
