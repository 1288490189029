import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { ApiElkAccount } from "api"

export interface ElkState {
  account?: ApiElkAccount
  conversationId?: string
}
const initialState: ElkState = {
  account: undefined,
  conversationId: undefined
}

const elk = createSlice({
  name: "elk",
  initialState,
  reducers: {
    setElkAccount(state, action: PayloadAction<ApiElkAccount | undefined>) {
      state.account = action.payload
    },
    setConversationId(state, action: PayloadAction<string | undefined>) {
      state.conversationId = action.payload
    }
  }
})

export const { setElkAccount, setConversationId } = elk.actions

export type ElkSliceAction = ObjectFunctionReturnTypes<typeof elk.actions>

export default elk.reducer
