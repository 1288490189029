import { useContext } from "react"

import { TabsContext } from "./TabsContext"

import styles from "./TabContent.module.scss"

interface TabContentProps {
  children: JSX.Element
  id: string
}

const TabContent = ({ children, id }: TabContentProps) => {
  const tabContext = useContext(TabsContext)
  const style = id === tabContext.activeTab ? styles.active : styles.hidden
  return <div className={style}>{children}</div>
}
export default TabContent
