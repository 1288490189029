import { useEffect, useState } from "react"
import { useIntl } from "react-intl"

import { SearchInput } from "@doktor-se/bones-ui"
import { Staff } from "@doktor-se/bones-ui/dist/web-shared/types"

import UserRow from "components/UserRow/UserRow"

import styles from "./SearchStaff.module.scss"

interface SearchStaffProps {
  changeStaff: (s: Staff) => void
  staff: Staff[]
}

const SearchStaff = ({ changeStaff, staff }: SearchStaffProps) => {
  const intl = useIntl()
  const [filter, setFilter] = useState("")
  const [filteredStaff, setFilteredStaff] = useState<Array<Staff>>([])

  useEffect(
    () => setFilteredStaff(staff.filter(p => p.displayName.toLowerCase().includes(filter.toLowerCase()))),
    [staff, filter]
  )

  return (
    <div className={styles.container}>
      <div className={styles.searchInputWrapper}>
        <SearchInput
          aria-label={intl.formatMessage({ id: "input.search.staff.aria.label" })}
          variant="onSurface"
          value={filter}
          onChange={value => setFilter(value)}
          fullWidth
        />
      </div>

      {filteredStaff.map((staff, i) => (
        <div className={styles.userRow} key={i}>
          <UserRow user={staff} onClick={() => changeStaff(staff)} tabIndex={i} />
        </div>
      ))}
    </div>
  )
}

export default SearchStaff
