import React from "react"

const ButtonEditCategory = ({ ...props }: React.SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(.597 .273)">
        <circle cx={6.42} cy={6.42} r={6.42} fill="currentColor" />
        <path
          d="M9.414 4.727a.29.29 0 00-.016-.097 1.268 1.268 0 00-.766-.767.292.292 0 00-.097-.016h-.373l-.418.418 1.253 1.252.417-.417v-.373zm-2.071-.061L3.987 8.022l-.627 1.88 1.88-.627 3.356-3.356z"
          fill="#FFF"
        />
      </g>
    </svg>
  )
}

export default ButtonEditCategory
