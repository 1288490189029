import React from "react"

const IconSettingVideo = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={31} height={31} {...props}>
      <g fill="none" fillRule="evenodd" transform="translate(.516 .41)">
        <circle cx={15} cy={15} r={15} fill="var(--color-primary-50)" />
        <g fill="#FFF" transform="translate(8.3 10.163)">
          <rect width={8.958} height={7.879} x={0.32} y={0.816} rx={1.81} />
          <path d="M12.904 7.865l-2.202-2.388a.665.665 0 010-.902l2.202-2.388a.665.665 0 011.154.451v4.776a.665.665 0 01-1.154.45z" />
        </g>
      </g>
    </svg>
  )
}

export default IconSettingVideo
