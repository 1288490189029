import React from "react"

// TODO Icon does not follow new color palette

const IconMicrophoneInfo: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="66" height="63" viewBox="0 0 66 63" {...props}>
      <g fill="none" fillRule="evenodd">
        <g stroke="#4db4ab" strokeWidth="4.2">
          <path d="M19.14 2.886a8.205 8.205 0 0 0-8.205 8.205v21.308a8.205 8.205 0 0 0 8.205 8.205h1.05a8.205 8.205 0 0 0 8.204-8.205V11.09a8.205 8.205 0 0 0-8.205-8.205H19.14z" />
          <path
            strokeLinecap="round"
            d="M2.98 32.797c0 9.215 7.47 16.684 16.685 16.684 9.214 0 16.684-7.47 16.684-16.684M19.665 49.668v9.385M13.532 59.909h12.266"
          />
        </g>
        <path
          fill="#FFF"
          d="M47.72 58.668c10.047 0 18.191-8.144 18.191-18.191 0-10.047-8.144-18.191-18.19-18.191-10.048 0-18.192 8.144-18.192 18.19 0 3.599 1.044 6.953 2.847 9.776.738 1.156-.548 4.6.424 5.559.905.892 4.054-.688 5.129 0a18.106 18.106 0 0 0 9.791 2.857z"
        />
        <g transform="matrix(-1 0 0 1 62.158 26.04)">
          <path
            fill="#009487"
            d="M14.285 28.355c-7.799 0-14.12-6.322-14.12-14.12 0-7.8 6.321-14.121 14.12-14.121 7.798 0 14.12 6.322 14.12 14.12 0 2.908-.879 5.611-2.386 7.858-.649.966.599 4.268 0 4.268 0 0-3.701-.475-4.495 0a14.055 14.055 0 0 1-7.24 1.995z"
          />
          <path
            fill="#2b7a86"
            d="M14.285 28.355c-7.799 0-14.12-6.322-14.12-14.12 0-7.8 2.386 9.56 14.12 9.56 11.734 0 14.12-17.36 14.12-9.56 0 2.907-.879 5.61-2.386 7.857-.649.966.599 4.268 0 4.268 0 0-3.701-.475-4.495 0a14.055 14.055 0 0 1-7.24 1.995z"
          />
          <path
            stroke="#4A4A4A"
            strokeWidth="3.195"
            d="M14.285 28.355c-7.799 0-14.12-6.322-14.12-14.12 0-7.8 6.321-14.121 14.12-14.121 7.798 0 14.12 6.322 14.12 14.12 0 2.908-.879 5.611-2.386 7.858-.649.966.599 4.268 0 4.268 0 0-3.701-.475-4.495 0a14.055 14.055 0 0 1-7.24 1.995z"
          />
          <rect width="3.262" height="8.969" x="12.701" y="7.596" fill="#FFF" rx="1.631" />
          <circle cx="14.332" cy="20.026" r="1.782" fill="#FFF" />
        </g>
      </g>
    </svg>
  )
}

export default IconMicrophoneInfo
