import { ThunkDispatch } from "redux-thunk"

import { pollAuthToken as pollAuthTokenCore, renewAuthToken } from "@doktor-se/bones-ui/dist/web-shared/libs/auth/auth"
import {
  apiFetchWrapper,
  csvFetchDownloadWrapper,
  externalFetchWrapper
} from "@doktor-se/bones-ui/dist/web-shared/libs/fetch/fetchLib"
import { FetchRequestHeader } from "@doktor-se/bones-ui/dist/web-shared/types"

import { logout } from "api"
import { handleTokenExpiry } from "api/auth/handler"
import { adaptationApiUrl, appApiUrl, refreshTokenDefaultValidity } from "config"
import { Thunk } from "lib/hooks"
import { AppAction } from "reducers"
import { setAuthTokens } from "reducers/auth"
import { RootState } from "reducers/init"
import { States, updateLoader } from "reducers/loader"

const getRefreshAuthTokenUrl = () => `${appApiUrl}/v1/auth/refresh`

export interface ApiFetchParams {
  url: string
  incomingOptions?: { [key: string]: any }
  config?: ApiFetchConfig
}

export interface ApiFetchConfig {
  errorHandling?: "v2"
}

type ApiFetchOverloads = {
  <Result = any>(url: string, incomingOptions?: { [key: string]: any }): Thunk<Promise<Result>>
  <Result = any>(params: ApiFetchParams): Thunk<Promise<Result>>
}

export const apiFetch: ApiFetchOverloads = (
  urlOrParams: string | ApiFetchParams,
  incomingOptions?: { [key: string]: any }
) => {
  return resolveParamsAndDoInternalApiFetch(appApiUrl, urlOrParams, incomingOptions)
}

export const adaptationApiFetch = (params: ApiFetchParams) => {
  return resolveParamsAndDoInternalApiFetch(adaptationApiUrl, params)
}

const resolveParamsAndDoInternalApiFetch = (
  baseUrl: string,
  urlOrParams: string | ApiFetchParams,
  incomingOptions?: { [p: string]: any } | undefined
) => {
  if (typeof urlOrParams === "string") {
    return internalApiFetch(baseUrl, urlOrParams, incomingOptions)
  } else {
    const { url, incomingOptions, config } = urlOrParams
    return internalApiFetch(baseUrl, url, incomingOptions, config)
  }
}

const internalApiFetch =
  <Result = any>(
    baseUrl: string,
    url: string,
    incomingOptions?: { [key: string]: any },
    config?: ApiFetchConfig
  ): Thunk<Promise<Result>> =>
  async (dispatch, getState) => {
    const res = (await apiFetchWrapper({
      url: `${baseUrl}${url}`,
      refreshAuthTokenUrl: getRefreshAuthTokenUrl(),
      incomingOptions,
      refreshTokenDefaultValidity,
      getRequestHeader: getRequestHeaderCallback(getState),
      authorize: authorizeCallback(dispatch),
      logout: () => dispatch(logout()),
      updateLoader: (state: States, loader: any) => dispatch(updateLoader({ loader, state })),
      errorHandling: config?.errorHandling
    })) as unknown

    return res as Result
  }

export const externalFetch =
  (url: string, incomingOptions?: { [key: string]: any }): Thunk<Promise<any>> =>
  async dispatch => {
    const loaderCallback = (state: States, loader: any) => dispatch(updateLoader({ loader, state }))
    return externalFetchWrapper(url, loaderCallback, incomingOptions)
  }

const authorizeCallback =
  (dispatch: ThunkDispatch<RootState, any, AppAction>) =>
  async (refreshTokenResponse: any): Promise<Response> => {
    const responseJson = await refreshTokenResponse.json()
    const formattedResponse = {
      accessToken: responseJson.access_token,
      refreshToken: responseJson.refresh_token
    }

    await dispatch(setAuthTokens(formattedResponse))
    dispatch(handleTokenExpiry(formattedResponse.refreshToken))

    return refreshTokenResponse
  }

const getRequestHeaderCallback =
  (getState: () => RootState) =>
  (propertyName: FetchRequestHeader): string => {
    switch (propertyName) {
      case "accessToken":
        return getState().auth.accessToken as string
      case "refreshToken":
        return getState().auth.refreshToken as string
      case "language":
        return getState().app.language as string
      default:
        return ""
    }
  }

export const csvFetchDownload =
  <Result = any>(
    url: string,
    incomingOptions?: { [key: string]: any },
    filename = "download"
  ): Thunk<Promise<Result>> =>
  async (dispatch, getState) => {
    const res = (await csvFetchDownloadWrapper({
      url: `${appApiUrl}${url}`,
      refreshAuthTokenUrl: getRefreshAuthTokenUrl(),
      incomingOptions,
      filename,
      refreshTokenDefaultValidity,
      getRequestHeader: getRequestHeaderCallback(getState),
      authorize: authorizeCallback(dispatch),
      logout: () => () => dispatch(logout()),
      updateLoader: (state: States, loader: any) => dispatch(updateLoader({ loader, state }))
    })) as unknown

    return res as Result
  }

export const pollAuthToken =
  (interval = 30000): Thunk =>
  (dispatch, getState) => {
    if (!getState().auth.refreshToken && !getState().auth.accessToken) {
      return
    }

    pollAuthTokenCore({
      refreshTokenDefaultValidity,
      refreshAuthTokenUrl: getRefreshAuthTokenUrl(),
      getRequestHeader: getRequestHeaderCallback(getState),
      authorize: authorizeCallback(dispatch),
      interval
    })
  }

export const refreshAuthToken = (): Thunk => (dispatch, getState) => {
  if (!getState().auth.refreshToken && !getState().auth.accessToken) {
    return
  }

  return renewAuthToken({
    refreshTokenDefaultValidity,
    refreshToken: getState().auth.refreshToken || "",
    apiUrl: getRefreshAuthTokenUrl(),
    appLanguage: getState().app.language,
    authorize: authorizeCallback(dispatch)
  })
}

export default apiFetch
