import React, { useEffect, useMemo, useState } from "react"
import { useIntl } from "react-intl"

import { ICONS, StaffBodyTextM, StaffHeading4 } from "@doktor-se/bones-ui"
import { Category, CountryCode, LanguageCode, Profile, Staff } from "@doktor-se/bones-ui/dist/web-shared/types"

import { createConversation, createDraftPatient, patientProfilesFetch, searchPatients } from "api"
import { handleErrors } from "api/error/handler"
import {
  assignToBotEnabled,
  assignToOtherStaffEnabled,
  assignToSelfEnabled,
  featureFlags,
  staffListForAssigning
} from "config"
import { useAppDispatch, useAppSelector } from "lib/hooks"
import { closeError } from "reducers/error"
import { clearPatients } from "reducers/users/users.reducer"

import { SearchCategoriesWrapper, SearchPatients } from "components/searchList"
import UserRow from "components/UserRow/UserRow"

import CommentStep from "./CommentStep"
import ConfirmStep from "./ConfirmStep"
import ProfileStep from "./ProfileStep"
import SearchStaff from "./SearchStaff"

import styles from "./CreateConversation.module.scss"

export interface Selected {
  category?: Category
  profile?: Profile
  patient?: {
    id: string
    displayName?: string
    customerMetadata?: { conversationLanguage?: LanguageCode }
    region?: CountryCode // Needed for creating draft users, currently hardcoded to "se" for doktor.se use case
    searchedValue: string
  }
  staff?: Staff
  comment?: { data?: string }
}

interface CreateConversationProps {
  show: boolean
  hide: () => void
}

const CreateConversation = ({ show, hide }: CreateConversationProps) => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const defaultRole = useAppSelector(state => state.auth.defaultRole)
  const [selected, setSelected] = useState<Selected>({})
  const [confirmed, setConfirmed] = useState(false)
  const authUser = useAppSelector(state => state.auth.user)
  const { staff, activeStaff, patients } = useAppSelector(state => state.users)
  const user = activeStaff.find(s => s.id === authUser?.id)
  const filteredStaffList = staffListForAssigning(activeStaff, user?.data?.clinic)
  const error = useAppSelector(state => state.error.error)
  const patientStepPassed = !!selected.patient
  const profileStepPassed = featureFlags.has("create_conversation_with_profile") ? !!selected.profile : true
  const categoriesStepPassed = !!selected.category
  const staffStepPassed = !!selected.staff
  const commentStepPassed =
    featureFlags.has("create_draft_user") && selected.staff?.id !== user?.id ? !!selected.comment : true
  const currentUser = staff.find(s => s.id === user?.id) as Staff
  const botUser = staff.find(s => s.role === "bot") as Staff
  const isDraftPatient = selected.patient?.id === "draft" || !selected.patient?.displayName
  const isPremiumHealthCategory = !!selected.category?.metadata.conversationMetaInit?.premiumHealth

  /* todo: TypeSafe single state */
  const step = {
    search: !patientStepPassed,
    profile: patientStepPassed && !profileStepPassed,
    categories: patientStepPassed && profileStepPassed && !categoriesStepPassed,
    staff: patientStepPassed && profileStepPassed && categoriesStepPassed && !staffStepPassed,
    comment: patientStepPassed && profileStepPassed && categoriesStepPassed && staffStepPassed && !commentStepPassed,
    confirm: patientStepPassed && profileStepPassed && categoriesStepPassed && staffStepPassed && commentStepPassed
  }

  useEffect(() => {
    if (step.profile && !!selected.patient?.id) {
      dispatch(patientProfilesFetch(selected.patient.id))
    }
  }, [dispatch, selected.patient?.id, step.profile])

  const categoryOnClick = (category: Category) => {
    setSelected(s => ({ ...s, category }))
  }

  const title = useMemo(() => {
    if (step.search) return intl.formatMessage({ id: "search.new.title.patient" })
    if (step.profile) return intl.formatMessage({ id: "search.new.title.profile" })
    if (step.categories) return intl.formatMessage({ id: "search.new.title.category" })
    if (step.staff) return intl.formatMessage({ id: "search.new.title.assign" })
    if (step.comment) return intl.formatMessage({ id: "search.new.title.comment" })
    return intl.formatMessage({ id: "search.new.title" })
  }, [intl, step.categories, step.comment, step.profile, step.search, step.staff])

  const handleConfirmResponse = (success: boolean) => {
    if (success) {
      setTimeout(() => {
        setConfirmed(false)
        setSelected({})
        hide()
      }, 1000)
    } else {
      setConfirmed(false)
    }
  }

  const confirm = async () => {
    if (step.confirm) {
      try {
        setConfirmed(true)
        let patient = selected.patient
        if (patient?.id === "draft" && patient?.searchedValue) {
          patient = await dispatch(createDraftPatient(patient.searchedValue))
        }

        const response = await dispatch(
          createConversation({
            patientId: patient?.id,
            categoryId: selected.category?.id,
            staffId: selected.staff?.id,
            clinicId: user?.data?.clinic,
            comment: selected.comment?.data,
            templateId: selected.category?.templateId,
            profileId: selected.profile?.id,
            language: selected.patient?.customerMetadata?.conversationLanguage,
            ...(isPremiumHealthCategory && { premiumHealth: true })
          })
        )
        handleConfirmResponse(!!response)
      } catch (error: any) {
        dispatch(handleErrors({ error }))
      }
    }
  }

  if (!show) return null

  return (
    <div className={styles.container}>
      <div className={styles.conversationContainer}>
        <div className={styles.header}>
          <StaffHeading4 as="h2" margin={{ bottom: "5px" }}>
            {intl.formatMessage({ id: "btn.conversation.new" })}
          </StaffHeading4>
          <StaffBodyTextM>{title}</StaffBodyTextM>

          <button
            className={styles.iconClose}
            onClick={() => {
              setSelected({})
              hide()
            }}>
            <ICONS.Close />
          </button>
        </div>

        {step.search && (
          <div className={styles.wrapper}>
            <SearchPatients
              patients={patients}
              changePatient={patient => setSelected(s => ({ ...s, patient }))}
              searchPatients={params => {
                dispatch(searchPatients(params))
                dispatch(closeError(error))
              }}
              clearPatients={() => {
                dispatch(clearPatients())
                dispatch(closeError(error))
              }}
              error={error}
            />
          </div>
        )}

        {step.profile && selected.patient?.id && (
          <ProfileStep
            changeProfile={profile => setSelected(s => ({ ...s, profile }))}
            patientId={selected.patient.id}
          />
        )}

        {step.categories && (
          <div className={styles.wrapper}>
            <SearchCategoriesWrapper onSelectCategory={categoryOnClick} />
          </div>
        )}

        {step.staff && (
          <div className={styles.wrapper}>
            {assignToSelfEnabled(isDraftPatient) && (
              <div className={styles.userRow}>
                <UserRow user={currentUser} onClick={() => setSelected(s => ({ ...s, staff: currentUser }))} />
              </div>
            )}
            {assignToBotEnabled(defaultRole) && (
              <div className={styles.userRow}>
                <UserRow user={botUser} onClick={() => setSelected(s => ({ ...s, staff: botUser }))} />
              </div>
            )}
            {assignToOtherStaffEnabled(defaultRole, isDraftPatient) && (
              <SearchStaff
                staff={filteredStaffList.filter(s => s.id !== user?.id)}
                changeStaff={(set: Staff) => setSelected(s => ({ ...s, staff: set }))}
              />
            )}
          </div>
        )}

        {step.comment && (
          <CommentStep
            showFlow={selected.staff?.role === "bot"}
            category={selected.category!}
            setCommentData={comment => setSelected(s => ({ ...s, comment }))}
          />
        )}

        {step.confirm && (
          <ConfirmStep
            hide={() => {
              hide()
              setSelected({})
            }}
            confirm={confirm}
            loader={false}
            confirmed={confirmed}
            selected={selected}
            setSelected={(exp: React.SetStateAction<Selected>) => setSelected(exp)}
          />
        )}
      </div>
    </div>
  )
}

export default CreateConversation
