import { useRef } from "react"
import { AriaPopoverProps, useOverlayTrigger } from "react-aria"
import { OverlayTriggerProps, useOverlayTriggerState } from "react-stately"

import dayjs from "dayjs"
import download from "downloadjs"

import { ICONS } from "@doktor-se/bones-ui"
import { Account } from "@doktor-se/bones-ui/dist/web-shared/types"

import { fetchSignedUrl } from "../../../../../../../../api"
import { useAppDispatch } from "../../../../../../../../lib/hooks"
import ActionButton from "../ActionButton"

export interface DownloadImagesButtonProps extends OverlayTriggerProps {
  conversationId: string
  imagesKeys: (undefined | string)[] | undefined
  account: Account
  buttonSize?: number
  // Popover position props
  placement?: AriaPopoverProps["placement"]
  offset?: AriaPopoverProps["offset"]
  crossOffset?: AriaPopoverProps["crossOffset"]
}

interface ImageData {
  url: string
}

export const downloadFile = (
  url: string,
  index: number,
  profile?: {
    pnr?: string
    displayName: string
  }
) => {
  const x = new XMLHttpRequest()
  let filename: string | undefined
  let mimeType: string | undefined
  const fileExtension = url?.split(/[#?]/)[0].split(".").pop()?.trim() || url

  const date = dayjs().format("YYYY-MM-DD_HH.mm.ss")
  const name = profile?.displayName.replace(" ", "_") || ""
  filename = `${name}_${date}_${index}.${fileExtension}`
  mimeType = `image/${fileExtension}`

  x.open("GET", url, true)
  x.responseType = "blob"
  x.onload = (e: any) => {
    download(e.target.response, filename, mimeType || `image/${fileExtension}`)
  }
  x.send()
}

const DownloadImagesButton = (props: DownloadImagesButtonProps): JSX.Element => {
  const { buttonSize, conversationId, imagesKeys, account } = props
  const dispatch = useAppDispatch()
  const buttonRef = useRef<HTMLButtonElement>(null)

  const state = useOverlayTriggerState(props)
  const { triggerProps } = useOverlayTrigger({ type: "dialog" }, state, buttonRef)
  const onClickDownload = async () => {
    const imagePromises = imagesKeys?.map(async key => {
      return { url: await dispatch(fetchSignedUrl(key!, conversationId)) } as ImageData
    })
    const imageDataList: ImageData[] = imagePromises ? await Promise.all(imagePromises) : []
    imageDataList.map((imageData, index) => downloadFile(imageData.url, index, account))
  }

  const iconSize = buttonSize ? buttonSize * 0.5 : undefined
  return (
    <>
      <ActionButton buttonSize={buttonSize} buttonRef={buttonRef} {...triggerProps} onPress={onClickDownload}>
        <ICONS.Download height={iconSize} width={iconSize} />
      </ActionButton>
    </>
  )
}

export default DownloadImagesButton
