import { useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"

import classnames from "classnames"

import { ICONS, StaffBodyTextM } from "@doktor-se/bones-ui"
import { AssignedConversation } from "@doktor-se/bones-ui/dist/web-shared/types"

import styles from "./Comment.module.scss"

interface CommentProps {
  comment?: string
  updatePatientComment: (patientId: string, comment: string) => void
  conversation: AssignedConversation
  prevConversationId?: string
}

const Comment = ({ comment, updatePatientComment, conversation, prevConversationId }: CommentProps) => {
  const intl = useIntl()

  const [editComment, setEditComment] = useState(false)
  const commentRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (prevConversationId !== conversation.id) {
      setEditComment(false)
    }
  }, [conversation.id, prevConversationId])

  const saveComment = () => {
    if (commentRef.current && conversation.account) {
      updatePatientComment(conversation.account.id, commentRef.current.value.trim())
    }
    setEditComment(!editComment)
  }

  return (
    <div className={styles.container}>
      <StaffBodyTextM className={styles.heading} margin={{ bottom: "4px" }}>
        {intl.formatMessage({ id: "patient.info.comment" })}
      </StaffBodyTextM>

      {editComment && (
        <button className={classnames(styles.button, styles.saveButton)}>
          <ICONS.CheckCircleFilled onClick={saveComment} height={34} width={34} />
        </button>
      )}
      {!editComment && (
        <button className={classnames(styles.button, styles.editButton)}>
          <ICONS.EditPen onClick={() => setEditComment(!editComment)} height={20} width={20} />
        </button>
      )}

      {editComment ? (
        <textarea rows={5} className={styles.textarea} ref={commentRef} defaultValue={comment} />
      ) : (
        <button className={styles.text} onClick={() => setEditComment(!editComment)}>
          <StaffBodyTextM as="span">{comment || "–"}</StaffBodyTextM>
        </button>
      )}
    </div>
  )
}

export default Comment
