import { useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"

import classNames from "classnames"

import { Button, ICONS, StaffBodyTextXL } from "@doktor-se/bones-ui"
import { useInputHeight } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { AssignedConversation, CountryCode } from "@doktor-se/bones-ui/dist/web-shared/types"

import { checkPermissions, claimSip, closeSipCall, sendSipDigit } from "api"
import { useAppDispatch, useAppSelector } from "lib/hooks"

import ReportBug from "components/topmenu/ReportBug/ReportBug"

import styles from "./DialNumber.module.scss"

export interface DialNumberProps {
  endCall: () => void
  conversation?: AssignedConversation
  countryCode?: CountryCode
}

const numbers = [
  ["1", "2", "3"],
  ["4", "5", "6"],
  ["7", "8", "9"],
  ["*", "0", "#"]
]

const approvedCharacters = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "#", "*", "+", "-"]

const DialNumber = ({ endCall, conversation, countryCode }: DialNumberProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const intl = useIntl()

  const ringtoneVolume = useAppSelector(state => state.app.ringtoneVolume)
  const account = useAppSelector(state => state.elk.account)

  const dtmfAudio = useRef<HTMLAudioElement>(null)
  const audio = useRef<HTMLAudioElement>(null)
  const numberInput = useRef<HTMLTextAreaElement>(null)

  const [phoneNumber, setPhoneNumber] = useState("")

  useInputHeight(numberInput, phoneNumber)

  useEffect(() => () => closeSipCall(), [])

  useEffect(() => {
    if (dtmfAudio && dtmfAudio.current) dtmfAudio.current.volume = ringtoneVolume
  }, [ringtoneVolume])

  const addNumber = (number: string) => {
    setPhoneNumber(phoneNumber + number)
  }

  const addPatientNumber = () => {
    if (!conversation) return
    setPhoneNumber((conversation.account && conversation.account.phone) || "")
  }

  const removeNumber = () => {
    setPhoneNumber(phoneNumber.slice(0, -1))
  }

  const addNumberKey = (evt: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (approvedCharacters.includes(evt.key)) {
      if (account) {
        sendSipDigit(evt.key)
      } else {
        addNumber(evt.key)
      }
    }
    if (!account && evt.key === "Backspace") removeNumber()
  }

  const paste = (evt: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const text = evt.clipboardData.getData("Text").trim().split(" ").join("")
    const textArray = [...text]
    let approved = true
    textArray.forEach(c => {
      if (!approvedCharacters.includes(c)) approved = false
    })
    if (approved) setPhoneNumber(text)
  }

  const startCall = () => {
    dispatch(
      checkPermissions(() =>
        dispatch(
          claimSip(
            phoneNumber,
            audio.current!,
            dtmfAudio.current!,
            conversation,
            countryCode || (conversation?.metadata?.region as CountryCode)
          )
        )
      )
    )
  }

  const numberClick = (n: string) => {
    if (account) sendSipDigit(n)
    if (!account) addNumber(n)
  }

  return (
    <div className={classNames(styles.container, { [styles.active]: account })}>
      <div className={styles.header}>
        {!!conversation?.account && phoneNumber === conversation?.account.phone && (
          <StaffBodyTextXL>{conversation.account.displayName}</StaffBodyTextXL>
        )}
        <textarea
          className={styles.numberInput}
          onPaste={evt => paste(evt)}
          onChange={() => {}}
          onKeyDown={evt => addNumberKey(evt)}
          value={phoneNumber}
          ref={numberInput}
        />
        {!!conversation && !account && (
          <ICONS.Close className={styles.closeWindow} onClick={endCall} onKeyPress={endCall} />
        )}
      </div>
      {!!addPatientNumber && !!conversation?.account?.phone && phoneNumber !== conversation.account.phone && (
        <div className={styles.callPatientButtonWrapper}>
          <Button variant="outline" color="primary" onPress={addPatientNumber}>
            {intl.formatMessage({ id: "control.call.patient" })}
          </Button>
        </div>
      )}

      <div data-testid="dialPad" className={styles.dialPad}>
        {numbers.map((r, index) => (
          <div key={index} className={styles.row}>
            {r.map(n => (
              <button className={styles.number} onClick={() => numberClick(n)} tabIndex={0} key={n}>
                {n}
              </button>
            ))}
          </div>
        ))}
      </div>

      <audio id="sipAudio" ref={audio}>
        <track kind="captions" />
      </audio>
      <audio id="dtmfAudio" ref={dtmfAudio}>
        <track kind="captions" />
      </audio>
      <div className={styles.footer}>
        {account ? (
          <button className={classNames(styles.callButton, styles.callEnd)} onClick={endCall}>
            <ICONS.CallEnd />
          </button>
        ) : (
          <button className={classNames(styles.callButton, styles.callStart)} onClick={startCall}>
            <ICONS.Call />
          </button>
        )}

        {!account && (
          <button className={styles.backButton} onClick={removeNumber}>
            <div className={styles.leftArrow} />
            <ICONS.Close width={10} height={10} className={styles.crossIcon} />
          </button>
        )}
      </div>
      <div className={styles.flagContainer}>
        <ReportBug customOffset={{ top: 0, left: 0 }} buttonType="pill" />
      </div>
    </div>
  )
}

export default DialNumber
