import SealdSDKWeb, { SealdSDK } from "@seald-io/sdk/browser/seald-sdk.polyfilled"
import dayjs from "dayjs"

import {
  AnyFilteredMessage,
  AssignedConversation,
  MessageCustomerMetadata,
  User
} from "@doktor-se/bones-ui/dist/web-shared/types"

import { fetchSignedUrl, logout } from "api"
import { fetchDatabaseRawKey, fetchSignupJWT, saveSealdID } from "api/encryption/encryption.api"
import { handleErrorsV2 } from "api/error/handler"
import {
  setBrowserSwitch,
  setSealdRevoked,
  setSealdSDKInitialisationStatus,
  setUserSealdId
} from "reducers/encryption/encryption.reducer"

import { CarealotUser } from "../types"
import apiFetch from "./fetch"
import { ReduxDispatch, Thunk } from "./hooks"

const enhanceErrorObject = (error: any, errorCode: string) => ({ ...error, details: { code: errorCode } })

let sealdSDKInstance: SealdSDK | null = null

export type CheckResult = "stored" | "nothingToStore" | "error"
export const sealdInit =
  (userId: string): Thunk =>
  async dispatch => {
    try {
      const appId = import.meta.env.VITE_APP_SEALD_APP_ID || ""
      const apiUrl = import.meta.env.VITE_APP_SEALD_API_URL || ""
      const databasePath = `encrypted-db-${userId}`
      const databaseRawKey: string | undefined = await dispatch(fetchDatabaseRawKey())

      sealdSDKInstance = SealdSDKWeb({
        appId: appId,
        apiURL: apiUrl,
        databaseRawKey: databaseRawKey,
        databasePath: databasePath,
        encryptionSessionCacheTTL: 30 * 60 * 1000
      })

      await sealdSDKInstance.initialize()
      dispatch(setSealdSDKInitialisationStatus({ success: true }))
    } catch (error: any) {
      if (error.status === 403 && error.code === "KEY_REVOKED") {
        // this happens when user switched device, but seald tried to be initialized on old PC/browser
        // we want to go further to handleUserRegistration to show deviceSwitch dialog
        dispatch(setSealdSDKInitialisationStatus({ success: true }))
      } else {
        const errorCode = "error.encryption.init.failed"
        dispatch(setSealdSDKInitialisationStatus({ success: false }))
        dispatch(handleErrorsV2({ error: enhanceErrorObject(error, errorCode), customMessageLokaliseKey: errorCode }))
      }
    }
  }

export const getSealdSDKInstance = () => sealdSDKInstance
export const staffIsOnConsentGivenList = (user: CarealotUser | undefined, conversation: AssignedConversation) => {
  return (
    user?.id &&
    Array.isArray(conversation.customerMetadata?.encryption?.consentHistory) && //TODO remove this check if all consentHistory is an Array
    conversation.customerMetadata?.encryption?.consentHistory?.some(
      item =>
        item.doctorId === user.id && item.sealdSessionId === conversation.customerMetadata?.encryption?.currentSessionId
    )
  )
}

export const dropDatabaseAndLogout = (): Thunk => async dispatch => {
  await sealdSDKInstance?.close()
  await sealdSDKInstance?.dropDatabase()
  dispatch(logout())
  dispatch(setSealdRevoked(false))
}

export const registerNewUser =
  (userId: string, alreadyRegistered: boolean): Thunk =>
  async dispatch => {
    if (alreadyRegistered) {
      await sealdSDKInstance?.close()
      await sealdSDKInstance?.dropDatabase()
      await sealdSDKInstance?.initialize()
    }
    const signupJWT = await dispatch(fetchSignupJWT())
    const sealdIdentity = await sealdSDKInstance?.initiateIdentity({ signupJWT: signupJWT, displayName: userId })
    dispatch(saveSealdID(sealdIdentity?.sealdId))
    dispatch(setUserSealdId({ sealdId: sealdIdentity?.sealdId }))
    dispatch(
      setBrowserSwitch({
        browserSwitch: false,
        alreadyRegistered: true
      })
    )
  }
export const handleSealdUserRegistration = (): Thunk => async dispatch => {
  try {
    const registrationStatus = await sealdSDKInstance?.registrationStatus()
    const user = await dispatch(apiFetch<User>("/account"))
    if (registrationStatus === "no-account") {
      if (user.customerMetadata?.encryption?.sealdId) {
        dispatch(
          setBrowserSwitch({
            browserSwitch: true,
            alreadyRegistered: false
          })
        )
      } else {
        user.id && dispatch(registerNewUser(user.id, false))
      }
    } else if (registrationStatus === "registered") {
      const accountInfo = await sealdSDKInstance?.getCurrentAccountInfo()
      if (user.customerMetadata?.encryption?.sealdId !== accountInfo?.sealdId) {
        dispatch(
          setBrowserSwitch({
            browserSwitch: true,
            alreadyRegistered: true
          })
        )
      } else {
        if (accountInfo?.deviceExpires) {
          const monthsDiff = Math.abs(dayjs().diff(accountInfo.deviceExpires, "month"))
          if (monthsDiff <= 24) {
            await sealdSDKInstance?.renewKey()
          }
        }
        dispatch(setUserSealdId({ sealdId: accountInfo?.sealdId }))
      }
    }
  } catch (error: any) {
    const errorCode = "error.encryption.init.failed"
    dispatch(handleErrorsV2({ error: enhanceErrorObject(error, errorCode), customMessageLokaliseKey: errorCode }))
  }
}

export const hasAccessToEncryptedSession = async (sessionId: string) => {
  const seald = getSealdSDKInstance()
  try {
    await seald?.retrieveEncryptionSession({ sessionId })
    return true
  } catch {
    return false
  }
}

const SEALD_SESSION_STORAGE_KEY = "SEALD_CONV_SESSION_MAP"

export const checkOrStoreSessionInLocalStorage = (
  conversation: AssignedConversation,
  user: CarealotUser
): CheckResult => {
  const existingSessionMap = localStorage.getItem(SEALD_SESSION_STORAGE_KEY)
  const encryptionMetadata = conversation.customerMetadata?.encryption!
  const sessionConsent = encryptionMetadata.consentHistory.filter(item => item.doctorId === user.id)
  // if no existing key in local_storage we create one and return true
  if (!existingSessionMap) {
    if (!!sessionConsent && sessionConsent.length === 1) {
      localStorage.setItem(
        SEALD_SESSION_STORAGE_KEY,
        JSON.stringify({ [conversation.id]: sessionConsent[0].sealdSessionId })
      )
      return "stored"
    } else {
      return "nothingToStore"
    }
  } else {
    // else we retrieve the existing values of conv <-> session map
    const storageValue = JSON.parse(existingSessionMap)
    const storedSessionId = storageValue[conversation.id]
    // we compare session from storage if it exists
    if (storedSessionId && storedSessionId !== sessionConsent[0]?.sealdSessionId) {
      return "error"
    } else {
      // if it doesnt exist we store it
      if (!!sessionConsent && sessionConsent.length === 1) {
        storageValue[conversation.id] = sessionConsent[0].sealdSessionId
        localStorage.setItem(SEALD_SESSION_STORAGE_KEY, JSON.stringify(storageValue))
        return "stored"
      } else {
        return "nothingToStore"
      }
    }
  }
}

export const removeUnassignedConversationsFromLocalStorage = (conversations: AssignedConversation[]) => {
  const existingSessionMap = localStorage.getItem(SEALD_SESSION_STORAGE_KEY)
  if (!existingSessionMap) return
  const storageValue = JSON.parse(existingSessionMap)
  for (let convId of Object.keys(storageValue)) {
    if (!conversations.find(c => c.id === convId)) {
      delete storageValue[convId]
    }
  }
  localStorage.setItem(SEALD_SESSION_STORAGE_KEY, JSON.stringify(storageValue))
}

export const encryptMessage = async (sealdSessionId: string, message: string) => {
  const seald = getSealdSDKInstance()
  try {
    const session = await seald?.retrieveEncryptionSession({ sessionId: sealdSessionId })
    return await session?.encryptMessage(message)
  } catch (error: any) {
    const errorCode = "error.encryption.encrypt.failed"
    throw enhanceErrorObject(error, errorCode)
  }
}

const readFile = (url: string, type: "image/jpeg" | "application/pdf"): Promise<Blob> =>
  new Promise(async (resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      const result = reader.result as ArrayBuffer
      const blob = new Blob([result], { type })
      resolve(blob)
    }
    reader.onerror = reject
    const fileContent = await fetch(url)
    const blob = await fileContent.blob()
    reader.readAsArrayBuffer(blob)
  })

const retrieveEncryptedSessionFromMessage = async (message: AnyFilteredMessage) => {
  const seald = getSealdSDKInstance()
  const metadata: MessageCustomerMetadata =
    typeof message.customerMetadata === "string" ? JSON.parse(message.customerMetadata) : message.customerMetadata
  const messageSessionId = metadata?.sealdSessionId
  return await seald?.retrieveEncryptionSession({ sessionId: messageSessionId })
}

export const decryptMessages = async (
  messages: AnyFilteredMessage[],
  conversationId: string,
  dispatch: ReduxDispatch
) => {
  try {
    return await Promise.all(
      messages.map(async m => {
        switch (m.type) {
          case "chat":
            const text = m.data.text
            if (text?.includes("sessionId")) {
              const session = await retrieveEncryptedSessionFromMessage(m)
              return { ...m, data: { ...m.data, text: await session!.decryptMessage(text) } }
            }
            return m
          case "image": {
            const fileUrl = await dispatch(fetchSignedUrl(m.data.key!, conversationId))
            const result = await readFile(fileUrl!, "image/jpeg")
            let decrypted
            try {
              const session = await retrieveEncryptedSessionFromMessage(m)
              decrypted = await session!.decryptFile(result)
            } catch (err) {
              return m
            }
            return {
              ...m,
              data: {
                ...m.data,
                blob: decrypted.data
              }
            }
          }
          case "attachment": {
            const fileUrl = await dispatch(fetchSignedUrl(m.data.key!, conversationId))
            const result = await readFile(fileUrl!, "application/pdf")
            let decrypted
            try {
              const session = await retrieveEncryptedSessionFromMessage(m)
              decrypted = await session!.decryptFile(result)
            } catch (err) {
              return m
            }
            const blob = new Blob([decrypted.data], { type: m.data.mimeType })
            return {
              ...m,
              data: {
                ...m.data,
                blob
              }
            }
          }
          default:
            return m
        }
      })
    )
  } catch (error: any) {
    console.error(error)
    const errorCode = "error.encryption.decrypt.failed"
    dispatch(handleErrorsV2({ error: enhanceErrorObject(error, errorCode), customMessageLokaliseKey: errorCode }))
  }
}

export const encryptFile = async (sealdSessionId: string, file: File) => {
  const seald = getSealdSDKInstance()
  try {
    const session = await seald?.retrieveEncryptionSession({ sessionId: sealdSessionId })
    return await session?.encryptFile(file, file.name)
  } catch (error: any) {
    const errorCode = "error.encryption.encrypt.failed"
    throw enhanceErrorObject(error, errorCode)
  }
}
