import React from "react"

const IconCloseConversation: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="currentColor" fillRule="nonzero">
        <path d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zm0 1.905a8.095 8.095 0 1 1 0 16.19 8.095 8.095 0 0 1 0-16.19z" />
        <path d="M5.387 9.46a.714.714 0 0 1 .937-.057l.073.065 2.507 2.549 4.56-4.81a.714.714 0 0 1 .936-.09l.074.062c.263.25.295.65.09.935l-.063.075-5.578 5.885-3.544-3.604a.714.714 0 0 1 .008-1.01z" />
      </g>
    </svg>
  )
}

export default IconCloseConversation
