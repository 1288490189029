import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"

import { ICONS, StaffBodyTextM, StaffBodyTextS } from "@doktor-se/bones-ui"
import { useTimeToNow } from "@doktor-se/bones-ui/dist/web-shared/hooks"
import { Category } from "@doktor-se/bones-ui/dist/web-shared/types"

import { claimConversation } from "api"
import { featureFlags } from "config"
import { useAppDispatch } from "lib/hooks"
import { QueueConversation } from "types"

import CountryFlag from "components/CountryFlag/CountryFlag"

import styles from "./ConversationInQueue.module.scss"

interface ConversationInQueueProps {
  conversation?: QueueConversation
  category?: Category
}

const ConversationInQueue = ({ conversation, category }: ConversationInQueueProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const intl = useIntl()
  const time = useTimeToNow(conversation && conversation?.queuePriority)

  if (!conversation) return null

  return (
    <button
      key={conversation.id}
      onClick={() => dispatch(claimConversation(conversation, navigate))}
      className={styles.grabConversation}>
      <div className={styles.grabButtonContainer}>
        <ICONS.AddCircle height={36} width={36} />

        {featureFlags.has("region") && conversation.metadata?.region && (
          <CountryFlag className={styles.regionFlag} countryOrLanguageCode={conversation.metadata.region} />
        )}
      </div>

      <div className={styles.textWrapper}>
        {category && (
          <>
            <StaffBodyTextM
              as="p"
              margin={{ right: "4px" }}
              style={{ textAlign: category.name.length > 15 ? "center" : "right" }}>
              {category.name}
            </StaffBodyTextM>
            <StaffBodyTextM className={styles.text}>&#8226;</StaffBodyTextM>
          </>
        )}
        <StaffBodyTextM className={styles.text} margin={{ left: "4px" }} style={{ justifyContent: "start" }}>
          {time.date ? intl.formatDate(time.date, { day: "numeric", month: "short" }) : time.timeLeft}
        </StaffBodyTextM>
      </div>
      {conversation.account?.displayName && (
        <div className={styles.text}>
          <StaffBodyTextS>{conversation.account.displayName}</StaffBodyTextS>
        </div>
      )}
    </button>
  )
}

export default ConversationInQueue
