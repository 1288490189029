import { useIntl } from "react-intl"

import { IconButton } from "@doktor-se/bones-ui/dist/web-shared/components"
import { Staff } from "@doktor-se/bones-ui/dist/web-shared/types"

import { SearchPageConversation } from "types"

import CloseDialog from "components/CloseDialog/CloseDialog"
import ReassignDialog from "components/ReassignDialog/ReassignDialog"
import ReopenDialog from "components/ReopenDialog/ReopenDialog"
import ToQueueDialog from "components/ToQueueDialog/ToQueueDialog"
import { IconCloseConversation, IconReassign, IconReopenActive, IconToQueue } from "icons"

import styles from "./ConversationControls.module.scss"

export interface ConversationControlsProps {
  conversation: SearchPageConversation
  reopen: boolean
  showReopen: () => void
  closeControl: () => void
  reopenConversation: (staffId: Staff["id"]) => void
  showClose: () => void
  close: boolean
  showToQueue: () => void
  toQueue: boolean
  showReassign: () => void
  reassign: boolean
  features: {
    hasAssignToOtherStaff: boolean
    hasAssignToQueue: boolean
    hasReopen: boolean
  }
}

const ConversationControls = ({
  conversation,
  reopen,
  showReopen,
  closeControl,
  showClose,
  close,
  showToQueue,
  toQueue,
  showReassign,
  reassign,
  features
}: ConversationControlsProps) => {
  const intl = useIntl()
  const { hasAssignToOtherStaff, hasAssignToQueue, hasReopen } = features

  return (
    <div className={styles.container} data-testid="controls">
      {conversation.state === "opened" && (
        <>
          {hasAssignToOtherStaff && (
            <IconButton
              onClick={showReassign}
              Icon={IconReassign}
              label={intl.formatMessage({ id: "label.reassign" })}
            />
          )}

          {conversation.assignedStaffId && (
            <>
              {hasAssignToQueue && (
                <IconButton
                  onClick={showToQueue}
                  Icon={IconToQueue}
                  label={intl.formatMessage({ id: "label.to.queue" })}
                />
              )}

              <IconButton
                onClick={showClose}
                Icon={IconCloseConversation}
                label={intl.formatMessage({ id: "label.close" })}
              />
            </>
          )}
        </>
      )}

      {conversation.state === "closed" && hasReopen && (
        <IconButton onClick={showReopen} Icon={IconReopenActive} label={intl.formatMessage({ id: "label.reopen" })} />
      )}

      {reopen && (
        <ReopenDialog
          isOpen={reopen}
          conversation={conversation}
          onClose={closeControl}
          hasAssignToOtherStaff={hasAssignToOtherStaff}
        />
      )}

      {reassign && <ReassignDialog isOpen={reassign} onClose={closeControl} conversation={conversation} />}

      {close && <CloseDialog isOpen={close} onClose={closeControl} conversation={conversation} />}

      {toQueue && <ToQueueDialog isOpen={toQueue} conversation={conversation} onClose={closeControl} />}
    </div>
  )
}

export default ConversationControls
